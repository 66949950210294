import React from 'react';
import {Typography} from '@material-ui/core';
import Button from '@material-ui/core/Button/Button';
import { ReactComponent as ContentIcon } from '../assets/icons/content.svg';


const EmptyStateMessage = ({
  title,
  callToAction,
  onClick,
} : {
  title: string,
  callToAction: string,
  onClick: (event: React.MouseEvent) => void
}) =>
  <div style={{
    marginTop: 82,
    display: 'flex',
    flexDirection: 'column',
    textAlign: 'center',
    justifyContent: 'center'
  }}>
    <ContentIcon style={{ fill: '#fff', height: 32, width: 32, alignSelf: 'center' }} />
    <Typography variant='h5' component='span'>
      {title}
    </Typography>
    <Button
      color='primary'
      size='large'
      onClick={onClick}
    >
      {callToAction}
    </Button>
  </div>;

export default EmptyStateMessage;
