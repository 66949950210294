import React, {useCallback, useState} from "react";
import {Box, Button, createStyles, Dialog, Theme, Typography} from "@material-ui/core";
import RichTextEditor from "../../../components/RichTextEditor";
import {updateUserOrInvitationProfileData} from "../../../api/usersApi";
import {useLocalizationProvider} from "../../../providers/LocalizationProvider";
import {makeStyles} from "@material-ui/core/styles";
import { UserInvitationWithCrudInstructions } from "../../../api/UserWithCRUDInstructions";
import LoadingSpinner from "../../../components/LoadingSpinner";
import User from "myfitworld-model/dist/firestoreCollectionTypes/User";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    dialog: {
      minWidth: 500,
      margin: theme.spacing(2)
    },
    marginBottom: {
      marginBottom: theme.spacing(2)
    },
    controlButton: {
      width: 150,
      marginRight: theme.spacing(1)
    }
  })
);

const NutritionalPlanDialog= ({
  user, loadUser, isOpen, onClose, updatePath
} : Props) => {
  const {translate} = useLocalizationProvider();
  const classes = useStyles();

  const [loading, setLoading] = useState(false);
  const [isChanged, setIsChanged] = useState(false);
  const [nutritionalInfo, setNutritionalInfo] = useState(user?.nutritionalInfo);

  const updateUserNutritionalInfo = useCallback(async () => {
    setLoading(true);
    if (user && user.id) {
      const userData =  { ...user, nutritionalInfo: nutritionalInfo };

      if (updatePath === 'users') {
        await updateUserOrInvitationProfileData({
          userId: user.id,
          userData: userData as unknown as User,
          updatePath: updatePath,
        });
      } else if (updatePath === 'invitations') {
        await updateUserOrInvitationProfileData({
          userId: user.id,
          userData: userData as unknown as UserInvitationWithCrudInstructions,
          updatePath: updatePath,
        });
      }
    }
    loadUser && await loadUser();
    setLoading(false);
    setIsChanged(false);
    onClose();
  }, [user, nutritionalInfo, onClose, loadUser, updatePath]);

  return (
    <Dialog
      open={isOpen}
      onClose={onClose}
    >
      <Box display="flex" flex={1} flexDirection="column" className={classes.dialog}>
        {loading && <LoadingSpinner />}
        <Typography variant="h5" className={classes.marginBottom}>
          {translate('client.nutritional_info_title')}
        </Typography>
        <Box>
          <RichTextEditor
            onChange={(value: any) => {
              setNutritionalInfo(JSON.stringify(value));
              setIsChanged(true);
            }}
            style={{marginBottom: 16}}
            initialValue={nutritionalInfo && JSON.parse(nutritionalInfo)}
          />
        </Box>
        <Box display="flex" flexDirection="row">
          <Button color="primary"
                  variant={isChanged ? 'contained' : 'outlined'}
                  className={classes.controlButton}
                  onClick={updateUserNutritionalInfo}
                  disabled={loading}
          >
            {translate('button.save')}
          </Button>
          <Button color="secondary"
                  className={classes.controlButton}
                  onClick={() => {
                    setIsChanged(false);
                    onClose();
                  }}
          >
            {translate('button.cancel')}
          </Button>
        </Box>
      </Box>
    </Dialog>
  );
};

export default NutritionalPlanDialog;

interface Props {
  user: User | UserInvitationWithCrudInstructions;
  loadUser?: () => Promise<any>;
  isOpen: boolean;
  onClose: () => void;
  updatePath: 'users' | 'invitations';
}
