import React, {useCallback, useState} from "react";
import {Box, createStyles, Fab, Theme} from "@material-ui/core";
import EditIcon from "@material-ui/icons/Edit";
import {makeStyles} from "@material-ui/core/styles";
import {User} from "myfitworld-model";
import ProfileImage from "../../../components/avatars/ProfileImage";
import {DropzoneDialog} from "material-ui-dropzone";
import firebase from "firebase";
import {getUserInitials} from "../../../utils/getUserInitials";

export const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    profileImageHolder: {
      display: 'inline-block',
      alignSelf: 'flex-start',
      position: 'relative',
    },
    editAvatar: {
      position: 'absolute',
      bottom: theme.spacing(0.5),
      right: theme.spacing(0.5),
    },
  })
);

const EditProfileImage = ({userProfile, setUserProfile, toggleChanged} : Props) => {
  const classes = useStyles();
  const [isOpenDialog, toggleIsOpenDialog] = useState<boolean>(false);

  const saveImage = useCallback(async (files) => {
    const image = files[0];
    const imageRef = firebase.storage().ref().child(`users/${userProfile?.id}/avatar/profile.jpeg`);
    const metadata = {contentType: 'image/jpeg'};

    const uploadImage = await imageRef.put(image, metadata);
    const downloadURL = await uploadImage.ref.getDownloadURL();
    setUserProfile({ ...userProfile, avatarURL: `${downloadURL}`});
    toggleChanged(true);
    toggleIsOpenDialog(false);
  }, [userProfile, setUserProfile, toggleChanged]);

  return (
    <Box className={classes.profileImageHolder}>
      <ProfileImage src={userProfile.avatarURL} initials={getUserInitials(userProfile.firstName, userProfile.lastName)}/>
      <Fab className={classes.editAvatar} size="small" color="primary" onClick={() => toggleIsOpenDialog(true)}>
        <EditIcon fontSize="small" style={{ color: "black" }}/>
      </Fab>
      <DropzoneDialog
        open={isOpenDialog}
        onSave={saveImage}
        acceptedFiles={['image/jpeg', 'image/png']}
        showPreviews={true}
        filesLimit={1}
        onClose={() => toggleIsOpenDialog(false)}
      />
    </Box>
  );
};

export default EditProfileImage;

interface Props {
  userProfile: User;
  setUserProfile: (value: User) => void;
  toggleChanged: (value: boolean) => void;
}
