import React, {useState} from 'react';
import {Box, Button, Card, CardContent, Chip, createStyles, Typography} from '@material-ui/core';
import {makeStyles} from '@material-ui/core/styles';
import useTheme from "@material-ui/core/styles/useTheme";
import BillingPlanDialog from "../dialogs/BillingPlanDialog";
import {BillingDetailsInterface} from "../utils/BillingDetailsInterface";
import {BillingDetails} from "myfitworld-model";
import {
  ANNUALLY_PLAN_DISCOUNT,
  ANNUALLY_PLAN_PRICE,
  MONTHLY_PLAN_PRICE,
  SIX_MONTH_PLAN_DISCOUNT,
  SIX_MONTH_PLAN_PRICE
} from "../utils";
import {cancelSubscription} from "../../../api/billingDetailsApi";
import {useStoreState} from "pullstate";
import globalState from "../../../globalState";
import SimpleDialog from "../../SimpleDialog";

const SubtleHelperText = {
  fontSize: '0.9rem'
};

const useStyles = makeStyles(() =>
  createStyles({
    card: {
      height: 220,
      cursor: 'pointer',
      transition: '160ms all',
      boxShadow: (props: { isSelected: boolean }) => props.isSelected ?
        '0 0 10px 0 rgba(52,216,103,0.70), 0 7px 12px -4px rgba(20,86,41,0.50)' :
        '',
    },
  }));

interface PlanProps {
  isSelected: boolean;
  onSelect: () => void;
  action?: "UPDATE";
  defaultState?: BillingDetailsInterface;
  billingDetails?: BillingDetails;
  onSave?: (data: BillingDetailsInterface) => void;
}

interface WrapperProps extends PlanProps {
  children: any
}

const CardWrapper = ({isSelected, children, onSelect}: WrapperProps) => {
  const theme = useTheme();
  const classes = useStyles({isSelected});
  return (
    <Card
      variant='outlined'
      elevation={isSelected ? 10 : 1}
      style={{
        opacity: isSelected ? 1 : 0.75,
        borderColor: isSelected ? theme.palette.primary.main : theme.palette.divider
      }}
      onClick={onSelect}
      classes={{root: classes.card}}
    >
      {children}
    </Card>
  )
};

export const MonthlyPlan = (props: PlanProps) => {
  const theme = useTheme();
  const [openDialog, setOpenDialog] = useState<boolean>(false);

  const {action, defaultState, billingDetails, onSave} = props;
  const currentOrganization = useStoreState(globalState, s => s.currentOrganization);
  const [openCancelDialog, setOpenCancelDialog] = useState<boolean>(false);

  return (
    <CardWrapper {...props}>
      <CardContent>
        <Typography color='textSecondary' gutterBottom>
          Monthly Plan
        </Typography>
        <Typography variant='h5' component='h2' gutterBottom>
          {MONTHLY_PLAN_PRICE} per month per client
        </Typography>
        <Typography color='textSecondary' gutterBottom style={SubtleHelperText}>
          <span style={{color: theme.palette.primary.light, fontWeight: 'bold'}}>
            • Registration is completely commitment FREE!
          </span>
        </Typography>
        <Typography color='textSecondary' gutterBottom style={SubtleHelperText}>
          <span style={{color: theme.palette.primary.light, fontWeight: 'bold'}}>
            • The first two clients you add to the MFW platform are FREE.
          </span>
        </Typography>
        {
          action && <Button
            color="primary"
            onClick={() => setOpenDialog(true)}
          >
            Change Plan
          </Button>
        }
        {
          action && <Button
            color="secondary"
            onClick={() => setOpenCancelDialog(true)}
          >
            Or Cancel Subscription
          </Button>
        }
      </CardContent>
      <BillingPlanDialog
        open={openDialog}
        action="UPDATE"
        defaultState={defaultState}
        billingDetails={billingDetails}
        onSave={onSave}
        onClose={() => setOpenDialog(false)}
      />
      <SimpleDialog
        title={"Cancel Subscription"}
        text={"Do you really want to cancel subscription?"}
        open={openCancelDialog}
        onClose={() => setOpenCancelDialog(false)}
        onConfirm={async () => {
          setOpenCancelDialog(false);
          currentOrganization?.id && billingDetails?.stripe?.subscriptionId && await cancelSubscription(currentOrganization?.id, billingDetails?.stripe?.subscriptionId);
        }}
        onConfirmLabel={"Yes"}
        onCancel={() => setOpenCancelDialog(false)}
        onCancelLabel={"No"}
      />
    </CardWrapper>
  );
};

export const SixMonthPlan = (props: PlanProps) => {
  const theme = useTheme();
  const [openDialog, setOpenDialog] = useState<boolean>(false);

  const {action, defaultState, billingDetails, onSave} = props;
  const currentOrganization = useStoreState(globalState, s => s.currentOrganization);
  const [openCancelDialog, setOpenCancelDialog] = useState<boolean>(false);

  return (
    <CardWrapper {...props}>
      <CardContent>
        <Box style={{display: 'flex', justifyContent: 'space-between'}}>
          <Typography color='textSecondary' gutterBottom>
            Six Month Plan
          </Typography>
          <Chip color='primary' size='small' label={`${SIX_MONTH_PLAN_DISCOUNT}% OFF!`}/>
        </Box>
        <Typography variant='h5' component='h2' gutterBottom>
          <span style={{textDecoration: 'line-through'}}>{MONTHLY_PLAN_PRICE}</span>
          {' '}
          {`${SIX_MONTH_PLAN_PRICE} per month per client`}
        </Typography>
        <Typography color='textSecondary' gutterBottom style={SubtleHelperText}>
          <span style={{color: theme.palette.primary.light, fontWeight: 'bold'}}>
            • Registration is completely commitment FREE!
          </span>
        </Typography>
        <Typography color='textSecondary' gutterBottom style={SubtleHelperText}>
          <span style={{color: theme.palette.primary.light, fontWeight: 'bold'}}>
            • The first two clients you add to the MFW platform are FREE.
          </span>
        </Typography>
        {
          action && <Button
            color="primary"
            onClick={() => setOpenDialog(true)}
          >
            Change Plan
          </Button>
        }
        {
          action && <Button
            color="secondary"
            onClick={() => setOpenCancelDialog(true)}
          >
            Or Cancel Subscription
          </Button>
        }
      </CardContent>
      <BillingPlanDialog
        open={openDialog}
        action="UPDATE"
        defaultState={defaultState}
        billingDetails={billingDetails}
        onSave={onSave}
        onClose={() => setOpenDialog(false)}
      />
      <SimpleDialog
        title={"Cancel Subscription"}
        text={"Do you really want to cancel subscription?"}
        open={openCancelDialog}
        onClose={() => setOpenCancelDialog(false)}
        onConfirm={async () => {
          setOpenCancelDialog(false);
          currentOrganization?.id && billingDetails?.stripe?.subscriptionId && await cancelSubscription(currentOrganization?.id, billingDetails?.stripe?.subscriptionId);
        }}
        onConfirmLabel={"Yes"}
        onCancel={() => setOpenCancelDialog(false)}
        onCancelLabel={"No"}
      />
    </CardWrapper>
  );
}

export const YearlyPlan = (props: PlanProps) => {
  const theme = useTheme();
  const [openDialog, setOpenDialog] = useState<boolean>(false);

  const {action, defaultState, billingDetails, onSave} = props;
  const currentOrganization = useStoreState(globalState, s => s.currentOrganization);
  const [openCancelDialog, setOpenCancelDialog] = useState<boolean>(false);

  return (
    <CardWrapper {...props}>
      <CardContent>
        <Box style={{display: 'flex', justifyContent: 'space-between'}}>
          <Typography color='textSecondary' gutterBottom>
            Annually plan
          </Typography>
          <Chip color='primary' size='small' label={`${ANNUALLY_PLAN_DISCOUNT}% OFF!`}/>
        </Box>
        <Typography variant='h5' component='h2' gutterBottom>
          <span style={{textDecoration: 'line-through'}}>{MONTHLY_PLAN_PRICE}</span>
          {' '}
          {`${ANNUALLY_PLAN_PRICE} per month per client`}
        </Typography>
        <Typography color='textSecondary' gutterBottom style={SubtleHelperText}>
          <span style={{color: theme.palette.primary.light, fontWeight: 'bold'}}>
            • Registration is completely commitment FREE!
          </span>
        </Typography>
        <Typography color='textSecondary' gutterBottom style={SubtleHelperText}>
          <span style={{color: theme.palette.primary.light, fontWeight: 'bold'}}>
            • The first two clients you add to the MFW platform are FREE.
          </span>
        </Typography>
        {
          action && <Button
            color="primary"
            onClick={() => setOpenDialog(true)}
          >
            Change Plan
          </Button>
        }
        {
          action && <Button
            color="secondary"
            onClick={() => setOpenCancelDialog(true)}
          >
            Or Cancel Subscription
          </Button>
        }
      </CardContent>
      <BillingPlanDialog
        open={openDialog}
        action="UPDATE"
        defaultState={defaultState}
        billingDetails={billingDetails}
        onSave={onSave}
        onClose={() => setOpenDialog(false)}
      />
      <SimpleDialog
        title={"Cancel Subscription"}
        text={"Do you really want to cancel subscription?"}
        open={openCancelDialog}
        onClose={() => setOpenCancelDialog(false)}
        onConfirm={async () => {
          setOpenCancelDialog(false);
          currentOrganization?.id && billingDetails?.stripe?.subscriptionId && await cancelSubscription(currentOrganization?.id, billingDetails?.stripe?.subscriptionId);
        }}
        onConfirmLabel={"Yes"}
        onCancel={() => setOpenCancelDialog(false)}
        onCancelLabel={"No"}
      />
    </CardWrapper>
  );
}
