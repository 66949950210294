import React, {useState} from 'react';
import { DndProvider } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';
import {Equipment, Exercise, ExerciseGroup, RoundDetails} from 'myfitworld-model';
import GroupItem from "./GroupItem";
import TableBody from "@material-ui/core/TableBody";
import DraggableItem from "./DraggableItem";

const GroupRows = ({
  group,
  onChange,
  exercises,
  equipment,
  disabled,
  setExerciseForPreview,
} : {
  group: ExerciseGroup
  onChange: (group: ExerciseGroup) => void,
  exercises: Exercise[],
  equipment: Equipment[],
  disabled?: boolean,
  setExerciseForPreview: (exercise: Exercise | undefined) => void,
}) => {
  const [ internalId ] = useState(`${Math.random()}`);
  const handleTypeChange = (value: 'Reps' | 'Time', exerciseIndex: number) => {
    if (group.exercises[exerciseIndex]) {
      const exercises = (group.exercises || []);
      exercises[exerciseIndex].type = value;
      exercises[exerciseIndex].rounds = exercises[exerciseIndex].rounds.map(_ => ({ }));
      onChange({ ...group });
    }
  };

  const handleRoundChange = (field: keyof RoundDetails, value: string, exerciseIndex: number, roundIndex: number) => {
    const parsedValue = Number(value);
    if (group.exercises[exerciseIndex] && group.exercises[exerciseIndex].rounds[roundIndex]) {
      group.exercises[exerciseIndex].rounds[roundIndex][field] = parsedValue;
      onChange({ ...group });
    }
  };

  const removeExercise = (exerciseIndex: number) => {
    if (group.exercises[exerciseIndex]) {
      group.exercises.splice(exerciseIndex, 1);
      onChange({ ...group });
    }
  };

  const swapItems = (dragIndex: number, hoverIndex: number) => {
    const list = group.exercises;
    let temp = list.splice(dragIndex, 1)[0];
    list.splice(hoverIndex, 0, temp);
    const changed = { ...group, exercises: list };
    onChange(changed);
  };

  return (
    <TableBody>
      <DndProvider backend={HTML5Backend}>
        {(group.exercises || []).map((exercise, exerciseIndex) =>
          <DraggableItem
            key={exerciseIndex}
            onMove={swapItems}
            index={exerciseIndex}
            accept={internalId}
          >
            <GroupItem
              exercise={exercise}
              exerciseIndex={exerciseIndex}
              exercises={exercises}
              equipment={equipment}
              numberOfRounds={group.numberOfRounds}
              handleTypeChange={handleTypeChange}
              handleRoundChange={handleRoundChange}
              removeExercise={removeExercise}
              disabled={disabled}
              setExerciseForPreview={setExerciseForPreview}
            />
          </DraggableItem>
        )}
      </DndProvider>
    </TableBody>
  );
};

export default GroupRows;
