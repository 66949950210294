import {useLocalizationProvider} from "../../../providers/LocalizationProvider";
import React, {Fragment} from "react";
import {Box, Divider, Typography} from "@material-ui/core";

const PublicProfileHeader = ({marginTop} : {marginTop: number}) => {
  const {translate} = useLocalizationProvider();
  return (
    <Fragment>
      <Box mt={marginTop} ml={5} mb={2}>
        <Typography variant="h4">{translate("My Profile")}</Typography>
      </Box>
      <Divider/>
    </Fragment>
  );
}

export default PublicProfileHeader;
