import {BillingDetails} from "myfitworld-model";
import {firestore} from "../firebase";
import firebase from "firebase";

const FUNCTIONS_BASE_URL = process.env.REACT_APP_FUNCTIONS_BASE_URL;

export const getOrganizationBillingDetails = (
  orgId: string
): Promise<BillingDetails | null> =>
  new Promise((resolve, reject) => {
    return firestore.collection('billingDetails').where(firebase.firestore.FieldPath.documentId(), '==', orgId).get().then((snapshot) => {
      snapshot.empty ? resolve(null) : resolve({id: snapshot.docs[0].id, ...snapshot.docs[0].data()} as BillingDetails);
    }).catch((err) => {
      console.log(err);
      reject(null);
    })
  });

export const getBillingDetailsByEmail = (
  email: string
): Promise<BillingDetails | null> =>
  new Promise((resolve, reject) => {
    return firestore.collection('billingDetails').where('email', '==', email).get().then((snapshot) => {
      snapshot.empty ? resolve(null) : resolve({id: snapshot.docs[0].id, ...snapshot.docs[0].data()} as BillingDetails);
    }).catch((err) => {
      console.log(err);
      reject(null);
    })
  });

export const setOrganizationBillingDetails = (
  orgId: string,
  billingDetails: any
): Promise<BillingDetails | null> =>
  new Promise((resolve, reject) => {
    return firestore.collection('billingDetails').doc(orgId).update(billingDetails).then((snapshot) => {
      console.log("success update");
    }).catch((err) => {
      console.log(err);
      reject(null);
    })
  });

export const createOrganizationBillingDetails = async (orgId: string, billingDetails: any) => {
  const token = await firebase.auth().currentUser?.getIdToken();
  const response = await fetch(`${FUNCTIONS_BASE_URL}/billingDetails/update/${orgId}`, {
    method: 'PUT',
    body: JSON.stringify(billingDetails),
    headers: {
      'Content-Type': 'application/json',
      'Authorization': token ? 'Bearer ' + token : ''
    }
  });
  return response;
}

export const updateOrganizationCardDetails = async (orgId: string, billingDetails: any) => {
  const token = await firebase.auth().currentUser?.getIdToken();
  const response = await fetch(`${FUNCTIONS_BASE_URL}/billingDetails/card/update/${orgId}`, {
    method: 'PUT',
    body: JSON.stringify(billingDetails),
    headers: {
      'Content-Type': 'application/json',
      'Authorization': token ? 'Bearer ' + token : ''
    }
  });
  return response;
}

export const updateOrganizationPlan = async (orgId: string, billingDetails: any) => {
  const token = await firebase.auth().currentUser?.getIdToken();
  const response = await fetch(`${FUNCTIONS_BASE_URL}/billingDetails/plan/update/${orgId}`, {
    method: 'PUT',
    body: JSON.stringify(billingDetails),
    headers: {
      'Content-Type': 'application/json',
      'Authorization': token ? 'Bearer ' + token : ''
    }
  });
  return response;
}

export const updateOrganizationBillingAddress = async (orgId: string, billingDetails: any) => {
  const token = await firebase.auth().currentUser?.getIdToken();
  const response = await fetch(`${FUNCTIONS_BASE_URL}/billingDetails/address/update/${orgId}`, {
    method: 'PUT',
    body: JSON.stringify(billingDetails),
    headers: {
      'Content-Type': 'application/json',
      'Authorization': token ? 'Bearer ' + token : ''
    }
  });
  return response;
}

export const incrementSubscriptionQuantity = async (orgId: string) => {
  const token = await firebase.auth().currentUser?.getIdToken();
  const response = await fetch(`${FUNCTIONS_BASE_URL}/subscriptionQuantity/increment/${orgId}`, {
    method: 'PUT',
    headers: {
      'Authorization': token ? 'Bearer ' + token : ''
    }
  });
  return response;
}

export const decrementSubscriptionQuantity = async (orgId: string) => {
  const token = await firebase.auth().currentUser?.getIdToken();
  const response = await fetch(`${FUNCTIONS_BASE_URL}/subscriptionQuantity/decrement/${orgId}`, {
    method: 'PUT',
    headers: {
      'Authorization': token ? 'Bearer ' + token : ''
    }
  });
  return response;
}

export const listInvoices = async (customerID: string, subscriptionID: string) => {
  const token = await firebase.auth().currentUser?.getIdToken();
  const response = await fetch(`${FUNCTIONS_BASE_URL}/listInvoices/customer/${customerID}/subscription/${subscriptionID}`, {
    method: 'GET',
    headers: {
      'Authorization': token ? 'Bearer ' + token : ''
    }
  });
  return response;
}

export const getUpcomingInvoice = async (customerID: string, subscriptionID: string) => {
  const token = await firebase.auth().currentUser?.getIdToken();
  const response = await fetch(`${FUNCTIONS_BASE_URL}/upcomingInvoice/customer/${customerID}/subscription/${subscriptionID}`, {
    method: 'GET',
    headers: {
      'Authorization': token ? 'Bearer ' + token : ''
    }
  });
  return response;
}

export const cancelSubscription = async (orgID: string, subscriptionID: string) => {
  const token = await firebase.auth().currentUser?.getIdToken();
  const response = await fetch(`${FUNCTIONS_BASE_URL}/cancelSubscription/organization/${orgID}/subscription/${subscriptionID}`, {
    method: 'DELETE',
    headers: {
      'Authorization': token ? 'Bearer ' + token : ''
    }
  });
  return response;
}

export const listCoupons = async () => {
  const token = await firebase.auth().currentUser?.getIdToken();
  const response = await fetch(`${FUNCTIONS_BASE_URL}/coupons/list`, {
    method: 'GET',
    headers: {
      'Authorization': token ? 'Bearer ' + token : ''
    }
  });
  return response;
}
