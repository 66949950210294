import {UserProgram} from "myfitworld-model/dist/firestoreCollectionTypes/User";
import {addHours, format} from "date-fns";

const transformWorkoutsIntoCalendarEvents = (program?: UserProgram) => {
  if (program && program.workouts) {
    const events = program.workouts.map((workout, index) => {
      const startDateTime = new Date(workout.dateTime)
      return ({
        index: index,
        title: `${workout.workout.title?.en || workout.workout.title?.sr} @${format(startDateTime, 'hh:mm a')}`,
        finished: workout.finished,
        resourceId: index,
        start: startDateTime,
        end: addHours(startDateTime, 1),
      });
    });
    const resourceMap = events.map(evt => ({
      resourceId: evt.resourceId,
      title: evt.title,
    }));

    return { events, resourceMap };
  }

  return { events: [], resourceMap: [] };
};

export default transformWorkoutsIntoCalendarEvents;
