import cloneDeep from 'lodash.clonedeep';

const cloneAndOmitProperties = <A, B>(obj: A, properties: string[]): B => {
  const omitted: A = cloneDeep(obj);
  properties.forEach(prop => {
    // @ts-ignore
    delete omitted[prop];
  });
  return omitted as unknown as B;
};

export default cloneAndOmitProperties;
