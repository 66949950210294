import React, {useState} from 'react';
import {
  Box,
  Button,
  createStyles,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  Theme,
  Typography
} from '@material-ui/core';
import ExitToAppIcon from '@material-ui/icons/ExitToApp';
import {MonthlyPlan, SixMonthPlan, YearlyPlan} from '../forms/PlanCards';
import {BillingPlanHelperNote} from '../utils/Notes';
import {makeStyles} from "@material-ui/core/styles";
import dialogNames from "../utils/DialogNames";
import {auth} from "../../../firebase";
import useNavigation from "../../../hooks/useNavigation";
import {BillingDetailsInterface} from "../utils/BillingDetailsInterface";
import {BillingDetails} from "myfitworld-model";
import {useStoreState} from "pullstate";
import globalState from "../../../globalState";
import {updateOrganizationPlan} from "../../../api/billingDetailsApi";
import {ANNUALLY_PLAN, MONTHLY_PLAN, SIX_MONTH_PLAN} from "../utils";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    actions: {
      '& > *': {
        marginLeft: theme.spacing(1),
      }
    },
  }),
);

const BillingPlanDialog = (props: Props) => {
  const classes = useStyles();
  const currentOrganization = useStoreState(globalState, s => s.currentOrganization);
  const {open, action, defaultState, billingDetails, onNext, onSave, onClose} = props;

  const [ plan, setPlan ] = useState<string>(defaultState?.plan ?? ANNUALLY_PLAN);
  const { navigation } = useNavigation();

  const handleLogout = () => {
    auth
      .signOut()
      .then(async (_) => {
        await navigation('/');
      })
      .catch((err) => console.log(err));
  };

  const onEdit = async () => {
    onSave && defaultState && onSave({...defaultState, plan: plan});
    currentOrganization && currentOrganization.id && await updateOrganizationPlan(
      currentOrganization.id,
      {
        ...billingDetails,
        stripe: {
          ...billingDetails?.stripe,
          priceId: plan === MONTHLY_PLAN ? process.env.REACT_APP_MONTHLY_PLAN_ID : plan === SIX_MONTH_PLAN ? process.env.REACT_APP_SIX_MONTH_PLAN_ID : process.env.REACT_APP_ANNUALLY_PLAN_ID
        },
      }
    );
    onClose && onClose();
  };

  const onCreateAndNext = () => {
    onSave && defaultState && onSave({...defaultState, plan: plan});
    onNext && onNext(dialogNames[1]);
  };

  return (
    <Dialog open={open} maxWidth = {'md'}>
      <DialogTitle>
        <Box pt={1} display="flex" justifyContent="space-between">
          <Typography variant='h4' component='p'>
            Choose Your Plan
          </Typography>
        </Box>
      </DialogTitle>
      <DialogContent style={{ paddingBottom: '3rem' }}>
        <Grid container spacing={2}>
          <Grid item xs={4}>
            <MonthlyPlan
              isSelected={plan === MONTHLY_PLAN}
              onSelect={() => setPlan(MONTHLY_PLAN)}
            />
          </Grid>
          <Grid item xs={4}>
            <SixMonthPlan
              isSelected={plan === SIX_MONTH_PLAN}
              onSelect={() => setPlan(SIX_MONTH_PLAN)}
            />
          </Grid>
          <Grid item xs={4}>
            <YearlyPlan
              isSelected={plan === ANNUALLY_PLAN}
              onSelect={() => setPlan(ANNUALLY_PLAN)}
            />
          </Grid>
        </Grid>
        <BillingPlanHelperNote />
      </DialogContent>
      <DialogActions>
        <Box px={2} pb={2} className={classes.actions}>
          {action === "UPDATE" && <Button color='primary' variant='text' onClick={() => onClose && onClose()}>
            Cancel
          </Button>}
          {action === "UPDATE" && <Button color='primary' variant='contained' onClick={() => onEdit()}>
            {plan === ANNUALLY_PLAN && 'Choose yearly'}
            {plan === SIX_MONTH_PLAN && 'Choose six month plan'}
            {plan === MONTHLY_PLAN && 'Choose monthly'}
          </Button>}
          {action === "CREATE" && <Button color='primary' variant='text' onClick={() => onCreateAndNext()}>
            Save and Next
          </Button>}
          {action === "CREATE" && <Button
            onClick={handleLogout} color="secondary"
            startIcon={<ExitToAppIcon />}
          >
            Or Log out
          </Button>}
        </Box>
      </DialogActions>
    </Dialog>
  );
}

interface Props {
  open: boolean;
  action: "CREATE" | "UPDATE",
  defaultState?: BillingDetailsInterface,
  billingDetails?: BillingDetails,
  onSave?: (data: BillingDetailsInterface) => void,
  onNext?: (dialogName: string) => void,
  onClose?: () => void
}

export default BillingPlanDialog;
