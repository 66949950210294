import React, {CSSProperties, Fragment, useContext, useMemo} from 'react';
import LanguageContext from "./language/LanguageContext";
import {Controller} from "react-hook-form";
import RichTextEditor from "./RichTextEditor";
import get from 'lodash/get';
import {LocalizedProperty} from "myfitworld-model";

interface LocalizedRichTextFieldProps {
  control: any,
  name: string,
  label?: string,
  initialValue?: LocalizedProperty,
  disabled?: boolean,
  style?: CSSProperties
}

const LocalizedTextField = (props: LocalizedRichTextFieldProps)  => {
  const {language, languages} = useContext(LanguageContext);
  const {initialValue, name, control, label, disabled, style} = props;

  const deltas = useMemo(() => languages.reduce((acc, lang) => {
      try {
        return {...acc, [lang]: JSON.parse(get(initialValue, lang) as string)}
      } catch (e) {
        return acc;
      }
    }, {}), [initialValue, languages]);

  return (
    <Fragment>
      {languages.map(lang => (
        <div key={`${name}-${lang}`} style={lang !== language ? {display: 'none'} : undefined}>
          <Controller
            control={control}
            name={`${name}.${lang}` || ''}
            defaultValue={get(deltas, lang)}
            render={({onChange, value}) => {
              return <RichTextEditor
                label={label}
                onChange={(value:any) => onChange(JSON.stringify(value))}
                initialValue={value && JSON.parse(value)}
                disabled={disabled}
                style={style}
              />
            }}
          />
        </div>
      ))}
    </Fragment>
  )
}

export default LocalizedTextField;
