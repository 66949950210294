import React, {useState} from 'react';
import TextField from "@material-ui/core/TextField";
import LocalizedTextField from "../../../../components/LocalizedTextField";
import LocalizedRichTextField from "../../../../components/LocalizedRichTextField";
import MultiSelectField from "../../../../components/MultiSelectField";
import RatingWidget from "../../../../components/RatingWidget";
import {Control, Controller} from "react-hook-form";
import {GeneralEntity} from "myfitworld-model";
import FileUploadField from "../../../../components/fileUpload/FileUploadField";
import {useLocalizationProvider} from "../../../../providers/LocalizationProvider";
import Grid from '@material-ui/core/Grid/Grid';
import Typography from '@material-ui/core/Typography/Typography';
import Accordion from '@material-ui/core/Accordion';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import {AccordionDetails, Box, createStyles, makeStyles, Theme} from "@material-ui/core";
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import useGlobalCachedResourceList from "../../../../api/useGlobalCachedResourceList";
import SubtleInfoMessage from "../../../../components/SubtleInfoMessage";
import Workout from "myfitworld-model/dist/firestoreCollectionTypes/Workout";
import TextMaskExpiration from "../../../../components/TextMaskExpiration";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    title: {
      paddingBottom: theme.spacing(3),

      [theme.breakpoints.down('sm')]: {
        width: '100%',
        paddingBottom: theme.spacing(2),
      },
      // [theme.breakpoints.up('md')]: {
      //   backgroundColor: theme.palette.primary.main,
      // },
      // [theme.breakpoints.up('lg')]: {
      //   backgroundColor: green[500],
      // },
    },
    details: {
      paddingBottom: theme.spacing(4),

      [theme.breakpoints.down('sm')]: {
        paddingBottom: theme.spacing(2),
      },
    }
  }),
);

const GeneralDetails = ({
  register,
  getValues,
  control,
  setValue,
  mode,
  disabled,
  withRestTimes,
  defaultState,
} : {
  register: () => void,
  getValues: (field: string) => any,
  setValue: (field: string, value: any) => void,
  control: Control,
  mode?: any,
  disabled?: boolean,
  withRestTimes: boolean,
  defaultState?: Workout,
}) => {
  const classes = useStyles();
  const {translate} = useLocalizationProvider();
  const { data: SportOptions } = useGlobalCachedResourceList<GeneralEntity>('sportsCache');
  const { data: FitnessActivitiesOptions } = useGlobalCachedResourceList<GeneralEntity>('fitnessActivitiesCache');
  const { data: MuscleGroupsOptions } = useGlobalCachedResourceList<GeneralEntity>('muscleGroupsCache');
  const { data: GoalsOptions } = useGlobalCachedResourceList<GeneralEntity>('goalsCache');
  const { data: MotorSkillsOptions } = useGlobalCachedResourceList<GeneralEntity>('motorSkillsCache');
  const { data: HealthIssuesOptions } = useGlobalCachedResourceList<GeneralEntity>('healthIssuesCache');
  const [expanded, setExpanded] = useState(mode === "Create");

  const getMinSecDurationText = (seconds: any, name: string) : string => {
    if (isNaN(seconds)) {
      return seconds;
    }

    const minNumber = Math.floor((seconds || 0 ) / 60);
    const secNumber = (seconds || 0 ) % 60;

    const minText = minNumber >= 10 ? `${minNumber}` : `0${minNumber}`;
    const secText = secNumber >= 10 ? `${secNumber}` : `0${secNumber}`;

    const text = `${minText}:${secText}`;
    setValue(name, text);
    return text;
  }

  const handleAccordionToggle = () => {
    setExpanded(!expanded);
  };

  return (
    <Box>
      <Box width={1/3} className={classes.title}>
        <LocalizedTextField
          inputRef={register}
          autoFocus
          margin='normal'
          name='title'
          label='Title'
          type='text'
          fullWidth
          required
          disabled={disabled}
        />
      </Box>

      <Box className={classes.details}>
        <Accordion
          defaultExpanded={expanded}
          onChange={handleAccordionToggle}
        >
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel1a-content"
            id="panel1a-header"
          >
            <Box>
              <Typography variant="body1">General Details</Typography>
              <Typography variant="body2" color="textSecondary">Click to {expanded ? 'collapse' : 'expand' }</Typography>
            </Box>
          </AccordionSummary>
          <AccordionDetails>
            <Grid item container direction="row" spacing={6}>
              <Grid item sm={6}>
                <Typography variant="body1" color="textSecondary" gutterBottom>Description</Typography>
                <LocalizedRichTextField
                  initialValue={getValues('description')}
                  control={control}
                  name='description'
                  disabled={disabled}
                />
                <Controller
                  as={FileUploadField}
                  name='thumbnailURL'
                  uploadLabel='Upload custom thumbnail'
                  changeLabel='Choose a new thumbnail'
                  uploadPath='thumbnails'
                  fileType='image'
                  control={control}
                  onUploadSuccessful={(thumbnailURL: string) => setValue('thumbnailURL', thumbnailURL)}
                  disabled={disabled}
                />
                <SubtleInfoMessage>
                  {translate('content.thumbnail_note')}
                </SubtleInfoMessage>
              </Grid>
              <Grid item sm={6}>
                <Typography variant="body1" color="textSecondary" gutterBottom>Details</Typography>
                <Controller
                  name={'goals'}
                  control={control}
                  render={({onChange, value}) => {
                    return <MultiSelectField label={translate('Goals')} options={GoalsOptions}
                                             value={value} disabled={disabled}
                                             onChange={values => onChange(values)} />
                  }}
                />
                <Controller
                  name={'muscleGroups'}
                  control={control}
                  render={({onChange, value}) => {
                    return <MultiSelectField label={translate('Muscle Groups')} options={MuscleGroupsOptions}
                                             value={value} disabled={disabled}
                                             onChange={values => onChange(values)} />
                  }}
                />
                <Controller
                  name={'secondaryMuscleGroups'}
                  control={control}
                  render={({onChange, value}) => {
                    return <MultiSelectField label={translate('Secondary Muscle Groups')} options={MuscleGroupsOptions}
                                             value={value} disabled={disabled}
                                             onChange={values => onChange(values)} />
                  }}
                />
                <Controller
                  name={'motorSkills'}
                  control={control}
                  render={({onChange, value}) => {
                    return <MultiSelectField label={translate('Motor Skills')} options={MotorSkillsOptions}
                                             value={value} disabled={disabled}
                                             onChange={values => onChange(values)} />
                  }}
                />
                <Controller
                  name={'sports'}
                  control={control}
                  render={({onChange, value}) => {
                    return <MultiSelectField label={translate('Sports')} options={SportOptions}
                                             value={value} disabled={disabled}
                                             onChange={values => onChange(values)} />
                  }}
                />
                <Controller
                  name={'fitnessActivity'}
                  control={control}
                  render={({onChange, value}) => {
                    return <MultiSelectField label={translate('Activity')} options={FitnessActivitiesOptions}
                                             value={value} disabled={disabled}
                                             onChange={values => onChange(values)} />
                  }}
                />
                <Controller
                  name={'suitableForHealthIssues'}
                  control={control}
                  render={({onChange, value}) => {
                    return <MultiSelectField label={translate('Suitable for health issues')} options={HealthIssuesOptions}
                                             value={value} disabled={disabled}
                                             onChange={values => onChange(values)} />
                  }}
                />
                <RatingWidget
                  control={control}
                  name='difficultyLevel'
                  label={translate("Training Level")}
                  disabled={disabled}
                />
              </Grid>
            </Grid>
          </AccordionDetails>
        </Accordion>
      </Box>
        {withRestTimes &&
          <Box>
            <Typography variant="body1" color="textSecondary" gutterBottom>Rest Times</Typography>
            <Grid container spacing={2}>
              <Grid item xs={12} md={4}>
                <TextField
                  id='interExerciseRest'
                  name='interExerciseRest'
                  inputRef={register}
                  InputProps={{
                    inputComponent: TextMaskExpiration as any,
                  }}
                  defaultValue={getMinSecDurationText(defaultState?.interExerciseRest, "interExerciseRest")}
                  label={translate('workout.edit.rest_times.inter_exercise')}
                  disabled={disabled}
                  fullWidth
                  required
                />
              </Grid>
              <Grid item xs={12} md={4}>
                <TextField
                  id='interRoundRest'
                  name='interRoundRest'
                  inputRef={register}
                  InputProps={{
                    inputComponent: TextMaskExpiration as any,
                  }}
                  defaultValue={getMinSecDurationText(defaultState?.interRoundRest, "interRoundRest")}
                  label={translate('workout.edit.rest_times.inter_round')}
                  disabled={disabled}
                  fullWidth
                  required
                />
              </Grid>
              <Grid item xs={12} md={4}>
                <TextField
                  id='interGroupRest'
                  name='interGroupRest'
                  inputRef={register}
                  InputProps={{
                    inputComponent: TextMaskExpiration as any,
                  }}
                  defaultValue={getMinSecDurationText(defaultState?.interGroupRest, "interGroupRest")}
                  label={translate('workout.edit.rest_times.inter_group')}
                  disabled={disabled}
                  fullWidth
                  required
                />
              </Grid>
            </Grid>
          </Box>
        }
    </Box>
  );
};

export default GeneralDetails;
