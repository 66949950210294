import React from 'react';
import IconButton from '@material-ui/core/IconButton';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import MoreVertIcon from '@material-ui/icons/MoreVert';

const SimpleMenu = ({
  options,
  onSelect,
  disabled,
} : {
  options: Array<{ label: string, action: string }>,
  onSelect: (action?: string) => void,
  disabled?: boolean
}) => {
  const [ anchorEl, setAnchorEl ] = React.useState<null | HTMLElement>(null);

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = (option?: string) => () => {
    onSelect(option);
    setAnchorEl(null);
  };

  return (
    <div style={{ alignSelf: 'center' }}>
      <IconButton
        aria-label="more"
        aria-controls="long-menu"
        aria-haspopup="true"
        onClick={handleClick}
        size="small"
      >
        <MoreVertIcon />
      </IconButton>
      <Menu
        id="simple-menu"
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleClose()}
      >
        {options.map((option, index) =>
          <MenuItem onClick={handleClose(option.action)} key={index} disabled={disabled}>
            {option.label}
          </MenuItem>
        )}
      </Menu>
    </div>
  );
};

export default SimpleMenu;
