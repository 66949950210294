import {Workout, Exercise, Equipment, GeneralEntity, ProgramTemplate} from "myfitworld-model";
import interfaceFactory from "./dataInterfaceFactory";

export const equipmentApi = interfaceFactory<Equipment>('equipment');
export const workoutApi = interfaceFactory<Workout>('workouts');
export const programTemplateApi = interfaceFactory<ProgramTemplate>('programs');
export const exercisesApi = interfaceFactory<Exercise>('exercises');


// enumerable entities

export const fitnessActivitiesApi = interfaceFactory<GeneralEntity>('fitnessActivities');
export const muscleGroupsApi = interfaceFactory<GeneralEntity>('muscleGroups');
export const goalsApi = interfaceFactory<GeneralEntity>('goals');
export const motorSkillsApi = interfaceFactory<GeneralEntity>('motorSkills');
export const sportsApi = interfaceFactory<GeneralEntity>('sports');
export const healthIssuesApi = interfaceFactory<GeneralEntity>('healthIssues');
export const expertisesApi = interfaceFactory<GeneralEntity>('expertises');
