import React, {useEffect, useState} from "react";
import {RouteComponentProps, useNavigate} from "@reach/router";
import PageContainer from "../PageContainer";
import {Button, createStyles, Grid, TextField, Theme, Typography} from "@material-ui/core";
import {StatsWorkout} from "myfitworld-model";
import getLocalizedProperty from "../../utils/getLocalizedProperty";
import format from "date-fns/fp/format";
import GeneralStatisticInformation from "../../components/statistics/GeneralStatisticInformation";
import ExerciseLog from "../../components/statistics/ExerciseLog";
import Box from "@material-ui/core/Box";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableContainer from "@material-ui/core/TableContainer";
import {makeStyles} from "@material-ui/core/styles";
import Toolbar from "@material-ui/core/Toolbar";
import IconButton from "@material-ui/core/IconButton";
import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIos';
import AppBar from "@material-ui/core/AppBar";
import ReactionImage from "../../components/ReactionImage";
import AddIcon from '@material-ui/icons/Add';
import {getStatisticsForSpecificWorkout, saveWorkoutStatistics} from "../../api/statisticsApi";
import SubjectIcon from '@material-ui/icons/Subject';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    table: {
      width: 800,
      marginTop: theme.spacing(4),
    }
  })
);

export default function ({userId, workoutId}: RouteComponentProps<{ userId: string; workoutId: string }>) {
  const classes = useStyles();
  const [statistic, setStatistic] = useState<StatsWorkout>();
  const navigate = useNavigate();

  const [note, setNote] = useState<string>(statistic?.trainerNote || "");
  const [showNote, setShowNote] = useState<boolean>(false);

  useEffect(() => {
    statistic?.trainerNote && setNote(statistic?.trainerNote || "");
  }, [statistic]);

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setNote(event.target.value);
  };

  const saveNote = () => {
    userId && statistic?.id && saveWorkoutStatistics(
      userId,
      {
        trainerNote: note
      },
      'UPDATE',
      statistic?.id
    ).then(
      () => {
        console.log("saved note");
      }
    );
  };

  useEffect(() => {
    if (!userId || !workoutId) return;
    getStatisticsForSpecificWorkout(workoutId, userId).then((stats) => {
      setStatistic(stats?.[0]);
    });
  }, [userId, workoutId]);

  return (
    <PageContainer>
      <AppBar style={{position: "relative", marginBottom: 24}}>
        <Toolbar>
          <IconButton edge="start" color="inherit" onClick={() => navigate(-1)} aria-label="close">
            <ArrowBackIosIcon/>
          </IconButton>
        </Toolbar>
      </AppBar>
      {statistic && (
        <Grid container>
          <Grid item sm={3} xs={12}></Grid>
          <Grid item sm={6} xs={12}>
            <Box display="flex" flexDirection="column" alignItems="center">
              <Box mb={3} textAlign="center">
                <Typography variant="h6">{format("yyyy/MM/dd", statistic?.startedAt)}</Typography>
                <Typography variant="h3" gutterBottom>
                  {`${getLocalizedProperty(statistic?.workout?.title)}${statistic?.markAsDone ? ' [Marked As Done]' : ''}`}
                </Typography>
                <Box>
                  {statistic.workoutReaction && <ReactionImage reaction={statistic.workoutReaction}/>}
                  <Typography variant="body1">
                    {statistic.note}
                  </Typography>
                </Box>
              </Box>
              <GeneralStatisticInformation statistics={statistic}/>
              <TableContainer className={classes.table}>
                <Table size="small" aria-label="simple table">
                  <TableBody>
                    {statistic.exercises &&
                    statistic.exercises.map((exercise, index) => (
                      <ExerciseLog
                        currentExercise={index + 1}
                        totalExercises={statistic!.exercises!.length}
                        exerciseStat={exercise}
                        key={exercise.id}
                      />
                    ))}
                  </TableBody>
                </Table>
              </TableContainer>
            </Box>
          </Grid>
          <Grid item sm={3} xs={12}>
            <Box>
              {!showNote && <Button
                variant="contained"
                color="secondary"
                startIcon={statistic?.trainerNote ? <SubjectIcon /> : <AddIcon/>}
                onClick={() => setShowNote(true)}
              >
                {statistic?.trainerNote ? "VIEW NOTE" : "ADD NOTE"}
              </Button>}
              {showNote &&
              <Box>
                <Box mb={2}>
                  <TextField
                    label="Note"
                    fullWidth
                    multiline
                    value={note}
                    onChange={handleChange}
                    rows={10}
                  />
                </Box>
                <Button
                  variant="contained"
                  color="primary"
                  onClick={saveNote}
                >
                  SAVE NOTE
                </Button>
              </Box>
              }
            </Box>
          </Grid>
        </Grid>
      )}
    </PageContainer>
  );
}
