import ContentFilterValues from "../model/ContentFilter";
import globalState from "../globalState";
import {Role} from "myfitworld-model";

const keys = [
  'healthIssues', 'motorSkills', 'muscleGroups', 'secondaryMuscleGroups',
  'difficultyLevel', 'fitnessActivity', 'sports', 'equipment', 'goals', 'muscleRegime'
];

const clientSideContentFilter = (list: any[], filter: ContentFilterValues, filterByAuthor: boolean) => {
  let res = [ ...list ];

  if (!filter.showMFWOriginals) {
    res = res.filter(_ => _.organizationId !== process.env.REACT_APP_MFW_ORGANIZATION_ID);
  }

  res = res.filter(_ => filter.archived !== undefined ? (_.archived ? _.archived === filter.archived : true) : _.archived !== true);

  for (let k of keys) {
    const key = k as (
      'sports' | 'muscleGroups' | 'secondaryMuscleGroups' | 'motorSkills' | 'muscleRegime' |
      'healthIssues' | 'fitnessActivity' | 'equipment' | 'difficultyLevel' | 'goals'
    );
    if (key !== 'difficultyLevel' && key !== 'muscleRegime') {
      res = res.filter(_ =>
        filter[key] && filter[key].length > 0 ?
          _ && (_[key] || []).indexOf(filter[key]) >= 0 :
          true
      );
    } else if (key === 'difficultyLevel' && filter.difficultyLevel) {
      res = res.filter(_ => Number(_.difficultyLevel) === Number(filter.difficultyLevel));
    } else if (key === 'muscleRegime' && filter.muscleRegime) {
      res = res.filter(_ => _.muscleRegime === filter.muscleRegime);
    }
  }

  const userRole = globalState.getRawState().currentRole;
  const userId = globalState.getRawState().currentUserId;

  if (userRole === Role.Trainer && filterByAuthor) {
    res = res.filter(_ =>
      _.organizationId === process.env.REACT_APP_MFW_ORGANIZATION_ID ||
      _.authorId === userId
    );
  }

  return res;
};

export default clientSideContentFilter;
