import {ExerciseGroup} from 'myfitworld-model/dist/firestoreCollectionTypes/Workout';
import {Box, createStyles, makeStyles, Theme, Typography} from '@material-ui/core';
import Button from '@material-ui/core/Button/Button';
import AddIcon from '@material-ui/core/SvgIcon/SvgIcon';
import React, {useState} from 'react';
import Exercise from 'myfitworld-model/dist/firestoreCollectionTypes/Exercise';
import {Equipment} from 'myfitworld-model/dist/firestoreCollectionTypes/Equipment';
import GroupForm from '../groupForm/GroupForm';
import ExercisePreviewDialog from "../../exercise/ExercisePreviewDialog";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    multiFieldsetSection: {
      display: 'flex',
      width: '100%',
    },
    fieldset: {
      flex: 1,
      border: 'none',
      minWidth: 0,
    },
    groupButton: {
      paddingTop: theme.spacing(4),
      paddingBottom: theme.spacing(4),
      borderStyle: 'dashed',
    },
  }),
);

const WorkoutDetails = ({
  warmUpGroup,
  setWarmUpGroup,
  coolDownGroup,
  setCoolDownGroup,
  exerciseGroups,
  updateGroup,
  loading,
  exercises,
  equipment,
  removeGroup,
  copyGroup,
  addGroup,
  disabled,
} : {
  warmUpGroup: ExerciseGroup,
  setWarmUpGroup: (group: ExerciseGroup) => void,
  coolDownGroup: ExerciseGroup,
  setCoolDownGroup: (group: ExerciseGroup) => void,
  exerciseGroups: ExerciseGroup[],
  updateGroup: (index: number, group: ExerciseGroup) => void,
  loading: boolean,
  exercises?: Exercise[],
  equipment?: Equipment[],
  removeGroup: (index: number) => void,
  copyGroup: (index: number) => void,
  addGroup: () => void,
  disabled?: boolean,
}) => {
  const classes = useStyles();
  const [ exerciseForPreview, setExerciseForPreview ] = useState<Exercise|undefined>();

  return (
    <div className={classes.multiFieldsetSection}>
      <fieldset className={classes.fieldset}>
        <Box my={6}>
          <GroupForm
            name='Warmup'
            group={warmUpGroup}
            onChange={(group: ExerciseGroup) => setWarmUpGroup(group)}
            exercises={exercises}
            equipment={equipment}
            disabled={disabled}
            setExerciseForPreview={setExerciseForPreview}
          />
        </Box>

        <Box mx={2}>
          <Typography variant="h4" gutterBottom>Main Workout</Typography>
        </Box>
        {exerciseGroups.map((group, index) =>
          <Box mt={index === 0 ? 1 : 6} mb={6} key={index}>
            <GroupForm
              name={`Group ${index + 1}`}
              group={group}
              onChange={(group: ExerciseGroup) => updateGroup(index, group)}
              exercises={exercises}
              equipment={equipment}
              deleteGroup={() => removeGroup(index)}
              copyGroup={() => copyGroup(index)}
              disabled={disabled}
              setExerciseForPreview={setExerciseForPreview}
            />
          </Box>
        )}

        <Box
          display='flex'
          alignItems='center'
          justifyContent='center'
          my={4}
        >
          <Button
            color='primary'
            disabled={loading || disabled}
            onClick={addGroup}
            size='large'
            fullWidth
            variant='outlined'
            startIcon={<AddIcon />}
            className={classes.groupButton}
          >
            Add Group
          </Button>
        </Box>

        <Box my={6}>
          <GroupForm
            name='Cooldown'
            group={coolDownGroup}
            onChange={(group: ExerciseGroup) => setCoolDownGroup(group)}
            exercises={exercises}
            equipment={equipment}
            disabled={disabled}
            setExerciseForPreview={setExerciseForPreview}
          />
        </Box>
      </fieldset>
      {exerciseForPreview &&
        <ExercisePreviewDialog
          exercise={exerciseForPreview}
          open={true}
          onClose={() => setExerciseForPreview(undefined)}
        />}
    </div>
  );
};

export default WorkoutDetails;
