import {BillingDetails} from "myfitworld-model";
import {ANNUALLY_PLAN, MONTHLY_PLAN, SIX_MONTH_PLAN} from "../utils";

export interface BillingDetailsInterface {
  plan: string,
  email: string,
  phoneNumber: string,
  address: string,
  city: string,
  countryCode: string,
  postalCode: string,
  stateCode: string,
  taxID: string,
  firstName: string,
  lastName: string,
  customerId: string,
  paymentMethodId: string,
  exp_month: string,
  exp_year: string,
  last4: string
}

export const emptyBillingDetails = {
  plan: ANNUALLY_PLAN,
  email: '',
  phoneNumber: '',
  address: '',
  city: '',
  countryCode: '',
  postalCode: '',
  stateCode: '',
  taxID: '',
  firstName: '',
  lastName: '',
  customerId: '',
  paymentMethodId: '',
  exp_month: '',
  exp_year: '',
  last4: ''
};

export const getBillingDetailsInterface = (res: BillingDetails) : BillingDetailsInterface => {
  return {
    plan: res.stripe.priceId === process.env.REACT_APP_MONTHLY_PLAN_ID ? MONTHLY_PLAN : res.stripe.priceId === process.env.REACT_APP_SIX_MONTH_PLAN_ID ? SIX_MONTH_PLAN : ANNUALLY_PLAN,
    email: res.email || "",
    phoneNumber: res.phone || "",
    address: res.billing_address?.address || "",
    city: res.billing_address?.city || "",
    countryCode: res.billing_address?.countryCode || "",
    stateCode: res.billing_address?.stateCode || "",
    taxID: res.billing_address?.taxID || "",
    postalCode: res.billing_address?.postalCode || "",
    firstName: res.card?.name?.split(" ")[0] || "",
    lastName: res.card?.name?.split(" ")[1] || "",
    customerId: res.stripe.customerId || "",
    paymentMethodId: res.stripe.paymentMethodId || "",
    exp_month: res.card?.exp_month || '',
    exp_year: res.card?.exp_year || '',
    last4: res.card?.last4 || ''
  };
}

export interface BillingDetailsErrors {
  email: boolean,
  phoneNumber: boolean,
  address: boolean,
  city: boolean,
  countryCode: boolean,
  stateCode: boolean,
  postalCode: boolean,
  firstName: boolean,
  lastName: boolean
}

export const defaultBillingDetailsErrors: BillingDetailsErrors = {
    email: false,
    phoneNumber: false,
    address: false,
    city: false,
    countryCode: false,
    stateCode: false,
    postalCode: false,
    firstName: false,
    lastName: false
}

export type BillingAddressAndContact = {
  email: string,
  phoneNumber: string,
  countryCode: string,
  stateCode: string,
  taxID: string,
  address: string,
  city: string,
  postalCode: string
};

export const emptyBillingAddressAndContact = {
  email: '',
  phoneNumber: '',
  address: '',
  city: '',
  countryCode: '',
  postalCode: '',
  stateCode: '',
  taxID: ''
};

export const getInitialBillingAddressAndContact = (data: BillingDetailsInterface | null | undefined) : BillingAddressAndContact => {
  return {
    email: data?.email ?? '',
    phoneNumber: data?.phoneNumber ?? '',
    address: data?.address ?? '',
    city: data?.city ?? '',
    countryCode: data?.countryCode ?? '',
    postalCode: data?.postalCode ?? '',
    stateCode: data?.stateCode ?? '',
    taxID: data?.taxID ?? ''
  }
}

export type FullNameAndCardDetails = {
  firstName: string,
  lastName: string
};

export const getFullNameAndCardDetails = (data: BillingDetailsInterface | null | undefined) : FullNameAndCardDetails => {
  return {
    firstName: data?.firstName || '',
    lastName: data?.lastName || ''
  }
}

export const emptyFullNameAndCardDetails  = {
  firstName: '',
  lastName: ''
};
