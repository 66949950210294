import React, {useEffect, useState} from "react";
import {Box, Typography} from "@material-ui/core";
import Grid from "@material-ui/core/Grid/Grid";
import Chip from "@material-ui/core/Chip";
import getLocalizedProperty from "../../../utils/getLocalizedProperty";
import {GeneralEntity, User} from "myfitworld-model";
import {expertisesApi} from "../../../api/common";
import Link from "@material-ui/core/Link";
import ProfileImageWithName from "../../../components/avatars/ProfileImageWithName";
import {defaultSocialMediaIcon, SocialMediaMetaData} from "../../../utils/SocialMediaMetaData";

const PersonalInfo = ({trainer}: Props) => {

  const [expertises, setExpertises] = useState<Array<GeneralEntity> | []>([]);

  useEffect(() => {
      try {
        (trainer.organizations || []).forEach(async (org) => {
          const data = await expertisesApi.list(org.id)
          setExpertises(prevState =>
            [...(prevState || []), ...(data || [])]
          );
        });
      } catch (e) {
        console.log(e);
      }
    },
    [trainer]
  );

  return (
    <Box display="flex" flexDirection="column">
      <ProfileImageWithName src={trainer.avatarURL} firstName={trainer.firstName} lastName={trainer.lastName}/>
      {
        trainer.aboutMe &&
        <Box m={4} mt={1} mb={2}>
          <Typography color="textPrimary">{trainer.aboutMe}</Typography>
        </Box>
      }
      {
        trainer.expertise &&
        <Box display="flex" flexDirection="row" alignItems="center" m={4} mt={0} mb={2}>
          <Grid container spacing={1}>
            {
              trainer.expertise.map((id, ind) => {
                const sport = expertises.find((e) => e.id === id);
                return sport ? (
                  <Grid item key={ind}>
                    <Chip variant="default" color="default" size="medium" label={getLocalizedProperty(sport.name)}/>
                  </Grid>
                ) : '';
              })
            }
          </Grid>
        </Box>
      }
      {
        trainer.socialMediaLinks &&
        <Box display="flex" flexDirection="row" alignItems="center" m={4} mt={0} mb={2}>
          <Grid container spacing={2}>
            {
              trainer.socialMediaLinks.map((link, ind) => {
                const res = SocialMediaMetaData("large").find((e) => e.type === link.type);
                return (
                  <Grid item key={ind}>
                    <Link href={
                      link.url.startsWith("https://") || link.url.startsWith("http://") ?
                        link.url : "http://"+link.url
                    }>
                      {res?.icon ?? defaultSocialMediaIcon}
                    </Link>
                  </Grid>
                );
              })
            }
          </Grid>
        </Box>
      }
    </Box>
  );
};

export default PersonalInfo;

interface Props {
  trainer: User;
}
