import React from 'react';
import {Grid} from '@material-ui/core';
import BillingAddressCard from '../forms/BillingAddressCard';
import PaymentMethodCard from '../forms/PaymentMethodCard';
import {MonthlyPlan, SixMonthPlan, YearlyPlan} from "../forms/PlanCards";
import {BillingDetailsInterface} from "../utils/BillingDetailsInterface";
import {BillingDetails} from "myfitworld-model";
import {ANNUALLY_PLAN, MONTHLY_PLAN, SIX_MONTH_PLAN} from "../utils";

const BillingDetailsInfo = ({defaultState, billingDetails, onSave} : Props) => {
  return (
    <Grid
      container
      spacing={2}
    >
      <Grid item>
        {defaultState.plan === MONTHLY_PLAN &&
        <MonthlyPlan
          isSelected={false}
          onSelect={() => {}}
          action="UPDATE"
          defaultState={defaultState}
          billingDetails={billingDetails}
          onSave={onSave}
        />}
        {defaultState.plan === SIX_MONTH_PLAN &&
        <SixMonthPlan
          isSelected={false}
          onSelect={() => {}}
          action="UPDATE"
          defaultState={defaultState}
          billingDetails={billingDetails}
          onSave={onSave}
        />}
        {defaultState.plan === ANNUALLY_PLAN &&
        <YearlyPlan
          isSelected={false}
          onSelect={() => {}}
          action="UPDATE"
          defaultState={defaultState}
          billingDetails={billingDetails}
          onSave={onSave}
        />}
      </Grid>
      <Grid item>
        <BillingAddressCard
          defaultState={defaultState}
          billingDetails={billingDetails}
          onSave={onSave}
        />
      </Grid>
      <Grid item>
        <PaymentMethodCard defaultState={defaultState} billingDetails={billingDetails}/>
      </Grid>
    </Grid>
  );
}

export default BillingDetailsInfo;

interface Props {
  defaultState: BillingDetailsInterface,
  billingDetails?: BillingDetails,
  onSave: (data: BillingDetailsInterface) => void
}
