import React, {CSSProperties} from 'react';
import {Box, Chip, Typography} from '@material-ui/core';
import {Equipment} from "myfitworld-model";
import MFWChip from "../../../components/MFWChip";
import BaseContentListItem from "../../content/BaseContentListItem";

const EquipmentListItem = (
  {item, onSelect, style}:
    { item: Equipment, onSelect: (event: React.MouseEvent) => void, disabled?: boolean, style?: CSSProperties }
) => {
  const tags = [
    [item.incline, 'Incline'],
    [item.speed, 'Speed'],
    [item.weight, 'Weight'],
    [item.rpm, 'Rpm'],
    [item.pace, 'Pace']
  ].filter(tag => tag[0]).map(tag => tag[1])

  return (
    <BaseContentListItem onSelect={onSelect} style={style}>
      <Box display="flex" flexDirection="column">
        <MFWChip organizationId={item.organizationId}/>
        <Box display="flex" flexDirection="row" alignItems="center">
          <Typography variant="h5">{item.name.en}</Typography>
          <Box display="flex" alignItems="center" flexWrap="nowrap" ml={0.5}>
            {tags.map((tag, i) => (
                <Box key={i} m={.25}>
                  <Chip
                    key={i}
                    size='small'
                    label={tag}
                  />
                </Box>
              )
            )}
          </Box>
        </Box>
      </Box>
    </BaseContentListItem>
  );
};

export default EquipmentListItem;
