import React, {CSSProperties, Fragment, useCallback, useState} from "react";
import ChatBubbleIcon from "@material-ui/icons/ChatBubble";
import {IconButton} from "@material-ui/core";
import ChatDialog from "./ChatDialog";

const ChatButton = ({userId, buttonStyle, iconStyle}: Props) => {
  const [isOpen, setOpen] = useState(false);
  const handleDialogOpen = useCallback((event: any) => {
    event.stopPropagation();
    setOpen(true);
  }, []);
  const handleDialogClose = useCallback((event: any) => {
    event.stopPropagation();
    setOpen(false);
  }, []);
  return (
    <Fragment>
      <IconButton onClick={handleDialogOpen} style={{...(buttonStyle || null)}}>
        <ChatBubbleIcon style={{...(iconStyle || null)}}/>
      </IconButton>
      {userId && isOpen && <ChatDialog isOpen={isOpen} handleClose={handleDialogClose} userId={userId}/>}
    </Fragment>
  );
};
export default ChatButton;

interface Props {
  userId?: string;
  buttonStyle?: CSSProperties;
  iconStyle?: CSSProperties;
}
