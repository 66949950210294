import {WorkoutDay} from "myfitworld-model";
import format from "date-fns/format";
import groupBy from 'lodash/fp/groupBy';
import flow from 'lodash/fp/flow';
import sortBy from 'lodash/fp/sortBy'

const groupWorkoutsByDate = (workouts: WorkoutDay[]): Record<string,WorkoutDay[]> => {
  return flow(
    sortBy('dateTime'),
    groupBy((item: WorkoutDay) => format(new Date(item.dateTime), 'yyyy-MM-dd')),
  )(workouts)
};

export default groupWorkoutsByDate;
