import React from "react";
import { InputLabel } from "@material-ui/core";

const Label = ({
  hasValue,
  changeLabel,
  uploadLabel,
} : {
  hasValue: boolean,
  changeLabel: string,
  uploadLabel: string,
}) =>

  <InputLabel shrink>{hasValue ? changeLabel : uploadLabel}</InputLabel>;

export default Label;

