import React, {useEffect, useState} from 'react';
import {Box, Typography} from "@material-ui/core";
import BillingDetailsInfo from "../../components/payment/view/BillingDetailsInfo";
import {getOrganizationBillingDetails} from "../../api/billingDetailsApi";
import {useStoreState} from "pullstate";
import globalState from "../../globalState";
import {
  BillingDetailsInterface,
  emptyBillingDetails,
  getBillingDetailsInterface
} from "../../components/payment/utils/BillingDetailsInterface";
import {BillingDetails} from "myfitworld-model";
import SideDrawer from "../../components/SideDrawer";
import Tab from "@material-ui/core/Tab";
import Tabs from "@material-ui/core/Tabs";
import TabPanel from "../../components/TabPanel";
import UpcomingInvoiceItemsTable from "./UpcomingInvoiceItemsTable";
import InvoicesTable from "./InvoicesTable";

const BillingPage = () => {

  const [ selectedTab, setSelectedTab ] = useState(0);
  const onChangeTab = (event: React.ChangeEvent<{}>, newValue: number) => {
    setSelectedTab(newValue);
  };

  const currentOrganization = useStoreState(globalState, s => s.currentOrganization);
  const [openDialog, setOpenDialog] = useState<boolean>(false);

  const [values, setValues] = useState<BillingDetailsInterface>(emptyBillingDetails);
  const [billingDetails, setBillingDetails] = useState<BillingDetails | undefined>(undefined);

  useEffect(() => {
    currentOrganization && currentOrganization.id && getOrganizationBillingDetails(currentOrganization.id).then(
      (res) => {
        if (res) {
          setValues(getBillingDetailsInterface(res));
          setBillingDetails(res);
        }
      }
    );
  }, [currentOrganization]);

  return (
    <Box mb={8}>
      <Box display="flex" justifyContent="center">
        <Tabs
          value={selectedTab}
          onChange={onChangeTab}
          variant='scrollable'
          scrollButtons='auto'
          textColor='primary'
        >
          <Tab label={"Invoices"} />
          <Tab label={"Upcoming Invoice"} />
        </Tabs>
      </Box>
      <TabPanel value={selectedTab} index={0}>
        <InvoicesTable billingDetails={billingDetails} setOpenDialog={setOpenDialog}/>
      </TabPanel>
      <TabPanel value={selectedTab} index={1}>
        <UpcomingInvoiceItemsTable billingDetails={billingDetails} setOpenDialog={setOpenDialog}/>
      </TabPanel>
      <SideDrawer isOpen={openDialog} onClose={() => setOpenDialog(false)}>
        <Typography variant="h4">Edit Billing Details</Typography>
        <Box mt={4}>
          <BillingDetailsInfo
            defaultState={values}
            billingDetails={billingDetails}
            onSave={(data: BillingDetailsInterface) => setValues(data)}
          />
        </Box>
      </SideDrawer>
    </Box>
  );
};

export default BillingPage;




