import React, {CSSProperties, useCallback, useRef} from "react";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import {createStyles, InputLabel, Theme, Typography} from '@material-ui/core';
import {makeStyles} from "@material-ui/core/styles";

const modules = {
  toolbar: [
    ["clean"],
    ["bold", "italic", "underline", "strike", "blockquote"],
    [
      { list: "ordered" },
      { list: "bullet" },
      { indent: "-1" },
      { indent: "+1" }
    ]
  ]
};

const formats = [
  "bold",
  "italic",
  "underline",
  "strike",
  "blockquote",
  "list",
  "bullet",
  "indent"
];

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    label: {
      display: 'flex',
      flexDirection: 'column',
      flex: 1,
    }
  })
);

const RichTextEditor = ({onChange, label, initialValue, disabled, style} : Props) => {
  const reactQuillRef = useRef<any>();
  const classes = useStyles();

  const handleChange = useCallback(() => {
    if (reactQuillRef && reactQuillRef.current) {
      const editor = (reactQuillRef.current as any).getEditor();
      const unprivilegedEditor = (reactQuillRef.current as any).makeUnprivilegedEditor(
        editor
      );
      onChange && onChange({ ops: unprivilegedEditor.getContents(0).ops });
    }
  }, [onChange, reactQuillRef]);

  try {
    return (
      <InputLabel className={classes.label}>
        { label && <Typography component="legend" color="textSecondary" variant="caption" gutterBottom>{label}</Typography> }
        <ReactQuill
          ref={reactQuillRef}
          onChange={handleChange}
          modules={disabled ? { toolbar: false } : modules}
          formats={formats}
          defaultValue={initialValue}
          readOnly={disabled}
          style={{ opacity: 0.75, ...style}}
        />
      </InputLabel>
    );
  } catch (e) {
    return null;
  }
}

export default RichTextEditor;

interface Props {
  onChange: (value: any) => void;
  initialValue: any;
  label?: string;
  disabled?: boolean;
  style?: CSSProperties;
}
