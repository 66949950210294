import {OrganizationUser} from "myfitworld-model";
import {useLocalizationProvider} from "../../providers/LocalizationProvider";
import {
  Box,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Tooltip,
  Typography,
} from "@material-ui/core";
import formatDistanceToNow from "date-fns/formatDistanceToNow";
import React, {Fragment, useEffect, useState} from "react";
import useTableStyles from "../../theme/useTableStyles";
import {firestore} from "../../firebase";
import Role from "myfitworld-model/dist/enums/Role";
import WarningIcon from "@material-ui/icons/Warning";
import ChatButton from "../chat/ChatButton";
import LoadingSpinner from "../LoadingSpinner";
import ProfileImage from "../avatars/ProfileImage";
import {getUserFullName} from "../../utils/getUserFullName";
import {getUserInitials} from "../../utils/getUserInitials";
import {useTheme} from "@material-ui/core/styles";
import safeInvoke from "../../utils/safeInvoke";
import uniqBy from "lodash/uniqBy";
import SimpleMenu from "../SimpleMenu";
import {updateUserOrganizations} from "../../api/usersApi";
import globalState, {GlobalState} from "../../globalState";
import Switch from "@material-ui/core/Switch";
import {useUserProvider} from "../../providers/UserProvider";


const UsersTable =
  ({
     roles,
     onClick,
     showRole,
     disableOrganizationFilter,
     showWarnings,
     filterFn,
     disabled
   }: Props) => {
    const theme = useTheme();
    const classes = useTableStyles();
    const {translate} = useLocalizationProvider();
    const {user: authUser} = useUserProvider();
    const [users, setUsers] = useState<OrganizationUser[]>([]);
    const [loading, setLoading] = useState<boolean>(true);

    useEffect(() => {
      const organizationUserRef = firestore.collection('organizationUser');

      if (authUser?.currentOrganization && !disableOrganizationFilter) {
        roles.forEach(
          (role, index, arr) => organizationUserRef
            .where('orgId', '==', authUser?.currentOrganization)
            .where('role', '==', role)
            .onSnapshot((querySnapshot) => {
                const data: Array<OrganizationUser> = [];
                querySnapshot.forEach(snapshot => {
                  data.push({
                    id: snapshot.id,
                    ...snapshot.data()
                  } as OrganizationUser);
                });
                setUsers(prevState => [...prevState, ...data]);
                if (arr.length === index + 1) {
                  setLoading(false);
                }
              }
            )
        );
      } else {
        organizationUserRef
          .where('role', '==', Role.SuperAdmin)
          .onSnapshot((querySnapshot) => {
              const data: Array<OrganizationUser> = [];
              querySnapshot.forEach(snapshot => {
                data.push({
                  id: snapshot.id,
                  ...snapshot.data(),
                } as OrganizationUser);
              });
              setUsers(prevState => [...prevState, ...data]);
              setLoading(false);
            }
          )
      }
    }, [authUser, roles, disableOrganizationFilter]);

    const toggleArchive = async (userData: OrganizationUser) => {
      const archived = !userData.archived;
      userData && await updateUserOrganizations({
        id: userData?.userId,
        organizations: userData?.user?.organizations?.map(
          (orgUser) => {
            return orgUser.id === userData.orgId && orgUser.role === userData.role
              ? {...orgUser, archived}
              : orgUser;
          }
        )
      });

      setUsers(prevState => prevState.filter((e) => e.id !== userData.id));
      globalState.update((state: GlobalState) => {
        state.toastQueue.push({message: `Changes saved!`, severity: "success"});
      });
    };

    const toggleAllowedToAddNewClients = async (userData: OrganizationUser) => {
      const role = userData.role === Role.Trainer ? Role.AssistantTrainer : Role.Trainer;
      userData && await updateUserOrganizations({
        id: userData?.userId,
        organizations: userData?.user?.organizations?.map(
          (orgUser) => {
            return orgUser.id === userData.orgId && orgUser.role === userData.role
              ? {...orgUser, role}
              : orgUser;
          }
        )
      });

      setUsers(prevState => prevState.map((user) => {
          return user.id === userData.id ? {...user, role} : user;
      }));
      globalState.update((state: GlobalState) => {
        state.toastQueue.push({message: `Changes saved!`, severity: "success"});
      });
    };

    const filteredUsers: OrganizationUser[] = uniqBy(filterFn && users ? filterFn(users) : users, 'id');

    return (
      <Box>
        {loading && <LoadingSpinner marginTop={50}/>}
        {!loading &&
        <Fragment>
          <TableContainer>
            <Table className={classes.table}>
              <TableHead>
                <TableRow className={classes.tableRow}>
                  <TableCell>{translate("Name")}</TableCell>
                  <TableCell align="right">{translate("Email")}</TableCell>
                  <TableCell align="right">{translate("Phone")}</TableCell>
                  {showRole && <TableCell align="right">{translate("Role")}</TableCell>}
                  <TableCell align="right">{translate("Created At")}</TableCell>
                  <TableCell align="right">{translate("Last Sign In")}</TableCell>
                  {
                    roles.includes(Role.Trainer) && !disabled &&
                    <TableCell align="right">{translate("Clients")}</TableCell>
                  }
                  {
                    roles.includes(Role.Trainer) && !disabled &&
                    <TableCell align="left">{translate("Can Add New Clients?")}</TableCell>
                  }
                  {
                    !roles.includes(Role.Client) && !roles.includes(Role.SuperAdmin) &&
                    <TableCell align="right">{translate("Actions")}</TableCell>
                  }
                </TableRow>
              </TableHead>
              <TableBody>
                {filteredUsers.map((orgUser) => {
                  const {user} = orgUser;
                  return (
                    <TableRow key={orgUser.id} onClick={() => onClick && onClick(orgUser.userId!, orgUser)} hover
                              className={classes.tableRow}>
                      <TableCell component="th" scope="row">
                        <Box display="flex" flexDirection="row" alignItems="center">
                          <Box ml={1}>
                            {(!showRole && !disableOrganizationFilter) &&
                            <ChatButton
                              buttonStyle={{padding: theme.spacing(1)}}
                              iconStyle={{fontSize: theme.spacing(2.5)}}
                              userId={orgUser.userId}
                            />
                            }
                          </Box>
                          {showWarnings && !(user?.trainers && Array.isArray(user?.trainers) && user?.trainers?.length > 0) && (
                            <Tooltip title="No trainer assigned">
                              <WarningIcon
                                style={{marginRight: 6, height: 18, color: "#ffc107", verticalAlign: "sub"}}/>
                            </Tooltip>
                          )}
                          <ProfileImage
                            src={user?.avatarURL}
                            initials={getUserInitials(user?.firstName, user?.lastName)}
                            fontSize={theme.spacing(2.5)}
                            size={theme.spacing(4)}
                            mirror={!!roles.find(e => e === Role.Client) && user?.avatarURL?.startsWith("https://firebasestorage.googleapis.com/")}
                          />
                          <Box ml={2}>
                            <Typography variant="h6">{getUserFullName(user?.firstName, user?.lastName)}</Typography>
                          </Box>
                        </Box>
                      </TableCell>
                      <TableCell align="right">{user?.email || ""}</TableCell>
                      <TableCell align="right">{user?.phoneNumber || ""}</TableCell>
                      {showRole && (
                        <TableCell align="right">
                          {orgUser.role}
                        </TableCell>
                      )}
                      <TableCell align="right">
                        {
                          user?.createdAt && safeInvoke('toDate')(user.createdAt) &&
                          formatDistanceToNow(safeInvoke('toDate')(user.createdAt), {addSuffix: true})
                        }
                      </TableCell>
                      <TableCell align="right">
                        {
                          user?.lastSignInTime && safeInvoke('toDate')(user.lastSignInTime) &&
                          formatDistanceToNow(safeInvoke('toDate')(user.lastSignInTime), {addSuffix: true})
                        }
                      </TableCell>
                      {
                        roles.includes(Role.Trainer) && !disabled &&
                        <TableCell align="right">
                          {user?.clients?.length || 0}
                        </TableCell>
                      }
                      {
                        roles.includes(Role.Trainer) && !disabled &&
                        <TableCell align="left">
                          <Switch
                            color="primary"
                            checked={orgUser.role === Role.Trainer}
                            onChange={async () => {
                              await toggleAllowedToAddNewClients(orgUser);
                            }}
                            disabled={!!(authUser && authUser.id === orgUser.userId)}
                          />
                          <Typography style={{display: 'inline-flex'}}>
                            {orgUser.role !== Role.AssistantTrainer ?
                              translate('Allowed to add new clients') :
                              translate('Can’t add new clients')
                            }
                          </Typography>
                        </TableCell>
                      }
                      {
                        orgUser.role !== Role.Client && !roles.includes(Role.SuperAdmin) && <TableCell align="right">
                          <SimpleMenu
                            options={[
                              orgUser.archived ? {label: 'Unarchive', action: 'UNARCHIVE'} : {
                                label: 'Archive',
                                action: 'ARCHIVE'
                              },
                            ]}
                            onSelect={async (action?: string) => {
                              if (action === "ARCHIVE" || action === "UNARCHIVE") {
                                await toggleArchive(orgUser);
                              }
                            }}
                            disabled={!!(authUser && authUser.id === orgUser.userId)}
                          />
                        </TableCell>
                      }
                    </TableRow>
                  )
                })}
              </TableBody>
            </Table>
          </TableContainer>
        </Fragment>
        }
      </Box>
    );
  };

export default UsersTable;

interface Props {
  roles: Role[];
  onClick?: (userId: string, user?: OrganizationUser) => void;
  showRole: boolean;
  disableOrganizationFilter?: boolean;
  showWarnings?: boolean;
  filterFn?: (data: OrganizationUser[]) => OrganizationUser[];
  disabled?: boolean;
}
