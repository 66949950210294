import {makeStyles} from "@material-ui/core/styles";

const useLoginPageStyles = makeStyles((theme) => ({
  root: {
    height: '100vh',
  },
  image: {
    backgroundRepeat: 'no-repeat',
    backgroundColor:
      theme.palette.type === 'light' ? theme.palette.grey[50] : theme.palette.grey[900],
    overflow: 'hidden',
    height: '100vh',
    position: 'relative',

    '&::after': {
      backgroundColor: theme.palette.background.default,
      content: '',
      display: 'block',
      width: '100vw',
      height: '100vh',
      mixBlendMode: 'hard-light',
      position: 'absolute',
      top: 0,
      left: 0,
      zIndex: 2,
    },

    '&::before': {
      backgroundColor: theme.palette.primary.main,
      content: '""',
      display: 'block',
      width: '100vw',
      height: '100vh',
      mixBlendMode: 'multiply',
      position: 'absolute',
      top: 0,
      left: 0,
      zIndex: 1,
    },

    '& img': {
      objectFit: 'cover',
      position: 'relative',
      filter: 'grayscale(1)',
    },
  },
  paper: {
    margin: theme.spacing(8, 6),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.secondary.main,
  },
  form: {
    width: '100%', // Fix IE 11 issue.
    marginTop: theme.spacing(1),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
}));

export default useLoginPageStyles;
