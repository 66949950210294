import React, {useEffect, useState} from "react";
import {Controller, useForm} from "react-hook-form";
import {Equipment, GeneralEntity} from "myfitworld-model";
import {Accordion, Badge, Box, FormControlLabel, Grid, Switch, TextField, Typography} from "@material-ui/core";
import ContentFilterValues from "../../model/ContentFilter";
import {useLocalizationProvider} from "../../providers/LocalizationProvider";
import initialContentFilterState from "../../utils/initialContentFilterState";
import DifficultyLevel from "myfitworld-model/dist/enums/DifficultyLevel";
import AccordionSummary from "@material-ui/core/AccordionSummary";
import AccordionDetails from "@material-ui/core/AccordionDetails";
import FilterListIcon from "@material-ui/icons/FilterList";
import AutoselectField from "../../components/AutoselectField";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import {ContentFilterOptions} from "../../utils/contentFilterOptions";
import Visible from "../../components/Visibile";
import useOrganization from "../../hooks/useOrganization";
import MuscleRegimeOptions from "../../utils/MuscleRegimeOptions";
import useGlobalCachedResourceList from "../../api/useGlobalCachedResourceList";

const difficultyLevels = [
  DifficultyLevel.BEGINNER,
  DifficultyLevel.MODERATE,
  DifficultyLevel.INTERMEDIATE,
  DifficultyLevel.ADVANCED,
  DifficultyLevel.OLYMPIC,
].map((level) => ({id: `${level}`, name: {en: `${level}`}}));

const countActiveFilters = (values: ContentFilterValues) => {
  return Object.keys(values).reduce((acc: number, key: string) => {
    if (values[key as keyof ContentFilterValues] && key !== "showMFWOriginals") {
      acc += 1;
    }
    return acc;
  }, 0);
};

const ContentFilter = ({
                         onFilterChange,
                         disabled = false,
                         options,
                         query,
                         setQuery,
                         onAccordionToggle
                       }: {
  onFilterChange: (filter: ContentFilterValues) => void;
  disabled?: boolean;
  options: ContentFilterOptions;
  query: string;
  setQuery: React.Dispatch<React.SetStateAction<string>>;
  onAccordionToggle?: (value: boolean) => void;

}) => {
  const {control, watch, getValues} = useForm<ContentFilterValues>({
    defaultValues: options.showMFWOriginals ? {
      ...initialContentFilterState,
      showMFWOriginals: options.showMFWOriginals
    } : initialContentFilterState
  });

  const sportsWatcher = watch("sports");
  const muscleRegimeWatcher = watch("muscleRegime");
  const muscleGroupsWatcher = watch("muscleGroups");
  const secondaryMuscleGroupsWatcher = watch("secondaryMuscleGroups");
  const motorSkillsWatcher = watch("motorSkills");
  const healthIssuesWatcher = watch("healthIssues");
  const equipmentWatcher = watch("equipment");
  const difficultyLevelWatcher = watch("difficultyLevel");
  const fitnessActivityWatcher = watch("fitnessActivity");
  const goalsWatcher = watch("goals");
  const archivedWatcher = watch("archived");
  const showMFWOriginalsWatcher = watch("showMFWOriginals");

  const {organizationId} = useOrganization();

  useEffect(() => {
    const state = {
      sports: sportsWatcher,
      muscleRegime: muscleRegimeWatcher,
      muscleGroups: muscleGroupsWatcher,
      secondaryMuscleGroups: secondaryMuscleGroupsWatcher,
      motorSkills: motorSkillsWatcher,
      healthIssues: healthIssuesWatcher,
      equipment: equipmentWatcher,
      difficultyLevel: difficultyLevelWatcher,
      fitnessActivity: fitnessActivityWatcher,
      goals: goalsWatcher,
      archived: archivedWatcher,
      showMFWOriginals: organizationId === process.env.REACT_APP_MFW_ORGANIZATION_ID ? true : showMFWOriginalsWatcher,
    };
    onFilterChange(state as ContentFilterValues);
  }, [
    sportsWatcher,
    muscleRegimeWatcher,
    muscleGroupsWatcher,
    secondaryMuscleGroupsWatcher,
    motorSkillsWatcher,
    healthIssuesWatcher,
    fitnessActivityWatcher,
    equipmentWatcher,
    difficultyLevelWatcher,
    archivedWatcher,
    onFilterChange,
    goalsWatcher,
    organizationId,
    showMFWOriginalsWatcher,
  ]);

  const {translate} = useLocalizationProvider();
  const {data: MuscleGroupsOptions} = useGlobalCachedResourceList<GeneralEntity>('muscleGroupsCache');
  const {data: SportOptions} = useGlobalCachedResourceList<GeneralEntity>('sportsCache');
  const {data: MotorSkillsOptions} = useGlobalCachedResourceList<GeneralEntity>('motorSkillsCache');
  const {data: HealthIssueOptions} = useGlobalCachedResourceList<GeneralEntity>('healthIssuesCache');
  const {data: EquipmentOptions} = useGlobalCachedResourceList<Equipment>('equipmentCache');
  const {data: FitnessActivitiesOptions} = useGlobalCachedResourceList<GeneralEntity>('fitnessActivitiesCache');
  const {data: GoalsOptions} = useGlobalCachedResourceList<GeneralEntity>('goalsCache');
  const [expanded, setExpanded] = useState(false);

  const muscleRegimeOptions = MuscleRegimeOptions.map(options => ({
    id: options.value,
    name: {en: translate(options.label)}
  }));

  const handleAccordionToggle = () => {
    onAccordionToggle && onAccordionToggle(!expanded);
    setExpanded(!expanded);
  };

  return (
    <Box mt={1} mb={1}>
      <Accordion onChange={handleAccordionToggle}>
        <AccordionSummary expandIcon={<ExpandMoreIcon/>}>
          <Box display="flex" alignItems="center">
            <Box mr={2}>
              <Badge badgeContent={countActiveFilters(getValues())} color="primary">
                <FilterListIcon color="disabled"/>
              </Badge>
            </Box>
            <Box>
              <Typography variant="body1">Filters</Typography>
              <Typography variant="body2" color="textSecondary">Click to {expanded ? 'collapse' : 'expand'}</Typography>
            </Box>
          </Box>
        </AccordionSummary>
        <AccordionDetails>
          <Grid container spacing={2} direction="row">
            <Visible if={options.sports}>
              <Grid item xs={12} md={4} lg={3}>
                <Controller
                  name={"sports"}
                  control={control}
                  render={({onChange, value}) => (
                    <AutoselectField
                      label={translate("Sports")}
                      options={SportOptions}
                      disabled={disabled}
                      value={value}
                      onChange={(values) => onChange(values)}
                    />
                  )}
                />
              </Grid>
            </Visible>
            <Visible if={options.muscleRegime}>
              <Grid item xs={12} md={4} lg={3}>
                <Controller
                  name={"muscleRegime"}
                  control={control}
                  render={({onChange, value}) => (
                    <AutoselectField
                      label={translate("MuscleRegimeLabel")}
                      options={muscleRegimeOptions}
                      disabled={disabled}
                      value={value}
                      onChange={(values) => onChange(values)}
                    />
                  )}
                />
              </Grid>
            </Visible>
            <Visible if={options.muscleGroups}>
              <Grid item xs={12} md={4} lg={3}>
                <Controller
                  name={"muscleGroups"}
                  control={control}
                  render={({onChange, value}) => (
                    <AutoselectField
                      label={translate("Muscle Groups")}
                      options={MuscleGroupsOptions}
                      disabled={disabled}
                      value={value}
                      onChange={(values) => onChange(values)}
                    />
                  )}
                />
              </Grid>
            </Visible>
            <Visible if={options.secondaryMuscleGroups}>
              <Grid item xs={12} md={4} lg={3}>
                <Controller
                  name={"secondaryMuscleGroups"}
                  control={control}
                  render={({onChange, value}) => (
                    <AutoselectField
                      label={translate("Secondary Muscle Groups")}
                      options={MuscleGroupsOptions}
                      disabled={disabled}
                      value={value}
                      onChange={(values) => onChange(values)}
                    />
                  )}
                />
              </Grid>
            </Visible>
            <Visible if={options.fitnessActivity}>
              <Grid item xs={12} md={4} lg={3}>
                <Controller
                  name={"fitnessActivity"}
                  control={control}
                  render={({onChange, value}) => (
                    <AutoselectField
                      label={translate("Activity")}
                      options={FitnessActivitiesOptions}
                      disabled={disabled}
                      value={value}
                      onChange={(values) => onChange(values)}
                    />
                  )}
                />
              </Grid>
            </Visible>
            <Visible if={options.goals}>
              <Grid item xs={12} md={4} lg={3}>
                <Controller
                  name={"goals"}
                  control={control}
                  render={({onChange, value}) => (
                    <AutoselectField
                      label={translate("Goals")}
                      options={GoalsOptions}
                      disabled={disabled}
                      value={value}
                      onChange={(values) => onChange(values)}
                    />
                  )}
                />
              </Grid>
            </Visible>
            <Visible if={options.motorSkills}>
              <Grid item xs={12} md={4} lg={3}>
                <Controller
                  name={"motorSkills"}
                  control={control}
                  render={({onChange, value}) => (
                    <AutoselectField
                      label={translate("Motor Skills")}
                      options={MotorSkillsOptions}
                      disabled={disabled}
                      value={value}
                      onChange={(values) => onChange(values)}
                    />
                  )}
                />
              </Grid>
            </Visible>
            <Visible if={options.healthIssues}>
              <Grid item xs={12} md={4} lg={3}>
                <Controller
                  name={"healthIssues"}
                  control={control}
                  render={({onChange, value}) => (
                    <AutoselectField
                      label={translate("Suitable for health issues")}
                      options={HealthIssueOptions}
                      disabled={disabled}
                      value={value}
                      onChange={(values) => onChange(values)}
                    />
                  )}
                />
              </Grid>
            </Visible>
            <Visible if={options.equipment}>
              <Grid item xs={12} md={4} lg={3}>
                <Controller
                  name={"equipment"}
                  control={control}
                  render={({onChange, value}) => (
                    <AutoselectField
                      label={translate("Equipment")}
                      options={EquipmentOptions}
                      disabled={disabled}
                      value={value}
                      onChange={(values) => onChange(values)}
                    />
                  )}
                />
              </Grid>
            </Visible>
            <Visible if={options.difficultyLevel}>
              <Grid item xs={12} md={4} lg={3}>
                <Controller
                  name={"difficultyLevel"}
                  control={control}
                  render={({onChange, value}) => (
                    <AutoselectField
                      label={translate("Training Level")}
                      options={difficultyLevels}
                      disabled={disabled}
                      value={value}
                      onChange={(values) => onChange(values)}
                    />
                  )}
                />
              </Grid>
            </Visible>
            <Visible if={options.archived}>
              <Grid item xs={12} md={4} lg={3} style={{alignSelf: "center"}}>
                <Controller
                  name={"archived"}
                  control={control}
                  render={({onChange, value}) => (
                    <FormControlLabel
                      control={
                        <Switch
                          checked={value}
                          onChange={(e) => onChange(e.target.checked)}
                          color="primary"
                          disabled={disabled}
                        />
                      }
                      label={translate("button.archived")}
                    />
                  )}
                />
              </Grid>
            </Visible>
          </Grid>
        </AccordionDetails>
      </Accordion>

      <Box mt={2}>
        <Grid container spacing={2}>
          <Grid item md={4} xs={12}>
            <TextField
              label={translate('workout.contentfilter.searchfield.label')}
              placeholder={translate('workout.contentfilter.searchfield.placeholder')}
              name='query'
              onChange={e => setQuery(e.target.value)}
              value={query}
              fullWidth
              style={{flexGrow: 1}}
            />
          </Grid>

          <Grid item md={4} xs={12}>
            {organizationId !== process.env.REACT_APP_MFW_ORGANIZATION_ID &&
            <Controller
              name={"showMFWOriginals"}
              control={control}
              render={({onChange, value}) => (
                <FormControlLabel
                  style={{marginLeft: 4, flexGrow: 1, flexShrink: 0}}
                  control={
                    <Switch
                      checked={value}
                      onChange={(e) => onChange(e.target.checked)}
                      color="primary"
                      disabled={disabled}
                    />
                  }
                  label={translate("Show MFW Originals")}
                />
              )}
            />
            }
          </Grid>
        </Grid>
      </Box>

    </Box>
  );
};

export default ContentFilter;
