import React, {useEffect} from 'react';
import {RouteComponentProps} from '@reach/router';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import PageContainer from '../PageContainer';
import ExercisesManagementPage from "./exercise/ExercisesManagementPage";
import WorkoutManagementPage from "./workout/WorkoutManagementPage";
import TabPanel from "../../components/TabPanel";
import ProgramTemplateManagementPage from "./program/ProgramTemplateManagementPage";
import {useLocalizationProvider} from "../../providers/LocalizationProvider";
import Box from '@material-ui/core/Box/Box';
import useNavigation from "../../hooks/useNavigation";
import {createStyles, makeStyles, Theme} from "@material-ui/core";

const indexToRoute = {
  0: 'exercises',
  1: 'workouts',
  2: 'programs',
};

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    tab: {
      minHeight: 32,
      paddingTop: theme.spacing(0),
      paddingBottom: theme.spacing(0),
    }
  })
)

const ContentManagementPage = (props: RouteComponentProps) => {
  const { navigation } = useNavigation();

  const styles = useStyles();

  useEffect(() => {
    navigation('/content?tab=programs', undefined, true);
    // Use no `deps as it would cause constant refreshes;
    // adding `navigation` would also cause unneeded refresh
    // eslint-disable-next-line
  }, []);

  const [value, setValue] = React.useState(2);
  const { translate } = useLocalizationProvider();

  const handleChange = async (event: React.ChangeEvent<{}>, newValue: 0 | 1 | 2) => {
    const r = await navigation(`/content?tab=${indexToRoute[newValue]}`);
    if (r) {
      setValue(newValue);
    }
  };

  return (
    <PageContainer>
      <Tabs
        centered
        value={value}
        onChange={handleChange}
        aria-label='Content navigation'
        textColor='primary'
      >
        <Tab label={translate('Exercises')} className={styles.tab}/>
        <Tab label={translate('Workouts')} className={styles.tab}/>
        <Tab label={translate('Programs')} className={styles.tab}/>
      </Tabs>
      <TabPanel value={value} index={0}>
        <Box>
          <ExercisesManagementPage />
        </Box>
      </TabPanel>
      <TabPanel value={value} index={1}>
        <Box >
          <WorkoutManagementPage />
        </Box>
      </TabPanel>
      <TabPanel value={value} index={2}>
        <Box >
          <ProgramTemplateManagementPage />
        </Box>
      </TabPanel>
    </PageContainer>
  );
};

export default ContentManagementPage;
