import React from "react";
import {Box, Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle} from "@material-ui/core";

const SimpleDialog = ({
  title,
  text,
  open,
  onClose,
  onConfirm,
  onConfirmLabel,
  onCancel,
  onCancelLabel,
  children,
}: {
  title: string,
  text: string,
  open: boolean,
  onClose: () => void,
  onConfirm: () => void,
  onConfirmLabel: string,
  onCancel: () => void,
  onCancelLabel: string,
  children?: React.ReactElement
}) => {
  return (
    <Dialog
      open={open}
      onClose={onClose}
    >
      <DialogTitle id="alert-dialog-title">{title.toUpperCase()}</DialogTitle>
      <Box>
        <DialogContent>
          <DialogContentText>
            {text}
          </DialogContentText>
          {children}
        </DialogContent>
      </Box>
      <DialogActions>
        <Button onClick={onConfirm} color="primary">
          {onConfirmLabel}
        </Button>
        <Button onClick={onCancel} color="primary">
          {onCancelLabel}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default SimpleDialog;
