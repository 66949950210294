import {StatsExercise, StatsWorkout} from "myfitworld-model";
import {firestore} from "../firebase";
import {isNil, omitBy} from 'lodash/fp';
import safeInvoke from "../utils/safeInvoke";

export const saveWorkoutStatistics = (
  userId: string,
  workoutStatistics: any,
  action: 'INSERT' | 'UPDATE',
  workoutStatisticId?: string
): Promise<void | any> => {
  const workoutStatisticRef = firestore
    .collection('users')
    .doc(userId)
    .collection('statistics');
  if (action === 'INSERT')
    return workoutStatisticRef.add(omitBy(isNil)(workoutStatistics));
  else
    return workoutStatisticRef
      .doc(workoutStatisticId)
      .set(omitBy(isNil)(workoutStatistics), { merge: true });
};

export const getStatisticsForSpecificWorkout = async (workoutId: string, userId: string): Promise<StatsWorkout[]> => {
  const statistics = (
    await firestore
      .collection("users")
      .doc(userId)
      .collection("statistics")
      .where("workoutId", "==", workoutId)
      .orderBy("finishedAt", "desc")
      .limit(1)
      .get()
  ).docs.map(async (stat) => {
    const statistic = {...stat.data(), id: stat.id, startedAt: safeInvoke("toDate")(stat.data().startedAt)};
    const exercises = await getStatsExercises(userId, statistic.id).get();
    return {
      ...statistic,
      exercises: exercises.docs.map((ex) => ({...ex.data(), id: ex.id} as StatsExercise)),
    } as StatsWorkout;
  });
  return Promise.all(statistics);
};

export const getStatsExercises = (userId: string, statisticsId: string) =>
  firestore
    .collection("users")
    .doc(userId)
    .collection("statistics")
    .doc(statisticsId)
    .collection("statisticsExercises")
    .orderBy("order", "asc");
