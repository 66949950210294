import React, {useState} from 'react';
import {Card, CardContent, Typography, Button} from '@material-ui/core';
import CardDetailsDialog from "../dialogs/CardDetailsDialog";
import {
  BillingDetailsErrors,
  BillingDetailsInterface,
  defaultBillingDetailsErrors
} from "../utils/BillingDetailsInterface";
import {BillingDetails} from "myfitworld-model";

const PaymentMethodCard = ({defaultState, billingDetails} : Props) => {
  const [openDialog, setOpenDialog] = useState<boolean>(false);
  const nameOnCard = `${defaultState.firstName} ${defaultState.lastName}`;

  const [ errors, setErrors ] = useState<BillingDetailsErrors>(defaultBillingDetailsErrors);

  return (
    <Card variant='outlined' style={{width: 435, height: 220}}>
      <CardContent>
        <Typography variant='body2' color="textSecondary">
          Payment method:
        </Typography>
        <Typography variant='body1'>
          {'**** **** **** '}
          {`${defaultState.last4}`}
          {` (expires on ${defaultState.exp_month}/${defaultState.exp_year})`}
        </Typography>

        <Typography variant='body2' color="textSecondary">
          Name on card:
        </Typography>
        <Typography variant='body1'>
          {nameOnCard}
        </Typography>

        <Button color="primary" onClick={() => setOpenDialog(true)}>
          Change Card
        </Button>
      </CardContent>
      <CardDetailsDialog
        open={openDialog}
        action="UPDATE"
        defaultState={defaultState}
        onClose={() => setOpenDialog(false)}
        errors={errors}
        setErrors={setErrors}
        billingDetails={billingDetails}
      />
    </Card>
  );
};

export default PaymentMethodCard;

interface Props {
  defaultState: BillingDetailsInterface,
  billingDetails?: BillingDetails
}
