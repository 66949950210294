import {Workout, WorkoutMinimal} from "myfitworld-model";

export const getWorkoutExercisesCount = (item: Workout): string => {
  const exercises =
    (item?.coolDownGroup?.exercises.length || 0) +
    (item?.warmUpGroup?.exercises.length || 0) +
    (item?.exerciseGroups?.reduce((acc, group) => acc + (group?.exercises.length || 0), 0) || 0);
  return `${exercises} ${exercises === 1 ? "exercise" : "exercises"}`;
};

export const getWorkoutMinimalExercisesCount = (item: WorkoutMinimal): number => {
  const exercises =
    (item?.coolDownGroup?.exercises.length || 0) +
    (item?.warmUpGroup?.exercises.length || 0) +
    (item?.exerciseGroups?.reduce((acc, group) => acc + (group?.exercises.length || 0), 0) || 0);
  return exercises;
};

