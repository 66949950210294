import React, {Fragment, useEffect, useState} from "react";
import WorkoutListItem from "../../content/workout/WorkoutListItem";
import {Exercise, User, Workout} from "myfitworld-model";
import {getData} from "../../../api/profileApi";

const getWorkoutList = (
  workouts: Array<Workout>,
  exercises: Array<Exercise>,
  equipment: Record<string, string>
) => {
  return workouts.map((workout, index) => (
    <WorkoutListItem key={index} item={workout} onSelect={() => {}} exercises={exercises} equipment={equipment}/>
  ))
}

const WorkoutList = ({trainer, equipment, exercises, toggleError}: Props) => {
  const [workouts, setWorkouts] = useState<Array<Workout>>([]);

  useEffect(() => {
      trainer && trainer.id && getData<Workout>("/workouts", trainer.id)
        .then((result) => setWorkouts(result.data ?? []))
        .catch((e) => {
          toggleError(true);
          console.log(e);
        });
    },
    [trainer, toggleError]
  );

  return (
    <Fragment>
      {equipment && getWorkoutList(workouts, exercises, equipment)}
    </Fragment>
  );
};

export default WorkoutList;

interface Props {
  trainer: User;
  equipment: Record<string, string> | null;
  exercises: Array<Exercise>;
  toggleError: (value: boolean) => void;
}
