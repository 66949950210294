import React from "react";
import {Box, Typography} from "@material-ui/core";
import Button from "@material-ui/core/Button/Button";
import {useLocalizationProvider} from "../../../providers/LocalizationProvider";
import {User} from "myfitworld-model";
import {updateUserProfile} from "../../../api/profileApi";
import globalState, {GlobalState} from "../../../globalState";

const EditProfileHeader = ({userProfile, changed, disabled, toggleChanged, toggleDisabled}: Props) => {
  const {translate} = useLocalizationProvider();

  const preSubmitSetup = () => {
    toggleDisabled(true);
    globalState.update((state: GlobalState) => {
      state.globalLoadingQueue.push(state.globalLoadingQueue.length + 1);
    });
  };

  const postSubmitCleanup = () => {
    toggleDisabled(false);
    globalState.update((state: GlobalState) => {
      state.globalLoadingQueue.shift();
    });
  };

  const handleSubmitSuccess = () => {
    toggleChanged(false);
    globalState.update((state: GlobalState) => {
      state.toastQueue.push({message: translate("messages.success"), severity: "success"});
    });
  };

  const handleSubmitError = () => {
    toggleChanged(true);
    globalState.update((state: GlobalState) => {
      state.toastQueue.push({message: translate("messages.error"), severity: "error"});
    });
  };

  const handleUpdate = () => {
    preSubmitSetup();
    updateUserProfile(userProfile)
      .then(handleSubmitSuccess)
      .catch(handleSubmitError)
      .finally(postSubmitCleanup);
  };

  return (
    <Box display="flex" flexDirection="row" justifyContent="space-between" alignItems="baseline" m={2}>
      <Typography
        variant="h4"
        color="textPrimary"
      >
        {translate('My Profile')}
      </Typography>

      <Button
        color="primary"
        variant={changed ? "contained" : "outlined"}
        onClick={handleUpdate}
        disabled={!changed || disabled}
      >
        {translate('button.save_changes')}
      </Button>
    </Box>
  );
};

export default EditProfileHeader;

interface Props {
  userProfile: User;
  changed: boolean;
  disabled: boolean;
  toggleChanged: (value: boolean) => void;
  toggleDisabled: (value: boolean) => void;
}
