import React from 'react';
import ReactDOM from 'react-dom';
import {ThemeProvider} from '@material-ui/core';
import './firebase';
import App from './App';
import * as serviceWorker from './serviceWorker';
import {LocationProvider} from '@reach/router';
import {LocalizationProvider} from './providers/LocalizationProvider';
import UserProvider from './providers/UserProvider';
import {createTheme} from './theme';
import './index.css';
import {InvitationProvider} from "./providers/InvitationProvider";
import {loadStripe} from '@stripe/stripe-js';
import {Elements} from '@stripe/react-stripe-js';
import * as Sentry from "@sentry/react";
import { Integrations } from "@sentry/tracing";

Sentry.init({
  dsn: "https://e6efd46554ee43b9affc3892d7837dad@o437157.ingest.sentry.io/5539681",
  integrations: [
    new Integrations.BrowserTracing(),
  ],
  tracesSampleRate: 1.0,
});

const stripePromise = (process.env.REACT_APP_STRIPE_PUBLIC_KEY && loadStripe(process.env.REACT_APP_STRIPE_PUBLIC_KEY)) || null;

ReactDOM.render(
  <LocationProvider>
    <LocalizationProvider>
      <ThemeProvider theme={createTheme()}>
        <InvitationProvider>
          <UserProvider>
            <Elements stripe={stripePromise}>
              <App/>
            </Elements>
          </UserProvider>
        </InvitationProvider>
      </ThemeProvider>
    </LocalizationProvider>
  </LocationProvider>,
  document.getElementById('root')
);

if (process.env.REACT_APP_ENV === 'production') {
  require('./vendor/hotjar');
}

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
