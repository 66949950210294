import {firestore} from "../firebase";
import {Invitation, UserProgram} from "myfitworld-model";
import {UserInvitationWithCrudInstructions} from "./UserWithCRUDInstructions";
import APIResponse from "../model/APIResponse";
import {sanitizeInput} from "./sanitizeInput";
import {processUserPrograms} from './usersApi';
import {WorkoutDay} from "myfitworld-model/src/firestoreCollectionTypes/User";
import firebase from "firebase";

const invitationCollectionRef = firestore.collection('invitations');

export const acceptInvitation = async (invitationId: string) => {
  invitationCollectionRef
    .doc(invitationId)
    .update({'acceptedOn': new Date()})
    .then(_ => true)
    .catch(err => {
        console.debug('Error accepting invitation', err);
        return false;
      }
    )
}

export const getInvitation = async (invitationId: string): Promise<Invitation | null> => {
  return new Promise<Invitation | null>(resolve => {
    invitationCollectionRef
      .doc(invitationId)
      .get()
      .then(result => {
        if (result.exists) {
          const data = result.data();
          const invitation = {
            id: result.id,
            ...data,
            createdAt: data?.createdAt && data.createdAt.toDate
                ? data.createdAt.toDate().toJSON() // Timestamp object
                : new Date(data?.createdAt).toJSON(),
            acceptedOn: data?.acceptedOn === null || data?.acceptedOn === undefined ?
              null :
                data?.acceptedOn && data.acceptedOn.toDate
                  ? data.acceptedOn.toDate().toJSON() // Timestamp object
                  : new Date(data?.acceptedOn).toJSON(),
          } as Invitation;

          firestore
            .collection(`invitations/${result.id}/assignedPrograms`)
            .get()
            .then((programs) => {
              if (programs.empty) {
                resolve(invitation);
              } else {
                const program = {
                  ...programs.docs[0].data(),
                  id: programs.docs[0].id
                } as unknown as UserProgram;

                firestore
                  .collection(`invitations/${result.id}/assignedPrograms/${program.id}/assignedWorkouts`)
                  .get()
                  .then((workout) => {
                    program.workouts = workout.docs.map((doc) => {
                      const data = doc.data();
                      return {
                        ...data,
                        id: doc.id,
                        dateTime:
                          data.dateTime && data.dateTime.toDate
                            ? data.dateTime.toDate().toJSON() // Timestamp object
                            : new Date(data.dateTime).toJSON(),
                      } as WorkoutDay;
                    });

                    invitation.userProgram = program;
                    resolve(invitation);
                  });
              }
            });
        } else {
          resolve(null);
        }
      })
      .catch(err => {
        console.debug('Error retrieving invitation', err);
        resolve(null)
      })
  })

}

export const deleteInvitation = async (invitationId: string) => {
  invitationCollectionRef
    .doc(invitationId)
    .delete()
    .then(_ => true)
    .catch(err => {
        console.debug('Error deleting invitation', err);
        return false;
      }
    )
}

export const updateInvitation = (data: UserInvitationWithCrudInstructions): Promise<APIResponse> => {
  const { userProgram, workoutDeletions } = data;
  delete data.userProgram;
  delete data.workoutDeletions;
  const sanitzedData = sanitizeInput(data);
  // @ts-ignore
  sanitzedData.createdAt = data.createdAt ? firebase.firestore.Timestamp.fromDate(new Date(data.createdAt)) : null;
  // @ts-ignore
  sanitzedData.acceptedOn = data.acceptedOn ? firebase.firestore.Timestamp.fromDate(new Date(data.acceptedOn)) : null;

  return new Promise((resolve, reject) => {
    try {
      firestore
        .collection("/invitations")
        .doc(data.id)
        .set({ ...sanitzedData })
        .then(() => {
          if (userProgram) {
            Promise.all(processUserPrograms([userProgram], data.id as string, workoutDeletions || [], 'invitations')).then(() => {
              resolve({ success: true });
            });
          } else {
            resolve({ success: true });
          }
        });
    } catch (e) {
      console.error(e);
      reject({
        success: false,
        errorMessage: "There was an error saving your data. Check if you are connected to the Internet and try again…",
      });
    }
  });
};

export default {
  acceptInvitation,
  deleteInvitation,
  getInvitation
}
