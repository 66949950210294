import React from 'react';
import {Grid, TextField} from '@material-ui/core';
import {BillingDetailsErrors} from "../../utils/BillingDetailsInterface";

const ContactInfo = ({email, phoneNumber, onChange, errors}: {
  email: string;
  phoneNumber: string;
  onChange: (e: any, validate: boolean) => void;
  errors?: BillingDetailsErrors;
}) => {

  return (
    <Grid container spacing={2}>
      <Grid item xs={6}>
        <TextField
          label='Email'
          name='email'
          color='primary'
          variant='standard'
          value={email}
          onChange={(e) => onChange(e, true)}
          required={true}
          helperText={errors?.email ? "This field is required." : null}
          error={!!errors?.email}
          fullWidth
        />
      </Grid>
      <Grid item xs={6}>
        <TextField
          label='Phone Number'
          name='phoneNumber'
          color='primary'
          variant='standard'
          value={phoneNumber}
          onChange={(e) => onChange(e, true)}
          required={true}
          helperText={errors?.phoneNumber ? "This field is required." : null}
          error={!!errors?.phoneNumber}
          fullWidth
        />
      </Grid>
    </Grid>
  );
}

export default ContactInfo;
