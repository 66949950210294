import React, {Fragment, useEffect, useState} from "react";
import {Box, LinearProgress, Divider, Hidden} from "@material-ui/core";
import {useLocalizationProvider} from "../../providers/LocalizationProvider";
import PersonalInfo from "./public_profile/PersonalInfo";
import {RouteComponentProps} from "@reach/router";
import {Equipment, Exercise, User, Workout} from "myfitworld-model";
import {getUserProfileByMyUrl} from "../../api/profileApi";
import {Alert} from "@material-ui/lab";
import buildKeyToNameMap from "../../utils/buildKeyToNameMap";
import {equipmentApi, exercisesApi, workoutApi} from "../../api/common";
import PublicProfileHeader from "./public_profile/PublicProfileHeader";
import TrainerLibrary from "./public_profile/TrainerLibrary";
import {ApiInterface} from "../../api/dataInterfaceFactory";

const loadData = <T extends {}>(
  trainer: User,
  api: ApiInterface<T>,
  setT: React.Dispatch<React.SetStateAction<T[]>>
) =>
  Promise.all((trainer.organizations || []).map((role) => api.list(role.id)))
    .then((result) => {
      result.map((data) => setT(prevState => [...prevState, ...data]));
    });


const PublicProfilePage = ({myUrl}: RouteComponentProps<{ myUrl: string }>) => {
  const {translate} = useLocalizationProvider();
  const [isProfileLoading, toggleProfileLoading] = useState(false);
  const [isDataLoading, toggleDataLoading] = useState(false);
  const [error, toggleError] = useState(false);
  const [trainer, setTrainer] = useState<User | null>(null);

  useEffect(() => {
      toggleProfileLoading(true);
      myUrl && getUserProfileByMyUrl(myUrl).then((result) => {
          result.data ? setTrainer(result.data) : toggleError(true);
        toggleProfileLoading(false);
        }
      ).catch((e) => {
        toggleError(true);
        console.log(e);
      });
    },
    [myUrl]
  );

  const [equipment, setEquipment] = useState<Array<Equipment>>([]);
  const [exercises, setExercises] = useState<Array<Exercise>>([]);
  const [workouts, setWorkouts] = useState<Array<Workout>>([]);

  useEffect( () => {
    const load = async () => {
      if (trainer) {
        toggleDataLoading(true);
        const p = [
          loadData<Equipment>(trainer, equipmentApi, setEquipment),
          loadData<Exercise>(trainer, exercisesApi, setExercises),
          loadData<Workout>(trainer, workoutApi, setWorkouts)
        ];
        await Promise.all(p)
          .then((r) => {
            toggleDataLoading(false);
          })
          .catch((e) => {
            toggleError(true);
            console.log(e);
          });
      }
    }
    load();
  }, [trainer]);

  if (isProfileLoading || isDataLoading) {
    return (
      <LinearProgress/>
    );
  }

  if (error) {
    return (
      <Box display="flex" justifyContent="center" alignItems="center" mt={20}>
        <Alert severity='error'>
          {translate('messages.error_loading')}
        </Alert>
      </Box>
    );
  }

  return (
    <Box display="flex" flexDirection="column" flex={1}>
      {trainer &&
      <Fragment>
        <Hidden smDown>
          {/*<PublicProfileHeader marginTop={10}/>*/}
          <Box display="flex" flexDirection="row" flex={1}>
            <Box display="flex" flexDirection="column" flex={2}>
              <PersonalInfo trainer={trainer}/>
            </Box>
            <Divider orientation="vertical" style={{height: document.body.clientHeight}}/>
            <Box flex={4}>
              <TrainerLibrary trainer={trainer} equipment={buildKeyToNameMap(equipment)} exercises={exercises} workouts={workouts} toggleError={toggleError}/>
            </Box>
          </Box>
        </Hidden>
        <Hidden mdUp>
          <PublicProfileHeader marginTop={5}/>
          <PersonalInfo trainer={trainer}/>
          <TrainerLibrary trainer={trainer} equipment={buildKeyToNameMap(equipment)} exercises={exercises} workouts={workouts} toggleError={toggleError}/>
        </Hidden>
      </Fragment>
      }
    </Box>
  );
};

export default PublicProfilePage;
