import { DeltaStatic } from 'quill';

const deltaToPlaintext = (delta: DeltaStatic) => {
  return delta.reduce(function (text, op) {
    if (!op.insert) {
      return text;
    }
    if (typeof op.insert !== 'string') {
      return text + ' ';
    }
    return text + op.insert;
  }, '');
};

const getDescriptionSnippet = (str?: string) => {
  if (typeof str === 'string' && str.length > 0) {
    try {
      const delta = JSON.parse(str);
      const text = deltaToPlaintext(delta.ops);
      if (typeof text === 'string' && str.length > 0) {
        if (text.length > 60) {
          return `${text.substr(0, 60)}…`;
        }
        if (text === ' ' || text === '\n') {
          return '';
        }
        return text;
      }
    } catch (e) {
      return '';
    }
  }
  return '';
};

export default getDescriptionSnippet;
