import React from 'react';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import Dialog from '@material-ui/core/Dialog';

export interface SimpleDialogProps {
  open: boolean;
  options: { value: string, label: string }[];
  onClose: (value?: string) => void;
}

const SimplePopupDialog = (props: SimpleDialogProps) => {
  const { options, onClose, open } = props;

  const handleClose = () => {
    onClose();
  };

  const handleListItemClick = (value: string) => {
    onClose(value);
  };

  return (
    <Dialog onClose={handleClose} open={open}>
      <List>
        {options.map((option) => (
          <ListItem button onClick={() => handleListItemClick(option.value)} key={option.value}>
            <ListItemText primary={option.label} />
          </ListItem>
        ))}
      </List>
    </Dialog>
  );
}

export default SimplePopupDialog;
