import React, {useState, useMemo, useCallback} from 'react';
import LanguageContext from "./LanguageContext";
import {availableLocales} from "../../providers/LocalizationProvider";

interface LanguageProviderProps {
  children: React.ReactFragment;
}

export const LanguageProvider: React.FC<LanguageProviderProps> = ({children}) => {
  const [language, setLanguage] = useState<string>('en');
  const [registered, setRegistered] = useState<Record<string, boolean>>({});
  const languages = availableLocales;

  const registerField = useCallback((name: string) => {
    setRegistered({...registered, [name]: true});
  },[registered])

  const deregisterField = useCallback((name: string) => {
    const {[name]: string, ...rest} = registered;
    setRegistered(rest);
  },[registered])

  const registeredFields = useMemo(() => Object.keys(registered), [registered]);


  const context = {
    setLanguage,
    language,
    languages,
    registerField,
    deregisterField,
    registeredFields
  }

  return <LanguageContext.Provider value={context}>{children}</LanguageContext.Provider>
}
