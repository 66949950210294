import React from 'react';

const FilePreview = ({
  value,
  type,
  videoPoster,
} : {
  value: string,
  type: 'video' | 'image',
  videoPoster?: string
}) =>
  type === 'video' ?
    (
      <video
        controls
        src={value}
        style={{ objectFit: "contain", maxWidth: '100%' }}
        poster={videoPoster}
      >
      {''}
      </video>
    ) : (
      <img
        style={{ objectFit: "contain", maxWidth: '100%' }}
        src={value}
        alt=''
      />
    )
  ;

export default FilePreview;
