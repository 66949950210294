import React from 'react';
import {Control, Controller} from 'react-hook-form';
import { Rating, IconContainerProps } from '@material-ui/lab';
import {createStyles, InputLabel, makeStyles, Theme, Box} from '@material-ui/core';
import SvgIcon, { SvgIconProps } from '@material-ui/core/SvgIcon';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    formControl: {
      width: '100%',
      marginBottom: theme.spacing(2),
    },
    ratingControlLabel: {
      display: 'flex',
      flexDirection: 'column',
      marginTop: theme.spacing(2),
      marginBottom: theme.spacing(1),
    },
  }),
);

function Level1(props: SvgIconProps) {
  return (
    <SvgIcon {...props}>
      <path fillRule="evenodd" clipRule="evenodd" d="M12 24C18.6274 24 24 18.6274 24 12C24 5.37258 18.6274 0 12 0C5.37258 0 0 5.37258 0 12C0 18.6274 5.37258 24 12 24ZM10.9434 16H12.9238V7.46875H12.7422L8.99805 8.76367V10.2637L10.9434 9.69531V16Z"/>
    </SvgIcon>
  );
}

function Level2(props: SvgIconProps) {
  return (
    <SvgIcon {...props}>
      <path fillRule="evenodd" clipRule="evenodd" d="M12 24C18.6274 24 24 18.6274 24 12C24 5.37258 18.6274 0 12 0C5.37258 0 0 5.37258 0 12C0 18.6274 5.37258 24 12 24ZM8.54688 16H14.5V14.4766H11.1426L12.2441 13.2051C12.8301 12.6191 13.2539 12.1523 13.5156 11.8047C13.7812 11.4531 13.9766 11.1191 14.1016 10.8027C14.2266 10.4824 14.2891 10.1543 14.2891 9.81836C14.2891 9.02148 14.0391 8.41016 13.5391 7.98438C13.043 7.55859 12.334 7.3457 11.4121 7.3457C10.8379 7.3457 10.3184 7.4707 9.85352 7.7207C9.38867 7.9707 9.02539 8.31836 8.76367 8.76367C8.50195 9.20508 8.37109 9.6875 8.37109 10.2109H10.3516C10.3516 9.82812 10.4453 9.50977 10.6328 9.25586C10.8203 8.99805 11.0645 8.86914 11.3652 8.86914C11.6699 8.86914 11.9023 8.96484 12.0625 9.15625C12.2227 9.34766 12.3027 9.62695 12.3027 9.99414C12.3027 10.4473 11.9648 11.0586 11.2891 11.8281L8.54688 14.7109V16Z"/>
    </SvgIcon>
  );
}

function Level3(props: SvgIconProps) {
  return (
    <SvgIcon {...props}>
      <path fillRule="evenodd" clipRule="evenodd" d="M12 24C18.6274 24 24 18.6274 24 12C24 5.37258 18.6274 0 12 0C5.37258 0 0 5.37258 0 12C0 18.6274 5.37258 24 12 24ZM11.2832 10.9082H10.3516V12.3789H11.3125C11.6914 12.3789 11.9883 12.4688 12.2031 12.6484C12.418 12.8281 12.5254 13.1328 12.5254 13.5625C12.5254 13.8633 12.4219 14.1113 12.2148 14.3066C12.0078 14.498 11.7305 14.5938 11.3828 14.5938C11.0742 14.5938 10.8145 14.498 10.6035 14.3066C10.3926 14.1152 10.2871 13.8887 10.2871 13.627H8.30078C8.30078 14.1074 8.42383 14.5371 8.66992 14.916C8.91602 15.291 9.27734 15.5859 9.75391 15.8008C10.2344 16.0117 10.7539 16.1172 11.3125 16.1172C12.2656 16.1172 13.0352 15.8887 13.6211 15.4316C14.207 14.9746 14.5 14.3652 14.5 13.6035C14.5 13.1113 14.3711 12.6992 14.1133 12.3672C13.8555 12.0312 13.5 11.7812 13.0469 11.6172C13.4766 11.4141 13.8027 11.1504 14.0254 10.8262C14.252 10.498 14.3652 10.1504 14.3652 9.7832C14.3652 9.02539 14.0938 8.42969 13.5508 7.99609C13.0078 7.5625 12.2617 7.3457 11.3125 7.3457C10.7812 7.3457 10.293 7.44531 9.84766 7.64453C9.40625 7.83984 9.06055 8.11328 8.81055 8.46484C8.56055 8.81641 8.43555 9.2168 8.43555 9.66602H10.4102C10.4102 9.43945 10.5059 9.25 10.6973 9.09766C10.8926 8.94531 11.1211 8.86914 11.3828 8.86914C11.7031 8.86914 11.9512 8.95898 12.127 9.13867C12.3027 9.31445 12.3906 9.54297 12.3906 9.82422C12.3906 10.5469 12.0215 10.9082 11.2832 10.9082Z"/>
    </SvgIcon>
  );
}

function Level4(props: SvgIconProps) {
  return (
    <SvgIcon {...props}>
      <path fillRule="evenodd" clipRule="evenodd" d="M12 24C18.6274 24 24 18.6274 24 12C24 5.37258 18.6274 0 12 0C5.37258 0 0 5.37258 0 12C0 18.6274 5.37258 24 12 24ZM14.6289 12.666H13.7617V7.46875H11.7871V7.48633L8.37109 12.9824L8.48828 14.1895H11.7871V16H13.7617V14.1895H14.6289V12.666ZM11.7871 12.666H10.252L11.6641 10.2402L11.7871 10.041V12.666Z"/>
    </SvgIcon>
  );
}

function Level5(props: SvgIconProps) {
  return (
    <SvgIcon {...props}>
      <path fillRule="evenodd" clipRule="evenodd" d="M12 24C18.6274 24 24 18.6274 24 12C24 5.37258 18.6274 0 12 0C5.37258 0 0 5.37258 0 12C0 18.6274 5.37258 24 12 24ZM9.18563 7.46875L8.66415 11.8281L10.2345 12.1973C10.461 11.9004 10.8048 11.752 11.2657 11.752C12.0782 11.752 12.4845 12.207 12.4845 13.1172C12.4845 14.1016 12.1524 14.5938 11.4884 14.5938C11.1954 14.5938 10.9591 14.502 10.7794 14.3184C10.5997 14.1348 10.5001 13.8867 10.4806 13.5742H8.50008C8.49618 14.0586 8.62704 14.4941 8.89266 14.8809C9.15829 15.2637 9.52548 15.5664 9.99423 15.7891C10.463 16.0078 10.9649 16.1172 11.5001 16.1172C12.1016 16.1172 12.6231 15.998 13.0645 15.7598C13.5059 15.5215 13.8477 15.1816 14.0899 14.7402C14.336 14.2949 14.4591 13.7988 14.4591 13.252C14.4591 12.3457 14.2306 11.6426 13.7735 11.1426C13.3204 10.6387 12.67 10.3867 11.8224 10.3867C11.6036 10.3867 11.379 10.418 11.1485 10.4805C10.922 10.543 10.7384 10.6152 10.5977 10.6973L10.7911 9.00391H14.172V7.46875H9.18563Z"/>
    </SvgIcon>
  );
}

const customIcons: { [index: string]: { icon: React.ReactElement; label: string } } = {
  1: {
    icon: <Level1/>,
    label: 'Very Dissatisfied',
  },
  2: {
    icon: <Level2/>,
    label: 'Dissatisfied',
  },
  3: {
    icon: <Level3/>,
    label: 'Neutral',
  },
  4: {
    icon: <Level4/>,
    label: 'Satisfied',
  },
  5: {
    icon: <Level5/>,
    label: 'Very Satisfied',
  },
};

function IconContainer(props: IconContainerProps) {
  const { value, ...other } = props;
  return <span {...other}>{customIcons[value].icon}</span>;
}


const RatingWidget = (
  { control, name, label, disabled }:
  { control: Control, name: string, label: string, disabled?: boolean }
) => {
  const classes = useStyles();

  return (
    <div className={classes.formControl}>
      <InputLabel className={classes.ratingControlLabel}>
        <Box mb={1}>
          <InputLabel shrink>{label}</InputLabel>
        </Box>
        <Controller
          name={name}
          control={control}
          render={({onChange, value}) =>
            <Box display="flex" flexDirection="row" alignItems="center">
              <Rating
                size="large"
                name={name}
                value={value}
                max={5}
                onChange={(_, value) => onChange(value)}
                disabled={disabled}
                getLabelText={(value: number) => customIcons[value].label}
                IconContainerComponent={IconContainer}
              />
            </Box>
          }
        />
      </InputLabel>
    </div>
  );
};

export default RatingWidget;
