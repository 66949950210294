import React from 'react';
import {Alert} from '@material-ui/lab';
import {useForm} from 'react-hook-form';
import Exercise from 'myfitworld-model/dist/firestoreCollectionTypes/Exercise';
import {Typography} from '@material-ui/core';
import Workout, {ExerciseGroup} from "myfitworld-model/dist/firestoreCollectionTypes/Workout";
import {Equipment} from "myfitworld-model";
import LanguageSelect from "../../../../components/language/LanguageSelect";
import GeneralDetails from "./GeneralDetails";
import useGroupState from "../../useGroupState";
import WorkoutDetails from "./WorkoutDetails";
import Box from "@material-ui/core/Box";
import useOrganization from "../../../../hooks/useOrganization";
import ContentFormActionBar from '../../ContentFormActionBar';
import useStateWithDirtyFlag from "../../../../hooks/useStateWithDirtyFlag";
import useEntityList from "../../../../hooks/useEntityList";
import {exercisesApi} from "../../../../api/common";
import useGlobalCachedResourceList from "../../../../api/useGlobalCachedResourceList";
import SubtleInfoMessage from "../../../../components/SubtleInfoMessage";
import {useLocalizationProvider} from "../../../../providers/LocalizationProvider";

const getSeconds = (text: any) => {
  if (!text) return 0;
  const time = text.split(":");
  const seconds = parseInt(time[0], 10) * 60 + parseInt(time[1], 10);
  return seconds;
};

const WorkoutForm = ({
  handleClose,
  handleCreate,
  handleUpdate,
  defaultState,
  mode,
  loading,
  formErrorMessage,
 } : {
  handleClose: () => void,
  handleCreate?: (data: Workout) => Promise<void>,
  handleUpdate?: (data: Workout) => Promise<void>,
  defaultState?: Workout,
  mode: 'Create' | 'Edit',
  loading: boolean,
  formErrorMessage?: string,
}) => {
  const { data: exercises } = useEntityList<Exercise>(exercisesApi.list);
  const { data: equipment } = useGlobalCachedResourceList<Equipment>('equipmentCache');

  const { register, handleSubmit: useFormHandleSubmit, watch, getValues, setValue, control, formState } = useForm<Workout>(
    mode === 'Edit' && defaultState !== undefined ?
      { defaultValues: defaultState } :
      { defaultValues: { difficultyLevel: 1 } }
  );

  const { groups: exerciseGroups, addGroup, removeGroup, copyGroup, updateGroup, isGroupDirty } = useGroupState<ExerciseGroup>(
    { numberOfRounds: 3, exercises: [] },
    mode === 'Edit' ? (defaultState?.exerciseGroups || []) : []
  );

  const { value: warmUpGroup, setter: setWarmUpGroup, isDirty: isWarmUpGroupDirty } = useStateWithDirtyFlag<ExerciseGroup>(
  defaultState?.warmUpGroup || { numberOfRounds: 1, exercises: [] }
  );

  const { value: coolDownGroup, setter: setCoolDownGroup, isDirty: isCoolDownGroupDirty } = useStateWithDirtyFlag<ExerciseGroup>(
    defaultState?.coolDownGroup || { numberOfRounds: 1, exercises: [] }
  );

  const isFormDirty = formState.isDirty || isGroupDirty || isWarmUpGroupDirty || isCoolDownGroupDirty;

  const { organizationId } = useOrganization();
  const disableForm = defaultState?.id ? defaultState?.organizationId !== organizationId : false;

  const {translate} = useLocalizationProvider();

  const onSubmit = async (raw: Workout) => {
    const data = { ...raw };

    if (mode === 'Edit') {
      handleUpdate && handleUpdate({
        ...defaultState,
        ...data,
        interExerciseRest: getSeconds(data.interExerciseRest),
        interRoundRest: getSeconds(data.interRoundRest),
        interGroupRest: getSeconds(data.interGroupRest),
        warmUpGroup: warmUpGroup,
        coolDownGroup: coolDownGroup,
        exerciseGroups: exerciseGroups,
      } as Workout);
    } else if (mode === 'Create') {
      handleCreate && handleCreate({
        ...data,
        interExerciseRest: getSeconds(data.interExerciseRest),
        interRoundRest: getSeconds(data.interRoundRest),
        interGroupRest: getSeconds(data.interGroupRest),
        warmUpGroup: warmUpGroup,
        coolDownGroup: coolDownGroup,
        exerciseGroups: exerciseGroups,
      } as Workout);
    }
  };

  return(
    <Box>
      <Typography variant='h3' gutterBottom>
        {mode === 'Edit' ? 'Edit Workout Plan' : 'Create a New Workout Plan'}
      </Typography>
      {mode === 'Create' &&
        <Typography variant='body1' color="textSecondary" gutterBottom>
          Add the basic details now, you can always edit the plan later.
        </Typography>}
      <LanguageSelect<Workout> watch={watch} />
      <GeneralDetails
        register={register}
        getValues={getValues}
        setValue={setValue}
        control={control}
        mode={mode}
        disabled={disableForm}
        defaultState={defaultState}
        withRestTimes
      />
      <SubtleInfoMessage>
        {translate('workout.edit.header_note')}
      </SubtleInfoMessage>

      <WorkoutDetails
        warmUpGroup={warmUpGroup}
        setWarmUpGroup={setWarmUpGroup}
        coolDownGroup={coolDownGroup}
        setCoolDownGroup={setCoolDownGroup}
        exerciseGroups={exerciseGroups}
        updateGroup={updateGroup}
        loading={loading}
        exercises={exercises}
        equipment={equipment}
        removeGroup={removeGroup}
        copyGroup={copyGroup}
        addGroup={addGroup}
        disabled={disableForm}
      />

      <ContentFormActionBar
        onSubmit={useFormHandleSubmit(onSubmit)}
        onClose={handleClose}
        archived={defaultState?.archived}
        handleArchive={() => handleUpdate && handleUpdate({...defaultState, archived: !defaultState?.archived} as Workout)}
        disabled={loading || disableForm}
        onSubmitLabel={mode === 'Edit' ? 'Update Workout' : 'Create Workout'}
        entityName='Workout'
        mode={mode}
        isFormDirty={isFormDirty}
      />

      {
        formErrorMessage &&
        <Alert severity='error' style={{marginTop: 24}}>
          {formErrorMessage}
        </Alert>
      }
    </Box>
  );
};

export default WorkoutForm;
