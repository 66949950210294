import React from 'react';
import {Box, Button, TableCell, TableHead, TableRow, Typography} from '@material-ui/core';
import SimpleMenu from '../../../../components/SimpleMenu';
import useStyles from './styles';

const getGroupContextMenuOptions = (includeGroupDeletion: boolean, includeGroupCopy: boolean) => {
  const options = [];
  if (includeGroupDeletion) {
    options.push({ label: 'Delete Group', action: 'DELETE' });
  }
  if (includeGroupCopy) {
    options.push({ label: 'Duplicate Group', action: 'COPY' });
  }
  return options;
};

const GroupHeader = ({
  name,
  numberOfRounds,
  removeRound,
  addRound,
  deleteGroup,
  copyGroup,
  disabled,
} : {
  name: string,
  numberOfRounds: number,
  removeRound: (index: number) => void,
  addRound: () => void,
  deleteGroup?: () => void,
  copyGroup?: () => void,
  disabled?: boolean,
}) => {
  const classes = useStyles();
  const groupContextMenuOptions = getGroupContextMenuOptions(!!deleteGroup, !!copyGroup);

  return (
    <TableHead>
      <TableRow>

      <TableCell>
        <Box display="flex">
          <Typography variant='h5' style={{ alignSelf: 'center' }} color={"textSecondary"}>{name}</Typography>
          {groupContextMenuOptions.length > 0 &&
            <SimpleMenu
              options={groupContextMenuOptions}
              onSelect={(action?: string) => {
                if (action && action === 'DELETE' && deleteGroup && !disabled) {
                  deleteGroup();
                }
                if (action && action === 'COPY' && copyGroup && !disabled) {
                  copyGroup();
                }
              }}
              disabled={disabled}
            />}
        </Box>
      </TableCell>

        {Array.from({ length: numberOfRounds || 0 }).map((_, index) =>
          <TableCell key={index}>
            <Box display="flex">
              <Typography variant='body2' style={{ alignSelf: 'center' }}>
                Round {index + 1}
              </Typography>
              <SimpleMenu
                options={[
                  { label: 'Delete Round', action: 'DELETE' },
                ]}
                onSelect={(action?: string) => {
                  if (action && action === 'DELETE') {
                    removeRound(index);
                  }
                }}
                disabled={disabled}
              />
            </Box>
          </TableCell>
        )}

      <TableCell align="right">
        <Button color='primary' variant='text' onClick={addRound} className={classes.nowrap} disabled={disabled}>
          + Add Round
        </Button>
      </TableCell>
      </TableRow>
    </TableHead>
  );
};

export default GroupHeader;
