import React, {PropsWithChildren} from 'react';
import {Button, Drawer} from "@material-ui/core";
import CloseIcon from "@material-ui/icons/Close";
import useDrawerStyles from "../theme/useDrawerStyles";
import {useLocalizationProvider} from "../providers/LocalizationProvider";

export interface SideDrawerProperties {
  isOpen: boolean;
  onClose: () => void;
}

export default function ({children, isOpen, onClose}: PropsWithChildren<SideDrawerProperties>) {
  const drawerClasses = useDrawerStyles();
  const {translate} = useLocalizationProvider();

  return (
    <Drawer
      classes={{paper: drawerClasses.drawerPaper}}
      variant="persistent"
      anchor="right"
      open={isOpen}
    >
      <div className={drawerClasses.drawerHeader}>
        <Button
          size="small"
          variant="outlined"
          startIcon={<CloseIcon/>}
          onClick={_ => onClose()}
        >
          {translate('Close')}
        </Button>
      </div>
      {children}
    </Drawer>
  )
}
