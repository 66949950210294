import React, {Fragment, useEffect, useState} from "react";
import {Exercise, ProgramTemplate, User, Workout} from "myfitworld-model";
import {getData} from "../../../api/profileApi";
import ProgramListItem from "../../content/program/ProgramListItem";

const getProgramList = (
  programs: Array<ProgramTemplate>,
  workouts: Array<Workout>,
  exercises: Array<Exercise>,
  equipment: Record<string, string>
) => {
  return programs.map((program, index) => (
    <ProgramListItem key={index} item={program}
                     workouts={workouts} exercises={exercises} equipment={equipment} onSelect={()=>{}}/>
  ))
}

const ProgramList = ({trainer, workouts, equipment, exercises, toggleError}: Props) => {
  const [programs, setPrograms] = useState<Array<ProgramTemplate>>([]);

  useEffect(() => {
      trainer && trainer.id && getData<ProgramTemplate>("/programs", trainer.id)
        .then((result) => setPrograms(result.data ?? []))
        .catch((e) => {
          toggleError(true);
          console.log(e);
        });
    },
    [trainer, toggleError]
  );

  return (
    <Fragment>
      {equipment && getProgramList(programs, workouts, exercises, equipment)}
    </Fragment>
  );
};

export default ProgramList;

interface Props {
  trainer: User;
  equipment: Record<string, string> | null;
  exercises: Array<Exercise>;
  workouts: Array<Workout>;
  toggleError: (value: boolean) => void;
}
