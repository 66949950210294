import React from "react";
import {Box, Typography} from "@material-ui/core";
import ProfileImage from "./ProfileImage";
import {getUserInitials} from "../../utils/getUserInitials";
import {getUserFullName} from "../../utils/getUserFullName";

const ProfileImageWithName = ({src, firstName, lastName}: Props) => {
  /*const {translate} = useLocalizationProvider();*/
  return (
    <Box display="flex" flexDirection="row" alignItems="center">
      <Box m={5}>
        <ProfileImage src={src} initials={getUserInitials(firstName, lastName)}/>
      </Box>
      <Box display="flex" flexDirection="column" mr={4}>
        <Box mb={2}>
          <Typography variant="h4" style={{whiteSpace: "nowrap"}}>
            {getUserFullName(firstName, lastName)}
          </Typography>
        </Box>
        {/*<Box>
          <Button variant="contained" color="primary" style={{padding: 12, fontWeight: "bold"}}>
            {translate("trainer_profile.contact")}
          </Button>
        </Box>*/}
      </Box>
    </Box>
  );
};

export default ProfileImageWithName;

interface Props {
  src: string | undefined | null;
  firstName: string | undefined | null;
  lastName: string | undefined | null;
}
