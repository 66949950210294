import {Typography, Box, Theme, createStyles} from "@material-ui/core";
import React from "react";
import StarIcon from '@material-ui/icons/Star';
import {makeStyles} from "@material-ui/core/styles";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    icon: {
      marginRight: theme.spacing(.5),
    },
  }),
);

export default ({ organizationId, withGrid = true }: { organizationId: string, withGrid?: boolean }) => {
  const classes = useStyles();

  if (organizationId === process.env.REACT_APP_MFW_ORGANIZATION_ID) {
    if (withGrid) {
      return (
        <Box display="flex" alignItems="center">
          <StarIcon className={classes.icon} fontSize="inherit" color="disabled"/>
          <Typography variant="caption" color="textSecondary">MFW Original</Typography>
        </Box>
      );
    } else {
      return (
        <Box display="flex" alignItems="center">
          <StarIcon fontSize="inherit"/>
          <Typography variant="caption" color="textSecondary">MFW Original</Typography>
        </Box>
      );
    }
  }

  return null;
}
