import React, {useMemo} from 'react';
import {RouteComponentProps} from '@reach/router';
import {Button, createStyles, makeStyles, Theme, Typography} from '@material-ui/core';
import NavigationDrawer from './navigation/NavigationDrawer';
import {ReactComponent as BarbelIcon} from '../assets/icons/barbel.svg';
import {ReactComponent as Calendar} from '../assets/icons/calendar.svg';
import {ReactComponent as ConnectionsIcon} from '../assets/icons/connections.svg';
import {ReactComponent as ContentIcon} from '../assets/icons/content.svg';
import {ReactComponent as GearIcon} from '../assets/icons/gear.svg';
import {ReactComponent as ProfileIcon} from '../assets/icons/profile.svg';
import Role from "myfitworld-model/dist/enums/Role";
import {auth} from "../firebase";
import OrganizationSwitcher from "./organizations/OrganizationSwitcher";
import {getCurrentUserRole} from "../api/usersApi";
import {useUserProvider} from "../providers/UserProvider";
import {useLocalizationProvider} from "../providers/LocalizationProvider";
import AccountBalanceWalletIcon from '@material-ui/icons/AccountBalanceWallet';
import {useStoreState} from "pullstate";
import globalState from "../globalState";
import {Organization} from "myfitworld-model";

export interface AppLayoutProps extends RouteComponentProps {
  children: React.ReactNode;
}

const superAdminMenuItems = [{
  label: 'Organizations',
  route: '/organizations',
  icon: <BarbelIcon/>,
}, {
  label: 'Users',
  route: '/users',
  icon: <ConnectionsIcon/>,
}];

const superAdminOrganizationOverrideMenuItems = [{
  label: 'Content',
  route: '/content',
  icon: <ContentIcon/>,
}, {
  label: 'Configuration',
  route: '/configuration',
  icon: <GearIcon/>,
}, {
  label: 'My Org',
  route: '/organization',
  icon: <BarbelIcon/>,
}, {
  label: 'Clients',
  route: '/clients',
  icon: <ConnectionsIcon/>,
}];

const adminMenuItems = [{
  label: 'My Schedule',
  route: '/schedule',
  icon: <Calendar/>
}, {
  label: 'Content',
  route: '/content',
  icon: <ContentIcon/>,
}, {
  label: 'Configuration',
  route: '/configuration',
  icon: <GearIcon/>,
}, {
  label: 'My Org',
  route: '/organization',
  icon: <BarbelIcon/>,
}, {
  label: 'Clients',
  route: '/clients',
  icon: <ConnectionsIcon/>,
}, {
  label: 'My Profile',
  route: '/profile',
  icon: <ProfileIcon/>,
}, {
  label: 'Billing',
  route: '/billing',
  icon: <AccountBalanceWalletIcon/>,
}];

const adminMenuItemsWithoutBilling = [{
  label: 'My Schedule',
  route: '/schedule',
  icon: <Calendar/>
}, {
  label: 'Content',
  route: '/content',
  icon: <ContentIcon/>,
}, {
  label: 'Configuration',
  route: '/configuration',
  icon: <GearIcon/>,
}, {
  label: 'My Org',
  route: '/organization',
  icon: <BarbelIcon/>,
}, {
  label: 'Clients',
  route: '/clients',
  icon: <ConnectionsIcon/>,
}, {
  label: 'My Profile',
  route: '/profile',
  icon: <ProfileIcon/>,
}];

const trainerMenuItems = [{
  label: 'My Schedule',
  route: '/schedule',
  icon: <Calendar/>
}, {
  label: 'Content',
  route: '/content',
  icon: <ContentIcon/>,
}, {
  label: 'Clients',
  route: '/clients',
  icon: <ConnectionsIcon/>,
}, {
  label: 'My Profile',
  route: '/profile',
  icon: <ProfileIcon/>,
}];

const menuItemsFor = (role?: Role, organization?: Organization) => {
  console.log("menuItemsFor", role, organization);
  switch (role) {
    case Role.SuperAdmin:
      return superAdminMenuItems;
    case Role.Admin:
      if (organization?.free) {
        return adminMenuItemsWithoutBilling;
      }
      return adminMenuItems;
    case Role.Trainer:
    case Role.AssistantTrainer:
      return trainerMenuItems;
    default:
      return [];
  }
}

const Unexpected = () => {
  const classes = useStyles();

  const {translate} = useLocalizationProvider();
  const handleLogout = () => {
    auth.signOut().catch((err) => console.log('Error signing out', err));
  };

  return (
    <div className={classes.error}>
      <Typography variant='body1' style={{marginBottom: '2em', whiteSpace: 'pre-line', textAlign: 'center'}}>
        {translate('Access is invitation only, please check with your admin to share you the invitation')}
      </Typography>
      <Button color='primary' variant='text' onClick={handleLogout}>Back to Login</Button>
    </div>
  )
}

const AppLayout = ({children}: AppLayoutProps) => {
  const classes = useStyles();
  const {user, organizationOverride} = useUserProvider();
  const currentUserRole = useMemo(() => {
    return user ? getCurrentUserRole(user, organizationOverride) : undefined;
  }, [user, organizationOverride]);
  const currentOrganization = useStoreState(globalState, s => s.currentOrganization);

  return currentUserRole ? (
    <div className={classes.root}>
      <NavigationDrawer menuItems={organizationOverride ? superAdminOrganizationOverrideMenuItems : menuItemsFor(currentUserRole, currentOrganization)}/>
      <main className={classes.content}>
        <OrganizationSwitcher/>
        {children}
      </main>
    </div>
  ) : <Unexpected/>;
};

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      display: 'flex',
      width: '100%',
    },
    content: {
      flex: 1,
      width: '100%',

      [theme.breakpoints.down('sm')]: {
        paddingTop: theme.spacing(8),
      },
    },
    offset: theme.mixins.toolbar,
    error: {
      display: 'flex',
      flexDirection: 'column',
      padding: '5em',
      width: '100%',
      height: '100%',
      justifyContent: 'center',
      alignItems: 'center'
    }
  }),
);

export default AppLayout;
