import useFirestoreCollection, {QueryFunction} from "./useFirestoreCollection";
import {Activity} from "myfitworld-model";
import {useCallback} from "react";
import safeInvoke from "../utils/safeInvoke";

export default function useChat(userId: string, limit: number) {
  const query: QueryFunction = useCallback((q) =>
      q.where("threadId", "==", userId)
        .orderBy("createdAt", "desc")
        .limit(limit),
    [userId, limit]
  );
  const chatCollection = useFirestoreCollection("activity", query);

  return chatCollection?.docs.map(
    (doc) =>
      ({
        ...doc.data(),
        createdAt: safeInvoke('toDate')(doc.data().createdAt),
        id: doc.id,
      } as Activity)
  );
}
