import React, {Fragment, useState } from 'react';
import {Button, createStyles, makeStyles, Table, Theme} from "@material-ui/core";
import {Equipment, Exercise, ExerciseGroup} from "myfitworld-model";
import ContentSelectDialog from "../ContentSelectDialog";
import GroupHeader from "./GroupHeader";
import GroupRows from "./GroupRows";
import AddIcon from '@material-ui/icons/Add';
import Box from "@material-ui/core/Box";
import {useLocalizationProvider} from "../../../../providers/LocalizationProvider";
import TableContainer from "@material-ui/core/TableContainer/TableContainer";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    table: {
      minWidth: 750,
      tableLayout: 'fixed',
    },
    col: {
      width: 'auto',
      [theme.breakpoints.down('sm')]: {
        width: 200,
      },
    }
  }),
);

const GroupForm = ({
  name,
  group,
  exercises,
  equipment,
  onChange,
  deleteGroup,
  copyGroup,
  disabled,
  setExerciseForPreview,
} : {
  name: string,
  group: ExerciseGroup,
  exercises?: Exercise[],
  equipment?: Equipment[],
  onChange: (group: ExerciseGroup) => void,
  deleteGroup?: () => void,
  copyGroup?: () => void,
  disabled?: boolean,
  setExerciseForPreview: (exercise: Exercise | undefined) => void,
}) => {
  const [ isExerciseDialogOpen, toggleExerciseDialogOpen ] = useState<boolean>(false);
  const classes = useStyles();
  const numberOfRounds = group.numberOfRounds || 0;

  const {translate} = useLocalizationProvider();

  const addRound = () => {
    if (!disabled) {
      const exercises = (group.exercises || []);
      exercises.forEach(exercise => {
        exercise.rounds.push({});
      });
      onChange({
        exercises,
        numberOfRounds: group.numberOfRounds + 1,
      });
    }
  };

  const removeRound = (index: number) => {
    if (!disabled) {
      const exercises = group.exercises || [];
      exercises.forEach(exercise => {
        exercise.rounds.splice(index, 1);
      });
      onChange({
        exercises,
        numberOfRounds: group.numberOfRounds - 1,
      });
    }
  };

  const handleExerciseSelection = (id?: string) => {
    if (id && !disabled) {
      const exercises = (group.exercises || []);
      exercises.push({
        exerciseId: id,
        type: 'Reps',
        rounds: Array.from({ length: group.numberOfRounds }).map(_ => ({}))
      });

      onChange({
        exercises,
        numberOfRounds: group.numberOfRounds,
      });
    }
    toggleExerciseDialogOpen(false);
  };

  if (exercises === undefined || equipment === undefined) {
    return null;
  }

  return (
    <Fragment>
      <TableContainer>
        <Table aria-label="simple table" size="small" className={classes.table}>
          <colgroup>
            <col style={{ width: 500 }}/>
            {Array.from({ length: numberOfRounds || 0 }).map((_, index) =>
              <col key={index} style={{ width: 140 }}/>
            )}
            <col className={classes.col}/>
          </colgroup>
          <GroupHeader
            name={name}
            numberOfRounds={numberOfRounds}
            addRound={addRound}
            removeRound={removeRound}
            deleteGroup={deleteGroup}
            copyGroup={copyGroup}
            disabled={disabled}
          />

          <GroupRows
            group={group}
            onChange={onChange}
            exercises={exercises}
            equipment={equipment}
            disabled={disabled}
            setExerciseForPreview={setExerciseForPreview}
          />
        </Table>
      </TableContainer>

      <Box my={1} mx={2}>
        <Button
          color='primary'
          variant='text'
          size='small'
          startIcon={<AddIcon />}
          onClick={() => toggleExerciseDialogOpen(true)}
          disabled={disabled}
        >
          {translate('workout.group_add_exercise')} {name}
        </Button>
      </Box>

      <ContentSelectDialog
        isOpen={isExerciseDialogOpen}
        contentList={exercises}
        onClose={handleExerciseSelection}
        type='Exercise'
      />
    </Fragment>
  );
};

export default GroupForm;
