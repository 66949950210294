import React, {useEffect, useState} from "react";
import {Activity, User} from "myfitworld-model";
import {Avatar, Box, Button, makeStyles, Typography} from "@material-ui/core";
import ArrowForwardIosIcon from "@material-ui/icons/ArrowForwardIos";
import usersApi from "../../api/usersApi";
import format from "date-fns/format";
import ReactionImage from "../ReactionImage";
import {useLocalizationProvider} from "../../providers/LocalizationProvider";
import {formatDistanceToNow} from "date-fns";

const useStyles = makeStyles((theme) => ({
  root: {
    marginBottom: theme.spacing(2),
    display: "flex",
    width: "100%",
  },
  avatar: {
    height: 32,
    width: 32,
  },
  image: {
    cursor: "pointer",
    height: "auto",
    maxWidth: "100%",
    width: 380,
  },
  systemMessageRoot: {
    display: "flex",
    width: "100%",
  },
  bubbleContainer: {
    width: "100%",
  },
  systemMessageContainer: {
    width: "100%",
    display: "flex",
    flexDirection: "column",
    alignItems: "flex-start",
    margin: theme.spacing(1),
    padding: theme.spacing(1),
    borderWidth: "1px",
    borderColor: theme.palette.primary.dark,
    borderStyle: "solid",
    borderRadius: 4,
  },
  headingContainer: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    width: "100%",
  },
  flexColumn: {
    width: "100%",
  },
  dateText: {
    marginBottom: theme.spacing(1),
  },
  messageText: {
    color: "#CDCDCD",
  },
  viewDetailsContainer: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "flex-end",
    alignItems: "center",
  },
  viewDetails: {
    color: "#CDCDCD",
    marginRight: 13,
  },
  systemMessageHeading: {
    fontSize: 18,
    color: "#CDCDCD",
  },
  iconVerticalFix: {
    paddingTop: 2,
  },
}));

const ActivityView = ({ activity, onViewDetails }: Props) => {
  const classes = useStyles();
  const { translate } = useLocalizationProvider();

  const [client, setClient] = useState<User>();
  useEffect(() => {
    usersApi([])
      .get(activity.threadId)
      .then((res) => setClient(res));
  }, [activity.threadId]);

  return (
    <div className={classes.root}>
      <Box display="flex" width={"100%"} ml={0}>
        <Avatar className={classes.avatar} src={client?.avatarURL} />
        <Box ml={2} className={classes.bubbleContainer}>
          <Box
            bgcolor={"background.default"}
            color={"text.primary"}
            py={1}
            px={2}
            borderRadius="borderRadius"
            boxShadow={1}
            className={classes.bubbleContainer}
          >
            <Typography color="inherit" variant="h6">
              {client?.firstName}
            </Typography>
            <Box mt={1} className={classes.bubbleContainer}>
              <div className={classes.systemMessageRoot}>
                <Box className={classes.systemMessageContainer}>
                  <Box className={classes.headingContainer}>
                    <Typography variant="h4">{translate("chat.workout_completed")}</Typography>
                    { activity.reaction && <ReactionImage reaction={activity.reaction} />}
                  </Box>
                  <Box className={classes.flexColumn}>
                    <Typography className={classes.dateText}>{format(activity.createdAt, "d. LLLL Y.")}</Typography>
                    <Typography className={classes.messageText}>“{activity.text}”</Typography>

                    <Box className={classes.viewDetailsContainer}>
                      <Button variant="text" color="primary" onClick={onViewDetails} endIcon={<ArrowForwardIosIcon />}>
                        {translate("chat.details_label")}
                      </Button>
                    </Box>
                  </Box>
                </Box>
              </div>
            </Box>
          </Box>
          <Box mt={1} display="flex" justifyContent="flex-end">
            <Typography noWrap color="textSecondary" variant="caption">
              {formatDistanceToNow(activity.createdAt, { addSuffix: true })}
            </Typography>
          </Box>
        </Box>
      </Box>
    </div>
  );
};

export default ActivityView;

interface Props {
  activity: Activity;
  onViewDetails: (event?: any) => void;
}
