import React from 'react';
import InvitationsTable from "../../components/invitations/InvitationsTable";
import {Role} from "myfitworld-model";

const ClientInvitationsTable = ({
  onRowClick,
  invitationsBlocked,
}: {
  onRowClick: (id: string) => void,
  invitationsBlocked?: boolean,
}) => {
  return (
    <InvitationsTable
      roles={[Role.Client]}
      showRole={false}
      onRowClick={onRowClick}
      createActionLabel='Invite client'
      invitationsBlocked={invitationsBlocked}
    />
  );
}

export default ClientInvitationsTable;
