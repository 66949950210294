import React, {useState} from 'react';
import DatePicker from 'react-datepicker';
import {TextField} from "@material-ui/core";
import format from 'date-fns/format';
import startOfDay from 'date-fns/startOfDay'

import {BrowserView, MobileView,} from "react-device-detect";

const DateTimeSelect = ({
                          value,
                          showTime,
                          minDate,
                          maxDate,
                          onChange,
                          autoFocus,
                        }: {
  value: Date,
  showTime?: boolean,
  minDate?: Date,
  maxDate?: Date,
  onChange: (dateTime: Date) => void,
  autoFocus?: boolean,
}) => {
  const [open, setOpen] = useState(autoFocus);
  const handleCalendarClose = () => {
    setOpen(false);
  };

  const handleCalendarOpen = () => {
    setOpen(true);
  };

  return (
    <div style={{display: "flex"}}>
      <BrowserView>
        <DatePicker
          selected={value}
          onChange={(date:Date) => showTime ? onChange(date) : onChange(startOfDay(date))}
          showTimeSelect={showTime}
          dateFormat={showTime ? 'yyyy/MM/dd HH:mm' : 'yyyy/MM/dd'}
          timeFormat='HH:mm'
          customInput={
            <TextField
              label='Date'
              variant='outlined'
              value={value || ''}
            />
          }
          minDate={minDate}
          maxDate={maxDate}
          onCalendarClose={handleCalendarClose}
          onCalendarOpen={handleCalendarOpen}
          open={open}
          autoFocus={open}
        />
      </BrowserView>
      <MobileView>
        <TextField
          id="datetime-local"
          label="Date"
          margin={"normal"}
          style={{minWidth: 180}}
          type={showTime ? 'datetime-local' : 'date'}
          defaultValue={format(value, showTime ? 'yyyy-MM-dd HH:mm' : 'yyyy-MM-dd')}
          inputMode='none'
          onChange={(event) => onChange(showTime ? new Date(event.currentTarget.value) : startOfDay(new Date(event.currentTarget.value)))}
          InputLabelProps={{
            shrink: true,
          }}
        />
      </MobileView>
    </div>
  );
};

export default DateTimeSelect;

