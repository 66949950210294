import React from 'react';
import Typography from '@material-ui/core/Typography';
import {useLocalizationProvider} from "../../providers/LocalizationProvider";
import {auth} from "../../firebase";
import {Button} from "@material-ui/core";
import useNavigation from "../../hooks/useNavigation";

export default () => {
  const {translate} = useLocalizationProvider();
  const {navigation} = useNavigation()

  return <div style={{ margin: '0 auto', maxWidth: 600, marginTop: 48 }}>
    <Typography variant='h1' style={{ marginBottom: 24 }}>
      {translate('invitation.error_page.title')}
    </Typography>

    <Typography variant='body1' style={{ marginBottom: 24 }}>
      {translate('invitation.error_page.description')}
    </Typography>

    <Typography variant='body1'>
      {translate('invitation.error_page.stepstotake')}
    </Typography>
    <Button onClick={() =>
      auth
        .signOut()
        .then((_) => {
          navigation('/');
        })
        .catch((err) => console.log(err))
    }>
      {translate('button.logout')}
    </Button>
  </div>;
}
