import React, {useCallback, useEffect, useState} from "react";
import { RouteComponentProps } from "@reach/router";
import {UserInvitationWithCrudInstructions, WorkoutDeletion} from "../../api/UserWithCRUDInstructions";
import globalState, { GlobalState } from "../../globalState";
import { Alert } from "@material-ui/lab";
import {LinearProgress} from "@material-ui/core";
import Box from "@material-ui/core/Box";
import useNavigation from "../../hooks/useNavigation";
import {getInvitation, updateInvitation} from "../../api/invitationsApi";
import ClientCalendar from "./form/clientCalendar/ClientCalendar";
import {UserProgram} from "myfitworld-model/dist/firestoreCollectionTypes/User";
import useEntityList from "../../hooks/useEntityList";
import ProgramTemplate from "myfitworld-model/dist/firestoreCollectionTypes/ProgramTemplate";
import {programTemplateApi, workoutApi} from "../../api/common";
import i18n from "i18n-js";
import Workout from "myfitworld-model/dist/firestoreCollectionTypes/Workout";
import {useExerciseProvider} from "../../providers/ExerciseProvider";
import useGlobalCachedResourceList from "../../api/useGlobalCachedResourceList";
import {Equipment} from "myfitworld-model/dist/firestoreCollectionTypes/Equipment";
import transformWorkoutsIntoCalendarEvents from "./form/clientCalendar/transformWorkoutsIntoCalendarEvents";
import {
  handleImportProgramTemplateToActiveProgram as handleImportProgramTemplateToActiveProgram_,
  handleAddNewProgram as handleAddNewProgram_,
  deleteWorkoutFromActiveProgram as deleteWorkoutFromActiveProgram_,
} from './utilities';
import TopActionBar from "./TopActionBar";
import ClientProfile from "./form/ClientProfile";

const loadingFlagsToLoadingPercent = (...args: boolean[]) =>
  args.map(arg => Number(!arg)).reduce((acc, next) => acc + next, 0) / args.length;

export default function ({ invitationId }: RouteComponentProps<{ invitationId: string }>) {
  const [invitation, setInvitation] = useState<UserInvitationWithCrudInstructions>();
  const [updateInProgress, toggleUpdateInProgress] = useState<boolean>(false);
  const [formErrorMessage, setFormErrorMessage] = useState<string | undefined>();
  const { navigation } = useNavigation();

  const {data: programTemplates, loading: programTemplatesLoading} = useEntityList<ProgramTemplate>(
    programTemplateApi.list, undefined,
    `title.${i18n.locale}`
  );
  const {data: workoutTemplates, loading: workoutTemplatesLoading} = useEntityList<Workout>(workoutApi.list, undefined,
    `title.${i18n.locale}`);
  const {exercises: exerciseList, isLoading: exercisesLoading} = useExerciseProvider();
  const {data: equipmentList, loading: equipmentLoading} = useGlobalCachedResourceList<Equipment>('equipmentCache');

  const activeProgram = invitation?.userProgram;
  const workoutsAsCalendarEvents = transformWorkoutsIntoCalendarEvents(activeProgram);

  const onSuccess = () => {
    globalState.update((state: GlobalState) => {
      state.toastQueue.push({ message: 'New client data saved! Send them the invitation if you’re done editing!', severity: "success" });
    });
    navigation('/clients', undefined, true);
  };

  const handleSubmit = async () => {
    if (invitation) {
      setFormErrorMessage(undefined);
      toggleUpdateInProgress(true);
      const r = await updateInvitation(invitation);
      toggleUpdateInProgress(false);
      if (r.errorMessage) {
        setFormErrorMessage(r.errorMessage);
      } else {
        onSuccess();
      }
    }
  };

  const loadInvitation = useCallback(async () => {
    if (invitationId) {
      const res = await getInvitation(invitationId);
      if (res) {
        await setInvitation(res);
        if (res.userProgram === undefined) {
          handleAddNewProgram_({
            startDate: new Date().toJSON(),
            defineExplicitTime: true,
            programTemplate: '',
            programTemplates: [], workoutTemplates: [],
            setPrograms: setNewProgram, programs: [],
          });
        }
      }
    }
  }, [invitationId]);

  useEffect(() => {
    loadInvitation();
  }, [loadInvitation]);

  const loadingPercent = loadingFlagsToLoadingPercent(
    invitation === undefined, programTemplatesLoading,
    workoutTemplatesLoading, exercisesLoading, equipmentLoading
  );

  const setNewProgram = (userPrograms: UserProgram[]) => {
    setInvitation(val => val ? ({ ...val, userProgram: userPrograms[0] }) : val);
  };

  const handleProgramChange = (index: number, program: UserProgram) =>
    setNewProgram([program]);

  const setWorkoutDeletions = (workoutDeletions: WorkoutDeletion[]) => {
    setInvitation(val => val ? ({ ...val, workoutDeletions: workoutDeletions }) : val);
  };

  const handleImportProgramTemplateToActiveProgram = (
    { startDate, programTemplate }: { startDate: string; programTemplate: string; }
    ) => {
    if (activeProgram) {
      handleImportProgramTemplateToActiveProgram_({
        startDate, programTemplate, workoutTemplates, programTemplates,
        activeProgram, handleProgramChange, activeProgramIndex: 0,
      });
    }
  };

  const deleteWorkoutFromActiveProgram = (workoutIndex: number) => {
    if (activeProgram) {
      deleteWorkoutFromActiveProgram_({
        workoutIndex, activeProgram, workoutDeletions: invitation?.workoutDeletions || [],
        setWorkoutDeletions, handleProgramChange, activeProgramIndex: 0,
      });
    }
  };

  if (invitation === undefined || loadingPercent < 1) {
    return (
      <LinearProgress
        variant="determinate"
        value={loadingPercent}
      />
    );
  }

  return (
    <Box px={3}>
      {updateInProgress && <LinearProgress variant="indeterminate" style={{ marginBottom: 12 }} />}

      <div>
        <TopActionBar
          handleClose={() => navigation("/clients")}
          loading={false}
          variant='contained'
          handleSave={handleSubmit}
          showSave={activeProgram !== undefined}
        />

        <Box mb={6}>
          <ClientProfile user={invitation} loadUser={loadInvitation} page="invitations"/>
        </Box>

        {activeProgram &&
          <div>
            <ClientCalendar
              user={invitation}
              loadUser={loadInvitation}
              program={activeProgram}
              events={workoutsAsCalendarEvents.events}
              resourceMap={workoutsAsCalendarEvents.resourceMap}
              exercises={exerciseList || []}
              equipment={equipmentList}
              workoutTemplates={workoutTemplates}
              programTemplates={programTemplates}
              onActiveProgramChange={(p: UserProgram) => handleProgramChange(0, p)}
              onDeleteWorkout={deleteWorkoutFromActiveProgram}
              onImportProgram={handleImportProgramTemplateToActiveProgram}
              type='invitations'
            />

            {formErrorMessage && (
              <Alert severity="error" style={{ marginTop: 24 }}>
                {formErrorMessage}
              </Alert>
            )}
          </div>}
      </div>
    </Box>
  );
}
