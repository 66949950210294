import {firestore} from '../firebase';
import {User, WorkoutDay} from "myfitworld-model";
import subHours from "date-fns/subHours";
import chunk from "lodash/chunk";

export interface ClientSchedulesResponse {
  clientCount: number,
  clients: User[],
  workouts: WorkoutDay[],
  error: boolean,
}

const getDefault = (hasError: boolean): ClientSchedulesResponse => ({
  clientCount: 0,
  workouts: [],
  clients: [],
  error: hasError,
});

const FIRESTORE_MAXIMUM_IN_QUERY_PARAMS = 10;

export const getTrainerSchedules = async (trainerId: string) :  Promise<ClientSchedulesResponse> => new Promise((resolve) => {
  firestore.collection("users").doc(trainerId).get().then((doc) => {
    if (doc.exists) {
      const clientIds = chunk((doc.data() as User).clients || [], FIRESTORE_MAXIMUM_IN_QUERY_PARAMS);
      if (clientIds.length === 0) {
        return resolve(getDefault(false));
      }

      const promises = clientIds.map((clientIdsChunk) => {
        return new Promise((resolve) => {
          firestore
            .collection('users')
            .where('id', 'in', clientIdsChunk)
            .get()
            .then(clientQuery => {
              const clients: User[] = [];
              clientQuery.forEach(val => {
                clients.push(val.data() as unknown as User);
              });

              firestore
                .collectionGroup('assignedWorkouts')
                .where('userId', 'in', clientIdsChunk)
                .where('dateTime', '>', subHours(new Date(), 12))
                .get()
                .then((workoutsQuery) => {
                  const workouts: WorkoutDay[] = [];
                  workoutsQuery.docs.forEach(val => {
                    const raw = val.data();
                    workouts.push({
                      ...raw as unknown as WorkoutDay,
                      parentId: val.ref.parent.parent?.id,
                      id: val.id,
                      dateTime:
                        raw.dateTime && raw.dateTime.toDate
                          ? raw.dateTime.toDate().toJSON() // Timestamp object
                          : new Date(raw.dateTime).toJSON()
                    });
                  });
                  resolve({
                    clientCount: clientIdsChunk.length,
                    clients,
                    workouts,
                    error: false
                  });
                }).catch(error => {
                resolve(getDefault(true))
              });
            });
        });
      });

      Promise.all(promises)
        .then(responses => {
          resolve(
            responses.reduce((prev, current) => {
              return {
                clientCount: (prev as ClientSchedulesResponse).clientCount + (current as ClientSchedulesResponse).clientCount,
                clients: [...((prev as ClientSchedulesResponse).clients || []), ...((current as ClientSchedulesResponse).clients || [])],
                workouts: [...((prev as ClientSchedulesResponse).workouts || []), ...((current as ClientSchedulesResponse).workouts || [])],
                error: false
              }
            }, getDefault(false) as ClientSchedulesResponse) as ClientSchedulesResponse
          )
        }).catch(error => {
          resolve(getDefault(true))
        });
    }
  })
})

export default getTrainerSchedules;
