import React, {useState} from 'react';
import {Box, createStyles, Grid, Theme} from "@material-ui/core";
import Button from '@material-ui/core/Button';
import {makeStyles} from "@material-ui/core/styles";
import SimpleDialog from "../../components/SimpleDialog";
import {useLocalizationProvider} from "../../providers/LocalizationProvider";
import ArrowBackIosIcon from "@material-ui/icons/ArrowBackIos";
import IconButton from "@material-ui/core/IconButton";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    sticky: {
      backgroundColor: theme.palette.background.default,
      paddingTop: theme.spacing(4),
      paddingBottom: theme.spacing(4),
      position: 'sticky',
      bottom: 0,
      zIndex: 1,
    },
    right: {
      position: 'absolute',
      right: 0,
    }
  }),
);

const ContentFormActionBar = ({
                                onSubmit,
                                onClose,
                                archived,
                                handleArchive,
                                disabled,
                                onSubmitLabel,
                                entityName,
                                mode,
                                isFormDirty,
                              }: {
  onSubmit: () => void,
  onClose: () => void,
  archived: boolean | undefined,
  handleArchive: () => void,
  disabled: boolean,
  onSubmitLabel: string,
  entityName: 'Workout' | 'Program',
  mode: 'Create' | 'Edit',
  isFormDirty: boolean
}) => {
  const classes = useStyles();
  const {translate} = useLocalizationProvider();
  const [isOpenDialog, setIsOpenDialog] = useState(false);

  return (
    <Box className={classes.sticky}>
      {disabled ?
        <IconButton edge="start" color="inherit" onClick={onClose} aria-label="close">
          <ArrowBackIosIcon/>
        </IconButton>
        :
        <Grid container spacing={2}>
          <Grid item>
            <Button
              color='primary'
              variant={isFormDirty ? 'contained' : 'outlined'}
              onClick={onSubmit}
              disabled={disabled}
            >
              {onSubmitLabel}
            </Button>
          </Grid>

          <Grid item>
            <Button color="secondary" onClick={onClose}>
              {translate('button.cancel')}
            </Button>
          </Grid>

          {
            mode === 'Edit' &&
            <Grid item>
              {
                archived &&
                <Button variant="outlined" onClick={() => setIsOpenDialog(true)} disabled={disabled}
                        className={classes.right}>
                  {translate('button.unarchive')}
                </Button>
              }

              {
                !archived &&
                <Button variant="outlined" onClick={() => setIsOpenDialog(true)} disabled={disabled}
                        className={classes.right}>
                  {translate('button.archive')}
                </Button>
              }

              <SimpleDialog
                title={translate(entityName === 'Workout' && archived ? 'workout.unarchive_workout' :
                  entityName === 'Workout' && !archived ? 'workout.archive_workout' :
                    entityName === 'Program' && archived ? 'program.unarchive_program' :
                      'program.archive_program'
                )}
                text={translate(entityName === 'Workout' && archived ? 'workout.unarchive_workout_question' :
                  entityName === 'Workout' && !archived ? 'workout.archive_workout_question' :
                    entityName === 'Program' && archived ? 'program.unarchive_program_question' :
                      'program.archive_program_question'
                )}
                open={isOpenDialog}
                onClose={() => setIsOpenDialog(false)}
                onConfirm={() => {
                  setIsOpenDialog(false);
                  handleArchive();
                }}
                onConfirmLabel={translate('general.confirmed')}
                onCancel={() => setIsOpenDialog(false)}
                onCancelLabel={translate('general.rejected')}
              />
            </Grid>
          }
        </Grid>
      }
    </Box>
  )
};

export default ContentFormActionBar;
