import React, {useState} from 'react';
import {createStyles, makeStyles, Theme} from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import IconButton from '@material-ui/core/IconButton';
import Typography from '@material-ui/core/Typography';
import CloseIcon from '@material-ui/icons/Close';
import Slide from '@material-ui/core/Slide';
import {TransitionProps} from '@material-ui/core/transitions';
import WorkoutDetails from "../../content/workout/workoutForm/WorkoutDetails";
import Workout, {ExerciseGroup, WorkoutMinimal} from "myfitworld-model/dist/firestoreCollectionTypes/Workout";
import Exercise from "myfitworld-model/dist/firestoreCollectionTypes/Exercise";
import {Equipment} from "myfitworld-model/dist/firestoreCollectionTypes/Equipment";
import {Box} from "@material-ui/core";
import useGroupState from "../../content/useGroupState";
import LocalizedTextField from "../../../components/LocalizedTextField";
import TextField from "@material-ui/core/TextField/TextField";
import {useForm} from "react-hook-form";
import LanguageSelect from "../../../components/language/LanguageSelect";
import {useLocalizationProvider} from "../../../providers/LocalizationProvider";
import Grid from "@material-ui/core/Grid";
import SubtleInfoMessage from "../../../components/SubtleInfoMessage";
import TextMaskExpiration from "../../../components/TextMaskExpiration";
import {LocalizedProperty} from "myfitworld-model/src/index";
import DifficultyLevel from "myfitworld-model/src/enums/DifficultyLevel";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    title: {
      marginLeft: theme.spacing(2),
      flex: 1,
    },
  }),
);

const Transition = React.forwardRef(function Transition(
  props: TransitionProps & { children?: React.ReactElement },
  ref: React.Ref<unknown>,
) {
  return <Slide direction='up' ref={ref} {...props} />;
});

interface WorkoutMinimalWithTimes {
  interExerciseRest: string;
  interRoundRest: string;
  interGroupRest: string;
  warmUpGroup: ExerciseGroup;
  coolDownGroup: ExerciseGroup;
  exerciseGroups: ExerciseGroup[];
  notes?: string;
  thumbnailURL?: string;
  title?: LocalizedProperty;
  difficultyLevel?: DifficultyLevel;
}

const WorkoutEditDialog = ({
                             open,
                             handleClose,
                             workout,
                             exercises,
                             equipment,
                             handleSave,
                           }: {
  open: boolean,
  handleClose: () => void,
  handleSave: (workout: WorkoutMinimal) => void,
  workout: WorkoutMinimal,
  exercises: Exercise[],
  equipment: Equipment[],
}) => {
  const classes = useStyles();
  const getMinSecDurationText = (seconds: any) : string => {
    if (isNaN(seconds)) {
      return seconds;
    }
    console.log("seconds", seconds);
    const minNumber = Math.floor((seconds || 0 ) / 60);
    const secNumber = (seconds || 0 ) % 60;
    console.log("minNumber", minNumber, "secNumber", secNumber);

    const minText = minNumber >= 10 ? `${minNumber}` : `0${minNumber}`;
    const secText = secNumber >= 10 ? `${secNumber}` : `0${secNumber}`;

    const text = `${minText}:${secText}`;
    console.log("text", text);
    return text;
  };

  const {register, watch, getValues} = useForm<WorkoutMinimalWithTimes>({defaultValues: {
      ...workout,
      interExerciseRest: getMinSecDurationText(workout?.interExerciseRest),
      interRoundRest: getMinSecDurationText(workout?.interRoundRest),
      interGroupRest: getMinSecDurationText(workout?.interGroupRest)
    }});

  const {translate} = useLocalizationProvider();

  const {groups: exerciseGroups, addGroup, removeGroup, copyGroup, updateGroup} = useGroupState<ExerciseGroup>(
    {numberOfRounds: 3, exercises: []},
    workout.exerciseGroups
  );
  const [warmUpGroup, setWarmUpGroup] = useState<ExerciseGroup>(
    workout.warmUpGroup || {numberOfRounds: 3, exercises: []}
  );
  const [coolDownGroup, setCoolDownGroup] = useState<ExerciseGroup>(
    workout.coolDownGroup || {numberOfRounds: 3, exercises: []}
  );

  const getSeconds = (text: any) => {
    if (!text) return 0;
    const time = text.split(":");
    const seconds = parseInt(time[0], 10) * 60 + parseInt(time[1], 10);
    return seconds;
  };

  const onSave = () => {
    const values = getValues();
    handleSave && handleSave({
      ...workout,
      exerciseGroups: exerciseGroups,
      warmUpGroup: warmUpGroup,
      coolDownGroup: coolDownGroup,
      title: values.title,
      interExerciseRest: getSeconds(values.interExerciseRest),
      interRoundRest: getSeconds(values.interRoundRest),
      interGroupRest: getSeconds(values.interGroupRest)
    });
  };

  return (
    <Dialog fullScreen open={open} onClose={handleClose} TransitionComponent={Transition}>
      <AppBar position="fixed">
        <Toolbar>
          <IconButton edge='start' color='inherit' onClick={handleClose} aria-label='close'>
            <CloseIcon/>
          </IconButton>
          <Typography variant='h5' className={classes.title}>
            {translate('workout.edit.title')}
          </Typography>
          <Button autoFocus color='inherit' onClick={onSave}>
            {translate('button.save')}
          </Button>
        </Toolbar>
      </AppBar>

      <Box my={8} px={2} py={4}>
        <LanguageSelect<Workout> watch={watch}/>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <LocalizedTextField
              inputRef={register}
              autoFocus
              margin='dense'
              name='title'
              label='Title'
              type='text'
              fullWidth
              required
            />
          </Grid>
          <Grid item xs={12} md={4}>
            <TextField
              id='interExerciseRest'
              name='interExerciseRest'
              inputRef={register}
              InputProps={{
                inputComponent: TextMaskExpiration as any,
              }}
              label={translate('workout.edit.rest_times.inter_exercise')}
              margin='dense'
              fullWidth
              required
            />
          </Grid>
          <Grid item xs={12} md={4}>
            <TextField
              id='interRoundRest'
              name='interRoundRest'
              inputRef={register}
              InputProps={{
                inputComponent: TextMaskExpiration as any,
              }}
              label={translate('workout.edit.rest_times.inter_round')}
              margin='dense'
              fullWidth
              required
            />
          </Grid>
          <Grid item xs={12} md={4}>
            <TextField
              id='interGroupRest'
              name='interGroupRest'
              inputRef={register}
              InputProps={{
                inputComponent: TextMaskExpiration as any,
              }}
              label={translate('workout.edit.rest_times.inter_group')}
              margin='dense'
              fullWidth
              required
            />
          </Grid>
        </Grid>

        <SubtleInfoMessage>
          {translate('workout.edit.header_note')}
        </SubtleInfoMessage>

        <WorkoutDetails
          warmUpGroup={warmUpGroup}
          setWarmUpGroup={setWarmUpGroup}
          coolDownGroup={coolDownGroup}
          setCoolDownGroup={setCoolDownGroup}
          exerciseGroups={exerciseGroups}
          updateGroup={updateGroup}
          loading={false}
          exercises={exercises}
          equipment={equipment}
          removeGroup={removeGroup}
          copyGroup={copyGroup}
          addGroup={addGroup}
        />
      </Box>
    </Dialog>
  );
};

export default WorkoutEditDialog;
