import {Equipment, Exercise} from "myfitworld-model";
import Workout from "myfitworld-model/dist/firestoreCollectionTypes/Workout";
import getLocalizedProperty from "../../../../utils/getLocalizedProperty";

export const getContentTitle = (id: string, content: Array<Exercise | Workout>) => {
  const foundItem = content.find(item => item.id === id);
  if (foundItem) {
    return getLocalizedProperty(foundItem.title)
  } else {
    return ''
  }
}

export type EquipmentOption = 'incline' | 'speed' | 'weight' | 'rpm' | 'pace' | 'power' | 'level';

export const equipmentOptions: Array<{ key: EquipmentOption, label: string }> = [
  {key: 'incline', label: 'Incline'}, {key: 'speed', label: 'Speed (km/h)'},
  {key: 'weight', label: 'Weight (kg)'}, {key: 'rpm', label: 'RPM'}, {key: 'pace', label: 'Pace (min/km)'},
  {key: 'power', label: 'Power (Watts)'}, {key: 'level', label: 'Level (1-20)'}
];

export const getEquipmentFields = (exerciseId: string, exercises: Exercise[], equipment: Equipment[]) => {
  const requiredEquipmentOptions = {incline: false, speed: false, weight: false, rpm: false, pace: false, power: false, level: false};
  const exercise = exercises.find(item => item.id === exerciseId);
  if (exercise) {
    const requiredEquipment = equipment.filter(item =>
      exercise && exercise.equipment && exercise.equipment.indexOf(item.id as string) >= 0
    );

    requiredEquipment.forEach(item => {
      Object.keys(requiredEquipmentOptions).forEach(key => {
        if (item[key as EquipmentOption] === true) {
          requiredEquipmentOptions[key as EquipmentOption] = true;
        }
      });
    });
  }

  return requiredEquipmentOptions;
};
