import Box from "@material-ui/core/Box";
import React, {CSSProperties, PropsWithChildren} from "react";
import {makeStyles} from "@material-ui/core/styles";
import {createStyles, Theme} from "@material-ui/core";


export const baseContentListStyles = makeStyles((theme: Theme) =>
  createStyles({
    container: {
      width: '100%',
      marginTop: theme.spacing(0.2),
      marginBottom: theme.spacing(0.2),
      cursor: 'pointer',
    },
    innerContainer: {
      paddingTop: theme.spacing(1),
      paddingBottom: theme.spacing(1),
      paddingLeft: theme.spacing(1),
      paddingRight: theme.spacing(1),
      width: '100%',
      overflow: "auto",
      overflowY: "hidden",
      whiteSpace: "nowrap",
      '&:hover': {
        // @ts-ignore
        backgroundColor: theme.palette.background['light']
      }
    },
    firstCell: {
      paddingLeft: theme.spacing(0),
    },
    thumbnail: {
      width: 190,
      marginRight: theme.spacing(1.5),
    },
    image: {
      width: '100%',
      height: '100%',
      objectFit: 'cover',
      borderRadius: theme.shape.borderRadius,
    },
    chips: {
      marginTop: theme.spacing(1),
      marginLeft: -theme.spacing(.5),
      marginRight: -theme.spacing(.5),
    },
    count: {
      marginRight: theme.spacing(1),
      flex: 1,
      alignItems: 'flex-end',
      textAlign: 'right'
    },
    item: {
      marginRight: theme.spacing(1)
    },
    details: {
      display: 'flex',
      marginRight: theme.spacing(1),
      flex: 1,
      flexDirection: 'row',
      justifyContent: 'flex-end',
      alignItems: 'flex-end',
      textAlign: 'right'
    },
  })
);

export default ({onSelect, children, style, height}: PropsWithChildren<{ onSelect: (event: React.MouseEvent) => void, height?: number, style?: CSSProperties }>) => {
  const classes = baseContentListStyles();

  return (
    <Box onClick={onSelect}
         className={classes.container}
         style={style}
         height={height}
         display="flex"
         flex={1}
    >
      <Box display="flex" alignItems="center" flex={1} className={classes.innerContainer}>
        {children}
      </Box>
    </Box>
  )
}
