import React, {useMemo} from 'react';
import {Equipment, Exercise} from 'myfitworld-model';
import ExerciseForm from './ExerciseForm';
import ExerciseListItem from './ExerciseListItem';
import { exercisesApi } from '../../../api/common';
import EntityManager from '../../../entity/EntityManager';
import {initialContentFilterState} from '../../../utils/initialContentFilterState';
import {GeneralEntity} from 'myfitworld-model';
import buildKeyToNameMap from '../../../utils/buildKeyToNameMap';
import i18n from 'i18n-js';
import {exerciseContentFilterOptions} from "../../../utils/contentFilterOptions";
import useGlobalCachedResourceList from "../../../api/useGlobalCachedResourceList";

const ExercisesManagementPage = () => {
  const { data: sportOptions } = useGlobalCachedResourceList<GeneralEntity>('sportsCache');
  const { data: muscleGroupOptions } = useGlobalCachedResourceList<GeneralEntity>('muscleGroupsCache');
  const { data: equipmentOptions } = useGlobalCachedResourceList<Equipment>('equipmentCache');

  const ExerciseListItemComponent = useMemo(() => (props: any) =>
    <ExerciseListItem
      {...props}
      sports={buildKeyToNameMap(sportOptions)}
      muscleGroups={buildKeyToNameMap(muscleGroupOptions)}
      equipment={buildKeyToNameMap(equipmentOptions)}
      style={props.style}
    />,
    [sportOptions, muscleGroupOptions, equipmentOptions]
  );

  return (
    <EntityManager<Exercise>
      entityName={'Exercise'}
      entityCreateHelper={'Add the basic details now, you can upload the video later.'}
      apiInterface={exercisesApi}
      initialFilterState={initialContentFilterState}
      ListItemComponent={ExerciseListItemComponent}
      FormComponent={ExerciseForm}
      emptyStateTitle='Ready to add a new exercise?'
      sortKey={`title.${i18n.locale}`}
      contentFilterOptions={exerciseContentFilterOptions}
      appendFormInfoToURL
    />
  )
};

export default ExercisesManagementPage;
