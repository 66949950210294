import React from 'react';
import LoginPage from "./LoginPage";

import './FireabaseUI.css';
import {RouteComponentProps} from "@reach/router";
import {useInvitationProvider} from "../../providers/InvitationProvider";
import LoadingSpinner from "../../components/LoadingSpinner";

const LoginContainer = (_: RouteComponentProps) => {
  const {invitation} = useInvitationProvider();
  console.log("inv", invitation);
  return invitation === undefined ? <LoadingSpinner />: (<LoginPage invitation={invitation}/>);
}

export default LoginContainer;
