import React, {useEffect, useState} from 'react';
import {Redirect, RouteComponentProps} from "@reach/router";
import LoadingSpinner from "../../components/LoadingSpinner";
import InvitationErrorPage from "./InvitationErrorPage";
import {useInvitationProvider} from "../../providers/InvitationProvider";
import invitationsApi from "../../api/invitationsApi";
import InvitationInfoPage from "../InvitationInfoPage";
import {useUserProvider} from "../../providers/UserProvider";

export default function ({invitationCode}: RouteComponentProps<{ invitationCode: string }>) {
  const [error, setError] = useState<string>();

  const {invitation, setInvitation} = useInvitationProvider();

  const {user} = useUserProvider();

  useEffect(() => {
    if (!invitationCode) return;
    invitationsApi.getInvitation(invitationCode)
      .then(result => {
        if (!result) {
          setError('invitation.error_notfound');
        } else {
          setInvitation(result ? result : null);
        }
      })
  }, [invitationCode, setInvitation]);

  if (error) {
    return <InvitationErrorPage/>;
  } else if (!invitation) {
    return <LoadingSpinner/>;
  } else {
    return user ? <InvitationInfoPage/> : <Redirect to='/' noThrow/>;
  }
}
