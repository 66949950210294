import React from 'react';
import {makeStyles, Theme, useTheme} from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import format from 'date-fns/format';
import isPast from 'date-fns/isPast';
import Box from '@material-ui/core/Box/Box';
import {IconButton, Paper} from "@material-ui/core";
import ChatButton from "../../components/chat/ChatButton";
import {ClientInfo} from "./buildUserInfoMap";
import {WorkoutDay} from "myfitworld-model";
import getLocalizedProperty from "../../utils/getLocalizedProperty";
import {useOrganizationsProvider} from "../../providers/OrganizationsProvider";
import EditIcon from '@material-ui/icons/Edit';
import {displayDuration} from "../../utils/displayDuration";
import ScheduleIcon from '@material-ui/icons/Schedule';
import {useOrganizationProvider} from "../../providers/OrganizationProvider";
import AssessmentIcon from "@material-ui/icons/Assessment";
import useNavigation from "../../hooks/useNavigation";

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2),
    maxWidth: 1000,
    borderStyle: "solid",
    borderWidth: theme.spacing(0.1)
  },
  timeField: {
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
  },
  small: {
    fontSize: theme.typography.pxToRem(12)
  },
}));

const WorkoutCard = ({clientInfo, clientId, workout, editWorkout}: Props) => {
  const theme = useTheme();
  const classes = useStyles();
  const {navigation} = useNavigation();

  const [{selectedOrganization}] = useOrganizationProvider();
  const {organizations} = useOrganizationsProvider();
  const clientOrganizations = selectedOrganization && (clientInfo.organizations?.filter((orgId) => orgId !== selectedOrganization.id) || []).map(
      orgId => organizations?.find((e) => e.id === orgId)?.name
  ).join(',');

  const dateTime = new Date(workout.dateTime);
  const time = format(dateTime, 'hh:mm a');

  return (
    <Paper
      style={{
        borderColor: workout.finished ?  theme.palette.background.paper : theme.palette.primary.main
      }}
      className={classes.root}
      elevation={isPast(dateTime) ? 0 : 1}
    >
      <Box p={2} display="flex" flexDirection="row" justifyContent="space-between">
        <Box display="flex" flexDirection="row">
          <Box mr={6}>
            <Typography variant='h6' color='textSecondary'>{time}</Typography>
          </Box>

          <Box display="flex" flexDirection="column">
            <Typography variant='h4' color='textSecondary' gutterBottom>{`${clientInfo.name} ${clientOrganizations ? `[${clientOrganizations}]` : ``}`}</Typography>
            <Typography variant='h5' color='textSecondary' gutterBottom>{getLocalizedProperty(workout.workout.title)}</Typography>
            <Box display="flex" flexDirection="row" alignItems="center">
              {<ScheduleIcon color="primary" />}
              <Typography variant='h5' color='textSecondary' style={{marginLeft: theme.spacing(1)}}>
                {displayDuration(workout.duration ? Math.round(workout.duration / 60 ): 0)}
              </Typography>
            </Box>
          </Box>
        </Box>

        <Box display="flex" flexDirection="column">
          {clientId && <ChatButton userId={clientId}/>}
          {clientId && !workout.finished && <IconButton onClick={(e) => {
            editWorkout(workout, clientId);
          }}>
            <EditIcon/>
          </IconButton>}
          {clientId && workout.finished && <IconButton onClick={() => navigation(`clients/${clientId}/statistics/${workout.id}`)}>
            <AssessmentIcon/>
          </IconButton>}
        </Box>
      </Box>
    </Paper>
  );
}

export default WorkoutCard;

interface Props {
  clientId?: string;
  clientInfo: ClientInfo;
  workout: WorkoutDay;
  editWorkout: (workout: any, workoutIndex: any) => void;
}
