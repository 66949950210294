import React, {PropsWithChildren, useContext, useEffect, useState} from 'react';
import * as firebase from 'firebase';
import {auth, firestore} from '../firebase';
import UserContext from '../contexts/UserContext';
import get from 'lodash/get';
import {BillingDetails, User} from "myfitworld-model";
import {acceptInvitation} from "../api/usersApi";
import {INV_KEY, useInvitationProvider} from "./InvitationProvider";
import globalState from "../globalState";
import {getOrganization} from "../api/organizationsApi";
import {SUBSCRIPTION_ACTIVE, SUBSCRIPTION_CREATED_NEW, SUBSCRIPTION_PAYMENT_FAILED} from "../components/payment/utils";
import Role from "myfitworld-model/dist/enums/Role";

interface UserProviderState {
  user: User | null;
  loading: boolean;
  needsInvitation?: boolean;
  organizationOverride?: string;
  enterApplication?: boolean;
}

interface UserWithCurrentRole extends User {
  currentRole?: Role;
}

const extractCurrentOrganization = (organizations: { role: string, id: string }[], organizationId: string) => {
  if (organizations && organizationId) {
    return organizations.find(o => o.id === organizationId);
  }
}

export const UserProvider = ({children}: PropsWithChildren<any>) => {
  const [state, setState] = useState<UserProviderState>({
    user: null,
    loading: true,
    needsInvitation: false,
    enterApplication: false
  })

  const setOrganizationOverride = (organizationId: string | undefined) => {
    let actingAs = (state.user as any) as UserWithCurrentRole;
    if (organizationId) {
      actingAs.currentRole = Role.Admin;
      actingAs.currentOrganization = organizationId;
    } else {
      actingAs.currentRole = Role.SuperAdmin;
    }
    setState({...state, user: actingAs, organizationOverride: organizationId})
  }

  const {invitation, setInvitation} = useInvitationProvider();

  useEffect(() => {

    const update = async () => {
      const role = state.user ? (
        // @ts-ignore
        state.user.role || state.user.currentRole || // legacy fallback
        (state.user.isSuperAdmin ? Role.SuperAdmin : undefined) ||
        (state.user.organizations && state.user.currentOrganization ? extractCurrentOrganization(state.user.organizations, state.user.currentOrganization)?.role : undefined)
      ) : undefined;

      globalState.update(s => {
        s.currentRole = role;
        s.currentUserId = state.user ? state.user.id : undefined;
      });

      if (role && role !== Role.SuperAdmin && state.user && state.user.currentOrganization) {
        if (state.user.currentOrganization) {
          const org = await getOrganization(state.user.currentOrganization);
          globalState.update(s => {
            s.currentOrganization = org;
          });
        }
      }
    };
    update();
  }, [state.user]);

  useEffect(() => {

    const loadUser = (userAuth: firebase.User) => {
      return firestore.collection('users').doc(userAuth.uid).onSnapshot(snapshot => {

        if (snapshot.exists && !invitation) {
          const user = {id: snapshot.id, ...snapshot.data()} as User;

          if (user.isSuperAdmin === true) {
            setState({user: user, loading: false, enterApplication: true});
          } else if (user?.currentOrganization === undefined){
            setState({user: user, loading: false, enterApplication: false});
          } else {
            getOrganization(user.currentOrganization).then((res) => {
              if (res?.free || user.isSuperAdmin === true) {
                setState({user: user, loading: false, enterApplication: true});
              } else {
                firestore.collection("billingDetails").where(firebase.firestore.FieldPath.documentId(), "==", user.currentOrganization).onSnapshot(snapshot => {
                    if (snapshot.empty) {
                      setState({user: user, loading: false, enterApplication: false});
                    } else {
                      const state = (snapshot.docs[0].data() as BillingDetails).state;
                      state === SUBSCRIPTION_ACTIVE || state === SUBSCRIPTION_PAYMENT_FAILED || state === SUBSCRIPTION_CREATED_NEW
                        ? setState({user: user, loading: false, enterApplication: true})
                        : setState({user: user, loading: false, enterApplication: false});
                    }
                  }
                );
              }
            });
          }
        } else if (snapshot.exists && invitation) {
          const existingUser = snapshot.data() as User;
          const roleExists = existingUser.organizations?.find((org) => org.id === invitation.organization);
          if (roleExists) {
            window.localStorage.setItem('accountExists', 'accountExists');
            window.localStorage.removeItem(INV_KEY);
            setState({user: null, loading: false, needsInvitation: false});
          } else {
            const data = {
              id: snapshot.id,
              ...snapshot.data(),
              myUrl: snapshot.id,
              email: userAuth.email || "",
              firstName: "",
              lastName: "",
              phoneNumber: userAuth.phoneNumber || "",
              avatarURL: userAuth.photoURL || null,
              createdAt: userAuth.metadata.creationTime ? new Date(Date.parse(userAuth.metadata.creationTime)) : new Date(),
              lastSignInTime: userAuth.metadata.lastSignInTime ? new Date(Date.parse(userAuth.metadata.lastSignInTime)) : new Date(),
            } as User;

            !acceptInvitation(data, invitation)
              ? setState({user: null, loading: true, needsInvitation: true})
              : setInvitation(null);
          }
        } else if (!snapshot.exists && invitation) {
          const data = {
            id: userAuth.uid,
            myUrl: userAuth.uid,
            email: userAuth.email || "",
            firstName: "",
            lastName: "",
            phoneNumber: userAuth.phoneNumber || "",
            avatarURL: userAuth.photoURL || null,
            createdAt: userAuth.metadata.creationTime ? new Date(Date.parse(userAuth.metadata.creationTime)) : new Date(),
            lastSignInTime: userAuth.metadata.lastSignInTime ? new Date(Date.parse(userAuth.metadata.lastSignInTime)) : new Date(),
          } as User;

          !acceptInvitation(data, invitation)
            ? setState({user: null, loading: true, needsInvitation: true})
            : setInvitation(null);
        } else if (!invitation) {

          setState({user: null, loading: false, needsInvitation: true});
        }
      }, err => {
        console.debug('error getting user', err);
      });
    }

    let firestoreUnsubscribe: () => void = () => {
    };
    const authUnsubscribe = auth.onAuthStateChanged(userAuth => {
      if (userAuth && get(userAuth, 'uid', '')) {
        firestoreUnsubscribe = loadUser(userAuth);
      } else {
        setState({user: null, loading: false});
      }
    });

    return () => {
      firestoreUnsubscribe();
      authUnsubscribe()
    }
  }, [invitation, setInvitation]);


  return (
    <UserContext.Provider value={{...state, setOrganizationOverride}}>
      {children}
    </UserContext.Provider>
  );
}

export const useUserProvider = () => {
  const context = useContext(UserContext);

  if (context === undefined) {
    throw new Error(
      'useUserProvider must be used within a UserProvider'
    );
  }
  return context;
};


export default UserProvider;
