import React from 'react';
import CircularProgress from '@material-ui/core/CircularProgress';
import {Box} from "@material-ui/core";

const LoadingSpinner = ({marginTop} : {
  marginTop?: number;
}) => {
  const mt = marginTop ?? 42;
  return (
    <Box style={{
      marginTop: mt,
      width: '100%',
      display: 'flex',
      justifyContent: 'center'
    }}>
      <CircularProgress/>
    </Box>
  );
};

export default LoadingSpinner;

