import React, {Fragment, useMemo, useState} from "react";
import {
  Button,
  FormControlLabel,
  Radio,
  RadioGroup,
  TextField,
  Typography,
  Box, makeStyles, Theme, createStyles, TableCell,
} from "@material-ui/core";
import {Equipment, Exercise, MultiRoundExercise, RoundDetails} from "myfitworld-model";
import applyDefaultValue from "../../../../utils/applyDefaultValue";
import {equipmentOptions, getEquipmentFields, getContentTitle} from "./util";
import {useLocalizationProvider} from "../../../../providers/LocalizationProvider";
import SimpleDialog from "../../../../components/SimpleDialog";
import thumbPlaceholder from '../../../../assets/images/thumb_placeholder.png';
import Image from "material-ui-image";
import getImageThumbnail from "../../../../utils/getImageThumbnail";
import {baseContentListStyles} from "../../BaseContentListItem";
import TextMaskExpiration from "../../../../components/TextMaskExpiration";

const withDefault = applyDefaultValue('');

const getSeconds = (text: any) : string=> {
  if (!text) return '0';
  const time = text.split(":");
  const seconds = parseInt(time[0], 10) * 60 + parseInt(time[1], 10);
  return `${seconds}`;
};

const getMinSecDurationText = (seconds: any) : string => {
  if (isNaN(seconds)) {
    return seconds;
  }

  const minNumber = Math.floor((seconds || 0 ) / 60);
  const secNumber = (seconds || 0 ) % 60;

  const minText = minNumber >= 10 ? `${minNumber}` : `0${minNumber}`;
  const secText = secNumber >= 10 ? `${secNumber}` : `0${secNumber}`;

  const text = `${minText}:${secText}`;
  return text;
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    radioGroup: {
      flexDirection: 'row',
    },
    tableCell: {
      paddingLeft: theme.spacing(1),
      paddingRight: theme.spacing(1),
    },
    nowrap: {
      whiteSpace: 'nowrap',
    }
  }),
);

const GroupItem = ({
                     exercise,
                     exercises,
                     exerciseIndex,
                     equipment,
                     numberOfRounds,
                     handleTypeChange,
                     handleRoundChange,
                     removeExercise,
                     disabled,
                     setExerciseForPreview,
                   }: {
  exercise: MultiRoundExercise,
  exercises: Exercise[],
  exerciseIndex: number,
  equipment: Equipment[],
  numberOfRounds: number,
  handleTypeChange: (type: 'Reps' | 'Time', index: number) => void,
  handleRoundChange: (field: keyof RoundDetails, value: string, exerciseIndex: number, roundIndex: number) => void,
  removeExercise: (exerciseIndex: number) => void,
  disabled?: boolean,
  setExerciseForPreview: (exercise: Exercise | undefined) => void,
}) => {
  const classes = useStyles();
  const baseContentListClasses = baseContentListStyles();
  const {translate} = useLocalizationProvider();
  const [isOpenDialog, setIsOpenDialog] = useState(false);

  const imageThumbnail = useMemo(() => {
    const foundItem = exercises.find(item => item.id === exercise.exerciseId);
    if (foundItem) {
      return foundItem.thumbnailURL ? getImageThumbnail((foundItem as any).thumbURLS || [])?.url || foundItem.thumbnailURL : thumbPlaceholder
    } else {
      return thumbPlaceholder
    }

  }, [exercise, exercises]);

  const selectedExercise = useMemo(() =>
    exercises.find(item => item.id === exercise.exerciseId),
    [exercise, exercises]
  );

  const exerciseTitle = useMemo(() =>
    getContentTitle(exercise.exerciseId, exercises), [exercise, exercises]);
  const equipmentFields = useMemo(() =>
      getEquipmentFields(exercise.exerciseId, exercises, equipment)
    , [exercise, exercises, equipment]);

  return (
    <Fragment>
      <TableCell>
        <Box display="flex">
          <Box mr={2}>
            <Image
              src={imageThumbnail}
              aspectRatio={(16 / 9)}
              color="transparent"
              className={baseContentListClasses.image}
              alt={exerciseTitle}
              style={{
                padding: 0,
                fontSize: 0,
                cursor: 'pointer'
              }}
              imageStyle={{ width: 120, height: 'auto', position:'static' }}
              disableSpinner
              onClick={e => {
                e.stopPropagation();
                setExerciseForPreview(selectedExercise);
              }}
            />
          </Box>
          <Box>
            <Typography variant='h6'>
              {exerciseTitle}
            </Typography>

            <RadioGroup
              aria-label='type'
              name='type'
              value={exercise.type}
              onChange={e => handleTypeChange(e.target.value as 'Reps' | 'Time', exerciseIndex)}
              className={classes.radioGroup}
            >
              <FormControlLabel
                value='Reps'
                control={<Radio size='small' disabled={disabled}/>} label='Reps'
              />
              <FormControlLabel
                value='Time'
                control={<Radio size='small' disabled={disabled}/>} label='Time'
              />
            </RadioGroup>
          </Box>
        </Box>
      </TableCell>

        {exercise.rounds.map((round, roundIndex) =>
          <TableCell className={classes.tableCell} key={roundIndex}>
            <Box display="flex" flexDirection="column">
              {exercise.type === 'Reps' &&
              <TextField
                name='reps' label='Reps' type='number'
                inputProps={{min: 0, step: 1}}
                value={withDefault(round.reps)}
                onChange={e => handleRoundChange('reps', e.target.value, exerciseIndex, roundIndex)}
                size="small"
                disabled={disabled}
                fullWidth
              />}
              {exercise.type === 'Time' &&
              <TextField
                name='time'
                label='Time'
                InputProps={{
                  inputComponent: TextMaskExpiration as any,
                }}
                value={getMinSecDurationText(withDefault(round.time))}
                onChange={e => handleRoundChange('time', getSeconds(e.target.value), exerciseIndex, roundIndex)}
                size="small"
                disabled={disabled}
                fullWidth
              />}
              {equipmentOptions
                .filter(option => equipmentFields[option.key])
                .map(option =>
                  <TextField
                    key={option.key}
                    name={option.key} label={option.label} type='number'
                    inputProps={{min: 0, step: 1}}
                    value={withDefault(round[option.key])}
                    onChange={e => handleRoundChange(option.key, e.target.value, exerciseIndex, roundIndex)}
                    size="small"
                    disabled={disabled}
                    fullWidth
                  />
                )}
            </Box>
          </TableCell>
        )}

      <TableCell align="right">
        <Button
          size='small'
          color='secondary'
          variant='text'
          onClick={() => setIsOpenDialog(true)}
          disabled={disabled}
          className={classes.nowrap}
        >
          {translate('workout.remove_exercise')}
        </Button>
        <SimpleDialog
          title={translate('workout.remove_exercise')}
          text={translate('workout.remove_exercise_question')}
          open={isOpenDialog}
          onClose={() => setIsOpenDialog(false)}
          onConfirm={() => {
            setIsOpenDialog(false);
            removeExercise(exerciseIndex);
          }}
          onConfirmLabel={translate('button.yes')}
          onCancel={() => setIsOpenDialog(false)}
          onCancelLabel={translate('button.no')}
        />
      </TableCell>
    </Fragment>
  );
};

export default GroupItem;
