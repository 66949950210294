import React from 'react';
import {useLocalizationProvider} from "../providers/LocalizationProvider";
import {Button, Container, Typography} from "@material-ui/core";
import AppStoreButtonBadge from '../assets/images/appstore_button_badge.svg';
import PlayStoreButtonBadge from '../assets/images/playstore_button_badge.png';
import {auth} from "../firebase";
import useNavigation from "../hooks/useNavigation";
import {RouteComponentProps} from "@reach/router";

export default function (_:RouteComponentProps) {
  const {translate} = useLocalizationProvider();

  const {navigation} = useNavigation();
  return (
    <Container maxWidth="sm">
      <Typography variant="h2">
        {translate('Open MyFitWorld mobile app and login start your program')}
      </Typography>
      <div style={{display:"flex", alignContent: 'center', justifyContent: 'center', marginTop: '20px', textAlign: 'center'}}>
        <div>
          <a
            href='https://play.google.com/store/apps/details?id=net.myfitworld.app&pcampaignid=pcampaignidMKT-Other-global-all-co-prtnr-py-PartBadge-Mar2515-1'>
            <img alt='Get it on Google Play' style={{width: 200}}
                 src={PlayStoreButtonBadge}/>
          </a>
        </div>
        <div>
          <a href='https://apps.apple.com/us/app/id1530209534'>
            <img alt='Download on App store' style={{width: 184, padding: 12}} src={AppStoreButtonBadge}/>
          </a>
        </div>
      </div>
      <div style={{textAlign: 'center'}}>
        <Button onClick={() =>
          auth
            .signOut()
            .then((_) => {
              navigation('/');
            })
            .catch((err) => console.log(err))
        }>
          {translate('button.logout')}
        </Button>

      </div>
    </Container>
  )
}
