import React from "react";
import { Box, Typography } from "@material-ui/core";
import { useLocalizationProvider } from "../../../providers/LocalizationProvider";

const ClientPersonalInformation = ({ label, value, translatableValue } : Props) => {
  const { translate } = useLocalizationProvider();

  return (
    <Box display="flex" flexDirection="column" justifyContent="flex-start">
      <Typography variant="body2" color="textSecondary">
        {label}
      </Typography>
      <Typography>{translatableValue ? missingTranslatableValue(translate(value)) : value}</Typography>
    </Box>
  );
};

export default ClientPersonalInformation;

interface Props {
  label: string;
  value: string;
  translatableValue?: boolean;
}

const missingTranslatableValue = (value: string) => (value.includes("[missing") ? "-" : value);
