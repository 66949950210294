import {storage} from '../firebase';

export type CustomFileMetadata = {
  type: 'exercise' | 'workout' | 'program'
  refId: string
}

const runUploadTask = ({
                         uploadPath,
                         file,
                         onProgressChange,
                         onError,
                         onDone
                       }: {
  uploadPath: string,
  file: File,
  onProgressChange: (progress: number) => void,
  onError: (error: Error) => void,
  onDone: (fileURL: string) => void,
}) => {
  const fileName = Math.floor(Math.random() * 100000) + '_' + file.name;
  const task = storage.ref().child(uploadPath + '/' + fileName).put(file);
  task.on(
    'state_changed',
    (snapshot) => {
      onProgressChange((snapshot.bytesTransferred / snapshot.totalBytes) * 100);
    },
    (error) => {
      onError(error)
    },
    () => {
      task.snapshot.ref.getDownloadURL().then((url) => {
        onDone(url)
      });
    }
  )
};

export default runUploadTask;
