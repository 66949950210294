import { makeStyles, Theme } from "@material-ui/core";

const wizardStyles = makeStyles((theme: Theme) => ({
  label: {
    marginBottom: theme.spacing(2),
  },
  calendarContainer: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  select: {
    width: '100%',
    marginBottom: theme.spacing(2),
    marginTop: theme.spacing(2),
  },
  ctaButton: {
    paddingTop: theme.spacing(4),
    paddingBottom: theme.spacing(4),
  }
}));

export default wizardStyles;
