import {firestore} from "../firebase";

export type ContentPath = 'workouts' | 'exercises' | 'programs';

export const deleteThumbnails = async (
  resourceId: string,
  resourcePath: ContentPath
) => {
  return firestore
    .collection(resourcePath)
    .doc(resourceId)
    .update({ thumbURLS: null })
};
