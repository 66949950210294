import {Invitation} from "myfitworld-model";
import {useLocalizationProvider} from "../../providers/LocalizationProvider";
import React, {Fragment, useEffect, useState} from "react";
import {firestore} from "../../firebase";
import {Button, Table, TableBody, TableCell, TableContainer, TableHead, TableRow} from "@material-ui/core";
import formatDistanceToNow from "date-fns/formatDistanceToNow";
import copy from "copy-to-clipboard";
import InviteUserForm from "./InviteUserForm";
import useTableStyles from "../../theme/useTableStyles";
import Role from "myfitworld-model/dist/enums/Role";
import safeInvoke from "../../utils/safeInvoke";
import {useOrganizationProvider} from "../../providers/OrganizationProvider";
import SideDrawer from "../SideDrawer";
import LoadingSpinner from "../LoadingSpinner";
import globalState from "../../globalState";
import {useStoreState} from "pullstate";
import {decrementSubscriptionQuantity} from "../../api/billingDetailsApi";

interface Props {
  roles: Role[];
  showRole: boolean;
  onRowClick?: (id: string) => void;
  createActionLabel: string;
  formHelperText?: string;
  invitationsBlocked?: boolean;
}

const InvitationsTable = ({roles, showRole, onRowClick, createActionLabel, formHelperText, invitationsBlocked}: Props) => {
  const tableClasses = useTableStyles();
  const {translate} = useLocalizationProvider();
  const [formOpen, setFormOpen] = useState(false);

  const [{selectedOrganization}] = useOrganizationProvider();
  const [invitations, setInvitations] = useState<Invitation[]>([]);
  const [loading, setLoading] = useState<boolean>(true);
  const role = useStoreState(globalState, s => s.currentRole);
  const userId = useStoreState(globalState, s => s.currentUserId);

  useEffect(() => {
    const collectionRef = firestore.collection('invitations')
    let ref = (selectedOrganization ?
        collectionRef.where('organization', '==', selectedOrganization.id) :
        collectionRef
    ).where('role', 'in', roles).where('acceptedOn', '==', null);

    if (role === Role.Trainer || role === undefined) {
      ref = ref.where('invitedBy', '==', userId || '');
    }

    return ref
      .orderBy('createdAt')
      .onSnapshot(snapshot => {
        setInvitations(snapshot.docs.map(doc => ({
          id: doc.id,
          ...doc.data(),
          createdAt: safeInvoke('toDate')(doc.data().createdAt)
        } as Invitation)));
        setLoading(false);
      }, error => {
        console.error('Error retrieving organizations', error)
      })

  }, [selectedOrganization, roles, userId, role])


  const resendInvitation = (invitationId: string) => {
    firestore.collection('invitations')
      .doc(invitationId)
      .update({
        isInSendingQueue: true
      }).catch(err => console.error('Error resending invitations', err))

  }

  const revokeInvitation = (invitationId: string) => {
    firestore.collection('invitations')
      .doc(invitationId)
      .delete()
      .then(async () => {
        if (roles.includes(Role.Client) && selectedOrganization?.id) {
          await decrementSubscriptionQuantity(selectedOrganization?.id);
        }
      })
      .catch(err => console.error('Error revoking invitations', err))
  }

  return (
    <Fragment>
      <div className={tableClasses.tableActionBar}>
        <Button
          color='primary'
          onClick={_ => setFormOpen(true)}
        >
          {translate(createActionLabel)}
        </Button>
      </div>

      {loading && <LoadingSpinner marginTop={50}/>}
      {!loading &&
      <TableContainer>
        <Table className={tableClasses.table} size="medium">
          <TableHead>
            <TableRow>
              <TableCell align="left">{translate('Name')}</TableCell>
              <TableCell align="left">{translate('Email')}</TableCell>
              <TableCell align="left">{translate('Phone')}</TableCell>
              {showRole && <TableCell align="left">{translate('Role')}</TableCell>}
              <TableCell align="left">{translate('Created At')}</TableCell>
              <TableCell align="left">{translate('Invitation Sent?')}</TableCell>
              <TableCell align="left">{translate('Link')}</TableCell>
              <TableCell align="right">{translate('Actions')}</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {invitations.map((invitation) => (
              <TableRow key={invitation.id} onClick={() => onRowClick && invitation.id && onRowClick(invitation.id)}
                        hover>
                <TableCell align="left">{
                  `${invitation.firstName ? invitation.firstName + ' ' : ''}${invitation.lastName ? invitation.lastName : ''}`
                }</TableCell>
                <TableCell align="left">{invitation.email || ''}</TableCell>
                <TableCell align="left">{invitation.phoneNumber || ''}</TableCell>

                {showRole && <TableCell align="left">{invitation.role}</TableCell>}

                <TableCell align="left">
                  {invitation.createdAt && formatDistanceToNow(new Date(invitation.createdAt), {addSuffix: true})}
                </TableCell>

                <TableCell align="left">
                  {invitation.isInSendingQueue ?
                    translate('Pending') :
                    invitation.invitationSent ?
                      translate('Yes') :
                      invitation.role === Role.Client ?
                        translate('No') :
                        translate('Pending')
                  }
                </TableCell>

                <TableCell align="left">
                  <Button onClick={(e) => {
                    e.stopPropagation();
                    copy(`${process.env.REACT_APP_BASE_URL}/i/${invitation.id}`);
                  }}>
                    {translate('Copy link')}
                  </Button>
                </TableCell>

                <TableCell align="right">
                  <Button
                    onClick={(e) => {
                      e.stopPropagation();
                      revokeInvitation(invitation.id!);
                    }}
                    disabled={invitationsBlocked}
                  >
                    {translate('Revoke')}
                  </Button>
                  <Button
                    onClick={(e) => {
                      e.stopPropagation();
                      resendInvitation(invitation.id!);
                    }}
                    disabled={invitation.isInSendingQueue || invitationsBlocked}
                  >
                    {invitation.invitationSent ?
                      translate('Resend') :
                      translate('Send')
                    }
                  </Button>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
      }

      <SideDrawer isOpen={formOpen} onClose={() => setFormOpen(false)}>
        <InviteUserForm
          onSubmit={() => setFormOpen(false)}
          roles={roles}
          createActionLabel={createActionLabel}
          helperText={formHelperText}
          invitationsBlocked={invitationsBlocked}
        />
      </SideDrawer>
    </Fragment>
  );
};

export default InvitationsTable;
