export interface ContentFilterOptions {
  sports: boolean;
  muscleGroups: boolean;
  secondaryMuscleGroups: boolean;
  fitnessActivity: boolean;
  motorSkills: boolean;
  healthIssues: boolean;
  equipment: boolean;
  difficultyLevel: boolean;
  goals: boolean;
  muscleRegime: boolean;
  archived: boolean;
  showMFWOriginals?: boolean;
}

export const exerciseContentFilterOptions = {
  sports: true,
  muscleGroups: true,
  secondaryMuscleGroups: true,
  fitnessActivity: true,
  motorSkills: true,
  healthIssues: true,
  equipment: true,
  difficultyLevel: true,
  goals: false,
  muscleRegime: true,
  archived: true,
};

export const workoutContentFilterOptions = {
  sports: true,
  muscleGroups: true,
  secondaryMuscleGroups: true,
  fitnessActivity: true,
  motorSkills: true,
  healthIssues: true,
  equipment: false,
  difficultyLevel: true,
  goals: true,
  muscleRegime: false,
  archived: true,
};

export const programContentFilterOptions = {
  sports: true,
  muscleGroups: true,
  secondaryMuscleGroups: true,
  fitnessActivity: true,
  motorSkills: true,
  healthIssues: true,
  equipment: false,
  difficultyLevel: true,
  goals: true,
  muscleRegime: false,
  archived: true,
};

export const emptyOptions = {
  sports: false,
  muscleGroups: false,
  secondaryMuscleGroups: false,
  fitnessActivity: false,
  motorSkills: false,
  healthIssues: false,
  equipment: false,
  difficultyLevel: false,
  goals: false,
  muscleRegime: false,
  archived: false,
};

export const getContentFilterOptions = (type: 'Exercise' | 'Workout' | 'Program'): ContentFilterOptions => {
  switch (type) {
    case 'Exercise':
      return exerciseContentFilterOptions;
    case 'Workout':
      return workoutContentFilterOptions;
    case 'Program':
      return programContentFilterOptions;
    default:
      return emptyOptions;
  }
}
