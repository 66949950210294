import React, {Fragment} from "react";
import {NoBillingDetailsNote} from "../../components/payment/utils/Notes";
import {Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle} from "@material-ui/core";
import {auth} from "../../firebase";
import useNavigation from "../../hooks/useNavigation";
import ExitToAppIcon from "@material-ui/icons/ExitToApp";

const BillingDetailsTrainerPage = () => {
  const { navigation } = useNavigation();

  const handleLogout = () => {
    auth
      .signOut()
      .then(async (_) => {
        await navigation('/');
      })
      .catch((err) => console.log(err));
  };

  return (
    <Fragment>
      <Dialog
        open={true}
        maxWidth='xs'
        fullWidth
      >
        <DialogTitle>{"Billing is not set up!"}</DialogTitle>
        <DialogContent>
          <DialogContentText>
            <NoBillingDetailsNote />
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={handleLogout} color="secondary"
            startIcon={<ExitToAppIcon />}
          >
            Log out
          </Button>
        </DialogActions>
      </Dialog>
    </Fragment>
  );
}

export default BillingDetailsTrainerPage;
