import {User} from "myfitworld-model";
import {firestore} from "../firebase";
import APIResponse from "../model/APIResponse";

export const updateUserProfile = (
  user: User
): Promise<APIResponse> =>
  new Promise((resolve, reject) => {
    return firestore.collection('/users').doc(user.id).set({...user}, {merge:true}).then(
      (doc) => resolve({ success: true })
    ).catch((err) => {
      console.log(err);
      reject({success: false});
    })
  });

interface DataAPIResponse<T> {
  success: boolean;
  data?: T;
}

export const getUserProfileByMyUrl = (
  value: string
): Promise<DataAPIResponse<User>> =>
  new Promise((resolve, reject) => {
    return firestore.collection('/users').where("myUrl", "==", value).get().then((snapshot) => {
        snapshot.empty ? resolve({success: true}) : resolve({
          success: true, data: {
            ...snapshot.docs[0].data(),
            id: snapshot.docs[0].id
          } as User
        });
      }
    ).catch((err) => {
      console.log(err);
      reject({success: false});
    })
  });

export const getData = <T>(
  collectionName: string,
  trainerId: string
): Promise<DataAPIResponse<Array<T>>> =>
  new Promise((resolve, reject) => {
    return firestore.collection(collectionName).where("authorId", "==", trainerId).get().then((snapshot) => {
        snapshot.empty ? resolve({success: true}) : resolve({
          success: true,
          data: snapshot.docs.map((doc) => ({...doc.data(), id: doc.id} as unknown as T)) as Array<T>
        });
      }
    ).catch(
      (err) => {
        console.log(err);
        reject({success: false});
      }
    )
  });

