import React, {useState} from 'react';
import {
  Dialog, DialogContent,
  Step, StepLabel, Stepper,
  Typography,
} from '@material-ui/core';
import ProgramTemplate from 'myfitworld-model/dist/firestoreCollectionTypes/ProgramTemplate';
import format from 'date-fns/fp/format';
import Step1 from './Step1';
import Step2 from './Step2';
import useStyles from './styles';
import getFirstMonday from '../../../../utils/getFirstMonday';

const ImportPersonalProgramSetupWizard = ({
  programTemplates,
  onDone,
  onCancel,
} : {
  programTemplates: ProgramTemplate[],
  onDone: (
    { startDate, programTemplate } :
    { startDate: string, programTemplate: string }
  ) => void,
  onCancel: () => void,
}) => {

  const [ startDate, setStartDate ] = useState<Date>(getFirstMonday());
  const [ activeStep, setActiveStep ] = useState(0);
  const [ programTemplate, setProgramTemplate ] = useState<string>('NONE');

  const classNames = useStyles();

  const onSetupWizardComplete = () => {
    onDone({
      startDate: format('yyyy/MM/dd', startDate),
      programTemplate: programTemplate,
    });
  };

  return (
    <div>
      <Dialog open={true} onClose={onCancel} maxWidth='sm' fullWidth>
        <DialogContent>
          <Typography variant='h5' component='p'>
            Import Workout Program
          </Typography>
          <Stepper activeStep={activeStep} orientation="vertical">
            <Step>
              <StepLabel>Select the start date of the program</StepLabel>
              <Step1
                classNames={classNames}
                startDate={startDate}
                setStartDate={setStartDate}
                nextStep={() => setActiveStep(activeStep + 1)}
              />
            </Step>

            <Step>
              <StepLabel>Select program template</StepLabel>
              <Step2
                programTemplate={programTemplate}
                setProgramTemplate={setProgramTemplate}
                programTemplates={programTemplates}
                classNames={classNames}
                nextStep={() => onSetupWizardComplete()}
                allowEmpty={false}
              />
            </Step>
          </Stepper>
        </DialogContent>
      </Dialog>
    </div>
  );
};

export default ImportPersonalProgramSetupWizard;
