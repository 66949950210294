import React, {useCallback, useEffect, useState} from 'react';
import {
  Box,
  Button,
  createStyles,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Theme,
  Typography
} from '@material-ui/core';
import BillingAddressDetails from '../forms/items/BillingAddressDetails';
import {
  BillingAddressAndContact, BillingDetailsErrors,
  BillingDetailsInterface,
  emptyBillingAddressAndContact,
  getInitialBillingAddressAndContact
} from '../utils/BillingDetailsInterface';
import {makeStyles} from "@material-ui/core/styles";
import dialogNames from "../utils/DialogNames";
import ContactInfo from "../forms/items/ContactInfo";
import {BillingDetails} from "myfitworld-model";
import {updateOrganizationBillingAddress} from "../../../api/billingDetailsApi";
import {useStoreState} from "pullstate";
import globalState from "../../../globalState";
import ExitToAppIcon from "@material-ui/icons/ExitToApp";
import {auth} from "../../../firebase";
import useNavigation from "../../../hooks/useNavigation";
import {BillingAddressHelperNote} from "../utils/Notes";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    actions: {
      '&> *': {
        marginLeft: theme.spacing(1),
      }
    },
  }),
);

const BillingAddressDialog = (props: Props) => {
  const classes = useStyles();
  const currentOrganization = useStoreState(globalState, s => s.currentOrganization);
  const {open, action, defaultState, billingDetails, onNext, onSave, onClose, errors, setErrors} = props;

  const [values, setValues] = useState<BillingAddressAndContact>(emptyBillingAddressAndContact);

  useEffect(() => {
    setValues(getInitialBillingAddressAndContact(defaultState));
  }, [defaultState]);

  const {navigation} = useNavigation();

  const handleLogout = () => {
    auth
      .signOut()
      .then(async (_) => {
        await navigation('/');
      })
      .catch((err) => console.log(err));
  };

  const validate = () => {
    setErrors && setErrors(Object.entries(values).reduce((errors, pair,) => {
      return {...errors, [pair[0]]: pair[1] === ""};
    }, {}) as BillingDetailsErrors);

    return Object.entries(values).reduce((acc, el) => acc || (el[1] === "" && el[0] !== "taxID" && el[0] !== "stateCode"), false);
  };

  const handleOnChange = useCallback((e: any, validate: boolean) => {
    const {name, value} = e.target;

    if (validate && setErrors && errors) {
      value === "" ?
        setErrors({...errors, [name]: true})
        : setErrors({...errors, [name]: false});
    }

    setValues(state => ({
      ...state,
      [name]: value
    }));
  }, [setValues, errors, setErrors]);

  const onEdit = async () => {
    if (!validate()) {
      onSave && defaultState && onSave({
        ...defaultState,
        ...values
      });
      currentOrganization && currentOrganization.id && await updateOrganizationBillingAddress(
        currentOrganization.id,
        {
          ...billingDetails,
          email: values.email,
          phone: values.phoneNumber,
          billing_address: {
            address: values.address,
            city: values.city,
            countryCode: values.countryCode,
            postalCode: values.postalCode,
            stateCode: values.stateCode,
            taxID: values.taxID
          }
        }
      );
      onClose && onClose();
    }
  };

  const onCreateAndNext = () => {
    if (!validate()) {
      onSave && defaultState && onSave({
        ...defaultState,
        ...values
      });
      onNext && onNext(dialogNames[2]);
    }
  };

  return (
    <Dialog open={open} maxWidth='sm' fullWidth>
      <DialogTitle>
        <Box pt={1}>
          <Typography variant='h4' component='p'>
            Add Your Billing Address
          </Typography>
        </Box>
      </DialogTitle>
      <DialogContent style={{paddingBottom: '3rem'}}>
        <ContactInfo
          email={values.email}
          phoneNumber={values.phoneNumber}
          onChange={handleOnChange}
          errors={errors}
        />
        <BillingAddressDetails
          address={values.address}
          city={values.city}
          countryCode={values.countryCode}
          postalCode={values.postalCode}
          stateCode={values.stateCode}
          taxID={values.taxID}
          onChange={handleOnChange}
          errors={errors}
        />
        <BillingAddressHelperNote />
      </DialogContent>
      <DialogActions>
        <Box px={2} pb={2} className={classes.actions}>
          {action === "UPDATE" && <Button color='primary' variant='text' onClick={() => onClose && onClose()}>
            Cancel
          </Button>}
          {action === "UPDATE" && <Button color='primary' variant='contained' onClick={() => onEdit()}>
            Save Billing Address
          </Button>}
          {action === "CREATE" && <Button color='primary' variant='text' onClick={() => onCreateAndNext()}>
            Save Billing Address and Next
          </Button>}
          {action === "CREATE" && <Button
            onClick={handleLogout} color="secondary"
            startIcon={<ExitToAppIcon/>}
          >
            Or Log out
          </Button>}
        </Box>
      </DialogActions>
    </Dialog>
  );
}

interface Props {
  open: boolean;
  action: "CREATE" | "UPDATE",
  defaultState?: BillingDetailsInterface,
  billingDetails?: BillingDetails,
  onSave?: (data: BillingDetailsInterface) => void,
  onNext?: (dialogName: string) => void,
  onClose?: () => void,
  errors?: BillingDetailsErrors,
  setErrors?: (errors: BillingDetailsErrors) => void
}

export default BillingAddressDialog;
