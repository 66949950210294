import firebase from "firebase/app";
import "firebase/auth";
import "firebase/firestore";
import "firebase/storage";

const firebaseConfig = {
  apiKey: "AIzaSyBLgzzK9yij7IPIwbSfgfY7kmM2frIVEgU",
  authDomain: "myfitworld-stage.firebaseapp.com",
  databaseURL: "https://myfitworld-stage.firebaseio.com",
  projectId: "myfitworld-stage",
  storageBucket: "myfitworld-stage.appspot.com",
  messagingSenderId: "507705216004",
  appId: "1:507705216004:web:619ea9d50c9729e8917951",
};

const prodFirebaseConfig = {
  apiKey: "AIzaSyC5daxgGnmvH18gPdMbVPiefbcAPqKOR-I",
  authDomain: "mfw-prod.firebaseapp.com",
  databaseURL: "https://mfw-prod.firebaseio.com",
  projectId: "mfw-prod",
  storageBucket: "mfw-prod.appspot.com",
  messagingSenderId: "1061239110485",
  appId: "1:1061239110485:web:daee362b79ceeb07f48ddc",
  measurementId: "G-GLCR5WP2BQ"
};

export const app = firebase.initializeApp(process.env.REACT_APP_ENV === 'production' ? prodFirebaseConfig : firebaseConfig);
export const auth = firebase.auth();
export const firestore = firebase.firestore();
export const storage = firebase.storage();
export const appleAuthProvider = new firebase.auth.OAuthProvider("apple.com");
export const facebookAuthProvider = new firebase.auth.FacebookAuthProvider();
