import React from "react";
import Box from "@material-ui/core/Box";
import Button from "@material-ui/core/Button/Button";
import ArrowBackIosIcon from "@material-ui/icons/ArrowBackIos";
import Visible from "../../components/Visibile";

const TopActionBar = ({
  archivedUser,
  handleClose,
  loading,
  variant,
  handleSave,
  showSave,
} : {
  archivedUser?: boolean;
  handleClose: () => void;
  loading: boolean;
  variant: 'outlined' | 'contained';
  handleSave: () => void;
  showSave: boolean;
}) => {
  return (
    <Box display="flex" alignItems="center" justifyContent="space-between" my={2}>
      <Button
        onClick={handleClose}
        aria-label="close"
        disabled={loading}
        startIcon={<ArrowBackIosIcon />}
      >
        Back
      </Button>
      {!archivedUser && <Visible if={showSave}>
        <Button
          autoFocus
          color="primary"
          variant={variant}
          onClick={handleSave}
          disabled={loading}
          style={{ width: 120 }}
        >
          Save
        </Button>
      </Visible>}
    </Box>
  );
}

export default TopActionBar;
