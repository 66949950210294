import React from "react";
import {createStyles, makeStyles, Theme} from "@material-ui/core";
import Divider from "@material-ui/core/Divider/Divider";
import List from "@material-ui/core/List/List";
import ListItem from "@material-ui/core/ListItem/ListItem";
import ListItemIcon from "@material-ui/core/ListItemIcon/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText/ListItemText";
import MenuItem from "../../model/MenuItem";
import logo from "../../assets/logo.png";
import ExitToAppIcon from "@material-ui/icons/ExitToApp";
import {auth} from "../../firebase";
import {useLocalizationProvider} from "../../providers/LocalizationProvider";
import useNavigation from "../../hooks/useNavigation";
import Box from "@material-ui/core/Box";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    logoLink: {
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
    },
    logo: {
      display: "flex",
      width: 64,
      padding: theme.spacing(1),
    },
    logoutBox: {
      bottom: "20px",
      display: "flex",
      position: "absolute",
      flexDirection: "column",
      alignItems: "center",
      width: "100%",
    },
    listItem: {
      flexDirection: "column",
      justifyContent: "center",
      padding: 0,
      height: 64,
    },
    listItemIcon: {
      justifyContent: "center",
    },
    listItemLast: {
      display: 'flex',
      flexGrow: 1,
      flexDirection: 'column',
      justifyContent: "flex-end",
    },
    listItemTextRoot: {
      maxHeight: "1em",
    },
    listItemText: {
      fontSize: "0.8em !important",
    },
  })
);

const DrawerContent = ({ menuItems } : { menuItems: MenuItem[] }) => {
  const classes = useStyles();
  const { navigation } = useNavigation();
  const { translate } = useLocalizationProvider();

  const handleLogout = () => {
    auth
      .signOut()
      .then((_) => {
        navigation('/');
      })
      .catch((err) => console.log(err));
  };
  return (
    <Box display='flex' flexDirection='column' flex='1'>
      <Box className={classes.logoLink} onClick={() => navigation('/')}>
        <img src={logo} className={classes.logo} alt="MyFitWorld Logo" />
      </Box>
      <Divider />
      <List>
        {menuItems.map((item) => (
          <Box
            key={item.label}
            style={{  marginBottom: "0em" }}
            onClick={() => navigation(item.route)}
          >
            <ListItem button selected={window.location.pathname.indexOf(item.route) >= 0} className={classes.listItem}>
              <ListItemIcon className={classes.listItemIcon}>{item.icon}</ListItemIcon>
              <ListItemText
                classes={{
                  root: classes.listItemTextRoot,
                  primary: classes.listItemText,
                }}
                primary={translate(item.label)}
              />
            </ListItem>
          </Box>
        ))}

      </List>
      <div className={classes.listItemLast}>
      <ListItem button className={classes.listItem} onClick={handleLogout}>
        <ListItemIcon className={classes.listItemIcon}>{<ExitToAppIcon />}</ListItemIcon>
        <ListItemText
          classes={{
            root: classes.listItemTextRoot,
            primary: classes.listItemText,
          }}
          primary="Log out"
        />
      </ListItem>
      </div>
    </Box>
  );
};

export default DrawerContent;
