import {
  Box,
  Button,
  Chip,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  Typography
} from "@material-ui/core";
import {Invoice} from "../../components/payment/utils";
import React, {useEffect, useState} from "react";
import useTableStyles from "../../theme/useTableStyles";
import {BillingDetails} from "myfitworld-model";
import {listInvoices} from "../../api/billingDetailsApi";
import LoadingSpinner from "../../components/LoadingSpinner";

const InvoicesTable = ({billingDetails, setOpenDialog}: Props) => {
  const tableClasses = useTableStyles();

  const [page, setPage] = useState<number>(0);
  const [rowsPerPage, setRowsPerPage] = useState<number>(10);

  const handleChangePage = (event: React.MouseEvent<HTMLButtonElement> | null, newPage: number) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
  ) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const [loading, setLoading] = useState<boolean>(true);
  const [invoices, setInvoices] = useState<Invoice[]>([]);

  useEffect(() => {
    if (billingDetails && billingDetails?.stripe?.customerId && billingDetails?.stripe?.subscriptionId) {
      listInvoices(billingDetails.stripe.customerId, billingDetails.stripe.subscriptionId).then((res) => {
        res.json().then((res) => {
          console.log("invoices", res);
          setInvoices(res);
        });
        setLoading(false);
      });
    }
  }, [billingDetails]);

  if (loading) {
    return <LoadingSpinner marginTop={16}/>;
  }

  return (
    <Box m={2} ml={4}>
      <Box
        display="flex"
        justifyContent="space-between"
        alignItems="baseline"
        mb={2}
      >
        <Typography
          variant='h4'
          component='h1'
          color='textSecondary'
        >
          {"Invoices"}
        </Typography>

        <Button
          color='primary'
          onClick={() => setOpenDialog(true)}
        >
          {"Edit billing details"}
        </Button>
      </Box>
      <TableContainer>
        <Table className={tableClasses.table} size="medium">
          <TableHead>
            <TableRow>
              <TableCell>{"Created"}</TableCell>
              <TableCell align="right">{"Amount"}</TableCell>
              <TableCell align="center">{"Status"}</TableCell>
              <TableCell align="right">{"Link"}</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {(invoices.slice(page*rowsPerPage, (page+1)*rowsPerPage) || []).map((invoice: Invoice, index) => (
              <TableRow key={index} hover>
                <TableCell>{(new Date(invoice.created * 1000)).toDateString()}</TableCell>
                <TableCell align="right">{`${invoice.total / 100} €`}</TableCell>
                <TableCell align="center">{
                  invoice.status === "paid" ? <Chip size="small" color="primary" label="Paid"/> :
                    <Chip size="small" label="Pending"/>
                }</TableCell>
                <TableCell align="right">
                  <Button target="_blank" href={invoice.hosted_invoice_url}>View Invoice</Button>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
        <TablePagination
          component="div"
          count={invoices.length}
          page={page}
          onChangePage={handleChangePage}
          rowsPerPage={rowsPerPage}
          onChangeRowsPerPage={handleChangeRowsPerPage}
        />
      </TableContainer>
    </Box>
  );
};

interface Props {
  billingDetails?: BillingDetails,
  setOpenDialog: (open: boolean) => void
}

export default InvoicesTable;
