import './styles.css';
import Fab from '@material-ui/core/Fab';
import AddIcon from '@material-ui/icons/Add';
import React from 'react';
import {Calendar as BigCalendar, dateFnsLocalizer} from 'react-big-calendar';
import 'react-big-calendar/lib/css/react-big-calendar.css';
import format from 'date-fns/format';
import parse from 'date-fns/parse';
import startOfWeek from 'date-fns/startOfWeek';
import getDay from 'date-fns/getDay';
import Popover from "@material-ui/core/Popover";
import Button from "@material-ui/core/Button";
import {makeStyles} from "@material-ui/core/styles"
const locales = {
  'en-US': require('date-fns/locale/en-US'),
  'sr': require('date-fns/locale/sr'),
  'sr-Latn': require('date-fns/locale/sr-Latn'),
};

const localizer = dateFnsLocalizer({
  format,
  parse,
  startOfWeek,
  getDay,
  locales,
});

const useStyles = makeStyles((theme) => ({
  fab: {
    position: 'fixed',
    bottom: theme.spacing(2),
    right: theme.spacing(2),
    zIndex: 4,
  },
}))

const Calendar = ({events, resourceMap, onSelectEvent, options, EventComponent, AgendaEventComponent, archivedUser}) => {
  const [anchorEl, setAnchorEl] = React.useState(null);
  const classes = useStyles();

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);

  return (
    <div>
      <BigCalendar
        popup
        localizer={localizer}
        startAccessor="start"
        endAccessor="end"
        style={{height: 500}}
        events={events}
        resourceMap={resourceMap}
        views={['month', 'agenda']}
        onSelectEvent={resource => onSelectEvent(resource.index)}
        components={{
          event: EventComponent,
          agenda: {
            event: AgendaEventComponent
          }
        }}
      />
      { !archivedUser &&
      <div style={{
        display: 'flex', alignContent: 'flex-end',
        justifyContent: 'flex-end', marginTop: '24px',
      }}>
        <Fab color="primary" aria-label="add" onClick={handleClick} className={classes.fab}>
          <AddIcon/>
        </Fab>
        <Popover
          open={open}
          anchorEl={anchorEl}
          onClose={handleClose}
          anchorOrigin={{
            vertical: 'center',
            horizontal: 'center',
          }}
          transformOrigin={{
            vertical: 'bottom',
            horizontal: 'right',
          }}
        >
          <div style={{padding: '1em 2em'}}>
            {options.map((option, index) =>
              <Button
                key={index}
                onClick={() => {
                  option.action();
                  handleClose();
                }}
                style={{display: 'block'}}
              >
                {option.label}
              </Button>
            )}
          </div>
        </Popover>
      </div>
      }
    </div>
  );
}

export default Calendar;
