import React, {useMemo, Fragment} from 'react';
import {Exercise, GeneralEntity} from 'myfitworld-model';
import Grid from '@material-ui/core/Grid/Grid';
import {Chip, Typography, Box} from '@material-ui/core';
import RichTextEditor from '../../../components/RichTextEditor';
import {useLocalizationProvider} from '../../../providers/LocalizationProvider';
import FilePreview from '../../../components/fileUpload/FilePreview';
import buildKeyToNameMap from '../../../utils/buildKeyToNameMap';

const ExercisePreview = ({
  exercise,
  sportOptions,
  equipmentOptions,
  motorSkillsOptions,
  healthIssueOptions,
  muscleGroupsOptions,
} : {
  exercise: Exercise,
  sportOptions: GeneralEntity[],
  equipmentOptions: GeneralEntity[],
  motorSkillsOptions: GeneralEntity[],
  healthIssueOptions: GeneralEntity[],
  muscleGroupsOptions: GeneralEntity[],
}) => {
  const { translate } = useLocalizationProvider();

  const sections = useMemo(() => {
    const sportMap = buildKeyToNameMap(sportOptions);
    const equipmentMap = buildKeyToNameMap(equipmentOptions);
    const motorSkillsMap = buildKeyToNameMap(motorSkillsOptions);
    const healthIssueMap = buildKeyToNameMap(healthIssueOptions);
    const muscleGroupsMap = buildKeyToNameMap(muscleGroupsOptions);
    return [
      { label: 'Sports', tags: (exercise.sports || []).map(item => sportMap[item]) },
      { label: 'Muscle Groups', tags: (exercise.muscleGroups || []).map(item => muscleGroupsMap[item]) },
      { label: 'Motor Skills', tags: (exercise.motorSkills || []).map(item => motorSkillsMap[item]) },
      { label: 'Suitable for health issues', tags: (exercise.healthIssues || []).map(item => healthIssueMap[item]) },
      { label: 'Equipment', tags: (exercise.equipment || []).map(item => equipmentMap[item]) }
    ];
  }, [
    exercise.sports, exercise.equipment, exercise.motorSkills, exercise.healthIssues, exercise.muscleGroups,
    sportOptions, equipmentOptions, motorSkillsOptions, healthIssueOptions, muscleGroupsOptions
  ]);

  return (
    <Box pb={4}>
      <Grid container direction='column' spacing={2}>
        <Grid item>
          <Typography variant='h3' component='span'>
            {exercise.title.en}
          </Typography>
        </Grid>
        <Grid item>
          <FilePreview
            value={exercise.videoURL}
            videoPoster={exercise.thumbnailURL}
            type='video'
          />
        </Grid>
        <Grid item>
          <RichTextEditor
            initialValue={exercise.description?.en || exercise.description?.sr}
            label={translate('Description')}
            disabled={true}
            onChange={() => {}}
          />
        </Grid>
        <Grid item>
          <Typography component="legend" color="textSecondary" variant="caption" gutterBottom>
            {translate('Training Level')}
          </Typography>
          <Typography variant='h4' component='span'>
            <span style={{ fontWeight: 700 }}>{exercise.difficultyLevel || '?'}</span>
            <span style={{ opacity: 0.75 }}>{' / 5'}</span>
          </Typography>
        </Grid>
        {sections.map(section =>
          <Fragment key={section.label}>
            <Grid item>
              <Typography variant='body1' component='span'>
                {translate(section.label)}
              </Typography>
            </Grid>
            <Grid item key={section.label}>
              {section.tags.map((tag, index) =>
                <Chip
                  key={index}
                  size='small'
                  label={tag}
                  variant='outlined'
                  style={{ margin: '0 2px'}}
                />
              )}
            </Grid>
          </Fragment>
        )}
      </Grid>
    </Box>
  );
}

export default ExercisePreview;
