import {createContext} from 'react';
import {User} from "myfitworld-model";


const UserContext = createContext<{
  user: User | null, loading: boolean, needsInvitation?: boolean, organizationOverride?: string,
  setOrganizationOverride: (organizationId: string | undefined) => void, enterApplication?: boolean
}>({
  user: null, loading: false, needsInvitation: false, enterApplication: false, setOrganizationOverride: () => {
  }
});

export default UserContext;
