import React, {Fragment, useCallback, useEffect, useState} from "react";
import SideDrawer, {SideDrawerProperties} from "../SideDrawer";
import useActivities from "../../hooks/useActivities";
import MessageView from "../chat/MessageView";
import ActivityView from "../chat/ActivityView";
import ActivityType from "myfitworld-model/dist/enums/ActivityType";
import {useUserProvider} from "../../providers/UserProvider";
import {firestore} from "../../firebase";
import {Activity} from "myfitworld-model";
import ChatDialog from "../chat/ChatDialog";
import {useNavigate} from "@reach/router";
import {Box} from "@material-ui/core";
import Button from "@material-ui/core/Button";
import {useLocalizationProvider} from "../../providers/LocalizationProvider";

const LIMIT_MESSAGES = 5;

const NotificationsDrawer = ({ isOpen, onClose }: Props) => {
  const { user } = useUserProvider();
  const navigate = useNavigate();
  const {translate} = useLocalizationProvider();
  const [isOpenDialog, setOpenDialog] = useState(false);
  const [dialogThreadId, setDialogThreadId] = useState<string>();

  const [limit, setLimit] = useState(LIMIT_MESSAGES);
  const activities = useActivities(limit);

  const handleDialogOpen = useCallback((event: any) => {
    event.stopPropagation();
    setOpenDialog(true);
  }, []);

  const handleDialogClose = useCallback((event: any) => {
    event.stopPropagation();
    setOpenDialog(false);
  }, []);

  useEffect(() => {
    if (!user) return;
    console.log("notifications", activities?.length, activities);
    console.log("clean notifications", activities?.filter((act) => !(act.seenBy || []).includes(user.id || "")));

    user.id && firestore.collection("users").doc(user.id).update({
      notificationCount: 0
    });

    activities?.filter((activity) => !(activity.seenBy || []).includes(user.id || "")).map((activity) =>
        firestore.runTransaction(async (transaction) => {
          const activityRef = getActivityRef(activity.id!);
          const data = (await transaction.get(activityRef)).data() as Activity;
          if (!(data.seenBy || []).includes(user.id || "")) {
            transaction.update(activityRef, { seenBy: [...(data.seenBy || []), user.id] });
          }
        })
      );
  }, [user, activities]);

  const onViewDetails = useCallback(
    (activity: Activity) => {
      navigate(`/clients/${activity.threadId}/statistics/${activity.workoutId}`);
      onClose();
    },
    [onClose, navigate]
  );
  return (
    <Fragment>
      <SideDrawer isOpen={isOpen} onClose={onClose}>
        <div>
          {activities &&
          activities.map((activity) =>
            activity.type === ActivityType.MESSAGE ? (
              <MessageView
                activity={activity}
                key={activity.id}
                onReply={(event: any, threadId: string) => {
                  setDialogThreadId(threadId);
                  handleDialogOpen(event);
                }}
                shouldShowReplyButton={true}
              />
            ) : (
              <ActivityView activity={activity} key={activity.id} onViewDetails={() => onViewDetails(activity)} />
            )
          )}

          {activities && activities.length >= limit &&
          <Box display="flex" justifyContent="center" style={{margin: 16}}>
            <Button
              variant="contained"
              onClick={() => setLimit((prevState) => prevState + LIMIT_MESSAGES)}
              style={{textTransform: "none", borderRadius: 16}}
            >
              {translate('chat.load_notifications')}
            </Button>
          </Box>
          }
        </div>
      </SideDrawer>
      {isOpenDialog && dialogThreadId && (
        <ChatDialog isOpen={isOpenDialog} handleClose={handleDialogClose} userId={dialogThreadId} />
      )}
    </Fragment>
  );
};

export default NotificationsDrawer;

interface Props extends SideDrawerProperties {}

const getActivityRef = (id: string) => firestore.collection("activity").doc(id);
