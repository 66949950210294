import React from 'react';
import {RouteComponentProps} from "@reach/router";
import PageContainer from "../PageContainer";
import {Box, Typography} from "@material-ui/core";
import Grid from "@material-ui/core/Grid/Grid";
import {useLocalizationProvider} from "../../providers/LocalizationProvider";
import OrganizationsTable from "../../components/organizations/OrganizationsTable";

const OrganizationsManagementPage = (_: RouteComponentProps) => {
  const {translate} = useLocalizationProvider();
  return (
    <PageContainer>
      <Box mt={8} mb={8}>
        <Grid container justify='space-between'>
          <Typography variant='h4' component='h1'>
            {translate('Organizations')}
          </Typography>
          <OrganizationsTable />
        </Grid>
      </Box>
    </PageContainer>
  )
}


export default OrganizationsManagementPage
