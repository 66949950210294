import React from "react";
import { Box, Typography } from "@material-ui/core";
import GeneralStatisticInformationField from "./GeneralStatisticInformationField";
import { StatsWorkout } from "myfitworld-model";
import Grid from "@material-ui/core/Grid";
import {displayDuration} from "../../utils/displayDuration";

const GeneralStatisticInformation = ({ statistics } : Props) => {
  const SECONDS = 60;
  return (
    <Box>
      <Typography variant="h6" color="primary" gutterBottom>
        Statistics
      </Typography>
      <Grid container spacing={4}>
        <Grid item>
          <GeneralStatisticInformationField value={statistics.duration? displayDuration(Math.round(statistics.duration / SECONDS)) : "-"} label="Total Time" />
        </Grid>
        <Grid item>
          <GeneralStatisticInformationField value={statistics.heartRate? `${statistics.heartRate} bpm` : "-"} label="Average Heart Rate" />
        </Grid>
        <Grid item>
          <GeneralStatisticInformationField value={statistics.calories? `${statistics.calories} kCal` : "-"} label="Total Calories" />
        </Grid>
      </Grid>
    </Box>
  );
};

export default GeneralStatisticInformation;

interface Props {
  statistics: StatsWorkout;
}
