import firebase from "firebase";
import APIResponse from "../model/APIResponse";
import {ApiInterface} from "./dataInterfaceFactory";
import {Invitation, OrganizationUser, Role, User} from "myfitworld-model";
import {firestore} from "../firebase";
import {sanitizeInput} from "./sanitizeInput";
import {UserProgram} from "myfitworld-model/dist/firestoreCollectionTypes/User";
import {WorkoutDay} from "myfitworld-model/src/firestoreCollectionTypes/User";
import UserWithCRUDInstructions, {
  UserInvitationWithCrudInstructions,
  WorkoutDeletion
} from "./UserWithCRUDInstructions";
import invitationsApi from "./invitationsApi";
import makeArrayContainsAnyArgumentForUserList from "../utils/makeArrayContainsAnyArgumentForUserList";

export const listUsers = (roles: Role[], orgId?: string) => (): Promise<User[]> => {
  return new Promise((resolve) => {
    let ref = firestore.collection("users");

    return (orgId ? ref.where(
      "organizations",
      "array-contains-any",
      makeArrayContainsAnyArgumentForUserList(roles, orgId)
    ) : ref)
      .get()
      .then((querySnapshot) => {
        const data: Array<User> = [];
        querySnapshot.forEach((snapshot) => {
          data.push({
            id: snapshot.id,
            ...snapshot.data(),
          } as User);
        });
        resolve(data);
      })
      .catch((error) => {
        console.error('Error retrieving users for roles, org id', roles, orgId, error);
        resolve([]);
      });
  });
};

export const getUser = async (id: string) =>
  new Promise((resolve, reject) => {
    firestore
      .collection("users")
      .doc(id)
      .get()
      .then((doc) => {
        if (doc.exists) {
          const user = {id: doc.id, ...doc.data()} as User;
          firestore
            .collection(`users/${doc.id}/assignedPrograms`)
            .get()
            .then((programs) => {
              user.programs = [];
              if (!programs.empty) {
                programs.forEach((p) => {
                  //@ts-ignore
                  user.programs.push({id: p.id, ...p.data()} as UserProgram);
                });
                const promises: Promise<void>[] = [];
                user.programs.forEach((program) => {
                  promises.push(
                    firestore
                      .collection(`users/${doc.id}/assignedPrograms/${program.id}/assignedWorkouts`)
                      .get()
                      .then((workout) => {
                        program.workouts = workout.docs.map((doc) => {
                          const data = doc.data();
                          return {
                            ...data,
                            id: doc.id,
                            dateTime:
                              data.dateTime && data.dateTime.toDate
                                ? data.dateTime.toDate().toJSON() // Timestamp object
                                : new Date(data.dateTime).toJSON(),
                          } as WorkoutDay;
                        });
                      })
                  );
                });
                Promise.all(promises).finally(() => resolve(user));
              } else {
                resolve(user);
              }
            });
        } else {
          reject(`users/${id} not found!`);
        }
      })
      .catch((error) => {
        console.error(error);
        resolve(undefined);
      });
  });

export const getOrganizationUser = async (userId: string, orgId: string, role: Role) =>
  new Promise((resolve, reject) => {
    firestore
      .collection("organizationUser")
      .doc(`${userId}${orgId}${role}`)
      .get()
      .then((doc) => {
        if (doc.exists) {
          const user = {id: doc.id, ...doc.data()} as OrganizationUser;
          resolve(user);
        } else {
          reject(`User ${userId}${orgId}${role} not found!`);
        }
      })
      .catch((error) => {
        console.error(error);
        resolve(undefined);
      });
  });

export const updateUserOrganizations = async (data: any) =>
  new Promise((resolve, reject) => {
    firestore
      .collection("users")
      .doc(data.id)
      .update(data)
      .then((doc) => {
        resolve("success");
      })
      .catch((error) => {
        console.error(error);
        resolve(undefined);
      });
  });

export const createUser = (raw: User): Promise<APIResponse> => {
  const {id, ...data} = raw;

  return new Promise((resolve, reject) => {
    try {
      firestore
        .collection("users")
        .add({
          ...sanitizeInput(data),
        })
        .then((_) => {
          resolve({success: true});
        });
    } catch (e) {
      console.error(e);
      reject({
        success: false,
        errorMessage: "There was an error saving your data. Check if you are connected to the Internet and try again…",
      });
    }
  });
};

const persistUserProgramWorkouts = (
  workoutRaw: WorkoutDay,
  userId: string,
  programId: string,
  operation: "UPDATE" | "INSERT",
  path: 'users' | 'invitations'
) => {
  const dateTime = workoutRaw.dateTime;
  const workout = sanitizeInput({...workoutRaw});
  // @ts-ignore
  workout.dateTime = firebase.firestore.Timestamp.fromDate(new Date(dateTime));

  return new Promise((resolve, reject) => {
    try {
      const ref = firestore.collection(`/${path}/${userId}/assignedPrograms/${programId}/assignedWorkouts`);
      if (operation === "UPDATE") {
        ref
          .doc(workout.id)
          .set({...workout, userId, finished: !!workout.finished})
          .then(() => {
            resolve(true);
          });
      } else {
        ref.add({...workout, userId, finished: !!workout.finished}).then(() => {
          resolve(true);
        });
      }
    } catch (e) {
      console.error(e);
      reject({
        success: false,
        errorMessage: "There was an error saving your data. Check if you are connected to the Internet and try again…",
      });
    }
  });
};

const processUserProgramWorkouts = (workouts: WorkoutDay[], userId: string, programId: string, path: 'users' | 'invitations') => {
  const updates = workouts.filter((w) => w.id !== undefined);
  const inserts = workouts.filter((w) => w.id === undefined);
  return [
    ...updates.map((w) => persistUserProgramWorkouts(w, userId, programId, "UPDATE", path)),
    ...inserts.map((w) => persistUserProgramWorkouts(w, userId, programId, "INSERT", path)),
  ];
};

const persistUserProgram = (program: UserProgram, userId: string, operation: "UPDATE" | "INSERT", path: 'users' | 'invitations') => {
  const workouts = program.workouts || [];
  delete program.workouts;

  return new Promise((resolve, reject) => {
    try {
      const ref = firestore.collection(`/${path}/${userId}/assignedPrograms`);
      if (operation === "UPDATE") {
        ref
          .doc(program.id)
          .set({...sanitizeInput(program)})
          .then(() => {
            if (workouts.length > 0) {
              Promise.all(processUserProgramWorkouts(workouts, userId, program.id as string, path)).then(() => {
                resolve({success: true});
              });
            } else {
              resolve(true);
            }
          });
      } else {
        ref.add({...sanitizeInput(program)}).then((newProgramDocument) => {
          if (workouts.length > 0) {
            Promise.all(processUserProgramWorkouts(workouts, userId, newProgramDocument.id as string, path)).then(() => {
              resolve({success: true});
            });
          } else {
            resolve(true);
          }
        });
      }
    } catch (e) {
      console.error(e);
      reject({
        success: false,
        errorMessage: "There was an error saving your data. Check if you are connected to the Internet and try again…",
      });
    }
  });
};

const deleteWorkoutDocuments = (userId: string, workoutDeletions: WorkoutDeletion[], path: 'users' | 'invitations') => {
  const promises: Promise<void>[] = [];

  workoutDeletions.forEach((op) => {
    const ref = firestore.collection(`${path}/${userId}/assignedPrograms/${op.programId}/assignedWorkouts`);
    op.workoutIds.forEach((id) => {
      promises.push(ref.doc(id).delete());
    });
  });

  return promises;
};

export const processUserPrograms = (programs: UserProgram[], userId: string, workoutDeletions: WorkoutDeletion[], path: 'users' | 'invitations') => {
  const updates = programs.filter((p) => p.id !== undefined);
  const inserts = programs.filter((p) => p.id === undefined);
  return [
    ...updates.map((u) => persistUserProgram(u, userId, "UPDATE", path)),
    ...inserts.map((u) => persistUserProgram(u, userId, "INSERT", path)),
    ...deleteWorkoutDocuments(userId, workoutDeletions, path),
  ];
};

export const updateUser = (data: UserWithCRUDInstructions): Promise<APIResponse> => {
  const {programs, workoutDeletions} = data;
  delete data.programs;
  delete data.workoutDeletions;

  return new Promise((resolve, reject) => {
    try {
      firestore
        .collection("/users")
        .doc(data.id)
        .set({...sanitizeInput(data)}, {merge: true})
        .then(() => {
          if (programs && programs.length > 0) {
            Promise.all(
              processUserPrograms(
                programs,
                data.id as string,
                workoutDeletions || [],
                'users'
              )
            ).then(() => {
              resolve({success: true});
            });
          } else {
            resolve({success: true});
          }
        });
    } catch (e) {
      console.error(e);
      reject({
        success: false,
        errorMessage: "There was an error saving your data. Check if you are connected to the Internet and try again…",
      });
    }
  });
};

type UpdateUserProfile = {
  userId: string;
  userData: User;
  updatePath: 'users';
}

type UpdateInvitationProfile = {
  userId: string;
  userData: UserInvitationWithCrudInstructions;
  updatePath: 'invitations';
}

export const updateUserOrInvitationProfileData = (data: UpdateUserProfile | UpdateInvitationProfile): Promise<void | any> => {
  return firestore.collection(data.updatePath).doc(data.userId).set(data.userData, {merge: true});
};

export async function assignOrganization(userId: string, organizationId: string, role: Role) {
  try {
    const newOrganization = [{id: organizationId, role: role, archived: false}];
    await firestore.collection("users").doc(userId).update({
      organizations: firebase.firestore.FieldValue.arrayUnion(...newOrganization),
      currentOrganization: organizationId,
    });

    const newMember = role === Role.Admin || role === Role.SuperAdmin
      ? {admins: firebase.firestore.FieldValue.arrayUnion(userId)}
      : {trainers: firebase.firestore.FieldValue.arrayUnion(userId)};
    await firestore.collection("organizations").doc(organizationId).update(newMember);

  } catch (err) {
    console.error("Could not update organization admin", err);
    throw err;
  }
}

const usersApi = (roles: Role[]) =>
  ({
    create: createUser,
    update: updateUser,
    list: listUsers(roles),
    get: getUser,
  } as ApiInterface<UserWithCRUDInstructions>);

export default usersApi;

/**
 *
 * @param user - user object, it should have id set (in case of new users pull it from auth metadata)
 * @param invitation
 */
export const acceptInvitation = async (user: User, invitation: Invitation) => {
  const ref = firestore.collection("users").doc(user.id);
  try {
    console.log("firebase auth", user);
    await ref.set(invitation.role === Role.Client ? {
      ...user,
      email: user.email || invitation.email,
      firstName: invitation.firstName,
      lastName: invitation.lastName,
      phoneNumber: user.phoneNumber || invitation.phoneNumber,
      fitnessProfile: invitation.fitnessProfile || null,
      trainers: firebase.firestore.FieldValue.arrayUnion(invitation.invitedBy)
    } : {
      ...user,
      email: user.email || invitation.email,
      firstName: invitation.firstName,
      lastName: invitation.lastName,
      phoneNumber: user.phoneNumber || invitation.phoneNumber
    }, {merge: true});

    invitation.role !== Role.SuperAdmin
      ? await assignOrganization(user.id!, invitation.organization, invitation.role)
      : await ref.update({isSuperAdmin: true});


    if (invitation.userProgram) {
      delete invitation.userProgram.id;
      await processUserPrograms([invitation.userProgram], user.id!, [], 'users');
    }
    await invitationsApi.acceptInvitation(invitation.id!);

    return true;
  } catch (err) {
    console.error("Error assigning invitation", err);
    return false;
  }
};

export const getCurrentUserRole = (user: User, organizationOverrideId?: string): Role | undefined => {
  if (organizationOverrideId) {
    return Role.Admin;
  }

  //@ts-ignore
  if (user.role === Role.SuperAdmin || user.isSuperAdmin) {
    return Role.SuperAdmin;
  }
  // this code here is a migration code and will be removed when users and org associations are migrated to new structure
  // @ts-ignore
  const associatedOrg = user.organizations
    ? user.organizations
      .map((item) => {
        // this is temporal until we migrate to new structure
        //@ts-ignore
        if (typeof item === "string") {
          return {id: item, role: Role.Admin};
        } else {
          return item;
        }
      })
      .find((item) => {
        return item.id === user.currentOrganization;
      })
    : undefined;
  if (associatedOrg) {
    return associatedOrg.role;
  } else {
    return undefined;
  }
};
