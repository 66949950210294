import useFirestoreCollection, { QueryFunction } from "./useFirestoreCollection";
import { Activity } from "myfitworld-model";
import { useCallback } from "react";
import { useUserProvider } from "../providers/UserProvider";
import safeInvoke from "../utils/safeInvoke";

export default function useActivities(limit: number) {
  const { user } = useUserProvider();

  const query: QueryFunction = useCallback(
    (q) => (user ? q.where("watchers", "array-contains", user.id).orderBy("createdAt", "desc").limit(limit) : q),
    [user, limit]
  );
  const activitiesCollection = useFirestoreCollection("activity", query);

  return activitiesCollection?.docs.map(
    (doc) =>
      ({
        ...doc.data(),
        createdAt: safeInvoke("toDate")(doc.data().createdAt),
        id: doc.id,
      } as Activity)
  );
}
