import React, {FormEvent} from 'react';
import { createStyles, makeStyles, Theme } from '@material-ui/core';
import Typography from '@material-ui/core/Typography';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    page: {
      display: 'flex',
      justifyContent: 'center',
      alignContent: 'center',
      alignItems: 'center',
      width: '100%',
      height: '100%',
    },
    heading: {
      marginBottom: '1em',
    },
    form: {
      width: '100%',
    },
  }),
);

export const Form = (
  { heading, children, onSubmit } :
  { heading?: string, children: React.ReactChild, onSubmit: (event: FormEvent<HTMLFormElement>) => void }
) => {
  const classes = useStyles();

  return (
    <form className={classes.form} onSubmit={onSubmit}>
      {heading ?
        <Typography variant='h4' component='h1' className={classes.heading}>
          {heading}
        </Typography> : null
      }
      {children}
    </form>
  );
};
