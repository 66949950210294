import {useMemo, useState} from "react";

const useStateWithDirtyFlag = <T>(defaultState: T) => {
  const [ value, _setter ] = useState<T>(defaultState);
  const [ isDirty, toggleDirty ] = useState(false);
  const setter = useMemo(() => (val: T) => {
    _setter(val);
    toggleDirty(true);
  }, [_setter, toggleDirty]);

  return {
    value,
    setter,
    isDirty
  };
}

export default useStateWithDirtyFlag;
