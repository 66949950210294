import React, {useCallback, useState} from "react";
import PageContainer from "../PageContainer";
import {useLocalizationProvider} from "../../providers/LocalizationProvider";
import Role from "myfitworld-model/dist/enums/Role";
import Tab from "@material-ui/core/Tab";
import Tabs from "@material-ui/core/Tabs";
import TabPanel from "../../components/TabPanel";
import InvitationsTable from "../../components/invitations/InvitationsTable";
import UsersTable from "../../components/users/UsersTable";
import {OrganizationUser} from "myfitworld-model";
import {Box} from "@material-ui/core";

export default function () {
  const { translate } = useLocalizationProvider();
  const [selectedTab, setSelectedTab] = useState<number>(0);

  const admins = [Role.Admin]
  const trainers = [Role.Trainer, Role.AssistantTrainer];
  const adminsAndTrainers = [...admins, ...trainers];

  const filterActiveClientsForAdmin = useCallback((data: OrganizationUser[]) => {
    return data.filter((user) => user.archived !== true);
  }, []);

  const filterArchivedClientsForAdmin = useCallback((data: OrganizationUser[]) => {
    return data.filter((user) => user.archived === true);
  }, []);

  return (
    <PageContainer>
      <Box>
        <Tabs
          centered
          value={selectedTab}
          textColor="primary"
          onChange={(_, value: number) => {
            setSelectedTab(value);
          }}
        >
          <Tab label={translate("Admins")} />
          <Tab label={translate("Trainers")} />
          <Tab label={translate("Archived Admins")} />
          <Tab label={translate("Archived Trainers")} />
          <Tab label={translate("Invitations")} />
        </Tabs>
      </Box>
      <TabPanel value={selectedTab} index={0}>
        <UsersTable
          roles={admins}
          showRole={false}
          filterFn={filterActiveClientsForAdmin}
        />
      </TabPanel>
      <TabPanel value={selectedTab} index={1}>
        <UsersTable
          roles={trainers}
          showRole={false}
          filterFn={filterActiveClientsForAdmin}
        />
      </TabPanel>
      <TabPanel value={selectedTab} index={2}>
        <UsersTable
          roles={admins}
          showRole={false}
          filterFn={filterArchivedClientsForAdmin}
        />
      </TabPanel>
      <TabPanel value={selectedTab} index={3}>
        <UsersTable
          roles={trainers}
          showRole={false}
          filterFn={filterArchivedClientsForAdmin}
        />
      </TabPanel>
      <TabPanel value={selectedTab} index={4}>
        <InvitationsTable
          roles={adminsAndTrainers}
          showRole={true}
          createActionLabel="Invite user"
        />
      </TabPanel>
    </PageContainer>
  );
}
