import {makeStyles} from "@material-ui/core/styles";
import {createStyles, Theme} from "@material-ui/core";

export default makeStyles((theme: Theme) =>
  createStyles({
    drawerPaper: {
      width: 500,
      padding: theme.spacing(4, 4),

      [theme.breakpoints.down('sm')]: {
        width: '100%',
        padding: theme.spacing(4, 2),
      },
    },
    drawerHeader: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'flex-start',
      marginBottom: theme.spacing(3),
    },
  })
);
