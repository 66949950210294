import {useLocalizationProvider} from "../../../providers/LocalizationProvider";
import React, {Fragment, useState} from "react";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import TabPanel from "../../../components/TabPanel";
import WorkoutList from "./WorkoutList";
import ProgramList from "./ProgramsList";
import {Exercise, User, Workout} from "myfitworld-model";

const TrainerLibrary = ({trainer, equipment, exercises, workouts, toggleError} : Props) => {
  const {translate} = useLocalizationProvider();
  const [selectedTab, setSelectedTab] = useState(0);

  return (
    <Fragment>
      <Tabs
        centered
        value={selectedTab}
        textColor='primary'
        onChange={(_, value: number) => {
          setSelectedTab(value);
        }}
        variant="fullWidth"
      >
        <Tab label={translate('Workouts')}/>
        <Tab label={translate('Programs')}/>
      </Tabs>
      <TabPanel value={selectedTab} index={0}>
        <WorkoutList trainer={trainer} equipment={equipment} exercises={exercises} toggleError={toggleError}/>
      </TabPanel>
      <TabPanel value={selectedTab} index={1}>
        <ProgramList workouts={workouts} trainer={trainer} equipment={equipment} exercises={exercises} toggleError={toggleError}/>
      </TabPanel>
    </Fragment>
  );
}

export default TrainerLibrary;

interface Props {
  trainer: User;
  equipment: Record<string, string> | null;
  exercises: Array<Exercise>;
  workouts: Array<Workout>;
  toggleError: (value: boolean) => void;
}
