import React, {Fragment, useContext, useEffect} from 'react';
import LanguageContext from "./language/LanguageContext";
import TextField, {BaseTextFieldProps} from "@material-ui/core/TextField";
import {InputAdornment} from "@material-ui/core";

const LocalizedTextField = (props: BaseTextFieldProps)  => {
  const {language, languages, registerField, deregisterField} = useContext(LanguageContext);
  const {name, required, inputRef} = props;

  useEffect(() => {
    if(name) {
      registerField(name);
      return () => deregisterField(name);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  },[name])


  return (
    <Fragment>
      {languages.map(lang => (
        <TextField
          {...props}
          key={`${name}-${lang}`}
          style={lang !== language ? {display: 'none'} : undefined}
          inputRef={(inputRef as any)({ required: required && lang === 'en', language: true })}
          name={`${name}.${lang}` || ''}
          required={required && lang === 'en'}
          autoFocus
          InputProps={{
            endAdornment: <InputAdornment position="end" style={{width: '1.2rem'}}>{lang.toLocaleUpperCase()}</InputAdornment>,
          }}
        />
      ))}
    </Fragment>
  )
}

export default LocalizedTextField;
