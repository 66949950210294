export const initialContentFilterState = {
  sports: '',
  muscleGroups: '',
  secondaryMuscleGroups: '',
  fitnessActivity: '',
  goals: '',
  motorSkills: '',
  healthIssues: '',
  equipment: '',
  difficultyLevel: '',
  muscleRegime: '',
  archived: false,
  showMFWOriginals: false,
};

export default initialContentFilterState;
