import {useStoreState} from 'pullstate';
import {differenceInSeconds} from "date-fns";
import {useCallback, useEffect, useState} from "react";
import i18n from "i18n-js";
import {
  equipmentApi, expertisesApi,
  fitnessActivitiesApi, goalsApi,
  healthIssuesApi,
  motorSkillsApi,
  muscleGroupsApi,
  sportsApi
} from "./common";
import globalState from '../globalState';
import useOrganization from "../hooks/useOrganization";

const CACHE_LIFE = 60; // seconds

export type GlobalStateKey =
  'equipmentCache' | 'muscleGroupsCache' | 'sportsCache' | 'motorSkillsCache' |
  'healthIssuesCache' | 'fitnessActivitiesCache' | 'goalsCache' | 'expertisesCache';

const getListApiForKey = (key: GlobalStateKey) => {
  switch (key) {
    case 'equipmentCache':
      return equipmentApi.list;
    case 'muscleGroupsCache':
      return muscleGroupsApi.list;
    case 'sportsCache':
      return sportsApi.list;
    case 'motorSkillsCache':
      return motorSkillsApi.list;
    case 'healthIssuesCache':
      return healthIssuesApi.list;
    case 'fitnessActivitiesCache':
      return fitnessActivitiesApi.list;
    case 'goalsCache':
      return goalsApi.list;
    case 'expertisesCache':
      return expertisesApi.list;
  }
}

const useGlobalCachedResourceList = <T>(
  globalStateKey: GlobalStateKey
) => {
  const [data, setData] = useState<Array<T>>([]);
  const [loading, setLoading] = useState<boolean>(false);
  const {organizationId} = useOrganization();
  const cached = useStoreState(globalState, s => s[globalStateKey]);

  const reload = (
    cached.data.length === 0 ||
    Math.abs(differenceInSeconds(new Date(), cached.loadedAt)) > CACHE_LIFE
  );

  const loadData = useCallback(async () => {
    setLoading(true);

    if (reload && organizationId) {
      const result = await getListApiForKey(globalStateKey)(organizationId, undefined, `name.${i18n.locale}`);
      setData(result as unknown as T[]);
      setLoading(false);
      globalState.update(state => {
        state[globalStateKey] = {
          data: result,
          loadedAt: new Date(),
        }
      })
    } else {
      setData(cached.data as unknown as T[]);
      setLoading(false);
    }
  }, [organizationId, globalStateKey, reload, cached.data]);

  useEffect(() => {
    loadData();
  }, [loadData]);

  return {data, loadData, loading};
}

export default useGlobalCachedResourceList;
