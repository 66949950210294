import React from 'react';
import {Typography} from '@material-ui/core';
import LockOutlinedIcon from '@material-ui/icons/LockOutlined';
import InfoOutlinedIcon from '@material-ui/icons/InfoOutlined';
import NotInterestedIcon from '@material-ui/icons/NotInterested';
import InfoIcon from '@material-ui/icons/Info';
import useTheme from "@material-ui/core/styles/useTheme";

export const IdentityNote = () =>
  <Typography variant='body2' style={{ opacity: 0.5, marginTop: '1rem' }}>
    <InfoOutlinedIcon style={{ verticalAlign: 'sub' }} fontSize='small' />
    {' '}
    {'Enter your name exactly as it’s written on the card.'}
  </Typography>;

export const BillingPlanHelperNote = () => {
  const theme = useTheme();

  return (
    <Typography variant='body2' style={{ opacity: 0.5, marginTop: '1rem' }}>
      <InfoIcon style={{ verticalAlign: 'sub' }} fontSize='small' />
      {' '}
      <span style={{color: theme.palette.primary.light, fontWeight: 'bold'}}>Payment card details are required at the initial registration due to the business policy of the Stripe payment processor - there is NO charge unless you decide to add a third and each subsequent client. </span>You can upgrade or downgrade your subscription plan at any time - <span style={{fontWeight: 'bold'}}>more on the Q & A section of the website www.myfitworld.net.</span>
    </Typography>
  )
}

export const BillingAddressHelperNote = () => {
  return (
    <Typography variant='body2' style={{ opacity: 0.5, marginTop: '1rem' }}>
      <InfoIcon style={{ verticalAlign: 'sub' }} fontSize='small' />
      {' '}
      All fields are required, except Tax ID field.
    </Typography>
  )
}

export const SecurityNote = () =>
  <Typography variant='body2' style={{ opacity: 0.5, marginTop: '1rem' }}>
    <LockOutlinedIcon style={{ verticalAlign: 'sub' }} fontSize='small' />
    {' '}
    {'Your data is encrypted and secured by Stripe.'}
  </Typography>;

export const NoBillingDetailsNote = () =>
  <Typography variant='body1' style={{ opacity: 0.8 }}>
    <NotInterestedIcon style={{ verticalAlign: 'sub' }} fontSize='small' />
    {' '}
    {'You cannot access the application because your organization has no billing details. Contact an admin and ask them to enter their billing details.'}
  </Typography>;
