import React, {ChangeEvent, Fragment, useState} from 'react';
import CloudUploadIcon from '@material-ui/icons/CloudUpload';
import {Box, CircularProgress, makeStyles, Theme} from '@material-ui/core';
import runUploadTask from '../../api/runUploadTask';
import FilePreview from './FilePreview';
import Label from "./Label";

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    display: 'flex',
    width: '100%',
    maxHeight: 280,
    alignContent: 'center',
    justifyContent: 'center',
    flexDirection: 'column',
    justifyItems: 'center',
    border: `1px dashed ${theme.palette.divider}`,
    borderRadius: 4,
    overflow: 'hidden',
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2),
    cursor: 'pointer',
    transition: '160ms all',
    '&:hover': {
      border: `1px dashed #fff`,
    }
  },
  icon: {
    alignSelf: 'center',
    color: '#fff'
  },
  progress: {
    alignSelf: 'center',
  },
  input: {
    display: 'none'
  }
}));

const acceptedTypes = {
  'video': 'video/*',
  'image': 'image/*',
};

const FileUploadField = ({
                           name,
                           uploadLabel,
                           changeLabel,
                           uploadPath,
                           fileType,
                           value,
                           onUploadSuccessful,
                           disabled
                         }: {
  name: string,
  uploadLabel: string,
  changeLabel: string,
  uploadPath: string,
  fileType: 'video' | 'image',
  value?: string,
  onUploadSuccessful: (fileUrl: string) => void,
  disabled?: boolean
}) => {
  const classes = useStyles();
  const [uploadProgress, setUploadProgress] = useState({inProgress: false, progress: 0});

  const handleSelectionChange = (e: ChangeEvent<HTMLInputElement>) => {
    const files = e.target.files;
    if (files instanceof FileList && files[0] && !disabled) {
      setUploadProgress({inProgress: true, progress: 0});
      runUploadTask({
        uploadPath,
        file: files[0],
        onProgressChange: (progress) => {
          setUploadProgress({inProgress: true, progress})
        },
        onError: (error) => {
          console.error(error);
          setUploadProgress({inProgress: false, progress: 0});
        },
        onDone: (fileURL => {
          setUploadProgress({inProgress: false, progress: 0});
          onUploadSuccessful(fileURL);
        })
      });
    }
  };

  return (
    <label
      className={classes.root}
      htmlFor={`${name}-${uploadPath}`}
    >
      {uploadProgress.inProgress &&
      <CircularProgress
        variant='static'
        className={classes.progress}
        value={uploadProgress.progress}
      />}

      {!uploadProgress.inProgress &&
      <Fragment>
        {value ?
          <Box display="flex" minHeight={0} justifyContent="center" overflow="hidden" maxWidth="100%">
            <FilePreview type={fileType} value={value}/>
          </Box> :
          <CloudUploadIcon className={classes.icon}/>}
        <Box p={1}>
          <Label
            hasValue={value !== undefined}
            changeLabel={changeLabel}
            uploadLabel={uploadLabel}
          />
        </Box>
      </Fragment>
      }

      <input
        id={`${name}-${uploadPath}`}
        className={classes.input}
        type='file'
        accept={acceptedTypes[fileType]}
        onChange={uploadProgress.inProgress ? undefined : handleSelectionChange}
        disabled={uploadProgress.inProgress || disabled}
      />
    </label>
  );
};

export default FileUploadField;
