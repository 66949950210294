import {Organization} from "myfitworld-model";
import {useLocalizationProvider} from "../../providers/LocalizationProvider";
import {Button, Chip, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow} from "@material-ui/core";
import SupervisorAccountIcon from '@material-ui/icons/SupervisorAccount';
import formatDistanceToNow from "date-fns/formatDistanceToNow";
import React, {Fragment, useEffect, useState} from "react";
import useTableStyles from "../../theme/useTableStyles";
import {firestore} from "../../firebase";
import safeInvoke from "../../utils/safeInvoke";
import SideDrawer from "../SideDrawer";
import OrganizationForm from "./OrganizationForm";
import {useUserProvider} from "../../providers/UserProvider";
import Box from "@material-ui/core/Box";
import LoadingSpinner from "../LoadingSpinner";

interface OrganizationExtended extends Organization {
  quantity: number
}

const OrganizationsTable = ({onClick}: { onClick?: (userId: string) => void }) => {
  const tableClasses = useTableStyles();

  const {translate} = useLocalizationProvider();
  const [organizations, setOrganizations] = useState<OrganizationExtended[]>([]);
  const [loading, setLoading] = useState<boolean>(true);

  const [drawerOpen, setDrawerOpen] = useState(false)
  const [selectedOrganization, setSelectedOrganization] = useState<OrganizationExtended | undefined>();

  const {setOrganizationOverride} = useUserProvider();

  const onDrawerClose = () => {
    setDrawerOpen(false);
    setSelectedOrganization(undefined);
  }

  useEffect(() => {

    return firestore.collection('organizations')
      .orderBy('name')
      .onSnapshot(snapshot => {
        const organizations = snapshot.docs.map(doc => ({
          id: doc.id,
          ...doc.data(),
          createdAt: safeInvoke('toDate')(doc.data().createdAt),
        } as Organization));

        firestore.collection('billingDetails').onSnapshot(snapshot => {
          setOrganizations(organizations.map((org) => {
            const billingDetails = snapshot.docs.find((data) => data.id === org.id)?.data();
            return {
              ...org,
              quantity: billingDetails?.stripe?.quantity || 0
            }
          }));
          setLoading(false);
        });
      }, error => {
        console.error('Error retrieving organizations', error)
      });

  }, [])

  return (
    <Fragment>
      <Box className={tableClasses.tableActionBar}>
        <Button
          color='primary'
          onClick={_ => setDrawerOpen(true)}
        >
          {translate('Create Organization')}
        </Button>
      </Box>

      {loading && <LoadingSpinner marginTop={50} />}
      {!loading &&
      <TableContainer component={Paper}>
        <Table className={tableClasses.table} size="small" aria-label="a dense table">
          <TableHead>
            <TableRow>
              <TableCell>{translate('Name')}</TableCell>
              <TableCell align="center">{'Payment'}</TableCell>
              <TableCell align="center">{translate('Number of clients')}</TableCell>
              <TableCell align="center">{'Alert'}</TableCell>
              <TableCell align="center">{'Discount'}</TableCell>
              <TableCell align="right"/>
              <TableCell align="right">{translate('Created At')}</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {organizations.map((organization) => (
              <TableRow key={organization.id} onClick={() => {
                setSelectedOrganization(organization);
                setDrawerOpen(true);
                onClick && onClick(organization.id!);
              }} hover>
                <TableCell component="th" scope="row">
                  {organization.name}
                </TableCell>
                <TableCell align="center">
                  <Chip label={`${organization?.free ? 'FREE' : 'STRIPE'}`} variant="default" color={organization?.free ? "secondary" : "default"}/>
                </TableCell>
                <TableCell align="center">{organization.quantity}</TableCell>
                <TableCell align="center">{organization?.alertOn ? organization.alertOn : '-'}</TableCell>
                <TableCell align="center">{organization?.stripe?.discountName ? organization.stripe.discountName : '-'}</TableCell>
                <TableCell align="right">
                  <Button
                    variant="text"
                    size="small"
                    color="primary"
                    startIcon={<SupervisorAccountIcon/>}
                    onClick={(event) => {
                      event.stopPropagation();
                      setOrganizationOverride(organization?.id)
                    }}
                  >
                    {translate('Manage')}
                  </Button>
                </TableCell>
                <TableCell align="right">
                  {organization.createdAt && formatDistanceToNow(organization.createdAt, {addSuffix: true})}
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
      }

      {
        drawerOpen &&
        <SideDrawer isOpen={drawerOpen || !!selectedOrganization} onClose={() => onDrawerClose()}>
          <OrganizationForm organization={selectedOrganization} onClose={() => onDrawerClose()}/>
        </SideDrawer>
      }
    </Fragment>
  );
};

export default OrganizationsTable;
