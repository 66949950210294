import React, {Fragment, useEffect, useMemo, useRef, useState} from 'react';
import clsx from 'clsx';
import LoadingSpinner from '../components/LoadingSpinner';
import EmptyStateMessage from '../pages/EmptyStateMessage';
import {
  CRUDListHeader,
  CRUDListLeftContainer,
  CRUDListLeftContainerRef,
  CRUDListRightContainer,
  CRUDListWrapper,
} from '../pages/CRUDList';
import globalState, {GlobalState} from "../globalState";
import * as firebase from 'firebase/app';
import APIResponse from "../model/APIResponse";
import {Entity, Exercise, Resource} from "myfitworld-model";
import {ApiInterface} from "../api/dataInterfaceFactory";
import ContentFilterValues from "../model/ContentFilter";
import ContentFilter from "../pages/content/ContentFilter";
import useEntityList from "../hooks/useEntityList";
import useOrganization from "../hooks/useOrganization";
import {createStyles, Drawer, Theme, Typography} from "@material-ui/core";
import {makeStyles} from "@material-ui/core/styles";
import CloseIcon from '@material-ui/icons/Close';
import Button from "@material-ui/core/Button";
import {ContentFilterOptions} from "../utils/contentFilterOptions";
import ExercisePreviewDialog from "../pages/content/exercise/ExercisePreviewDialog";

import {FixedSizeList as List} from 'react-window';
import {storage} from "../firebase";
import {CustomFileMetadata} from "../api/runUploadTask";
import {isMFWExercise} from "../utils/isMFWcontent";
import useNavigation from "../hooks/useNavigation";
import {useLocation} from "@reach/router";
import get from "lodash/get";
import {ContentPath, deleteThumbnails} from "../api/thumbnailsApi";

const drawerWidth = 500;

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    drawer: {
      width: drawerWidth,
      flexShrink: 0,

      [theme.breakpoints.down('sm')]: {
        width: '100%',
      },
    },
    drawerPaper: {
      width: drawerWidth,
      padding: theme.spacing(4, 4, 0),

      [theme.breakpoints.down('sm')]: {
        width: '100%',
        padding: theme.spacing(4, 2, 0),
      },
    },
    drawerHeader: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'flex-start',
      marginBottom: theme.spacing(3),
    },
    content: {
      flex: '0 1 100%',
      transition: theme.transitions.create('margin', {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
      }),
      marginRight: -drawerWidth,
      [theme.breakpoints.down('sm')]: {
        overflowY: 'hidden',
      },
    },
    contentShift: {
      transition: theme.transitions.create('margin', {
        easing: theme.transitions.easing.easeOut,
        duration: theme.transitions.duration.enteringScreen,
      }),
      marginRight: 0,
    },
  }),
);

const SplitScreen = React.forwardRef((
  {sidebarOpen, handleClose, ListContent, FormContent}: { sidebarOpen: boolean, handleClose: () => void, ListContent: any, FormContent: any }, ref: CRUDListLeftContainerRef) => {

  const classes = useStyles();

  return (
    <CRUDListWrapper>
      <main
        className={clsx(classes.content, {
          [classes.contentShift]: sidebarOpen,
        })}
      >
        <CRUDListLeftContainer ref={ref}>{ListContent}</CRUDListLeftContainer>
      </main>
      <Drawer
        className={classes.drawer}
        variant="persistent"
        anchor="right"
        open={sidebarOpen}
        classes={{paper: classes.drawerPaper}}
      >
        <div className={classes.drawerHeader}>
          <Button
            size="small"
            startIcon={<CloseIcon/>}
            onClick={handleClose}
          >
            Close
          </Button>
        </div>
        <CRUDListRightContainer>
          {sidebarOpen && FormContent}
        </CRUDListRightContainer>
      </Drawer>
    </CRUDListWrapper>
  )
})

const FullWidth = React.forwardRef((
  {sidebarOpen, ListContent, FormContent}: { sidebarOpen: boolean, ListContent: any, FormContent: any }, ref: CRUDListLeftContainerRef) => (
  <Fragment>
    {!sidebarOpen && <CRUDListWrapper>
      <CRUDListLeftContainer ref={ref}> {ListContent} </CRUDListLeftContainer>
    </CRUDListWrapper>}
    {sidebarOpen && FormContent}
  </Fragment>
));

const anyFilterActive = (contentFilter?: ContentFilterValues, query?: string) => {
  let result = Boolean(query);
  if (contentFilter) {
    Object.keys(contentFilter).forEach(key => {
      // @ts-ignore
      result = result || Boolean(contentFilter[key]);
    });
  }
  return result;
};

function EntityManager<T extends Resource>({
                                             entityName, fullWidthForm, entityCreateHelper, apiInterface,
                                             initialFilterState, ListItemComponent, FormComponent, emptyStateTitle,
                                             loadFullEntityData, contentFilterOptions,
                                             sortKey,
                                             listItemHeight, appendFormInfoToURL
                                           }: {
  entityName: string,
  fullWidthForm?: boolean
  entityCreateHelper?: string,
  apiInterface: ApiInterface<T>
  ListItemComponent: React.ElementType,
  FormComponent: React.ElementType,
  initialFilterState?: ContentFilterValues,
  emptyStateTitle: string,
  loadFullEntityData?: boolean,
  sortKey?: string,
  contentFilterOptions?: ContentFilterOptions,
  listItemHeight?: number,
  appendFormInfoToURL?: boolean,
}) {
  const [working, setWorking] = useState(false);
  const [formErrorMessage, setFormErrorMessage] = useState<string | undefined>();
  const [editFormOpenForItem, setEditFormOpenForItem] = useState<T>();
  const [createFormOpen, setCreateFormOpen] = useState(false);
  const [previewMFWExercise, setPreviewMFWExercise] = useState<Exercise | null>(null);
  const [contentFilter, setContentFilter] = useState<ContentFilterValues | undefined>(initialFilterState);
  const [query, setQuery] = useState<string>('');

  let {data, loadData, loading} = useEntityList<T>(apiInterface.list, contentFilter, sortKey);
  const {organizationId} = useOrganization();
  const {navigation} = useNavigation();
  const location = useLocation();
  const formOpenPath = location.pathname + (location.search ? location.search + '&form=open' : '?form=open');

  useEffect(() => {
    if (location.search.indexOf('form=open') === -1 && (createFormOpen || editFormOpenForItem)) {
      setEditFormOpenForItem(undefined);
      setCreateFormOpen(false);
    }
    // trigger only when search param changes
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location.search]);

  if (query.length >= 3) {
    data = data.filter(_ => get(_, 'title.en', '').toUpperCase().indexOf(query.toUpperCase()) >= 0);
  }

  const closeFormPanel = () => {
    setEditFormOpenForItem(undefined);
    setCreateFormOpen(false);
    navigation(location.pathname, undefined, true);
  };

  const Panel = useMemo(() => fullWidthForm ? FullWidth : SplitScreen, [fullWidthForm]);

  const handleCreate = async (d: T) => {
    preSubmitSetup();
    await apiInterface.create({
      ...d,
      id: '-1',
      organizationId,
    })
      .then(async ({documentRef}) => await handleSubmitSuccess(documentRef))
      .catch(handleSubmitError)
      .finally(postSubmitCleanup);
  };

  const handleUpdate = async (d: T) => {
    preSubmitSetup();
    await apiInterface.update(d)
      .then(async ({documentRef}) => await handleSubmitSuccess(documentRef))
      .catch(handleSubmitError)
      .finally(postSubmitCleanup);
  };

  const preSubmitSetup = () => {
    setWorking(true);
    setFormErrorMessage(undefined);
    globalState.update((state: GlobalState) => {
      state.globalLoadingQueue.push(state.globalLoadingQueue.length + 1);
    });
  };

  const postSubmitCleanup = () => {
    globalState.update((state: GlobalState) => {
      state.globalLoadingQueue.shift();
    });
    loadData();
  };

  const handleSubmitSuccess = async (documentRef: firebase.firestore.DocumentReference<firebase.firestore.DocumentData> | undefined) => {
    switch (entityName.toLowerCase()) {
      case 'exercise':
      case 'program':
      case 'workout':
        await documentRef?.get()
          .then(async (doc) => {
            const customMetadata = {
              type: entityName.toLowerCase(),
              refId: documentRef?.id
            } as CustomFileMetadata;
            // delete the old thumbURLS first
            await deleteThumbnails(doc.id, (entityName.toLowerCase() + 's') as ContentPath);
            if (doc.get('thumbnailURL')) {
              await storage.refFromURL(doc.get('thumbnailURL'))
                .updateMetadata({customMetadata})
                .catch(err => console.error(`error updating metadata id: ${doc.id}`, err))
            }
          });
        break;
      default:
        break;
    }

    globalState.update((state: GlobalState) => {
      state.toastQueue.push({message: `${entityName} changes saved!`, severity: "success"});
    });
    setWorking(false);
    closeFormPanel();
  };

  const handleSubmitError = (res: APIResponse) => {
    setWorking(false);
    setFormErrorMessage(res.errorMessage)
  };

  const selectItem = async (entity: T) => {
    if (isMFWExercise(entity as unknown as Entity, entityName, organizationId)) {
      setPreviewMFWExercise(entity as unknown as Exercise);
    } else {
      if (loadFullEntityData && entity.id) {
        const result = await apiInterface.get(entity.id);
        if (result) {
          setEditFormOpenForItem(result);
        } else {
          setEditFormOpenForItem(entity);
        }
      } else {
        setEditFormOpenForItem(entity);
      }
      appendFormInfoToURL && navigation(formOpenPath);
    }
  };

  const isFormOpen = createFormOpen || !!editFormOpenForItem;
  const isFullWidthFormOpen = isFormOpen && fullWidthForm;

  const [accordionOpen, setAccordionOpen] = useState(false);

  return (
    <Fragment>
      <CRUDListHeader
        title={entityName}
        ctaLabel={`Create New ${entityName}`}
        disabled={editFormOpenForItem !== undefined}
        onClick={() => {
          setCreateFormOpen(true);
          appendFormInfoToURL && navigation(formOpenPath);
        }}
      />

      {!isFullWidthFormOpen && contentFilter && contentFilterOptions &&
      <ContentFilter onFilterChange={setContentFilter} options={contentFilterOptions} query={query} setQuery={setQuery}
                     onAccordionToggle={(value) => setAccordionOpen(value)}/>
      }

      {
        !createFormOpen &&
        (data && data.length === 0) &&
        anyFilterActive(contentFilter, query) &&
        !loading &&
        <Typography variant='body2'>No items match your filter options.</Typography>
      }

      {
        !createFormOpen &&
        (data && data.length === 0) &&
        contentFilter &&
        !loading &&
        <EmptyStateMessage
          title={emptyStateTitle}
          callToAction='Create new!'
          onClick={() => {
            setCreateFormOpen(true);
            appendFormInfoToURL && navigation(formOpenPath);
          }}
        />
      }

      {(data === undefined || loading) ? <LoadingSpinner/> :

        <Panel
          sidebarOpen={createFormOpen || !!editFormOpenForItem}
          handleClose={closeFormPanel}
          ListContent={
            <DataList listItemHeight={listItemHeight}
                      data={data}
                      ListItemComponent={ListItemComponent}
                      selectItem={selectItem}
                      accordionOpen={accordionOpen}
            />
          }
          FormContent={
            <FormComponent
              handleClose={closeFormPanel}
              handleCreate={handleCreate}
              handleUpdate={handleUpdate}
              mode={editFormOpenForItem ? 'Edit' : 'Create'}
              loading={working}
              formErrorMessage={formErrorMessage}
              defaultState={editFormOpenForItem}
              entityName={entityName}
              entityCreateHelper={entityCreateHelper}
            />
          }
        />
      }
      <ExercisePreviewDialog
        exercise={previewMFWExercise}
        open={previewMFWExercise !== null}
        onClose={() => setPreviewMFWExercise(null)}
      />
    </Fragment>
  );
}


const DataList = ({ListItemComponent, data, listItemHeight, selectItem, accordionOpen}:
                    { ListItemComponent: React.ElementType, data: any, listItemHeight?: number, selectItem: (entity: any) => void, accordionOpen: boolean }) => {

  const [listHeight, setListHeight] = useState<number>(600);

  const listContainerRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    const handleResize = () => {
      if (window.innerWidth / window.innerHeight > 1) {
        setListHeight(window.innerHeight - (listContainerRef?.current?.offsetTop || 450) - window.innerHeight * 0.05);
      }
    }

    window.addEventListener('resize', handleResize);

    // wait for the paint to be done so we have better offset position
    setTimeout(handleResize, 400);

    return () => {
      window.removeEventListener('resize', handleResize);
    }
  }, [listContainerRef, accordionOpen])

  return (<List
    outerRef={listContainerRef}
    itemCount={(data || []).length}
    itemData={(data || [])}
    height={listHeight}
    width={'100%'}
    itemSize={listItemHeight || 120}
  >
    {({index, data, style}) => (<ListItemComponent
        key={data[index].id}
        item={data[index]}
        onSelect={() => {
          selectItem(data[index])
        }}
        disabled={false}
        style={style}
      />
    )}
  </List>)
}
export default EntityManager;
