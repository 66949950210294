export enum ThumbSize {
  s200 = 200,
  s800 = 800,
  s1200 = 1200,
  s1920 = 1920,
}

const getImageThumbnail = (thumbURLS:[{size:number, url:string}], thumbsize:ThumbSize = ThumbSize.s200) => {
  return thumbURLS.find(item => item.size === thumbsize)
}

export default getImageThumbnail;
