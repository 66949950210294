import {ApiInterface, createProviderFactory, getProviderFactory, updateProviderFactory} from "./dataInterfaceFactory";
import {Organization, Resource, Role} from "myfitworld-model";
import {firestore} from "../firebase";
import OrganizationType from "myfitworld-model/dist/enums/OrganizationType";

function rootListProviderFactory<T extends Resource>(endpoint: string): (_: string) => Promise<Array<T>> {
  return async () => {
    return new Promise((resolve) => {
      firestore
        .collection(endpoint)
        .where('type', '==', OrganizationType.Gym)
        .get()
        .then(querySnapshot => {
          const data: Array<T> = [];
          querySnapshot.forEach(snapshot => {
            data.push({
              id: snapshot.id,
              ...snapshot.data()
            } as T);
          });
          resolve(data);
        })
        .catch((error) => {
          console.error(error);
          resolve([])
        })
    })
  }
}


const organizationsApi: ApiInterface<Organization> = {
  create: createProviderFactory<Organization>("organizations", {type: OrganizationType.Gym}),
  update: updateProviderFactory<Organization>("organizations"),
  list: rootListProviderFactory<Organization>("organizations"),
  get: getProviderFactory<Organization>("organizations")
};

export default organizationsApi;

export const getRoleForUser = (userId: string, organization:Organization) => {
  return organization.admins?.find(item => item === userId) ? Role.Admin : Role.Trainer;
}

export const getOrganization = async (organizationId: string): Promise<Organization | undefined> => {
  return new Promise((resolve) => {
    firestore
      .collection('organizations')
      .doc(organizationId)
      .get()
      .then(querySnapshot => {
        resolve({id: querySnapshot.id, ...querySnapshot.data()} as Organization);
      })
      .catch(() => {
        resolve();
      })
  })
}


