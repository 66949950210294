import React, {useCallback, useEffect, useLayoutEffect, useRef, useState} from "react";
import { Box, Dialog, DialogActions, DialogContent, DialogTitle, IconButton, TextField } from "@material-ui/core";
import CloseIcon from "@material-ui/icons/Close";
import { createStyles, makeStyles } from "@material-ui/core/styles";
import useChat from "../../hooks/useChat";
import { firestore } from "../../firebase";
import { Activity, User } from "myfitworld-model";
import ActivityType from "myfitworld-model/dist/enums/ActivityType";
import { useUserProvider } from "../../providers/UserProvider";
import createUUID from "../../utils/createUUID";
import SendIcon from "@material-ui/icons/Send";
import MessageView from "./MessageView";
import ActivityView from "./ActivityView";
import Mousetrap from "mousetrap";
import useNavigation from "../../hooks/useNavigation";
import LoadingSpinner from "../LoadingSpinner";
import Button from "@material-ui/core/Button";
import {useLocalizationProvider} from "../../providers/LocalizationProvider";

const LIMIT_MESSAGES = 6;

const useStyles = makeStyles(() =>
  createStyles({
    title: {
      display: "flex",
      flex: 1,
      flexDirection: "row",
      justifyContent: "space-between"
    }
  })
);
const ChatDialog = ({ isOpen, handleClose, userId }: Props) => {
  const classes = useStyles();
  const { user } = useUserProvider();
  const { navigation } = useNavigation();
  const {translate} = useLocalizationProvider();

  const [message, setMessage] = useState<string>("");

  const [limit, setLimit] = useState(LIMIT_MESSAGES);
  const messages = useChat(userId, limit);

  const inputRef = useRef<HTMLTextAreaElement>(null);
  const submitButtonRf = useRef<HTMLButtonElement>(null);

  /* eslint-disable  react-hooks/exhaustive-deps */
  useLayoutEffect(() => {
    if (inputRef.current && submitButtonRf.current) {
      inputRef.current.focus();
      let moustrapInstance = new Mousetrap(inputRef.current);
      moustrapInstance.stopCallback = function () {
        return false;
      };
      moustrapInstance.bind(["enter"], () => submitButtonRf.current?.click());
      return () => {
        moustrapInstance?.unbind(["enter"]);
      };
    }
  }, [inputRef.current, submitButtonRf.current]);
  /* eslint-disable  react-hooks/exhaustive-deps */


  useEffect(() => {
    const msgDialog = document.getElementById("messagesDialog");
    if (msgDialog) {
      msgDialog.scrollTo(0, msgDialog.scrollHeight);
    }
  }, [messages]);

  const onViewDetails = useCallback(
    (event: any, activity: Activity) => {
      navigation(`/clients/${activity.threadId}/statistics/${activity.workoutId}`);
      handleClose(event);
    },
    [handleClose, navigation]
  );

  const onSendMessage = useCallback(() => {
    if (!user) return;
    saveMessage({
      threadId: userId,
      senderId: user?.id,
      text: message,
      watchers: [userId],
      message: {
        _id: createUUID(),
        createdAt: new Date(),
        text: message,
        user: {
          _id: user?.id,
          name: getUserName(user),
        },
      },
    } as Activity)
      .then((_) => {
        setMessage("");
      })
      .catch((err) => console.error("error sending message", err));
  }, [user, userId, message]);

  return (
    <Dialog
      aria-labelledby="chat-dialog"
      open={isOpen}
      maxWidth="md"
      fullWidth
      onClick={(event) => event.stopPropagation()}
    >
      <Box className={classes.title}>
        <DialogTitle>Chat</DialogTitle>
        <Box style={{margin: 16}}>
          <IconButton onClick={handleClose} size={"small"}>
            <CloseIcon />
          </IconButton>
        </Box>
      </Box>
      <DialogContent id="messagesDialog">
        {!messages && <LoadingSpinner marginTop={20}/>}
        {messages && messages.length >= limit &&
          <Box display="flex" justifyContent="center" style={{margin: 16}}>
            <Button
              variant="contained"
              onClick={() => setLimit((prevState) => prevState + LIMIT_MESSAGES)}
              style={{textTransform: "none", borderRadius: 16}}
            >
              {translate('chat.load_messages')}
            </Button>
          </Box>
        }
        {messages &&
        messages.slice(0).reverse().map((msg) => {
          return msg.type === ActivityType.MESSAGE ? (
            <MessageView activity={msg} key={msg.id}/>
          ) : (
            <ActivityView activity={msg} key={msg.id} onViewDetails={(event: any) => onViewDetails(event, msg)}/>
          );
        })}
      </DialogContent>
      <DialogActions>
        <TextField
          style={{ flex: 1 }}
          value={message}
          onChange={(event) => setMessage(event.target.value)}
          inputRef={inputRef}
        />
        <IconButton onClick={onSendMessage} color="primary" buttonRef={submitButtonRf}>
          <SendIcon />
        </IconButton>
      </DialogActions>
    </Dialog>
  );
};

export default ChatDialog;

interface Props {
  isOpen: boolean;
  handleClose: (event: any) => void;
  userId: string;
}

const saveMessage = (activity: Activity) =>
  firestore.collection("activity").add({
    ...activity,
    createdAt: new Date(),
    type: ActivityType.MESSAGE,
  } as Activity);

const getUserName = (user: User) => {
  let username = "";
  if (user.firstName) username += user.firstName;
  if (user.lastName) {
    username += user.lastName;
    return username;
  }
  if (user.firstName || user.lastName) username = `${user.firstName || ""} ${user.lastName || ""}`;
  else if (user.email) username = user.email.split("@")[0];
  else if (user.phoneNumber) username = user.phoneNumber;
  else username = "Unknown";
  return username;
};
