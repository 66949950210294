import {Role, User} from 'myfitworld-model';
import {getUserFullName} from "../../utils/getUserFullName";

export interface ClientInfo {
  name: string,
  organizations: Array<string>
}

const buildUserInfoMap = (clients: User[]) => {
  const list = clients.map(client => ({
    name: getUserFullName(client?.firstName, client?.lastName),
    organizations: (client.organizations || []).filter(org => org.role === Role.Client).map(e => e.id),
    id: client.id as string,
  }));

  return list.reduce((acc, next) => {
    acc[next.id] = {name: next.name, organizations: next.organizations || []};
    return acc;
  }, {} as { [key: string]: ClientInfo });
}

export default buildUserInfoMap;
