import React, {useState} from 'react';
import {Controller, useForm} from "react-hook-form";
import Button from "@material-ui/core/Button";
import {useLocalizationProvider} from "../../providers/LocalizationProvider";
import {Box, createStyles, TextField, Theme, Typography} from "@material-ui/core";
import {Alert} from "@material-ui/lab";
import {firestore} from "../../firebase";
import {Invitation, Role} from "myfitworld-model";
import {useOrganizationProvider} from "../../providers/OrganizationProvider";
import {UnpackNestedValue} from "react-hook-form/dist/types/form";
import {useUserProvider} from "../../providers/UserProvider";
import AutoselectField from "../AutoselectField";
import firebase from "firebase";
import {makeStyles} from "@material-ui/core/styles";
import {validatePhoneNumber} from "../../validators/validatePhoneNumber";
import {validateEmail} from "../../validators/validateEmail";
import {validateEmailOrPhoneNumber} from "../../validators/validateEmailOrPhoneNumber";
import {isEmpty} from "../../validators/isEmpty";
import {incrementSubscriptionQuantity} from "../../api/billingDetailsApi";

type FormValues = {
  firstName: string;
  lastName: string;
  emailOrPhone: string;
  role: Role;
};

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      '& .MuiTextField-root': {
        marginBottom: theme.spacing(1),
      },
    },
  }),
);

const InviteUserForm = (
  {onSubmit, roles, createActionLabel, helperText, invitationsBlocked}: Props
) => {

  const {register, control, handleSubmit, errors} = useForm<FormValues>({
    criteriaMode: "all"
  });
  const [loading, setLoading] = useState(false);

  const classes = useStyles();
  const {translate} = useLocalizationProvider();
  const {user} = useUserProvider();

  const roleOptions = roles.map(role => ({id: role, name: {en: role}}));

  const [{selectedOrganization}] = useOrganizationProvider();

  const onSubmitHandler = handleSubmit((data: UnpackNestedValue<FormValues>) => {
    setLoading(true);
    firestore.collection('invitations')
      .add({
        firstName: data.firstName,
        lastName: data.lastName,
        email: validateEmail(data.emailOrPhone) === true ? data.emailOrPhone : null,
        phoneNumber: validatePhoneNumber(data.emailOrPhone) === true ? data.emailOrPhone : null,
        organization: roles[0] === Role.SuperAdmin ? null : selectedOrganization?.id || null,
        role: roles.length > 1 ? data.role : roles[0],
        createdAt: firebase.firestore.Timestamp.fromDate(new Date()),
        invitedBy: user?.id,
        invitationSent: false,
        isInSendingQueue: roles && roles[0] !== Role.Client,
        acceptedOn: null,
      } as unknown as Invitation)
      .then( async () => {
        setLoading(false);
        if (roles.includes(Role.Client) && selectedOrganization?.id) {
          await incrementSubscriptionQuantity(selectedOrganization?.id);
        }
        onSubmit();
      })
      .catch(err => {
        console.error('error sending invitation', err);
        setLoading(false)
      });
  });

  return (
    <form onSubmit={onSubmitHandler} className={classes.root}>
      <TextField
        name='firstName'
        inputRef={register({
          required: "Please enter first name",
          validate: value => isEmpty(value)
        })}
        label={translate('invitation.first_name')}
        error={!!errors.firstName}
        type='text'
        autoFocus
        fullWidth
      />

      <TextField
        name='lastName'
        inputRef={register({
          required: "Please enter last name",
          validate: value => isEmpty(value)
        })}
        label={translate('invitation.last_name')}
        error={!!errors.lastName}
        type='text'
        autoFocus
        fullWidth
      />

      <TextField
        name='emailOrPhone'
        inputRef={register({
          required: "Please enter email or phone number",
          validate: value => validateEmailOrPhoneNumber(value)
        })}
        error={!!errors.emailOrPhone}
        label={translate('Email or Phone Number')}
        type='text'
        autoFocus
        fullWidth
      />

      {roles.length > 1 &&
      <Controller
        name='role'
        rules={{required: "Please choose role",}}
        control={control}
        render={({onChange, value}) => {
          return (
            <AutoselectField
              label={translate('Role')}
              value={value}
              options={roleOptions || []}
              onChange={value => onChange(value)}
            />
          )
        }}
      />
      }

      {roles.length === 1 &&
      <Alert severity="info">
        This will create a new client profile that you can assign workouts to. When you’re done, click "SEND" and we’ll deliver an invite message to them.
        <span style={{fontWeight: "bold"}}> By creating a client, you increase the number of clients on your subscription plan by one. If you want to delete the invitation, click "REVOKE", this operation reduces the number of clients on your subscription plan by one.</span>
      </Alert>
      }

      {invitationsBlocked &&
      <Box mt={1}>
        <Alert severity="warning">
          {translate("invitation.invitation_disabled")}
        </Alert>
      </Box>
      }

      <Box style={{marginTop: 24}}>
        <Button
          color='primary' variant='contained'
          onClick={onSubmitHandler}
          disabled={loading || invitationsBlocked}
        >
          {translate(createActionLabel)}
        </Button>
      </Box>

      {(errors.emailOrPhone || errors.role || errors.firstName || errors.lastName) &&
      <Alert severity='error' style={{marginTop: 24}}>
        {errors.firstName?.message && <Typography color="inherit">{translate(errors.firstName.message)}</Typography>}
        {errors.lastName?.message && <Typography color="inherit">{translate(errors.lastName.message)}</Typography>}
        {errors.emailOrPhone?.message && <Typography color="inherit">{translate(errors.emailOrPhone.message)}</Typography>}
        {errors.role?.message && <Typography color="inherit">{translate(errors.role.message)}</Typography>}
      </Alert>}
    </form>
  )
}

export default InviteUserForm;

interface Props {
  roles: Role[];
  onSubmit: () => void;
  createActionLabel: string;
  helperText?: string;
  invitationsBlocked?: boolean;
}
