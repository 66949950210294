import {User} from "myfitworld-model";
import {useLocalizationProvider} from "../../providers/LocalizationProvider";
import {Box, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Typography,} from "@material-ui/core";
import formatDistanceToNow from "date-fns/formatDistanceToNow";
import React, {Fragment, useEffect, useState} from "react";
import useTableStyles from "../../theme/useTableStyles";
import {firestore} from "../../firebase";
import Role from "myfitworld-model/dist/enums/Role";
import {getUserFullName} from "../../utils/getUserFullName";
import {getUserInitials} from "../../utils/getUserInitials";
import {useTheme} from "@material-ui/core/styles";
import safeInvoke from "../../utils/safeInvoke";
import {useUserProvider} from "../../providers/UserProvider";
import LoadingSpinner from "../../components/LoadingSpinner";
import ProfileImage from "../../components/avatars/ProfileImage";


const UsersTable =
  ({
     roles,
     showRole,
     disableOrganizationFilter
   }: Props) => {
    const theme = useTheme();
    const classes = useTableStyles();
    const {translate} = useLocalizationProvider();
    const {user: authUser} = useUserProvider();
    const [users, setUsers] = useState<User[]>([]);
    const [loading, setLoading] = useState<boolean>(true);

    useEffect(() => {
      const organizationUserRef = firestore.collection('users');

      if (disableOrganizationFilter) {
        organizationUserRef
          .where('isSuperAdmin', '==', true)
          .onSnapshot((querySnapshot) => {
              const data: Array<User> = [];
              querySnapshot.forEach(snapshot => {
                data.push({
                  id: snapshot.id,
                  ...snapshot.data(),
                } as User);
              });
              setUsers(prevState => [...prevState, ...(data || []).filter(el => el.id !== authUser?.id)]);
              setLoading(false);
            }
          );
      }
    }, [disableOrganizationFilter, authUser]);

    return (
      <Box>
        {loading && <LoadingSpinner marginTop={50}/>}
        {!loading &&
        <Fragment>
          <TableContainer>
            <Table className={classes.table}>
              <TableHead>
                <TableRow className={classes.tableRow}>
                  <TableCell>{translate("Name")}</TableCell>
                  <TableCell align="right">{translate("Email")}</TableCell>
                  <TableCell align="right">{translate("Phone")}</TableCell>
                  {showRole && <TableCell align="right">{translate("Role")}</TableCell>}
                  <TableCell align="right">{translate("Created At")}</TableCell>
                  <TableCell align="right">{translate("Last Sign In")}</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {users.map((user) => {
                  return (
                    <TableRow key={user.id} hover className={classes.tableRow}>
                      <TableCell component="th" scope="row">
                        <Box display="flex" flexDirection="row" alignItems="center">
                          <ProfileImage
                            src={user?.avatarURL}
                            initials={getUserInitials(user?.firstName, user?.lastName)}
                            fontSize={theme.spacing(2.5)}
                            size={theme.spacing(4)}
                            mirror={!!roles.find(e => e === Role.Client) && user?.avatarURL?.startsWith("https://firebasestorage.googleapis.com/")}
                          />
                          <Box ml={2}>
                            <Typography variant="h6">{getUserFullName(user?.firstName, user?.lastName)}</Typography>
                          </Box>
                        </Box>
                      </TableCell>
                      <TableCell align="right">{user?.email || ""}</TableCell>
                      <TableCell align="right">{user?.phoneNumber || ""}</TableCell>
                      <TableCell align="right">
                        {
                          user?.createdAt && safeInvoke('toDate')(user.createdAt) &&
                          formatDistanceToNow(safeInvoke('toDate')(user.createdAt), {addSuffix: true})
                        }
                      </TableCell>
                      <TableCell align="right">
                        {
                          user?.lastSignInTime && safeInvoke('toDate')(user.lastSignInTime) &&
                          formatDistanceToNow(safeInvoke('toDate')(user.lastSignInTime), {addSuffix: true})
                        }
                      </TableCell>
                    </TableRow>
                  )
                })}
              </TableBody>
            </Table>
          </TableContainer>
        </Fragment>
        }
      </Box>
    );
  };

export default UsersTable;

interface Props {
  roles: Role[];
  showRole: boolean;
  disableOrganizationFilter?: boolean;
}
