import React, {useEffect, useState} from 'react';
import Button from '@material-ui/core/Button';
import {Typography, Box, Grid} from '@material-ui/core';
import {Alert} from '@material-ui/lab';
import {FormProvider, useForm} from 'react-hook-form';
import {Resource} from "myfitworld-model";
import LanguageSelect from "../components/language/LanguageSelect";
import {UnpackNestedValue, UseFormMethods} from "react-hook-form/dist/types/form";
import {createStyles, makeStyles, Theme} from "@material-ui/core/styles";
import SimpleDialog from '../components/SimpleDialog';
import {useLocalizationProvider} from "../providers/LocalizationProvider";

export interface EntityFormProps<T> {
  entityName: string,
  entityCreateHelper?: string,
  handleClose: () => void,
  handleCreate?: (data: UnpackNestedValue<T>) => Promise<void>,
  handleUpdate?: (data: UnpackNestedValue<T>) => Promise<void>,
  defaultState?: T,
  mode: 'Create' | 'Edit',
  loading: boolean,
  formErrorMessage?: string,
  multiLang?: boolean,
  children: (props: UseFormMethods<T>) => any,
  disabled?: boolean,
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    sticky: {
      position: 'sticky',
      bottom: 0,
      backgroundColor: theme.palette.background.default,
      paddingTop: theme.spacing(3),
      paddingBottom: theme.spacing(3),
      zIndex: 1,
    },
    right: {
      position: 'absolute',
      right: 0,
    }
  }),
);

function EntityForm<T extends Resource>({
  entityName,
  entityCreateHelper,
  handleClose,
  handleCreate,
  handleUpdate,
  defaultState,
  mode,
  loading,
  formErrorMessage,
  multiLang = true,
  children,
  disabled,
}: EntityFormProps<T>) {
  const classes = useStyles();
  const {translate} = useLocalizationProvider();

  const [isOpenDialog, setIsOpenDialog] = useState(false);

  const formProps = useForm<T>(
    (mode === 'Edit' && defaultState !== undefined ?
      {defaultValues: defaultState} :
      {defaultValues: {}}) as any
  );

  useEffect(() => {
    if (defaultState !== undefined) {
      formProps.reset(defaultState as any)
    } else {
      formProps.reset();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [defaultState]);

  const onSubmit = async (data: UnpackNestedValue<T>) => {
    if (data) {
      if (mode === 'Edit') {
        handleUpdate && handleUpdate({...defaultState, ...data} as UnpackNestedValue<T>);
      } else if (mode === 'Create') {
        handleCreate && handleCreate(data);
      }
    }
  };

  return (
    <Box style={{flex: 1}}>
      <Box mb={2}>
        <Typography variant='h4' gutterBottom>
          {mode === 'Edit' ? `Edit ${entityName}` : `Create New ${entityName}`}
        </Typography>
        {
          mode === 'Create' && entityCreateHelper &&
          <Typography variant='body1' color="textSecondary">
            {entityCreateHelper}
          </Typography>
        }

        <Box my={2}>
          {multiLang && <LanguageSelect<T> watch={formProps.watch}/>}
        </Box>

        <FormProvider {...formProps}>
          <form>
            {children({...formProps})}
          </form>
        </FormProvider>
      </Box>

      <Box className={classes.sticky}>
        <Grid container spacing={2}>
          <Grid item>
            <Button
              color='primary' variant={formProps.formState.isDirty ? 'contained' : 'outlined'}
              onClick={formProps.handleSubmit(onSubmit)}
              disabled={loading || disabled}
            >
              {mode === 'Edit' ? 'Update' : 'Create'}
            </Button>
          </Grid>

          <Grid item>
            <Button color='secondary' onClick={handleClose} disabled={loading}>
              Cancel
            </Button>
          </Grid>

          {
            mode === 'Edit' && entityName === 'Exercise' &&
            <Grid item>
              {
                (defaultState as any)?.archived &&
                <Button variant="outlined" onClick={() => setIsOpenDialog(true)} disabled={disabled} className={classes.right}>
                  Unarchive
                </Button>
              }

              {
                !(defaultState as any)?.archived &&
                <Button variant="outlined" onClick={() => setIsOpenDialog(true)} disabled={disabled} className={classes.right}>
                  Archive
                </Button>
              }
              <SimpleDialog
                title={translate((defaultState as any)?.archived ?
                  'exercise.unarchive_exercise' : 'exercise.archive_exercise'
                )}
                text={translate((defaultState as any)?.archived ?
                  'exercise.unarchive_exercise_question' : 'exercise.archive_exercise_question'
                )}
                open={isOpenDialog}
                onClose={() => setIsOpenDialog(false)}
                onConfirm={() => {
                  setIsOpenDialog(false);
                  handleUpdate && handleUpdate({...defaultState, archived: !(defaultState as any)?.archived} as any);
                }}
                onConfirmLabel={translate('general.confirmed')}
                onCancel={() => setIsOpenDialog(false)}
                onCancelLabel={translate('general.rejected')}
              />
            </Grid>
          }
        </Grid>
        {
          formErrorMessage &&
          <Alert severity='error' style={{marginTop: 24}}>
            {formErrorMessage}
          </Alert>
        }
      </Box>
    </Box>
  );
}

export default EntityForm;
