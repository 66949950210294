import React, {createContext, PropsWithChildren, useContext, useEffect, useState} from 'react';
import memoize from 'lodash/memoize';
import i18n, {Scope, TranslateOptions} from 'i18n-js';

const translationGetters: Record<string, { isRTL: boolean, load: () => any }> = {
  // lazy requires
  // ar: { isRTL: true, load: () => require('../locales/ar.json') },
  en: {isRTL: false, load: () => require('../locales/en.json')},
  // fr: { isRTL: false, load: () => require('../locales/fr.json') },
  // hr: { isRTL: false, load: () => require('../locales/hr.json') },
  sr: {isRTL: false, load: () => require('../locales/sr.json')}
};

const translate = memoize(
  (key, config) => i18n.t(key, config),
  (key, config) => (config ? key + JSON.stringify(config) : key)
);

export const availableLocales = Object.keys(translationGetters);

type LocContext = {
  translate: (scope: Scope, options?: TranslateOptions) => string,
  selectLocale: (locale: string) => void,
  availableLocales: string[],
  selectedLocale: string
}

export const defaultValue = {
  translate: i18n.t,
  availableLocales: availableLocales,
  selectedLocale: 'en',
  selectLocale: (_: string) => {
  }
} as LocContext;

export const LocalizationContext = createContext<LocContext>(defaultValue);


const findBestMatchLocale = (localeString: string) => {
  return localeString.indexOf('-') > 0 ?
    Object.keys(translationGetters).find(item => item === localeString.substr(0, localeString.indexOf('-')))
    : Object.keys(translationGetters).find(item => item === localeString);
};

export const LocalizationProvider = ({children}: PropsWithChildren<any>) => {

  const [selectedLocale, setSelectedLocale] = useState<string>('en');

  useEffect(() => {
    setI18nConfig(navigator.language);
  }, []);

  const setI18nConfig = (selectedLocale: string) => {
    // fallback if no available language fits
    const languageTag =
      findBestMatchLocale(selectedLocale) ||
      'en';

    if (translate && translate.cache && translate.cache.clear) {
      // clear translation cache
      translate.cache.clear();
    }

    // set i18n-js config
    i18n.translations = {[languageTag]: translationGetters[languageTag].load()};
    i18n.locale = languageTag;

    console.log('set locale', i18n.locale, selectedLocale);

    setSelectedLocale(languageTag);
  };

  return <LocalizationContext.Provider
    value={{...defaultValue, selectedLocale: selectedLocale, selectLocale: locale => setI18nConfig(locale)}}>
    {children}
  </LocalizationContext.Provider>;
};

export const useLocalizationProvider = () => {
  const context = useContext(LocalizationContext);

  if (context === undefined) {
    throw new Error(
      'useLocalizationProvider must be used within a LocalizationProvider'
    );
  }
  return context;
};
