/* eslint-disable no-console */
/* eslint-disable import/prefer-default-export */
import merge from 'lodash.merge';
import {createMuiTheme, responsiveFontSizes} from '@material-ui/core';
import typography from './typography';
import {strongShadows} from './shadows';
import Color from "color";

export const THEMES = {
  MFW_DARK: 'MFW_DARK',
};

const swatches = {
  neonTeal: "#34d867",
  blue: "#006CFF",
  white: "#CDCDCD",
  darkGray: "#1B1B1B",
  gray: "#333333",
  lightGray: "#6b6b6b",
  orange: "#FF4800",
};

const themeConfigs = [
  {
    name: THEMES.MFW_DARK,
    palette: {
      type: 'dark',
      action: {
        active: 'rgba(255, 255, 255, 0.54)',
        selected: Color(swatches.neonTeal).alpha(0.65).string(),
        focus: 'rgba(255, 255, 255, 0.12)'
      },
      background: {
        default: '#242424',
        dark: '#000',
        light: swatches.gray,
      },
      primary: {
        main: swatches.neonTeal,
      },
      secondary: {
        main: swatches.blue,
      },
    },
    shadows: strongShadows
  },
];

const baseConfig = {
  direction: 'ltr',
  typography,
  overrides: {
    MuiAccordion: {
      root: {
        backgroundColor: themeConfigs[0].palette.background.light,
      }
    },
    MuiAccordionSummary: {
      content: {
        marginTop: 4,
        marginBottom: 4
      }
    },
    MuiTableRow: {
      hover: {
        cursor: 'pointer'
      }
    },
    MuiCard: {
      backgroundColor: themeConfigs[0].palette.background.light,
    },
    MuiLinearProgress: {
      root: {
        borderRadius: 3,
        overflow: 'hidden'
      }
    },
    MuiRating: {
      icon: {
        marginRight: 4,
      },
      iconFilled: {
        color: themeConfigs[0].palette.primary.main,
      },
    },
    MuiPaper: {
      root: {
        backgroundColor: themeConfigs[0].palette.background.default,
      }
    },
    MuiInputLabel: {
      filled: {
        opacity: .5,
      },
    }
  },
  props: {
    MuiButtonBase: {
      disableRipple: true, // No more ripple, on the whole application 💣!
    },
    MuiTextField: {
      variant: 'filled',
    },
    MuiTabs: {
      indicatorColor: 'primary',
    },
    MuiChip: {
      variant: 'outlined',
      size: 'small',
    },
  }
};

export const defaultSettings = {
  direction: 'ltr',
  responsiveFontSizes: true,
  theme: THEMES.MFW_DARK
};

export function createTheme(settings = defaultSettings) {
  let themeConfig = themeConfigs.find((theme) => theme.name === settings.theme);

  if (!themeConfig) {
    console.warn(new Error(`The theme ${settings.theme} is not valid`));
    [themeConfig] = themeConfigs;
  }

  let theme = createMuiTheme(
    merge(
      {},
      baseConfig,
      themeConfig,
      {direction: settings.direction}
    )
  );

  if (settings.responsiveFontSizes) {
    theme = responsiveFontSizes(theme);
  }

  return theme;
}
