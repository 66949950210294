import React, {useCallback, useState} from "react";
import Calendar from "../../../../components/calendar/Calendar";
import WorkoutEditDialog from "../WorkoutEditDialog";
import Workout, {
  WorkoutMin,
  WorkoutMinimal,
  WorkoutMinProperties
} from "myfitworld-model/dist/firestoreCollectionTypes/Workout";
import Exercise from "myfitworld-model/dist/firestoreCollectionTypes/Exercise";
import {Equipment} from "myfitworld-model/dist/firestoreCollectionTypes/Equipment";
import {UserProgram, WorkoutDay} from "myfitworld-model/dist/firestoreCollectionTypes/User";
import SimplePopupDialog from "../../../../components/SimplePopupDialog";
import {useMasterPopup} from "./useCalendarDialog";
import DateTimeEditDialog from "../DateTimeEditDialog";
import ContentSelectDialog from "../../../content/workout/ContentSelectDialog";
import cloneAndOmitProperties from "../../../../utils/cloneAndOmitProperties";
import unusedWorkoutProperties from "../unusedWorkoutProperties";
import ImportPersonalProgramSetupWizard from "../setupWizard/importProgramWizard";
import ProgramTemplate from "myfitworld-model/dist/firestoreCollectionTypes/ProgramTemplate";
import useNavigation from "../../../../hooks/useNavigation";
import NutritionalPlanDialog from "../NutritionalPlanDialog";
import UserWithCRUDInstructions, {UserInvitationWithCrudInstructions} from "../../../../api/UserWithCRUDInstructions";
import AgendaEvent from "./AgentaEvent";
import CalendarEvent from "./CalendarEvent";
import {saveWorkoutStatistics} from "../../../../api/statisticsApi";
import getShortModel from "../../../../utils/getShortModel";
import {getWorkoutMinimalExercisesCount} from "../../../../utils/getWorkoutExercisesCount";

const WorkoutOptions = [
  { label: "Edit Date and Time", value: "SCHEDULE" },
  { label: "Edit Workout", value: "EDIT" },
  { label: "Mark As Done", value: "MARK_AS_DONE" },
  { label: "Delete", value: "DELETE" },
];
const ClientCalendar = ({
  user,
  loadUser,
  archivedUser,
  events,
  resourceMap,
  workoutTemplates,
  programTemplates,
  exercises,
  equipment,
  program,
  onActiveProgramChange,
  onDeleteWorkout,
  onImportProgram,
  type,
}: {
  user: UserWithCRUDInstructions | UserInvitationWithCrudInstructions;
  loadUser?: () => Promise<any>;
  archivedUser?: boolean;
  events: any[];
  resourceMap: any[];
  workoutTemplates: Workout[];
  programTemplates: ProgramTemplate[];
  exercises: Exercise[];
  equipment: Equipment[];
  program: UserProgram;
  onActiveProgramChange: (p: UserProgram) => void;
  onDeleteWorkout: (workoutIndex: number) => void;
  onImportProgram: ({ startDate, programTemplate }: { startDate: string, programTemplate: string }) => void;
  type: 'users' | 'invitations';
}) => {
  const {
    masterPopupState: workoutEditDialogState,
    openMasterPopup: openWorkoutEditDialog,
    closeMasterPopup: closeWorkoutEditDialog,
  } = useMasterPopup<WorkoutMinimal>();
  const {
    masterPopupState: dateTimeEditDialogState,
    openMasterPopup: openDateTimeEditDialog,
    closeMasterPopup: closeDateTimeEditDialog,
  } = useMasterPopup<WorkoutDay>();
  const { masterPopupState, openMasterPopup, closeMasterPopup } = useMasterPopup<WorkoutDay>();
  const [ addWorkoutDialogOpen, toggleWorkoutDialogOpen ] = useState(false);
  const [ importProgramDialogOpen, toggleImportProgramDialogOpen ] = useState(false);
  const [ nutritionalInfoDialogOpen, toggleNutritionalInfoDialogOpen ] = useState(false);
  const { navigation } = useNavigation();


  const onMarkAsDoneWorkout = async (data: any, workoutIndex: number) => {
    const workout = data.workout;
    user?.id && await saveWorkoutStatistics(
      user?.id,
      {
        workoutId: data.id,
        startedAt: new Date(),
        workout: getShortModel<WorkoutMin>(WorkoutMinProperties, workout),
        finished: true,
        finishedAt: new Date(),
        markAsDone: true,
        exercisesDoneCount: getWorkoutMinimalExercisesCount(workout)
      },
      'INSERT'
    );

    const changed = { ...program };
    changed.workouts![workoutIndex] = {
      ...data,
      finished: true
    };
    onActiveProgramChange(changed);
  };

  const onAddWorkout = (template: Workout) => {
    const minimalWorkout = cloneAndOmitProperties<Workout, WorkoutMinimal>(template, unusedWorkoutProperties);
    const changed = { ...program };

    const defaultDate = new Date();
    defaultDate.setHours(9);
    defaultDate.setMinutes(0);
    defaultDate.setSeconds(0);

    changed.workouts?.push({
      dateTime: defaultDate.toJSON(),
      originalWorkoutId: template.id,
      workout: minimalWorkout,
    } as WorkoutDay);

    onActiveProgramChange(changed);

    // Close workout selection dialog and open up the Date/Time dialog.
    toggleWorkoutDialogOpen(false);
    if (changed.workouts) {
      openDateTimeEditDialog(changed.workouts[changed.workouts.length - 1], changed.workouts.length - 1);
    }
  };

  const onWorkoutDataChange = (workout: WorkoutMinimal) => {
    const index = workoutEditDialogState?.workoutIndex;
    if (workout && typeof index === "number" && index >= 0) {
      const changed = { ...program };
      changed.workouts![index].workout = workout;
      onActiveProgramChange(changed);
      closeWorkoutEditDialog();
    }
  };

  const onWorkoutDayChange = (day: WorkoutDay) => {
    const index = dateTimeEditDialogState?.workoutIndex;
    if (day && typeof index === "number" && index >= 0) {
      const changed = { ...program };
      changed.workouts![index] = day;
      onActiveProgramChange(changed);
      closeDateTimeEditDialog();
    }
  };

  const onSelectEvent = useCallback((workoutIndex: number) => {
    if (workoutIndex >= 0) {
      if (program.workouts![workoutIndex].finished) {
        if (!program.workouts![workoutIndex].id || !program.workouts![workoutIndex].userId) {
          console.error("Something went wrong in History CLIENTCALENDAR.TSX");
          return;
        }
        navigation(
          `/clients/${program.workouts![workoutIndex].userId}/statistics/${program.workouts![workoutIndex].id}`
        );
      } else {
        if (!archivedUser) {
          openMasterPopup(program.workouts![workoutIndex], workoutIndex);
        }
      }
    }
  }, [ navigation, openMasterPopup, program.workouts, archivedUser ]);

  return (
    <div style={{ width: "100%", height: "100%" }}>
      <Calendar
        archivedUser={archivedUser}
        events={events}
        resourceMap={resourceMap}
        onSelectEvent={onSelectEvent}
        options={[
          { label: 'Add Workout', action: () => toggleWorkoutDialogOpen(true) },
          { label: 'Add Program', action: () => toggleImportProgramDialogOpen(true) },
          { label: 'Nutritional plan', action: () => toggleNutritionalInfoDialogOpen(true) }
        ]}
        EventComponent={CalendarEvent}
        AgendaEventComponent={AgendaEvent(onSelectEvent)}
      />

      <NutritionalPlanDialog
        user={user}
        loadUser={loadUser}
        isOpen={nutritionalInfoDialogOpen}
        onClose={() => toggleNutritionalInfoDialogOpen(false)}
        updatePath={type}
      />

      <SimplePopupDialog
        options={masterPopupState.workout && masterPopupState.workout.finished ? [] : WorkoutOptions}
        open={masterPopupState.open}
        onClose={async (value) => {
          const workoutIndex = masterPopupState?.workoutIndex;
          if (masterPopupState.workout && typeof workoutIndex === "number" && workoutIndex >= 0) {
            if (value === "EDIT") {
              openWorkoutEditDialog(masterPopupState.workout.workout, workoutIndex);
            } else if (value === "SCHEDULE") {
              openDateTimeEditDialog(masterPopupState.workout, workoutIndex);
            } else if (value === "DELETE" && program.id) {
              onDeleteWorkout(workoutIndex);
            } else if (value === "MARK_AS_DONE") {
              await onMarkAsDoneWorkout(masterPopupState.workout, workoutIndex);
            }
            closeMasterPopup();
          } else {
            closeMasterPopup();
          }
        }}
      />

      {workoutEditDialogState.open && workoutEditDialogState.workout && (
        <WorkoutEditDialog
          open={true}
          workout={workoutEditDialogState.workout}
          exercises={exercises}
          equipment={equipment}
          handleClose={closeWorkoutEditDialog}
          handleSave={onWorkoutDataChange}
        />
      )}

      {dateTimeEditDialogState.open && dateTimeEditDialogState.workout && (
        <DateTimeEditDialog
          open={true}
          workoutDay={dateTimeEditDialogState.workout}
          handleClose={closeDateTimeEditDialog}
          handleSave={onWorkoutDayChange}
          showTime={program.defineExplicitTime}
        />
      )}

      <ContentSelectDialog
        isOpen={addWorkoutDialogOpen}
        contentList={workoutTemplates}
        onClose={(workoutId) => {
          const workout = workoutTemplates.find((w) => w.id === workoutId);
          if (workout) {
            onAddWorkout(workout);
          } else {
            toggleWorkoutDialogOpen(false);
          }
        }}
        type="Workout"
      />

      {importProgramDialogOpen &&
        <ImportPersonalProgramSetupWizard
          onCancel={() => toggleImportProgramDialogOpen(false)}
          programTemplates={programTemplates}
          onDone={data => {
            onImportProgram(data);
            toggleImportProgramDialogOpen(false);
          }}
        />}
    </div>
  );
};

export default ClientCalendar;
