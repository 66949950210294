import React, {useMemo, useState} from "react";
import {
  Box,
  Button,
  CircularProgress,
  Grid,
  IconButton,
  Tooltip,
  Typography,
  useTheme,
  withStyles
} from "@material-ui/core";
import UserWithCRUDInstructions from "../../../api/UserWithCRUDInstructions";
import ClientPersonalInformation from "./ClientPersonalInformation";
import getTrainingLevel from "../../../utils/getTrainingLevel";
import {useLocalizationProvider} from "../../../providers/LocalizationProvider";
import {getUserFullName} from "../../../utils/getUserFullName";
import ProfileImage from "../../../components/avatars/ProfileImage";
import {getUserInitials} from "../../../utils/getUserInitials";
import {GeneralEntity, Invitation, Role, User} from "myfitworld-model";
import SideDrawer from "../../../components/SideDrawer";
import EditIcon from '@material-ui/icons/Edit';
import EditProfileForm from "./EditProfileForm";
import {updateUserOrganizations} from "../../../api/usersApi";
import globalState, {GlobalState} from "../../../globalState";
import {useStoreState} from "pullstate";
import {decrementSubscriptionQuantity, incrementSubscriptionQuantity} from "../../../api/billingDetailsApi";
import useGlobalCachedResourceList from "../../../api/useGlobalCachedResourceList";
import buildKeyToNameMap from "../../../utils/buildKeyToNameMap";
import {useUserProvider} from "../../../providers/UserProvider";

const CustomTooltip = withStyles({
  tooltip: {
    fontSize: "1.2em"
  }
})(Tooltip);

const showClientPersonalInformationIfExists = (
  label: string,
  value: string | undefined | number,
  translatableValue = false,
  unit: string = ""
) => {
  return (
    <Grid item xs={4} md={3}>
      {
        value && unit ?
          <ClientPersonalInformation label={label} value={`${value} ${unit}`} translatableValue={translatableValue}/>
          : <ClientPersonalInformation label={label} value={value ? `${value}` : '-'}
                                       translatableValue={translatableValue}/>
      }
    </Grid>
  );
}

const getArchivedField = (user: UserWithCRUDInstructions, organizationID: string): boolean | undefined => {
  return user?.organizations?.find((orgUser) =>
    orgUser.id === organizationID && orgUser.role === Role.Client
  )?.archived;
}

const ClientProfile = ({user, loadUser, archivedUser, page}: Props) => {
  const {translate} = useLocalizationProvider();
  const theme = useTheme();
  const currentOrganization = useStoreState(globalState, s => s.currentOrganization);
  const {organizationOverride} = useUserProvider();
  const organizationID = currentOrganization?.id || organizationOverride;
  const {data: healthIssuesList} = useGlobalCachedResourceList<GeneralEntity>('healthIssuesCache');
  const {data: goalsList} = useGlobalCachedResourceList<GeneralEntity>('goalsCache');
  const [archiveLoading, setArchiveLoading] = useState<boolean>(false);

  const userHealthIssues = useMemo(() => {
    const healthIssues = buildKeyToNameMap(healthIssuesList);
    if (user?.fitnessProfile?.issues?.length === 0) {
      return "None";
    }
    return (user?.fitnessProfile?.issues?.map((issueId) => {
      return healthIssues[issueId];
    }) || []).join(', ');
  }, [healthIssuesList, user]);

  const userGoals = useMemo(() => {
    const goals = buildKeyToNameMap(goalsList);
    console.log(user?.fitnessProfile?.goals);
    if (user?.fitnessProfile?.goals?.length === 0) {
      return "None";
    }
    return (user?.fitnessProfile?.goals?.map((goalId) => {
      return goals[goalId];
    }) || []).join(', ');
  }, [goalsList, user]);

  const [openEditProfileForm, setEditProfileForm] = useState(false);
  const bmi = user.fitnessProfile?.weight && user.fitnessProfile?.height
  && user.fitnessProfile?.weight > 0 && user.fitnessProfile?.height ? (user.fitnessProfile?.weight / Math.pow(user.fitnessProfile?.height / 100, 2)).toFixed(1)
    : undefined;

  const toggleArchive = async () => {
    setArchiveLoading(true);
    organizationID && await updateUserOrganizations({
      ...(user as User),
      organizations: (user as User).organizations?.map(
        (orgUser) => {
          return orgUser.id === organizationID && orgUser.role === Role.Client
            ? {...orgUser, archived: !getArchivedField(user as User, organizationID)}
            : orgUser;
        }
      )
    });
    if (organizationID) {
      !getArchivedField(user as User, organizationID) ? await decrementSubscriptionQuantity(organizationID) : await incrementSubscriptionQuantity(organizationID);
    }
    globalState.update((state: GlobalState) => {
      state.toastQueue.push({message: `Changes saved!`, severity: "success"});
    });
    loadUser && await loadUser();
    setArchiveLoading(false);
  };

  return (
    <Grid item container spacing={3}>
      <Grid item xs={12} md={4} style={{display: "flex", alignItems: "center"}}>
        <Box display="flex" alignItems="center">
          <ProfileImage
            src={'avatarURL' in user ? user.avatarURL : null}
            initials={getUserInitials(user.firstName, user.lastName)}
            fontSize={60}
            size={120}
            mirror={'avatarURL' in user ? user?.avatarURL?.startsWith("https://firebasestorage.googleapis.com/") : false}
          />
          <Box ml={2}>
            <Typography variant="h5">{getUserFullName(user.firstName, user.lastName)}</Typography>
            <Typography variant="body2" color="textSecondary">{user.email}</Typography>
            <Typography variant="body2" color="textSecondary">{user.phoneNumber}</Typography>
            {page === "invitations" && <Typography variant="body2" style={{opacity: 0.7}}>
              {translate('invitation.invitation_id')}: {user.id}
            </Typography>}
            {!archivedUser && page === "users" && <CustomTooltip title="If you archive the client you will be able to reactivate him. By archiving a client, you reduce the number of clients on your subscription plan by one." arrow>
              <Button size="small" variant="outlined" onClick={async () => {
                await toggleArchive();
              }}>
                {archiveLoading && <CircularProgress size="1rem"/>} {" ARCHIVE CLIENT"}
              </Button>
            </CustomTooltip>}
          </Box>
        </Box>
      </Grid>
      <Grid item xs={12} md={8} container>
        <Box mb={2}>
          <Typography variant="h5">
            {translate('client.personal_info')}
            {!archivedUser &&
            <IconButton
              style={{padding: theme.spacing(0.5), marginLeft: theme.spacing(0.5)}}
              onClick={() => setEditProfileForm(true)}
            >
              <EditIcon fontSize="small"/>
            </IconButton>
            }
          </Typography>
        </Box>
        <Grid container spacing={2}>
          {showClientPersonalInformationIfExists("Age", user.fitnessProfile?.age || '–')}
          {showClientPersonalInformationIfExists(
            "Gender",
            user.fitnessProfile?.gender ? `gender.${user.fitnessProfile?.gender}` : '–',
            true
          )}
          {showClientPersonalInformationIfExists("BMI", bmi, false, `kg/\u33A1`)}
          {showClientPersonalInformationIfExists("Height", user.fitnessProfile?.height, false, "cm")}
          {showClientPersonalInformationIfExists("Weight", user.fitnessProfile?.weight, false, "kg")}
          {showClientPersonalInformationIfExists("Training Level", getTrainingLevel(user.fitnessProfile?.level), true)}
          {showClientPersonalInformationIfExists("Health Issues", userHealthIssues, false)}
          {showClientPersonalInformationIfExists("Goals", userGoals, false)}
        </Grid>
      </Grid>
      <SideDrawer isOpen={openEditProfileForm} onClose={() => setEditProfileForm(false)}>
        <EditProfileForm user={user} loadUser={loadUser} onClose={() => setEditProfileForm(false)} page={page}/>
      </SideDrawer>
    </Grid>
  );
};

export default ClientProfile;

interface Props {
  user: UserWithCRUDInstructions | Invitation;
  loadUser: () => Promise<any>;
  archivedUser?: boolean;
  page: 'users' | 'invitations';
}
