import {Typography} from '@material-ui/core';
import InfoOutlinedIcon from '@material-ui/icons/InfoOutlined';
import React from 'react';

export default (props: { children: any }) =>
  <Typography variant='body2' style={{ opacity: 0.8 }}>
    <InfoOutlinedIcon style={{
      fontSize: '16px',
      marginRight: '4px',
      verticalAlign: 'sub'
    }} />
    {props.children}
  </Typography>
