import React, {Fragment, useEffect, useMemo} from "react";
import {Redirect, Router} from "@reach/router";
import AppLayout from "./components/AppLayout";
import ContentManagementPage from "./pages/content/ContentManagementPage";
import {Box, CircularProgress, createStyles, CssBaseline, Theme} from "@material-ui/core";
import {makeStyles} from "@material-ui/core/styles";
import Snackbar from "./components/snackbar/Snackbar";
import TopBarLoadingIndicator from "./components/TopBarLoadingIndicator";
import LoginContainer from "./pages/auth/LoginContainer";
import {LanguageProvider} from "./components/language/LanguageProvider";
import ConfigurationManagementPage from "./pages/configuration/ConfigurationManagementPage";
import Role from "myfitworld-model/dist/enums/Role";
import OrganizationsManagementPage from "./pages/organizations/OrganizationsManagementPage";
import ClientManagementPage from "./pages/clients/ClientManagementPage";
import {OrganizationProvider} from "./providers/OrganizationProvider";
import MyOrganizationPage from "./pages/organization/MyOrganizationPage";
import InvitationPage from "./pages/auth/InvitationPage";
import ClientFormContainerPage from "./pages/clients/ClientFormContainerPage";
import ClientLandingPage from "./pages/ClientLandingPage";
import {auth} from "./firebase";
import SuperAdminUserManagementPage from "./pages/users/SuperAdminUserManagementPage";
import SchedulePage from "./pages/schedule/SchedulePage";
import {useUserProvider} from "./providers/UserProvider";
import {getCurrentUserRole} from "./api/usersApi";
import ClientStatisticPage from "./pages/clients/ClientStatisticPage";
import ExerciseProvider from "./providers/ExerciseProvider";
import InvitationFormContainerPage from "./pages/clients/InvitationFormContainerPage";
import PublicProfilePage from "./pages/profile/PublicProfilePage";
import EditProfilePage from "./pages/profile/EditProfilePage";
import InvitationInfoPage from "./pages/InvitationInfoPage";
import {OrganizationsProvider} from "./providers/OrganizationsProvider";
import {Organization} from "myfitworld-model";
import {useStoreState} from "pullstate";
import globalState from "./globalState";
import BillingPage from "./pages/billing/BillingPage";
import BillingDetailsPage from "./pages/billing/BillingDetailsPage";
import TrainerOrganizationLoginPage from "./pages/auth/TrainerOrganizationLoginPage";

const useGlobalStyles = makeStyles(() =>
  createStyles({
    "@global": {
      "*": {
        boxSizing: "border-box",
        margin: 0,
        padding: 0,
      },
      html: {
        "-webkit-font-smoothing": "antialiased",
        "-moz-osx-font-smoothing": "grayscale",
        height: "100%",
        width: "100%",
      },
      body: {
        width: "100%",
      },
      "#root": {
        height: "100%",
        width: "100%",
      },
    },
  })
);

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    loadingSplashScreen: {
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
    },
    root: {
      backgroundColor: theme.palette.background.default,
      width: '100%',
    },
  })
);

declare interface Route {
  path: string;
  Component: React.ElementType;
  condition?: (org?: Organization) => void;
}

const superAdminRoutes = [
  {
    path: "/organizations",
    Component: OrganizationsManagementPage,
  },
  {
    path: "/users",
    Component: SuperAdminUserManagementPage,
  },
];

const adminRoutes = [
  {
    path: "/schedule",
    Component: SchedulePage,
  },
  {
    path: "/content",
    Component: ContentManagementPage,
  },
  {
    path: "/configuration",
    Component: ConfigurationManagementPage,
  },
  {
    path: "/organization",
    Component: MyOrganizationPage,
  },
  {
    path: "/clients",
    Component: ClientManagementPage,
  },
  {
    path: "/profile",
    Component: EditProfilePage,
  },
  {
    path: "/billing",
    Component: BillingPage,
  },
];

const trainerRoutes = [
  {
    path: "/schedule",
    Component: SchedulePage,
  },
  {
    path: "/content",
    Component: ContentManagementPage,
  },
  {
    path: "/clients",
    Component: ClientManagementPage,
  },
  {
    path: "/profile",
    Component: EditProfilePage,
  },
];

const routesFor = (role?: Role, currentOrganization?: Organization): Array<Route> => {
  let routes: Route[] = [];
  if (role === Role.SuperAdmin) {
    routes = superAdminRoutes;
  } else if (role === Role.Admin) {
    routes = adminRoutes;
  } else if (role === Role.Trainer || role === Role.AssistantTrainer) {
    routes = trainerRoutes;
  }

  return routes.filter(r => {
    if (r.condition) {
      return r.condition(currentOrganization);
    }
    return true;
  });
};

const getRedirectRoute = (role: Role, organizationOverride: string | undefined) => {
  if (organizationOverride) {
    return "/organization";
  } else if (role === Role.SuperAdmin) {
    return "/organizations";
  } else if (role === Role.Trainer || role === Role.AssistantTrainer || role === Role.Admin) {
    return "/schedule";
  } else {
    return "/content";
  }
};

function App() {
  useGlobalStyles();
  const classes = useStyles();
  const {user, organizationOverride, loading, enterApplication} = useUserProvider();
  const currentOrganization = useStoreState(globalState, s => s.currentOrganization);

  const currentUserRole = useMemo(() => {
    return user ? getCurrentUserRole(user, organizationOverride) : undefined;
  }, [user, organizationOverride]);

  useEffect(() => {
    if (window.location.pathname === "/signout") {
      auth.signOut();
    }
  }, []);

  if (loading) {
    return (
      <Box flex={1} className={`${classes.root} ${classes.loadingSplashScreen}`}>
        <CircularProgress/>
      </Box>
    );
  }

  return (
    <Fragment>
      <CssBaseline/>
      <Box flex={1} className={classes.root}>
        <Snackbar/>
        <TopBarLoadingIndicator/>
        <LanguageProvider>
          {user ? (
            currentUserRole === Role.Client ? (
              <Router>
                <PublicProfilePage path="/t/:myUrl"/>
                <ClientLandingPage path="*"/>
              </Router>
            ) : (
              <OrganizationsProvider>
                <OrganizationProvider>
                  <ExerciseProvider>
                    {currentUserRole? enterApplication ? (
                        <AppLayout>
                          <Router>
                            {routesFor(currentUserRole, currentOrganization).map(({path, Component}: Route, i: number) => (
                              <Component key={i} path={path}/>
                            ))}
                            <InvitationPage path="/i/:invitationCode"/>
                            <ClientFormContainerPage path="/clients/:userId"/>
                            <InvitationFormContainerPage path="/invitations/:invitationId"/>
                            <ClientStatisticPage path="/clients/:userId/statistics/:workoutId"/>
                            <PublicProfilePage path="/t/:myUrl"/>
                            <Redirect from="*" to={getRedirectRoute(currentUserRole, organizationOverride)} noThrow/>
                          </Router>
                        </AppLayout>
                      ) : (
                        <Router>
                          <BillingDetailsPage path="*" />
                        </Router>
                      ):
                      <Router>
                        <InvitationPage path="/i/:invitationCode"/>
                        <InvitationInfoPage path="*"/>
                      </Router>
                    }
                  </ExerciseProvider>
                </OrganizationProvider>
              </OrganizationsProvider>
            )
          ) : (
            <Router>
              <InvitationPage path="/i/:invitationCode"/>
              <LoginContainer path="*"/>
              <TrainerOrganizationLoginPage path="/organizations/login"/>
              <PublicProfilePage path="/t/:myUrl"/>
            </Router>
          )}
        </LanguageProvider>
      </Box>
    </Fragment>
  );
}

export default App;
