import React, {useCallback, useContext, useEffect, useState} from 'react';
import {Link} from '@reach/router';
import getTrainerSchedules, {ClientSchedulesResponse} from '../../api/getTrainerSchedules';
import UserContext from "../../contexts/UserContext";
import get from "lodash/get";
import LoadingSpinner from "../../components/LoadingSpinner";
import {Alert} from "@material-ui/lab";
import ScheduleList from "./ScheduleList";
import {firestore} from "../../firebase";
import {WorkoutDay} from "myfitworld-model";
import {WorkoutMin, WorkoutMinimal, WorkoutMinProperties} from "myfitworld-model/dist/firestoreCollectionTypes/Workout";
import {saveWorkoutStatistics} from "../../api/statisticsApi";
import getShortModel from "../../utils/getShortModel";
import {getWorkoutMinimalExercisesCount} from "../../utils/getWorkoutExercisesCount";

const SchedulePage = () => {
  const userContext = useContext(UserContext);
  const trainerId = get(userContext, 'user.id');

  const [loading, toggleLoading] = useState(false);
  const [result, setResult] = useState<ClientSchedulesResponse>();

  const load = useCallback(async () => {
    const res = await getTrainerSchedules(trainerId);
    setResult(res);
  }, [trainerId]);

  useEffect(() => {
    (async () => {
      toggleLoading(true);
      await load();
      toggleLoading(false);
    })();
  }, [load]);

  const onMarkAsDoneWorkout = async (data: WorkoutDay, clientId: string) => {
    toggleLoading(true);
    const workout = data.workout;
    await saveWorkoutStatistics(
      clientId,
      {
        workoutId: data.id,
        startedAt: new Date(),
        workout: getShortModel<WorkoutMin>(WorkoutMinProperties, workout),
        finished: true,
        finishedAt: new Date(),
        markAsDone: true,
        exercisesDoneCount: getWorkoutMinimalExercisesCount(workout)
      },
      'INSERT'
    );

    setTimeout(async () => {
      await load();
      toggleLoading(false);
    }, 5000);
  }

  const onDeleteWorkout = (workout: WorkoutDay, clientId: string) => {
    firestore.collection(`users/${clientId}/assignedPrograms/${workout.parentId}/assignedWorkouts/`).doc(workout.id).delete().then(async () => {
      await load();
    }).catch(e => console.log(e));
  }

  const onEditWorkout = (workout: WorkoutDay, clientId: string, data: WorkoutMinimal) => {
    firestore.collection(`users/${clientId}/assignedPrograms/${workout.parentId}/assignedWorkouts/`).doc(workout.id).update({
      workout: data
    }).then(async () => {
      await load();
    }).catch(e => console.log(e));
  }

  const onEditWorkoutDateAndTime = (workout: WorkoutDay, clientId: string, data: WorkoutDay) => {
    firestore.collection(`users/${clientId}/assignedPrograms/${workout.parentId}/assignedWorkouts/`).doc(workout.id).update({
      dateTime: new Date(data.dateTime)
    })
      .then(async () => {
        await load();
      }).catch(e => console.log(e));
  }

  if (loading || result === undefined) {
    return (
      <div>
        <LoadingSpinner/>
      </div>
    )
  }

  if (result?.error) {
    return (
      <Alert severity='error' style={{margin: '24px auto 0 auto', maxWidth: 520, padding: 24}}>
        There was an error loading your schedule. Please refresh the page. If the problem persists, please contact
        support.
      </Alert>
    );
  }

  if (result?.clientCount === 0) {
    return (
      <Alert severity='info' style={{margin: '24px auto 0 auto', maxWidth: 520, padding: 24}}>
        Your account is setup, but you don’t have any clients, yet! Go to the <Link to='/clients' style={{
        color: 'white',
        fontWeight: 'bold'
      }}>clients section</Link> to invite and manage your clients.
      </Alert>
    );
  }

  if (result?.workouts.length === 0) {
    return (
      <Alert severity='info' style={{margin: '24px auto 0 auto', maxWidth: 520, padding: 24}}>
        All done for now? Currently, none of you clients have any upcoming workouts. Head over to the <Link
        to='/clients' style={{color: 'white', fontWeight: 'bold'}}>clients section</Link> to manage their workout
        program.
      </Alert>
    );
  }

  if (result && result.clientCount > 0 && result.workouts.length > 0 && result.clients) {
    return <ScheduleList
      clients={result.clients}
      workouts={result.workouts}
      onMarkAsDoneWorkout={onMarkAsDoneWorkout}
      onDeleteWorkout={onDeleteWorkout}
      onEditWorkout={onEditWorkout}
      onEditWorkoutDateAndTime={onEditWorkoutDateAndTime}
    />;
  }

  return null;
};

export default SchedulePage;
