import React, {CSSProperties, useMemo} from "react";
import {Box, Chip, Typography} from "@material-ui/core";
import {Workout} from "myfitworld-model";
import getDescriptionSnippet from "../../../utils/getDescriptionSnippet";
import MFWChip from "../../../components/MFWChip";
import Hidden from "@material-ui/core/Hidden";
import getLocalizedProperty from "../../../utils/getLocalizedProperty";
import BaseContentListItem, {baseContentListStyles} from "../BaseContentListItem";
import Image from "material-ui-image";
import thumbPlaceholder from '../../../assets/images/thumb_placeholder.png';
import getImageThumbnail from "../../../utils/getImageThumbnail";
import Exercise from "myfitworld-model/dist/firestoreCollectionTypes/Exercise";
import {getWorkoutExercisesCount} from "../../../utils/getWorkoutExercisesCount";
import {getWorkoutEquipment} from "../../../utils/getWorkoutEquipment";

const WorkoutListItem = (
  {item, onSelect, equipment, exercises, hideTags, style}:
    {
      item: Workout;
      onSelect: (event: React.MouseEvent) => void,
      exercises?: Array<Exercise>,
      equipment?: Record<string, string>,
      hideTags?: boolean,
      style?: CSSProperties
    }) => {
  const tags = useMemo(() => {
    if (hideTags) {
      return [];
    }

    const equipmentIds = getWorkoutEquipment(item, exercises);
    return equipment ? [
      ...(equipmentIds.map(k => equipment[k]) || [])
    ] : [];
  }, [hideTags, equipment, exercises, item]);
  const baseContentListClasses = baseContentListStyles();

  return (
    <BaseContentListItem onSelect={onSelect} style={style}>
      <Box display="flex" justifyContent="space-between" flex={1}>
        <Box display="flex" flexDirection="row" alignItems="center">
          <Box className={baseContentListClasses.thumbnail}>
            <Image
              src={item.thumbnailURL ? getImageThumbnail((item as any).thumbURLS || [])?.url || item.thumbnailURL : thumbPlaceholder}
              aspectRatio={(16 / 9)}
              color="transparent"
              className={baseContentListClasses.image}
              alt={getLocalizedProperty(item.title)}
              disableSpinner
            />
          </Box>
          <Box>
            <MFWChip organizationId={item.organizationId}/>
            <Typography variant="h5">{getLocalizedProperty(item.title)}</Typography>
            <Typography variant="h6" color="textSecondary">
              {`${getWorkoutExercisesCount(item)}`}
            </Typography>
            <Hidden mdDown>
              <Typography variant="body2" color="textSecondary">
                {item.description && getDescriptionSnippet(getLocalizedProperty(item.description))}
              </Typography>
            </Hidden>
            <Box className={baseContentListClasses.chips} display="flex" flexWrap="nowrap">
              {tags.map((tag, i) =>
                <Box key={i} m={.25}>
                  <Chip size='small' label={tag} variant="outlined"/>
                </Box>
              )}
            </Box>
          </Box>
        </Box>
        <Box>
          {item.difficultyLevel !== undefined &&
          <Box display="flex" flexDirection="row" ml={10}>
            <Typography noWrap variant='h2' color="primary">
              {`${item?.difficultyLevel}`}
            </Typography> &nbsp; &nbsp;
            <Typography noWrap variant='h2'>
              {`/ 5`}
            </Typography>
          </Box>
          }
        </Box>
      </Box>
    </BaseContentListItem>
  );
};

export default WorkoutListItem;
