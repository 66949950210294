import React, {useCallback, useRef, useState} from "react";
import {
  Box, ClickAwayListener,
  createStyles, debounce, Grow,
  IconButton,
  InputAdornment, ListItemIcon, ListItemText, MenuItem, MenuList, Paper, Popper,
  Theme, Tooltip,
  Typography
} from "@material-ui/core";
import {useLocalizationProvider} from "../../../providers/LocalizationProvider";
import TextField from "@material-ui/core/TextField";
import {makeStyles} from "@material-ui/core/styles";
import {User} from "myfitworld-model";
import AddCircleOutlineIcon from '@material-ui/icons/AddCircleOutline';
import DeleteIcon from '@material-ui/icons/Delete';
import {SocialMediaMetaData} from "../../../utils/SocialMediaMetaData";
import CopyButton from "../../../components/CopyButton";
import {getUserProfileByMyUrl} from "../../../api/profileApi";

export const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    url: {
      marginBottom: theme.spacing(2),
    },
    button: {
      marginTop: theme.spacing(1),
    },
    iconButton: {
      padding: theme.spacing(0.5),
    },
    add: {
      marginRight: theme.spacing(1),
    }
  })
);

export const handleDelayedAPIRequest = debounce((value, func) => func(value), 3000);

const EditSocialMediaLinks = ({userProfile, setUserProfile, toggleChanged, disabled, mobile}: Props) => {
  const classes = useStyles();
  const {translate} = useLocalizationProvider();

  const textFieldRef = useRef(null);

  const anchorRef = useRef(null);
  const [unique, setUnique] = useState<boolean>(true);
  const [disabledInput, toggleDisabledInput] = useState<boolean>(false);
  const [message, setMessage] = useState<string>("");

  const onChangeMyUrl = useCallback((e: any) => {
    toggleChanged(false);
    setMessage(translate("messages.searching"));
    const {name, value} = e.target;
    setUserProfile({...userProfile, [name]: value});

    handleDelayedAPIRequest(value, (value: string) => {
      toggleDisabledInput(true);
      getUserProfileByMyUrl(value).then((result)=> {
        if (value === "" || (result.data && result.data.id !== userProfile.id)) {
          setUnique(false);
          toggleChanged(false);
          setMessage(translate("messages.unique_url_error"));
        } else {
          setUnique(true);
          toggleChanged(true);
          setMessage(translate("messages.unique_url_success"));
        }
      }).catch(() => toggleChanged(false))
        .finally(() => toggleDisabledInput(false));
    });
  }, [userProfile, setUserProfile, toggleChanged, translate]);

  const [isOpenSelect, toggleIsOpenSelect] = useState<boolean>(false);

  const onSelectSocialMediaLink = useCallback(
    (e, type) => {
      const link = userProfile.socialMediaLinks?.find((link) => link.type === type);
      if (!link) {
        setUserProfile({
          ...userProfile,
          socialMediaLinks: [...(userProfile.socialMediaLinks || []), {type: type, url: ''}]
        });
        toggleChanged(true);
      }
    },
    [userProfile, setUserProfile, toggleChanged]
  );

  const onChangeSocialMediaLink = useCallback(
    async (e) => {
      const {name, value} = e.target;
      const link = userProfile.socialMediaLinks?.find((link) => link.type === name);
      link ? setUserProfile({
          ...userProfile,
          socialMediaLinks: (userProfile.socialMediaLinks || []).map((link) => (link.type === name ? {
            type: name,
            url: value
          } : link))
        })
        : setUserProfile({
          ...userProfile,
          socialMediaLinks: [...(userProfile.socialMediaLinks || []), {type: name, url: value}]
        });
      toggleChanged(true);
    },
    [userProfile, setUserProfile, toggleChanged]
  );

  const onDeleteSocialMediaLink = useCallback(
    async (e, type) => {
      setUserProfile({
        ...userProfile,
        socialMediaLinks: (userProfile.socialMediaLinks || []).filter((link) => link.type !== type)
      });
      toggleChanged(true);
    },
    [userProfile, setUserProfile, toggleChanged]
  );

  return (
    <Box display="flex" flexDirection="column" flex={2} m={2}>
      <Box className={classes.url}>
        <Typography>{translate('trainer_profile.url')}</Typography>
        <TextField
          name="myUrl"
          value={userProfile.myUrl ?? ''}
          onChange={(e) => onChangeMyUrl(e)}
          type="text"
          variant="outlined"
          margin="dense"
          autoFocus
          style={{width: `${mobile ? '100%' : '40%'}`, textAlign: "center"}}
          inputRef={textFieldRef}
          InputProps={{
            startAdornment: <InputAdornment position="start" style={{paddingRight: 0, marginRight: 0}}>{`${window.location.host}/t/`}</InputAdornment>,
            endAdornment: <InputAdornment position="end">
              <CopyButton textFieldRef={textFieldRef} startText={`${window.location.host}/t/`}/>
            </InputAdornment>
          }}
          disabled={disabled || disabledInput}
          error={!unique}
          helperText={message}
        />
      </Box>

      <Box display="flex" flexDirection="row" alignItems="center" style={{width: `${mobile ? '100%' : '70%'}`}}>
        <Typography className={classes.add}>{translate("trainer_profile.social_media")}</Typography>
        <Tooltip title="Add social media link" arrow>
          <IconButton ref={anchorRef} className={classes.iconButton} onClick={() => toggleIsOpenSelect(true)} disabled={disabled}>
            <AddCircleOutlineIcon/>
          </IconButton>
        </Tooltip>
        <Popper open={isOpenSelect} anchorEl={anchorRef.current} transition style={{zIndex: 10}}>
          {({TransitionProps, placement}) => (
            <Grow
              {...TransitionProps}
              style={{
                transformOrigin: placement === 'bottom' ? 'center top' : 'center bottom',
              }}
            >
              <Paper>
                <ClickAwayListener onClickAway={() => toggleIsOpenSelect(false)}>
                  <MenuList>
                    {SocialMediaMetaData().map((item, index) => (
                      <MenuItem value={item.type} key={index} onClick={(e) => onSelectSocialMediaLink(e, item.type)}>
                        <ListItemIcon>
                          {item.icon}
                        </ListItemIcon>
                        <ListItemText primary={item.name}/>
                      </MenuItem>
                    ))}
                  </MenuList>
                </ClickAwayListener>
              </Paper>
            </Grow>
          )}
        </Popper>
      </Box>

      {
        userProfile.socialMediaLinks && userProfile.socialMediaLinks.map((item, index) => (
          <Box display="flex" flexDirection="row" alignItems="center" key={index}>
            <TextField
              name={item.type}
              label={translate(`trainer_profile.${item.type}`)}
              value={item.url ?? ''}
              onChange={(e) => onChangeSocialMediaLink(e)}
              disabled={disabled}
              type="text"
              variant="outlined"
              margin="dense"
              style={{width: `${mobile ? '100%' : '70%'}`}}
              autoFocus
              InputProps={{
                startAdornment:
                  <InputAdornment position="start">
                    {SocialMediaMetaData().find((link) => link.type === item.type)?.icon}
                  </InputAdornment>
              }}
            />
            <Box flex={1} m={1}>
              <Tooltip title="Delete link" arrow>
                <IconButton
                  onClick={e => onDeleteSocialMediaLink(e, item.type)}
                  disabled={disabled}
                  className={classes.iconButton}
                >
                  <DeleteIcon/>
                </IconButton>
              </Tooltip>
            </Box>
          </Box>
        ))
      }
    </Box>
  );
};

export default EditSocialMediaLinks;

interface Props {
  userProfile: User;
  setUserProfile: (value: User) => void;
  toggleChanged: (value: boolean) => void;
  disabled: boolean;
  mobile: boolean;
}
