import isMonday from "date-fns/fp/isMonday";
import startOfWeek from "date-fns/startOfWeek";
import addDays from "date-fns/fp/addDays";

const getFirstMonday = (): Date => {
  const today = new Date();
  if (isMonday(today)) {
    return today;
  }
  return startOfWeek(addDays(6)(today), { weekStartsOn: 1 });
};

export default getFirstMonday;
