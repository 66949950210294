import MaskedInput, {MaskedInputProps} from "react-text-mask";
import React, {FC} from "react";

interface TextMaskProps extends MaskedInputProps {
  inputRef: (ref: HTMLInputElement | null) => void;
}

const TextMaskExpiration: FC<TextMaskProps> = ({ inputRef, ...other }) => {
  return (
    <MaskedInput
      {...other}
      ref={(ref: any) => {
        inputRef(ref ? ref.inputElement : null);
      }}
      showMask={true}
      mask={[/[0-5]/, /\d/, ':', /[0-5]/, /\d/]}
      placeholderChar={'0'}
    />
  );
};

export default TextMaskExpiration;
