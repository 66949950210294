import React from "react";
import get from "lodash/get";
import { Enumerable } from "myfitworld-model";
import { Autocomplete } from "@material-ui/lab";
import TextField from "@material-ui/core/TextField/TextField";
import i18n from "i18n-js";

const AutoselectField = ({
  label,
  options,
  onChange,
  disabled = false,
}: {
  label: string;
  options: Array<Enumerable>;
  value?: Array<string>;
  onChange: (value: any) => void;
  disabled?: boolean;
}) => {
  return (
    <Autocomplete
      options={options}
      getOptionLabel={(option) => get(option, `name.${i18n.locale}`)}
      onChange={(event, newInputValue) => {
        onChange(newInputValue?.id);
      }}
      disabled={disabled}
      renderInput={(params) => <TextField {...params} label={label} value={(option: any) => get(option, "id")} />}
    />
  );
};

export default AutoselectField;
