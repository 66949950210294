import {WorkoutDay} from "myfitworld-model";
import {ClientInfo} from "./buildUserInfoMap";
import {useMasterPopup} from "../clients/form/clientCalendar/useCalendarDialog";
import {Box, Grid, Typography} from "@material-ui/core";
import WorkoutCard from "./WorkoutCard";
import SimplePopupDialog from "../../components/SimplePopupDialog";
import React from "react";
import WorkoutEditDialog from "../clients/form/WorkoutEditDialog";
import DateTimeEditDialog from "../clients/form/DateTimeEditDialog";
import {useExerciseProvider} from "../../providers/ExerciseProvider";
import useGlobalCachedResourceList from "../../api/useGlobalCachedResourceList";
import {Equipment} from "myfitworld-model/dist/firestoreCollectionTypes/Equipment";
import {WorkoutMinimal} from "myfitworld-model/dist/firestoreCollectionTypes/Workout";

const WorkoutOptions = [
  { label: "Edit Date and Time", value: "SCHEDULE" },
  { label: "Edit Workout", value: "EDIT" },
  { label: "Mark As Done", value: "MARK_AS_DONE" },
  { label: "Delete", value: "DELETE" },
];

const WorkoutsSection = ({
  title, subTitle, workouts, onMarkAsDoneWorkout, onDeleteWorkout, onEditWorkout, onEditWorkoutDateAndTime, clientInfo
} : Props) => {
  const {exercises} = useExerciseProvider();
  const {data: equipment} = useGlobalCachedResourceList<Equipment>('equipmentCache');

  const { masterPopupState, openMasterPopup, closeMasterPopup } = useMasterPopup<WorkoutDay>();
  const {
    masterPopupState: dateTimeEditDialogState,
    openMasterPopup: openDateTimeEditDialog,
    closeMasterPopup: closeDateTimeEditDialog,
  } = useMasterPopup<WorkoutDay>();
  const {
    masterPopupState: workoutEditDialogState,
    openMasterPopup: openWorkoutEditDialog,
    closeMasterPopup: closeWorkoutEditDialog,
  } = useMasterPopup<WorkoutDay>();

  return (
    <Box mt={6}>
      <Box px={2}>
        <Grid container direction="row" alignItems="center" spacing={2}>
          <Grid item><Typography variant='h4' color="primary">{title}</Typography></Grid>
          <Grid item><Typography variant='h5' color="textSecondary">{subTitle}</Typography></Grid>
        </Grid>
        {workouts.map((workout, key) =>
          <WorkoutCard
            clientInfo={clientInfo[workout.userId || '']}
            clientId={workout.userId}
            workout={workout}
            editWorkout={openMasterPopup}
            key={key}
          />
        )}
      </Box>
      <SimplePopupDialog
        options={masterPopupState.workout && masterPopupState.workout.finished ? [] : WorkoutOptions}
        open={masterPopupState.open}
        onClose={(value) => {
          const workoutIndex = masterPopupState?.workoutIndex;
          if (masterPopupState.workout) {
            if (value === "EDIT") {
              openWorkoutEditDialog(masterPopupState.workout, workoutIndex);
            } else if (value === "SCHEDULE") {
              openDateTimeEditDialog(masterPopupState.workout, workoutIndex);
            } else if (value === "DELETE") {
              onDeleteWorkout(masterPopupState.workout, masterPopupState.workoutIndex);
              closeMasterPopup();
            } else if (value === "MARK_AS_DONE") {
              onMarkAsDoneWorkout(masterPopupState.workout, masterPopupState.workoutIndex);
              closeMasterPopup();
            } else {
              closeMasterPopup();
            }
          } else {
            closeMasterPopup();
          }
        }}
      />
      {dateTimeEditDialogState.open && dateTimeEditDialogState.workout && (
        <DateTimeEditDialog
          open={true}
          showTime={true}
          workoutDay={dateTimeEditDialogState.workout}
          handleSave={(data) => {
            masterPopupState.workout && onEditWorkoutDateAndTime(masterPopupState.workout, masterPopupState.workoutIndex, data);
            closeMasterPopup();
          }}
          handleClose={closeDateTimeEditDialog}
        />
      )}

      {workoutEditDialogState.open && workoutEditDialogState.workout && (
        <WorkoutEditDialog
          open={true}
          workout={workoutEditDialogState.workout.workout}
          exercises={exercises || []}
          equipment={equipment}
          handleSave={(data) => {
            masterPopupState.workout && onEditWorkout(masterPopupState.workout, masterPopupState.workoutIndex, data);
            closeMasterPopup();
          }}
          handleClose={closeWorkoutEditDialog}
        />
      )}
    </Box>
  )
};

interface Props {
  title: string;
  subTitle: string,
  workouts: WorkoutDay[];
  onMarkAsDoneWorkout: (workout: WorkoutDay, clientId: string) => void;
  onDeleteWorkout: (workout: WorkoutDay, clientId: string) => void;
  onEditWorkout: (workout: WorkoutDay, clientId: string, data: WorkoutMinimal) => void;
  onEditWorkoutDateAndTime: (workout: WorkoutDay, clientId: string, data: WorkoutDay) => void;
  clientInfo: { [key: string]: ClientInfo };
  showDate: boolean;
}

export default WorkoutsSection;
