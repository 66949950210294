import FitnessLevel from "myfitworld-model/dist/enums/FitnessLevel";

const getTrainingLevel = (level?: FitnessLevel) =>
  level ? levels.find((l) => l.fitnessLevel === level)?.label : undefined;

export const getTrainingLevelByNumber = (level?: number | null) => {
  if (level) {
    switch (level) {
      case 1:
        return FitnessLevel.NOT_FIT;
      case 2:
        return FitnessLevel.ALMOST_FIT;
      case 3:
        return FitnessLevel.FIT;
      case 4:
        return FitnessLevel.PRO_FIT;
      case 5:
        return FitnessLevel.ADVANCED_FIT;
      default:
        return FitnessLevel.FIT;
    }
  }
  return null;
}

export const getTrainingLevelByEnum = (level?: FitnessLevel | null) => {
  if (level) {
    switch (level) {
      case FitnessLevel.NOT_FIT:
        return 1;
      case FitnessLevel.ALMOST_FIT:
        return 2;
      case FitnessLevel.FIT:
        return 3;
      case FitnessLevel.PRO_FIT:
        return 4;
      case FitnessLevel.ADVANCED_FIT:
        return 5;
      default:
        return 3;
    }
  }
  return null;
}

export const levels = [
  {
    label: "levels.not_fit",
    fitnessLevel: FitnessLevel.NOT_FIT,
  },
  {
    label: "levels.almost_fit",
    fitnessLevel: FitnessLevel.ALMOST_FIT,
  },
  {
    label: "levels.fit",
    fitnessLevel: FitnessLevel.FIT,
  },

  {
    label: "levels.pro_fit",
    fitnessLevel: FitnessLevel.PRO_FIT,
  },
  {
    label: "levels.advanced_fit",
    fitnessLevel: FitnessLevel.ADVANCED_FIT,
  },
] as { label: string; fitnessLevel: FitnessLevel }[];

export default getTrainingLevel;
