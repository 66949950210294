import {makeStyles} from "@material-ui/core/styles";
import {createStyles, Theme} from "@material-ui/core";

export default makeStyles((theme: Theme) =>
  createStyles({
      table: {
        minWidth: 650,
      },
      tableRow: {
        '& > *': {
          whiteSpace: 'nowrap',
        }
      },
      tableActionBar: {
        display: 'flex',
        justifyContent: 'flex-end',
        marginBottom: theme.spacing(3),
      },
    }
  )
);
