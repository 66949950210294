import React, { useCallback, useEffect, useState } from "react";
import {Role} from "myfitworld-model";
import { RouteComponentProps } from "@reach/router";
import ClientForm from "./form/ClientForm";
import usersApiFactory from "../../api/usersApi";
import LoadingSpinner from "../../components/LoadingSpinner";
import UserWithCRUDInstructions from "../../api/UserWithCRUDInstructions";
import globalState, { GlobalState } from "../../globalState";
import { Alert } from "@material-ui/lab";
import { LinearProgress } from "@material-ui/core";
import Box from "@material-ui/core/Box";
import useNavigation from "../../hooks/useNavigation";
const usersApi = usersApiFactory([Role.Client]);

export default function ({ userId }: RouteComponentProps<{ userId: string }>) {
  const [user, setUser] = useState<UserWithCRUDInstructions>();
  const [updateInProgress, toggleUpdateInProgress] = useState<boolean>(false);
  const [formErrorMessage, setFormErrorMessage] = useState<string | undefined>();
  const { navigation } = useNavigation();

  const onSuccess = () => {
    globalState.update((state: GlobalState) => {
      state.toastQueue.push({ message: "Client data saved!", severity: "success" });
    });
    navigation("/clients", undefined, true);
  };

  const loadUser = useCallback(async () => {
    if (userId) {
      const user = await usersApi.get(userId);
      setUser(user);
    }
  }, [userId]);

  useEffect(() => {
    loadUser();
  }, [loadUser]);

  if (user === undefined) {
    return <LoadingSpinner />;
  }

  return (
    <Box px={3}>
      {formErrorMessage && (
        <Alert severity="error" style={{ marginTop: 24 }}>
          {formErrorMessage}
        </Alert>
      )}

      {updateInProgress && <LinearProgress variant="indeterminate" style={{ marginBottom: 12 }} />}

      <ClientForm
        handleClose={() => navigation("/clients")}
        mode="Edit"
        defaultState={user}
        loading={updateInProgress}
        loadUser={loadUser}
        handleUpdate={async (data) => {
          setFormErrorMessage(undefined);
          toggleUpdateInProgress(true);
          const res = await usersApi.update(data);
          toggleUpdateInProgress(false);

          if (res.success) {
            onSuccess();
          } else {
            setUser(undefined);
            await loadUser();
            setFormErrorMessage(res.errorMessage);
          }
        }}
      />
    </Box>
  );
}
