import React, {useMemo} from 'react';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import Select from '@material-ui/core/Select';
import Chip from '@material-ui/core/Chip';
import FormControl from '@material-ui/core/FormControl';
import {Enumerable} from "myfitworld-model";
import Grid from '@material-ui/core/Grid/Grid';
import getLocalizedProperty from "../utils/getLocalizedProperty";

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

const MultiSelectField = ({
  label,
  options,
  value,
  onChange,
  required = false,
  disabled,
} : {
  label: string,
  options: Array<Enumerable>,
  value?: Array<string>,
  onChange: (event: any) => void,
  required?: boolean,
  disabled?: boolean,
}) => {
  const optionsMap = useMemo(() => options.reduce((acc: { [key: string]: string }, option) => {
    acc[option.id || ''] = getLocalizedProperty(option.name);
    return acc;
  }, {}), [options]);

  return (
    <FormControl
      variant="filled"
      required={required}
      fullWidth
      margin="dense"
    >
      <InputLabel>
        {label}
      </InputLabel>
      <Select
        multiple
        renderValue={(selected) => (
          <Grid container spacing={1}>
            {(selected as string[])
              .filter(option => optionsMap[option] !== undefined)
              .map((value) => (
                <Grid item key={value}>
                  <Chip label={optionsMap[value]} />
                </Grid>
              ))
            }
          </Grid>
        )}
        value={value || []}
        MenuProps={MenuProps}
        onChange={e => onChange((e as any).target.value) }
        disabled={disabled}
      >
        {options.map((option) => (
          <MenuItem key={option.id} value={option.id}>
            {getLocalizedProperty(option.name)}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
};

export default MultiSelectField;
