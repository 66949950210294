import React, {useMemo, useState} from 'react';
import {Equipment, Exercise, GeneralEntity, Workout} from 'myfitworld-model';
import {Dialog, makeStyles} from '@material-ui/core';
import get from 'lodash/get';
import ContentFilterValues from "../../../model/ContentFilter";
import clientSideContentFilter from "../../../api/clientSideContentFilter";
import initialContentFilterState from "../../../utils/initialContentFilterState";
import ExerciseListItem from "../exercise/ExerciseListItem";
import WorkoutListItem from "./WorkoutListItem";
import ContentFilter from "../ContentFilter";
import {getContentFilterOptions} from "../../../utils/contentFilterOptions";
import set from 'lodash/fp/set';
import {FixedSizeList as List} from "react-window";
import useGlobalCachedResourceList from "../../../api/useGlobalCachedResourceList";
import buildKeyToNameMap from "../../../utils/buildKeyToNameMap";
import useEntityList from "../../../hooks/useEntityList";
import {exercisesApi, workoutApi} from "../../../api/common";
import i18n from "i18n-js";

const useStyles = makeStyles(() => ({
  dialogPaper: {
    minHeight: '80vh',
    maxHeight: '80vh',
  },
}));

const filterContent = (content: any[], filter: ContentFilterValues, query: string) => {
  let list = clientSideContentFilter(content, filter, true);
  if (query && query.length >= 3) {
    list = list.filter(_ => get(_, 'title.en', '').toUpperCase().indexOf(query.toUpperCase()) >= 0)
  }
  return list;
}

const ContentSelectDialog = ({
                               contentList,
                               isOpen,
                               onClose,
                               type,
                             }: {
  contentList: Array<Exercise | Workout>,
  isOpen: boolean,
  onClose: (id?: string) => void,
  type: 'Exercise' | 'Workout' | 'Program'
}) => {
  const classes = useStyles();
  const [query, setQuery] = useState('');

  const {data: sportOptions} = useGlobalCachedResourceList<GeneralEntity>('sportsCache');
  const {data: muscleGroupOptions} = useGlobalCachedResourceList<GeneralEntity>('muscleGroupsCache');
  const {data: equipmentOptions} = useGlobalCachedResourceList<Equipment>('equipmentCache');
  const {data: exercisesOptions} = useEntityList<Exercise>(exercisesApi.list);

  const ExerciseListItemComponent = useMemo(() => (props: any) =>
      <ExerciseListItem
        {...props}
        sports={buildKeyToNameMap(sportOptions)}
        muscleGroups={buildKeyToNameMap(muscleGroupOptions)}
        equipment={buildKeyToNameMap(equipmentOptions)}
        style={props.style}
      />,
    [sportOptions, muscleGroupOptions, equipmentOptions]
  );

  const WorkoutListItemComponent = useMemo(() => (props: any) =>
      <WorkoutListItem
        {...props}
        exercises={exercisesOptions}
        equipment={buildKeyToNameMap(equipmentOptions)}
        style={props.style}
      />,
    [equipmentOptions, exercisesOptions]
  );

  const [contentFilter, setContentFilter] = useState<ContentFilterValues>(initialContentFilterState);

  let {data} = useEntityList<Exercise | Workout>(type === "Exercise" ? exercisesApi.list : workoutApi.list, contentFilter, `title.${i18n.locale}`);

  const filteredContentList = useMemo(() =>
      filterContent(data, contentFilter, query),
    [data, contentFilter, query]
  );

  const [listHeight, setListHeight] = useState<number>(100);

  return (
    <Dialog
      onClose={() => onClose()}
      aria-labelledby='simple-dialog-title'
      open={isOpen}
      fullWidth
      maxWidth='xl'
      classes={{paper: classes.dialogPaper}}
      ref={(instance: HTMLDivElement) => {
        if (instance) {
          setListHeight(window.innerHeight - instance.offsetTop - window.innerHeight * 0.2);
        }
      }
      }
    >
      <div style={{padding: '1rem'}}>
        {contentFilter &&
        <ContentFilter
          onFilterChange={setContentFilter}
          options={set('showMFWOriginals', true)(getContentFilterOptions(type))}
          query={query}
          setQuery={setQuery}
        />
        }
      </div>

      {(//@ts-ignore
        <List
          itemCount={(filteredContentList || []).length}
          itemData={(filteredContentList || [])}
          height={listHeight}
          width={'100%'}
          itemSize={120}
        >
          {({index, data, style}) => (type === 'Exercise' ?
              <ExerciseListItemComponent
                key={data[index].id}
                item={data[index]}
                onSelect={() => onClose(data[index].id)}
                style={style}
              /> :
              <WorkoutListItemComponent
                key={data[index].id}
                item={data[index]}
                onSelect={() => onClose(data[index].id)}
                style={style}
              />
          )
          }
        </List>
      )}

    </Dialog>
  );
};

export default ContentSelectDialog;
