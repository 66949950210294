import { Enumerable } from 'myfitworld-model';
import getLocalizedProperty from "./getLocalizedProperty";

const buildKeyToNameMap = (list: Array<Enumerable>) =>
  list.reduce((acc: { [key: string]: string }, item) => {
    acc[item.id || ''] = getLocalizedProperty(item.name);
    return acc;
  }, {});

export default buildKeyToNameMap;
