import React from 'react';
import {IconButton, Typography} from '@material-ui/core';
import MoreVertIcon from '@material-ui/icons/MoreVert';
import AssessmentIcon from '@material-ui/icons/Assessment';

const AgendaEvent = (onSelect: (index: number) => void) =>
  ({ event }: { event: { title: string, resourceId: number, finished?: boolean } }) => {
    return (
      <Typography variant='body1'>
        <IconButton
          onClick={() => onSelect(event.resourceId)}
          size="small"
          style={{ marginRight: 2 }}
        >
          {event.finished ?
            <AssessmentIcon fontSize='small' /> :
            <MoreVertIcon fontSize='small' />
          }
        </IconButton>
        <span style={{ opacity: event.finished ? 0.75 : 1 }}>
          {event.title}
        </span>
      </Typography>
    );
  };

export default AgendaEvent;
