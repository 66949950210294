import React, {useState, Fragment} from "react";
import {Box, Hidden} from "@material-ui/core";
import {useUserProvider} from "../../providers/UserProvider";
import EditProfileHeader from "./form/EditProfileHeader";
import EditProfileInfo from "./form/EditProfileInfo";
import SocialMediaLinks from "./form/EditSocialMediaLinks";
import EditProfileImage from "./form/EditProfileImage";
import {User} from "myfitworld-model";
import PageContainer from "../PageContainer";

const EditProfilePage = () => {

  const [changed, toggleChanged] = useState<boolean>(false);
  const [disabled, toggleDisabled] = useState<boolean>(false);

  const {user} = useUserProvider();
  const [userProfile, setUserProfile] = useState<User | null>(user);

  return (
    <PageContainer>
      {user && userProfile &&
        <Fragment>
          <Hidden smDown>
            <EditProfileHeader userProfile={userProfile} changed={changed} disabled={disabled}
                               toggleChanged={toggleChanged} toggleDisabled={toggleDisabled}/>
            <Box display="flex" flexDirection="column">
              <Box display="flex" flexDirection="row">
                <Box flex={1} mr={10} mb={2}></Box>
                <Box flex={2} mr={10} mb={2}>
                  <EditProfileImage userProfile={userProfile} setUserProfile={setUserProfile}
                                    toggleChanged={toggleChanged}/>
                </Box>
              </Box>
              <Box display="flex" flexDirection="row" mb={10}>
                <EditProfileInfo userProfile={userProfile} setUserProfile={setUserProfile}
                                 toggleChanged={toggleChanged} disabled={disabled}/>
                <SocialMediaLinks userProfile={userProfile} setUserProfile={setUserProfile}
                                  toggleChanged={toggleChanged} disabled={disabled} mobile={false}/>
              </Box>
            </Box>
          </Hidden>
          <Hidden mdUp>
            <EditProfileHeader userProfile={userProfile} changed={changed} disabled={disabled}
                               toggleChanged={toggleChanged} toggleDisabled={toggleDisabled}/>
            <Box display="flex" justifyContent="center" mt={5}>
              <EditProfileImage userProfile={userProfile} setUserProfile={setUserProfile}
                                toggleChanged={toggleChanged}/>
            </Box>
            <Box>
              <EditProfileInfo userProfile={userProfile} setUserProfile={setUserProfile}
                               toggleChanged={toggleChanged} disabled={disabled}/>
              <SocialMediaLinks userProfile={userProfile} setUserProfile={setUserProfile}
                                toggleChanged={toggleChanged} disabled={disabled} mobile={true}/>
            </Box>
          </Hidden>
        </Fragment>
      }
    </PageContainer>
  );
};

export default EditProfilePage;
