import React, {useEffect, useState} from 'react';
import {useLocalizationProvider} from "../providers/LocalizationProvider";
import {Button, Container, Typography} from "@material-ui/core";
import {useInvitationProvider} from "../providers/InvitationProvider";
import organizationsApi from "../api/organizationsApi";
import {useUserProvider} from "../providers/UserProvider";
import {acceptInvitation, getCurrentUserRole} from "../api/usersApi";
import {Organization} from "myfitworld-model";
import useNavigation from "../hooks/useNavigation";
import {auth} from "../firebase";
import InvitationErrorPage from "./auth/InvitationErrorPage";
import {RouteComponentProps} from "@reach/router";
import Role from "myfitworld-model/dist/enums/Role";

export default function (_: RouteComponentProps) {
  const {user} = useUserProvider();
  const {translate} = useLocalizationProvider();
  const {invitation, setInvitation} = useInvitationProvider();

  const [invitedOrg, setInvitedOrg] = useState<Organization>()

  const {navigation} = useNavigation();

  useEffect(() => {
    if (!invitation) {
      return;
    }
    invitation.organization && organizationsApi.get(invitation.organization)
      .then(result => {
        setInvitedOrg(result);
      })
  }, [invitation])

  console.log(invitation?.role);
  return (invitation ?
      <Container maxWidth="sm">
        <Typography variant="h2">
          {invitation && (invitation.role === Role.SuperAdmin ? translate('invitation.info_page.title_super_admin')
            : translate('invitation.info_page.title', {
            organization: invitedOrg?.name,
            role: invitation?.role
          }))}
        </Typography>
        {user && getCurrentUserRole(user) === Role.SuperAdmin &&
        <Typography variant="h3">
          {translate('invitation.info_page.error_superadmin')}
        </Typography>
        }
        <div style={{marginTop: '20px', textAlign: 'center'}}>
          {user && getCurrentUserRole(user) !== Role.SuperAdmin &&
          <Button
            color='primary' variant='outlined'
            onClick={() => {
              acceptInvitation(user!, invitation!);
              navigation('/');
            }}
          >
            {translate('invitation.info_page.button_accept')}
          </Button>
          }
          <Button
            color='secondary' variant='text'
            onClick={() => {
              setInvitation(null);
              navigation('/');
            }}
          >
            {translate('invitation.info_page.button_skip')}
          </Button>

        </div>

        <div style={{marginTop: '20px', textAlign: 'center'}}>
          {user?.email || user?.phoneNumber ?
            <Typography variant="h3">
              You are logged in as {user?.firstName || ''} {user?.lastName || ''} {user?.email || user?.phoneNumber}
            </Typography>
            :
            <Button onClick={() =>
              auth
                .signOut()
                .then((_) => {
                  navigation('/');
                })
                .catch((err) => console.log(err))
            }>
              {translate('button.logout')}
            </Button>
          }
        </div>

      </Container>
      :
      <InvitationErrorPage/>
  )
}
