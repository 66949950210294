import React, { useEffect, useState } from "react";
import { Activity, User } from "myfitworld-model";
import { Avatar, Box, Typography, makeStyles, IconButton } from "@material-ui/core";
import usersApi from "../../api/usersApi";
import { useUserProvider } from "../../providers/UserProvider";
import { formatDistanceToNow } from "date-fns";
import ChatIcon from "@material-ui/icons/Chat";

const useStyles = makeStyles((theme) => ({
  root: {
    marginBottom: theme.spacing(2),
    display: "flex",
  },
  avatar: {
    height: 32,
    width: 32,
  },
  reply: {
    marginLeft: theme.spacing(2),
  },
}));

const MessageView = ({ activity, shouldShowReplyButton, onReply }: Props) => {
  const classes = useStyles();
  const { user } = useUserProvider();

  const [sender, setSender] = useState<User>();

  useEffect(() => {
    usersApi([])
      .get(activity.senderId)
      .then((res) => setSender(res));
  }, [activity.senderId]);

  return (
    <div className={classes.root}>
      <Box display="flex" maxWidth={500} ml={activity.senderId === user?.id ? "auto" : 0}>
        <Avatar className={classes.avatar} src={sender?.avatarURL} />
        <Box ml={2}>
          <Box
            bgcolor={activity.senderId === user?.id ? "secondary.main" : "primary.dark"}
            color={activity.senderId === user?.id ? "secondary.contrastText" : "text.primary"}
            py={1}
            px={2}
            borderRadius="borderRadius"
            boxShadow={1}
            display="flex"
            flexDirection="row"
          >
            <Box display="flex" flexDirection="column">
              <Typography color="inherit" variant="h6">
                {activity.senderId === user?.id ? "Me" : sender?.firstName}
              </Typography>
              <Box mt={1}>
                <Typography color="inherit" variant="body1">
                  {activity.text}
                </Typography>
              </Box>
            </Box>
            {shouldShowReplyButton && onReply && (
              <Box ml={2}>
                <IconButton onClick={(event: any) => onReply(event, activity.threadId)}>
                  <ChatIcon />
                </IconButton>
              </Box>
            )}
          </Box>
          <Box mt={1} display="flex" justifyContent="flex-end">
            <Typography noWrap color="textSecondary" variant="caption">
              {formatDistanceToNow(activity.createdAt)}
            </Typography>
          </Box>
        </Box>
      </Box>
    </div>
  );
};

export default MessageView;

interface Props {
  activity: Activity;
  shouldShowReplyButton?: boolean;
  onReply?: (event: any, threadId: string) => void;
}
