import React, {Fragment, useState} from "react";
import NotificationsIcon from "@material-ui/icons/Notifications";
import {createStyles, makeStyles} from "@material-ui/core/styles";
import {Badge, IconButton} from "@material-ui/core";
import NotificationsDrawer from "./NotificationsDrawer";
import {useUserProvider} from "../../providers/UserProvider";

const useStyles = makeStyles(() =>
  createStyles({
    notification: {
      color: "white",
    },
  })
);
const NotificationButton = () => {
  const classes = useStyles();
  const [isOpen, setOpen] = useState(false);
  const {user} = useUserProvider();
  const handleNotifications = () => {
    setOpen((prevState) => !prevState);
  };
  return (
    <Fragment>
      <IconButton onClick={handleNotifications}>
        <Badge badgeContent={user?.notificationCount && user?.notificationCount > 0 ? user?.notificationCount : undefined} color="primary">
          <NotificationsIcon className={classes.notification}/>
        </Badge>
      </IconButton>
      {isOpen && <NotificationsDrawer isOpen={isOpen} onClose={handleNotifications}/>}
    </Fragment>
  );
};

export default NotificationButton;
