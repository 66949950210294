import React, {Fragment, useState} from 'react';
import {RouteComponentProps} from '@reach/router';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import TabPanel from "../../components/TabPanel";
import EquipmentManagementPage from "./equipment/EquipmentManagementPage";
import GeneralEntityFactory from "./generalEntity/GeneralEntityPageFactory";
import {
  fitnessActivitiesApi,
  muscleGroupsApi,
  goalsApi,
  motorSkillsApi,
  sportsApi,
  healthIssuesApi,
  expertisesApi
} from '../../api/common';
import {Box} from "@material-ui/core";

const entities = [
  {
    id: 'equipment',
    label: 'Equipment',
    Component: EquipmentManagementPage
  },
  {
    id: 'fitness',
    label: 'Activity',
    Component: GeneralEntityFactory(fitnessActivitiesApi, 'Activity')
  },
  {
    id: 'muscle',
    label: 'Muscle Groups',
    Component: GeneralEntityFactory(muscleGroupsApi, 'Muscle Group')
  },
  {
    id: 'goals',
    label: 'Goals',
    Component: GeneralEntityFactory(goalsApi, 'Goal')
  },
  {
    id: 'motor',
    label: 'Motor Skills',
    Component: GeneralEntityFactory(motorSkillsApi, 'Motor Skill')
  },
  {
    id: 'sport',
    label: 'Sport List',
    Component: GeneralEntityFactory(sportsApi, 'Sport')
  },
  {
    id: 'health',
    label: 'Health Issues',
    Component: GeneralEntityFactory(healthIssuesApi, 'Health Issue')
  },
  {
    id: 'expertise',
    label: 'Expertise',
    Component: GeneralEntityFactory(expertisesApi, 'Expertise')
  }
]

const ConfigurationManagementPage = (props: RouteComponentProps) => {
  const [value, setValue] = useState(0);

  const handleChange = (event: React.ChangeEvent<{}>, newValue: number) => {
    setValue(newValue);
  };

  return (
    <Fragment>
      <Box display="flex" justifyContent="center">
        <Tabs
          value={value}
          onChange={handleChange}
          variant='scrollable'
          scrollButtons='auto'
          aria-label='Content navigation'
          textColor='primary'
        >
          {entities.map((entity, i) => <Tab key={i} label={entity.label}/>)}
        </Tabs></Box>
      {entities.map(({Component}, i) => (
        <TabPanel value={value} index={i} key={i}>
          <Component/>
        </TabPanel>
      ))}
    </Fragment>
  );
};

export default ConfigurationManagementPage;
