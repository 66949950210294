import {Exercise, MultiRoundExercise, Workout} from "myfitworld-model";
import uniqBy from "lodash/uniqBy";
import uniq from "lodash/uniq";

export const getWorkoutEquipment = (item: Workout | undefined, exercises: Array<Exercise> | undefined): Array<string> => {
  const exerciseIds = uniqBy([
    ...(item?.warmUpGroup?.exercises || []),
    ...(item?.coolDownGroup?.exercises || []),
    ...(item?.exerciseGroups?.flatMap((e) => e.exercises || []) || [])
  ], 'exerciseId');

  return uniq(exerciseIds.flatMap(value => {
    const exercise = exercises?.find(e => (value as MultiRoundExercise).exerciseId === e.id);
    return exercise? exercise?.equipment || [] : [];
  }));
};
