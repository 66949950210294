import React, {useMemo} from 'react';
import {User, WorkoutDay} from "myfitworld-model";
import {Box, Typography} from "@material-ui/core";
import formatRelative from "date-fns/formatRelative";
import {format} from "date-fns";
import groupWorkoutsByDate from './groupWorkoutsByDate';
import buildUserInfoMap from "./buildUserInfoMap";
import WorkoutsSection from "./WorkoutsSection";
import {WorkoutMinimal} from "myfitworld-model/dist/firestoreCollectionTypes/Workout";
import capitalize from 'lodash/capitalize';

const ScheduleList = ({clients, workouts, onMarkAsDoneWorkout, onDeleteWorkout, onEditWorkout, onEditWorkoutDateAndTime}: Props) => {
  const workoutBuckets = useMemo(() => groupWorkoutsByDate(workouts), [workouts]);
  const clientInfo = useMemo(() => buildUserInfoMap(clients), [clients]);

  return (
    <Box p={2}>
      <Box px={2}>
        <Typography variant='h2'>My Schedule</Typography>
      </Box>

      {workoutBuckets && Object.keys(workoutBuckets).map(key => {
          const relativeFormatString = formatRelative(new Date(workoutBuckets[key][0].dateTime), new Date());

          return <WorkoutsSection
            title={relativeFormatString.indexOf(' ') > 0 ? capitalize(relativeFormatString.split(' ')[0]): format(new Date(workoutBuckets[key][0].dateTime), 'dd. MMMM')}
            subTitle={relativeFormatString.indexOf(' ') > 0 ? format(new Date(workoutBuckets[key][0].dateTime), 'dd. MMMM') : ''}
            workouts={workoutBuckets[key]}
            onMarkAsDoneWorkout={onMarkAsDoneWorkout}
            onDeleteWorkout={onDeleteWorkout}
            onEditWorkout={onEditWorkout}
            onEditWorkoutDateAndTime={onEditWorkoutDateAndTime}
            clientInfo={clientInfo}
            key={key}
            showDate
          />
        }
      )
      }
    </Box>
  );
};

interface Props {
  clients: Array<User>;
  workouts: Array<WorkoutDay>;
  onMarkAsDoneWorkout: (workout: WorkoutDay, clientId: string) => void;
  onDeleteWorkout: (workout: WorkoutDay, clientId: string) => void;
  onEditWorkout: (workout: WorkoutDay, clientId: string, data: WorkoutMinimal) => void;
  onEditWorkoutDateAndTime: (workout: WorkoutDay, clientId: string, data: WorkoutDay) => void;
}

export default ScheduleList;
