import React, {useState} from 'react';
import Role from 'myfitworld-model/dist/enums/Role';
import Tab from '@material-ui/core/Tab';
import Tabs from '@material-ui/core/Tabs';
import PageContainer from '../PageContainer';
import {useLocalizationProvider} from '../../providers/LocalizationProvider';
import TabPanel from '../../components/TabPanel';
import InvitationsTable from '../../components/invitations/InvitationsTable';
import UsersTable from "./UserTable";

export default function () {
  const {translate} = useLocalizationProvider();
  const [selectedTab, setSelectedTab] = useState(0);

  return (
    <PageContainer>
      <div>
        <Tabs
          centered
          value={selectedTab}
          textColor='primary'
          onChange={(_, value: number) => {
            setSelectedTab(value);
          }}
        >
          <Tab label={translate('Users')}/>
          <Tab label={translate('Invitations')}/>
        </Tabs>
      </div>
      <TabPanel value={selectedTab} index={0}>
        <UsersTable roles={[Role.SuperAdmin]} showRole={false} disableOrganizationFilter />
      </TabPanel>
      <TabPanel value={selectedTab} index={1}>
        <InvitationsTable
          roles={[Role.SuperAdmin]}
          showRole={false}
          createActionLabel='Invite user'
        />
      </TabPanel>
    </PageContainer>
  );
};





