import {FormControl, MenuItem, Select} from "@material-ui/core";
import DayOfWeek from "myfitworld-model/dist/enums/DayOfWeek";
import React from "react";

const options = [
  { value: DayOfWeek.MONDAY, label: 'Monday' },
  { value: DayOfWeek.TUESDAY, label: 'Tuesday' },
  { value: DayOfWeek.WEDNESDAY, label: 'Wednesday' },
  { value: DayOfWeek.THURSDAY, label: 'Thursday' },
  { value: DayOfWeek.FRIDAY, label: 'Friday' },
  { value: DayOfWeek.SATURDAY, label: 'Saturday' },
  { value: DayOfWeek.SUNDAY, label: 'Sunday' }
];

const DayOfWeekSelect = ({
  value,
  workoutId,
  onChange,
  disabled,
} : {
  value: DayOfWeek,
  workoutId: string,
  onChange: ({ day, workoutId }: { day: DayOfWeek, workoutId: string }) => void,
  disabled: boolean,
}) =>
  <FormControl variant='outlined'>
    <Select
      style={{ minWidth: 170 }}
      value={value}
      onChange={(e) => {
        const day = e.target.value as DayOfWeek;
        onChange({ day, workoutId });
      }}
      disabled={disabled}
    >
      {
        options.map(option => <MenuItem key={option.value} value={option.value}>
          {option.label}
        </MenuItem>)
      }
    </Select>
  </FormControl>;

export default DayOfWeekSelect;
