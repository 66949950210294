import { useState } from 'react';

export  interface DialogProps<T> { open: boolean; workout?: T; workoutIndex?: any }


export const useMasterPopup = <T>() => {
  const [ masterPopupState, setMasterPopupState ] = useState<DialogProps<T>>(
    { open: false, workout: undefined, workoutIndex: undefined }
  );

  const closeMasterPopup = () => {
    setMasterPopupState({ open: false, workout: undefined, workoutIndex: undefined });
  }

  const openMasterPopup = (workout: T, workoutIndex: any) => {
    setMasterPopupState({ open: true, workout, workoutIndex });
  }

  return {
    masterPopupState,
    setMasterPopupState,
    openMasterPopup,
    closeMasterPopup,
  }
};
