import React from 'react';
import EquipmentListItem from './EquipmentListItem';
import EquipmentForm from './EquipmentForm';
import EntityManager from '../../../entity/EntityManager';
import PageContainer from "../../PageContainer";
import {equipmentApi} from "../../../api/common";
import {Equipment} from "myfitworld-model";
import {makeStyles} from "@material-ui/core/styles";
import i18n from 'i18n-js';

export const useStyles = makeStyles({
  root: {
    flexGrow: 1,
  },
});

const EquipmentManagementPage = () => {
  const classes = useStyles();

  return (
    <PageContainer>
      <div className={classes.root}>
        <EntityManager<Equipment>
          entityName={"Equipment"}
          entityCreateHelper={"Describe exercise equipment preferences "}
          apiInterface={equipmentApi}
          ListItemComponent={EquipmentListItem}
          FormComponent={EquipmentForm}
          emptyStateTitle='Add the first equipment to get started.'
          sortKey={`name.${i18n.locale}`}
          listItemHeight={62}
        />
      </div>
    </PageContainer>
  )
};

export default EquipmentManagementPage;
