export const displayDuration = (
  durationInMinutes: number
): string => {
  if (durationInMinutes !== 0) {
    const hours = Math.floor(durationInMinutes / 60);
    const minutes = durationInMinutes - hours * 60;
    return hours && minutes ? `${hours} h ${minutes} min` :
      hours && !minutes ? `${hours} h` :
        !hours && minutes ? `${minutes} min` : `0 min`;
  }
  return '0 min';
};

export const displayDurationInMMSS = (
  durationInSeconds: number
): string => {
  const minutes = Math.floor(durationInSeconds / 60);
  const mm = minutes >= 10 ? `${ minutes }` : `0${minutes}`;
  const seconds = durationInSeconds % 60;
  const ss = seconds >= 10 ? `${ seconds }` : `0${seconds}`;
  return `${mm} : ${ss}`;
};
