import React, {useEffect, useState} from 'react';
import {Controller, useForm} from 'react-hook-form';
import Button from '@material-ui/core/Button';
import {useLocalizationProvider} from '../../providers/LocalizationProvider';
import {Box, Checkbox, TextField, Typography} from '@material-ui/core';
import {Alert} from '@material-ui/lab';
import {Organization} from 'myfitworld-model';
import {UnpackNestedValue} from 'react-hook-form/dist/types/form';
import organizationsApi from '../../api/organizationsApi';
import OrganizationType from 'myfitworld-model/dist/enums/OrganizationType';
import Switch from '@material-ui/core/Switch';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import SelectField from '../SelectField';
import {listCoupons} from "../../api/billingDetailsApi";

interface OrganizationFormValues {
  name: string,
  free: boolean,
  hasDiscount: boolean,
  discount: string,
  hasAlert: boolean,
  quantity: number
}

export default function ({organization, onClose}: { organization?: Organization, onClose: () => void }) {

  const {register, control, handleSubmit, watch, errors} = useForm<OrganizationFormValues>({
    criteriaMode: 'all',
    defaultValues: {
      name: organization?.name || '',
      free: organization?.free || false,
      hasAlert: !!(organization?.alertOn && organization?.alertOn > 0),
      quantity: !!(organization?.alertOn && organization?.alertOn > 0) ? organization?.alertOn : 0,
      hasDiscount: !!organization?.stripe,
      discount: !!organization?.stripe ? organization.stripe.discountId : ''
    }
  });

  const [coupons, setCoupons] = useState<{
    id: string,
    name: { en: string }
  }[]>([]);

  useEffect(() => {
    listCoupons().then((res) => {
      res.json().then((res) => {
        setCoupons(res.data.map((coupon: any) => ({
          id: coupon.id,
          name: { en: coupon.name }
        })));
      });
    });
  }, []);

  const watchFree = watch('free');
  const watchHasDiscount = watch('hasDiscount');
  const watchHasAlert = watch('hasAlert');

  const [loading, setLoading] = useState(false);
  const {translate} = useLocalizationProvider();

  const onSubmitHandler = handleSubmit((data: UnpackNestedValue<OrganizationFormValues>) => {
    setLoading(true);
    console.log(data);
    let storePromise = !organization ? organizationsApi.create({
        name: data.name,
        free: data.free,
        createdAt: new Date(),
        active: true,
        type: OrganizationType.Gym,
        // @ts-ignore
        alertOn: data.hasAlert ? parseInt(data.quantity) : 0,
        stripe: data.hasDiscount ? {
          discountId: data.discount,
          discountName: coupons.find((e) => e.id === data.discount)?.name.en || ''
        } : null
      } as Organization)
      : organizationsApi.update({
        ...organization,
        name: data.name,
        free: data.free,
        // @ts-ignore
        alertOn: data.hasAlert ? parseInt(data.quantity) : 0,
        stripe: data.hasDiscount ? {
          discountId: data.discount,
          discountName: coupons.find((e) => e.id === data.discount)?.name.en || ''
        } : null
      } as Organization);

    storePromise.then(_ => {
      setLoading(false);
      onClose();
    }).catch(err => {
      setLoading(false);
      console.error('Error creating org', err);
    });
  });

  return (
    <form onSubmit={onSubmitHandler}>
      <Box mb={2}>
        <Typography variant='h4' gutterBottom>
          {!organization ? 'Create Organization' : 'Update Organization'}
        </Typography>
      </Box>

      <Controller
        as={TextField}
        name='name'
        control={control}
        inputRef={register({
          required: 'Please enter name',
        })}
        label={translate('Name')}
        type='text'
        fullWidth
        autoFocus
      />

      <Box display='flex' flexDirection='row' alignItems='center' mt={1} mb={1}>
        <Controller
          name='free'
          control={control}
          render={(props) => (
            <Checkbox
              onChange={(e) => props.onChange(e.target.checked)}
              checked={props.value}
            />
          )}
        />
        <Typography color='textPrimary'>Free use of the application</Typography>
      </Box>

      <Box mb={2}>
        <FormControlLabel
          label={'Add Discount'}
          control={
            <Controller
              name='hasDiscount'
              control={control}
              render={({onChange, value}) => {
                return <Switch name='hasDiscount' disabled={watchFree} color='primary' checked={value}
                               onChange={(_, checked) => onChange(checked)}/>
              }}
              disabled={watchFree}
            />}
        />

        {
          watchHasDiscount &&
          (<Controller
            name={'discount'}
            control={control}
            render={({onChange, value}) => {
              return <SelectField label={translate('Discount')} options={coupons}
                                  value={value}
                                  onChange={value => onChange(value)}/>
            }}
          />)
        }
      </Box>

      <Box mb={4}>
        <FormControlLabel
          label={'Add Alert'}
          control={
            <Controller
              name='hasAlert'
              control={control}
              render={({onChange, value}) => {
                return <Switch name='hasAlert' color='primary' checked={value}
                               onChange={(_, checked) => onChange(checked)}/>
              }}
            />}
        />

        {
          watchHasAlert &&
          (<Controller
              as={TextField}
              name='quantity'
              control={control}
              inputRef={register}
              label={translate('Number of Clients')}
              type='number'
              fullWidth
              autoFocus
            />)
        }
      </Box>

      <Box mt={2}>
        <Button
          color='primary' variant='contained'
          onClick={onSubmitHandler}
          disabled={loading}
        >
          {!organization ? translate('Create') : translate('Update')}
        </Button>
      </Box>

      {errors.name &&
      <Alert severity='error' style={{marginTop: 24}}>
        {errors.name.message && <p>{translate(errors.name.message)}</p>}
      </Alert>}
    </form>
  )
}
