import React, {useMemo} from 'react';
import {exercisesApi, programTemplateApi, workoutApi} from '../../../api/common';
import {Workout, ProgramTemplate, Equipment} from "myfitworld-model";
import EntityManager from "../../../entity/EntityManager";
import useEntityList from "../../../hooks/useEntityList";
import ProgramListItem from "./ProgramListItem";
import ProgramTemplateForm from "./ProgramTemplateForm";
import i18n from "i18n-js";
import {initialContentFilterState} from "../../../utils/initialContentFilterState";
import {programContentFilterOptions} from "../../../utils/contentFilterOptions";
import Exercise from "myfitworld-model/dist/firestoreCollectionTypes/Exercise";
import useGlobalCachedResourceList from "../../../api/useGlobalCachedResourceList";
import buildKeyToNameMap from "../../../utils/buildKeyToNameMap";

const ProgramManagementPage = () => {
  const { data: workouts } = useEntityList<Workout>(workoutApi.list);
  const { data: exercises } = useEntityList<Exercise>(exercisesApi.list);
  const { data: equipment } = useGlobalCachedResourceList<Equipment>('equipmentCache');

  const ProgramListItemComponent = useMemo(() => (props: any) =>
      <ProgramListItem
        {...props}
        workouts={workouts}
        exercises={exercises}
        equipment={buildKeyToNameMap(equipment)}
        style={props.style}
      />,
    [workouts, exercises, equipment]
  );

  return (
    <EntityManager<ProgramTemplate>
      fullWidthForm
      entityName={"Program"}
      entityCreateHelper={"Add the basic details now, you can always edit the plan later."}
      apiInterface={programTemplateApi}
      ListItemComponent={ProgramListItemComponent}
      initialFilterState={initialContentFilterState}
      FormComponent={ProgramTemplateForm}
      emptyStateTitle='Setup your first program template.'
      sortKey={`title.${i18n.locale}`}
      contentFilterOptions={programContentFilterOptions}
      appendFormInfoToURL
    />
  )
};

export default ProgramManagementPage;
