import { useLocation, useNavigate } from "@reach/router";
import {useMemo} from "react";
import queryString from "querystring";

const useNavigation = () => {
  const navigate = useNavigate();
  const location = useLocation();

  const navigation = useMemo(() => async (route: string, state?: { [key: string]: any }, skipChecks?: boolean) => {
    const queries = queryString.parse(location.search.replace('?', ''));

    // @ts-ignore
    if ((queries.form !== 'open' && !location?.state?.formDirty) || skipChecks === true) {
      await navigate(route, state ? { state} : undefined);
      return true;
    } else {
      const prompt = window.confirm(
        `
          You have a form open. Moving to a new page without saving will delete any changes. \n
          Do you wish to proceed?
        `
      );
      if (prompt) {
        await navigate(route, state ? { state} : undefined);
        return true;
      }
    }
    return false;
  }, [location, navigate]);

  return {
    navigation,
  };
};

export default useNavigation;
