import ProgramTemplate from "myfitworld-model/dist/firestoreCollectionTypes/ProgramTemplate";
import Workout, { WorkoutMinimal } from "myfitworld-model/dist/firestoreCollectionTypes/Workout";
import { WorkoutDay } from "myfitworld-model/dist/firestoreCollectionTypes/User";
import cloneAndOmitProperties from "../../../utils/cloneAndOmitProperties";
import DayOfWeek from "myfitworld-model/dist/enums/DayOfWeek";
import addDays from "date-fns/fp/addDays";
import startOfWeek from "date-fns/startOfWeek";
import dayOfWeekToDayIndex from "../../../utils/dayOfWeekToDayIndex";
import unusedWorkoutProperties from "./unusedWorkoutProperties";

const pickDate = (planStartDate: string, weekIndex: number, day: DayOfWeek): string => {
  const start = new Date(planStartDate);
  const adjustedForWeek = addDays(6 * (weekIndex + 1))(start);

  return startOfWeek(adjustedForWeek, { weekStartsOn: dayOfWeekToDayIndex(day) }).toJSON();
};

const extractWorkouts = (
  programs: ProgramTemplate[],
  workouts: Workout[],
  programId: string,
  startDate: string
): WorkoutDay[] => {
  const program = programs.find((p) => p.id === programId);
  if (program) {
    const workoutDays: WorkoutDay[] = [];
    program.workoutWeeks.forEach((week, index) => {
      week.days.forEach((day) => {
        const workout = workouts.find((w) => w.id === day.workoutId);
        if (workout) {
          workoutDays.push({
            dateTime: pickDate(startDate, index, day.day),
            originalWorkoutId: day.workoutId,
            workout: cloneAndOmitProperties<Workout, WorkoutMinimal>(workout, unusedWorkoutProperties),
          });
        }
      });
    });
    return workoutDays;
  }
  return [];
};

export default extractWorkouts;
