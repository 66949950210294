import React from "react";
import {Button, StepContent} from "@material-ui/core";
import Select from "@material-ui/core/Select/Select";
import MenuItem from "@material-ui/core/MenuItem/MenuItem";
import ProgramTemplate from "myfitworld-model/dist/firestoreCollectionTypes/ProgramTemplate";
import getLocalizedProperty from "../../../../utils/getLocalizedProperty";

const Step2 = ({
  programTemplate,
  setProgramTemplate,
  programTemplates,
  classNames,
  nextStep,
  allowEmpty,
  ...props
} : {
  programTemplate: string,
  setProgramTemplate: (val: string) => void,
  programTemplates: ProgramTemplate[],
  classNames: any,
  nextStep: () => void,
  allowEmpty: boolean,
}) =>
  <StepContent {...props}>
    <Select
      value={programTemplate || ''}
      onChange={e => setProgramTemplate(e.target.value as string)}
      className={classNames.select}
    >
      <MenuItem value='NONE'><em>None selected</em></MenuItem>
      {programTemplates.map((template) => (
        <MenuItem key={template.id} value={template.id}>
          {getLocalizedProperty(template.title)}
        </MenuItem>
      ))}
    </Select>
    <Button color='primary' disabled={programTemplate === 'NONE'} onClick={nextStep}>
      Use selected
    </Button>
    {allowEmpty &&
      <Button color='primary' onClick={() => {
        setProgramTemplate('NONE');
        nextStep();
      }}>
        Start from scratch
      </Button>
    }
  </StepContent>;

export default Step2;
