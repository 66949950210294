import {useTheme} from "@material-ui/core/styles";
import Box from "@material-ui/core/Box";
import AssessmentIcon from "@material-ui/icons/Assessment";
import {Typography} from "@material-ui/core";
import React from "react";

const CalendarEvent = ({event} : { event :any }) => {
  const theme = useTheme();
  return (
    <Box display="flex" alignItems="center" style={{
      background: event.finished ? 'grey' : '#006CFF',
      padding: theme.spacing(0.2),
      borderRadius: theme.spacing(0.5),
    }}>
      {event.finished && <AssessmentIcon fontSize='small'/>}
      <Box ml={1}>
        <Typography variant="body2">{event.title}</Typography>
      </Box>
    </Box>
  )
};

export default CalendarEvent;
