import React from "react";
import FacebookIcon from "@material-ui/icons/Facebook";
import InstagramIcon from "@material-ui/icons/Instagram";
import TwitterIcon from "@material-ui/icons/Twitter";
import LanguageIcon from "@material-ui/icons/Language";
import WebIcon from '@material-ui/icons/Web';

export const SocialMediaMetaData = (fontSize: "small" | "inherit" | "default" | "large" = "default") => {
  return [
    {
      type: "facebook",
      name: "Facebook",
      icon: <FacebookIcon fontSize={fontSize}/>
    },
    {
      type: "instagram",
      name: "Instagram",
      icon: <InstagramIcon fontSize={fontSize}/>
    },
    {
      type: "twitter",
      name: "Twitter",
      icon: <TwitterIcon fontSize={fontSize}/>
    },
    {
      type: "website",
      name: "Website",
      icon: <LanguageIcon fontSize={fontSize}/>
    },
  ];
};

export const defaultSocialMediaIcon = <WebIcon />;
