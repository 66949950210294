import React, {useCallback, useState} from "react";
import {
  createStyles,
  IconButton, Theme, Tooltip
} from "@material-ui/core";
import FileCopyIcon from "@material-ui/icons/FileCopy";
import {makeStyles} from "@material-ui/core/styles";
import copy from "copy-to-clipboard";

export const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    iconButton: {
      padding: theme.spacing(0.5),
    }
  })
);

const CopyButton = ({textFieldRef, startText} : Props) => {
  const classes = useStyles();
  const [tooltipMessage, setTooltipMessage] =useState<string>("Copy link");
  const copyText = useCallback(
    (e) => {
      const isCopied = copy(`${startText ?? ""}${(textFieldRef?.current as any)?.value ?? ''}`);
      isCopied ? setTooltipMessage("Copied!") : setTooltipMessage("Press Ctrl+C or ⌘+C to copy");
    },
    [textFieldRef, startText]
  );

  return (
    <Tooltip title={tooltipMessage} onClose={() => setTooltipMessage("Copy link")}>
      <IconButton
        onClick={e => copyText(e)}
        className={classes.iconButton}
      >
        <FileCopyIcon />
      </IconButton>
    </Tooltip>
  );
};

export default CopyButton;

interface Props {
  startText: string,
  textFieldRef: React.RefObject<any>
}
