import React from 'react';
import {FormControl, Grid, InputLabel, MenuItem, Select, TextField} from '@material-ui/core';
import {getStates} from "../../../../utils/getStates";
import {BillingDetailsErrors} from "../../utils/BillingDetailsInterface";

const countries = require("i18n-iso-countries");
countries.registerLocale(require("i18n-iso-countries/langs/en.json"));

const BillingAddressDetails = ({
  address,
  city,
  countryCode,
  postalCode,
  stateCode,
  taxID,
  onChange,
  errors
}: {
  address: string;
  city: string;
  countryCode: string;
  postalCode: string;
  stateCode: string;
  taxID: string;
  onChange: (e: any, validate: boolean) => void;
  errors?: BillingDetailsErrors;
}) => {
  return (
    <div>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <TextField
            label='Address'
            name='address'
            color='primary'
            variant='standard'
            margin="dense"
            value={address}
            onChange={(e) => onChange(e, true)}
            required={true}
            helperText={errors?.address ? "This field is required." : null}
            error={!!errors?.address}
            fullWidth
          />
        </Grid>
      </Grid>

      <Grid container spacing={2}>
        <Grid item xs={6}>
          <FormControl style={{width: "100%"}}>
            <InputLabel id="country-id">Country</InputLabel>
            <Select
              labelId="country-id"
              name="countryCode"
              value={countryCode}
              onChange={(e) => onChange(e, true)}
              required={true}
              error={!!errors?.countryCode}
            >
              {
                Object.entries(countries.getNames("en", {select: "official"})).map(([key,value]) =>
                  <MenuItem value={key}>{`${value}`}</MenuItem>
                )
              }
            </Select>
          </FormControl>
        </Grid>
        <Grid item xs={6}>
          {
            getStates(countryCode) &&
            <FormControl style={{width: "100%"}}>
              <InputLabel id="state-id">State/Province</InputLabel>
              <Select
                labelId="state-id"
                name="stateCode"
                value={stateCode}
                onChange={(e) => onChange(e, true)}
                required={true}
                error={!!errors?.stateCode}
              >
                {
                  (getStates(countryCode) || []).map(([key,value]) =>
                    <MenuItem value={key}>{`${value}`}</MenuItem>
                  )
                }
              </Select>
            </FormControl>
          }
        </Grid>
      </Grid>

      <Grid container spacing={2}>
        <Grid item xs={12}>
          <TextField
            label='Tax ID'
            name='taxID'
            color='primary'
            variant='standard'
            margin="dense"
            value={taxID}
            onChange={(e) => onChange(e, false)}
            fullWidth
          />
        </Grid>
      </Grid>

      <Grid container spacing={2}>
        <Grid item xs={6}>
          <TextField
            label='City'
            name='city'
            color='primary'
            variant='standard'
            margin="dense"
            value={city}
            onChange={(e) => onChange(e, true)}
            required={true}
            error={!!errors?.city}
            helperText={errors?.city ? "This field is required." : null}
            fullWidth
          />
        </Grid>
        <Grid item xs={6}>
          <TextField
            label='Postal code'
            name='postalCode'
            color='primary'
            variant='standard'
            margin="dense"
            value={postalCode}
            onChange={(e) => onChange(e, true)}
            required={true}
            error={!!errors?.postalCode}
            helperText={errors?.postalCode ? "This field is required." : null}
            fullWidth
          />
        </Grid>
      </Grid>
    </div>
  );
}

export default BillingAddressDetails;
