import React, {useMemo} from 'react';
import Exercise from 'myfitworld-model/dist/firestoreCollectionTypes/Exercise';
import {exercisesApi, workoutApi} from '../../../api/common';
import WorkoutForm from './workoutForm';
import WorkoutListItem from './WorkoutListItem';
import {Equipment, Workout} from "myfitworld-model";
import EntityManager from "../../../entity/EntityManager";
import useEntityList from "../../../hooks/useEntityList";
import i18n from 'i18n-js';
import {initialContentFilterState} from "../../../utils/initialContentFilterState";
import {workoutContentFilterOptions} from "../../../utils/contentFilterOptions";
import useGlobalCachedResourceList from "../../../api/useGlobalCachedResourceList";
import buildKeyToNameMap from "../../../utils/buildKeyToNameMap";

const WorkoutManagementPage = () => {
  const { data: exercises } = useEntityList<Exercise>(exercisesApi.list);
  const { data: equipment } = useGlobalCachedResourceList<Equipment>('equipmentCache');

  const WorkoutListItemComponent = useMemo(() => (props: any) =>
      <WorkoutListItem
        {...props}
        exercises={exercises}
        equipment={buildKeyToNameMap(equipment)}
        style={props.style}
      />,
    [equipment, exercises]
  );

  return (
    <EntityManager<Workout>
      fullWidthForm
      entityName={"Workout"}
      entityCreateHelper={"Add the basic details now, you can always edit the plan later."}
      apiInterface={workoutApi}
      initialFilterState={initialContentFilterState}
      ListItemComponent={WorkoutListItemComponent}
      FormComponent={WorkoutForm}
      emptyStateTitle='Setup your first workout template.'
      sortKey={`title.${i18n.locale}`}
      contentFilterOptions={workoutContentFilterOptions}
      appendFormInfoToURL
    />
  )
}

export default WorkoutManagementPage;
