import React from 'react';
import {GeneralEntity} from "myfitworld-model";
import EntityManager from "../../../entity/EntityManager";
import GeneralEntityListItem from "./GeneralEntityListItem";
import GeneralEntityForm from "./GeneralEntityForm";
import {ApiInterface} from "../../../api/dataInterfaceFactory";
import PageContainer from "../../PageContainer";
import i18n from "i18n-js";


const GeneralEntityFactory = (api: ApiInterface<GeneralEntity>, entityName: string) => () => (
  <PageContainer>
    <div style={{flexGrow: 1}}>
      <EntityManager<GeneralEntity>
        entityName={entityName}
        apiInterface={api}
        ListItemComponent={GeneralEntityListItem}
        FormComponent={GeneralEntityForm}
        emptyStateTitle={`No registered items, start by adding a ${entityName}.`}
        sortKey={`name.${i18n.locale}`}
        listItemHeight={62}
      />
    </div>
  </PageContainer>
)

export default GeneralEntityFactory;
