import React from "react";
import { Box, Typography } from "@material-ui/core";
import { StatsExercise } from "myfitworld-model";
import getLocalizedProperty from "../../utils/getLocalizedProperty";
import TableCell from "@material-ui/core/TableCell";
import TableRow from "@material-ui/core/TableRow";
import {displayDurationInMMSS} from "../../utils/displayDuration";

const ExerciseLog = ({ exerciseStat, totalExercises, currentExercise } : Props) => {
  return (
    <TableRow>
      <TableCell>{`${currentExercise}/${totalExercises}`}</TableCell>
      <TableCell component="th" scope="row">
        {getLocalizedProperty(exerciseStat.exercise?.title)}
      </TableCell>
      <TableCell align="right">
        <Box display="flex" flexDirection="column">
          {(exerciseStat.reps || exerciseStat.repsDone) &&
            getLogValue(exerciseStat.reps, exerciseStat.repsDone, "reps")}
          {(exerciseStat.time || exerciseStat.timeDone) && getLogTime(exerciseStat?.time || 0, exerciseStat?.timeDone || 0)}
          {(exerciseStat.weight || exerciseStat.weightDone) &&
            getLogValue(exerciseStat.weight, exerciseStat.weightDone, "kg")}
        </Box>
      </TableCell>
    </TableRow>
  );
};

export default ExerciseLog;

interface Props {
  totalExercises?: number;
  currentExercise?: number;
  exerciseStat: StatsExercise;
}

const getLogTime = (toDo: number, done: number) => {
  if (toDo && !done) {
    return <Typography>{displayDurationInMMSS(toDo || 0)}</Typography>;
  } else if (!toDo && done) {
    return <Typography>{displayDurationInMMSS(done || 0)}</Typography>;
  } else if (!toDo || !done) {
    return <Typography>{`-`}</Typography>;
  } else if (toDo === done) {
    return <Typography>{displayDurationInMMSS(toDo || 0)}</Typography>;
  } else if (toDo < done) {
    return <Typography style={{ color: "#34d867" }}>{`${displayDurationInMMSS(toDo || 0)} (+${displayDurationInMMSS((done || 0) - (toDo || 0))})`}</Typography>;
  } else if (toDo > done) {
    return <Typography style={{ color: "#FF682D" }}>{`${displayDurationInMMSS(toDo || 0)} (-${displayDurationInMMSS((toDo || 0) - (done || 0))})`}</Typography>;
  }
};


const getLogValue = (toDo?: number, done?: number, measure?: string) => {
  if (toDo && !done) {
    return <Typography>{`${toDo} ${measure || ""}`}</Typography>;
  } else if (!toDo && done) {
    return <Typography>{`${done} ${measure || ""}`}</Typography>;
  } else if (!toDo || !done) {
    return <Typography>{`-`}</Typography>;
  } else if (toDo === done) {
    return <Typography>{`${toDo} ${measure || ""}`}</Typography>;
  } else if (toDo < done) {
    return <Typography style={{ color: "#34d867" }}>{`${toDo} (+${done - toDo}) ${measure || ""}`}</Typography>;
  } else if (toDo > done) {
    return <Typography style={{ color: "#FF682D" }}>{`${toDo} (${done - toDo}) ${measure || ""}`}</Typography>;
  }
};
