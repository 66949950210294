import { Store } from 'pullstate';
import GeneralEntity from 'myfitworld-model/dist/firestoreCollectionTypes/GeneralEntity';
import {Organization, Role} from "myfitworld-model";

export interface Toast {
  message: string;
  severity: "success" | "error"
}

export interface Cache {
  data: Array<GeneralEntity>;
  loadedAt: Date;
}

export interface GlobalState {
  currentRole?: Role;
  currentUserId?: string;
  currentOrganization?: Organization;
  globalLoadingQueue: Array<number>;
  toastQueue: Array<Toast>;
  equipmentCache: Cache;
  muscleGroupsCache: Cache;
  sportsCache: Cache;
  motorSkillsCache: Cache;
  healthIssuesCache: Cache;
  fitnessActivitiesCache: Cache;
  goalsCache: Cache;
  expertisesCache: Cache;
}

const globalState = new Store({
  currentRole: undefined,
  currentUserId: undefined,
  globalLoadingQueue: [],
  toastQueue: [],
  equipmentCache: { data: [], loadedAt: new Date() },
  muscleGroupsCache: { data: [], loadedAt: new Date() },
  sportsCache: { data: [], loadedAt: new Date() },
  motorSkillsCache: { data: [], loadedAt: new Date() },
  healthIssuesCache: { data: [], loadedAt: new Date() },
  fitnessActivitiesCache: { data: [], loadedAt: new Date() },
  goalsCache: { data: [], loadedAt: new Date() },
  expertisesCache: { data: [], loadedAt: new Date() }
} as GlobalState);

export default globalState;
