import cloneDeep from 'lodash.clonedeep';
import useStateWithDirtyFlag from "../../hooks/useStateWithDirtyFlag";

const useGroupState = <T>(newGroupData: T, defaultState?: Array<T>) => {
  const {
    value: groups,
    setter: setGroups,
    isDirty: isGroupDirty,
  } = useStateWithDirtyFlag<Array<T>>(defaultState || []);

  const addGroup = () => {
    groups.push(newGroupData);
    setGroups([ ...groups ]);
  };

  const removeGroup = (index: number) => {
    if (groups[index]) {
      groups.splice(index, 1);
      setGroups([...groups]);
    }
  };

  const copyGroup = (index: number) => {
    if (groups[index]) {
      const copyOfGroup = cloneDeep(groups[index]);
      groups.splice(index + 1, 0, copyOfGroup);
      setGroups([ ...groups ]);
    }
  };

  const updateGroup = (index: number, group: T) => {
    if (groups[index]) {
      groups[index] = group;
      setGroups([...groups]);
    }
  };

  return {
    groups,
    addGroup,
    removeGroup,
    copyGroup,
    updateGroup,
    isGroupDirty,
  }
};

export default useGroupState;
