import {RouteComponentProps, useNavigate} from "@reach/router";
import React, {useCallback, useState} from "react";
import {Box, Button, Checkbox, CssBaseline, TextField, Typography, useTheme} from "@material-ui/core";
import useRandomImage from "../assets/useRandomImage";
import Grid from "@material-ui/core/Grid";
import Image from "material-ui-image";
import Paper from "@material-ui/core/Paper";
import logo from "../../assets/logo.png";
import {useForm} from "react-hook-form";
import {firestore} from "../../firebase";
import {Invitation, Organization, Role} from "myfitworld-model";
import firebase from "firebase";
import organizationsApi from "../../api/organizationsApi";
import OrganizationType from "myfitworld-model/dist/enums/OrganizationType";
import {Alert} from "@material-ui/lab";
import useLoginPageStyles from "./useLoginPageStyles";
import Link from "@material-ui/core/Link";
import {getBillingDetailsByEmail} from "../../api/billingDetailsApi";

const TrainerOrganizationLoginPage = (_: RouteComponentProps) => {
  const classes = useLoginPageStyles();
  const theme = useTheme();
  const loginImageSource = useRandomImage();
  const navigate = useNavigate();

  const {register, errors, handleSubmit, watch} = useForm();
  const formData = watch();

  const [disabledForm, setDisabledForm] = useState<boolean>(false);
  const [successMessage, setSuccessMessage] = useState<string | null>(null);
  const [accountExists, setAccountExists] = useState<boolean>(false);
  const [resendMessage, setResendMessage] = useState<boolean>(false);

  console.log("errors", errors);
  const onSubmit = useCallback(async (data) => {
    console.log("Submit", data);

    setDisabledForm(true);
    setResendMessage(false);

    const organizationAccount = await getBillingDetailsByEmail(data.email);
    console.log("org acc", organizationAccount);
    if (organizationAccount) {
      setAccountExists(true);
      setDisabledForm(false);
    } else {
      setAccountExists(false);
      setSuccessMessage("You have successfully created your account. In a few moments you will receive an email with a login link.");

      const organization = await organizationsApi.create({
        name: data.organizationName,
        free: false,
        createdAt: new Date(),
        active: true,
        type: OrganizationType.Gym
      } as Organization);


      const [firstName = "", lastName = ""] = data.fullName.split(" ");
      organization.documentRef?.id && await firestore.collection('invitations')
        .add({
          firstName: firstName,
          lastName: lastName,
          email: data.email,
          phoneNumber: null,
          organization: organization.documentRef?.id,
          role: Role.Admin,
          createdAt: firebase.firestore.Timestamp.fromDate(new Date()),
          invitedBy: null,
          invitationSent: false,
          isInSendingQueue: true,
          acceptedOn: null,
        } as unknown as Invitation);
    }
  }, []);


  const resendInvitation = async (data: any) => {
    console.log("data resend", data);
    setAccountExists(false);
    const organizationAccount = await getBillingDetailsByEmail(data.email);
    organizationAccount && firestore.collection('invitations').where('organization', '==', organizationAccount.id).get().then(
      (snap) => {
        if (snap.docs.length > 0) {
          const invitationId = snap.docs[0].id;
          firestore.collection('invitations').doc(invitationId).update({isInSendingQueue: true});
          setResendMessage(true);
        }
      }
    );
  }

  return (
    <Grid container component="main" className={classes.root}>
      <CssBaseline/>
      <Grid item xs={false} sm={4} md={8} className={classes.image}>
        <Image
          src={loginImageSource}
          disableSpinner
          color={theme.palette.background.default}
          style={{opacity: 0.35, height: '100%'}}
        />
      </Grid>
      <Grid item xs={12} sm={8} md={4} component={Paper} elevation={6} square>
        <Box
          m={2}
          display="flex"
          flexDirection="column"
          alignItems="center"
        >
          <Box
            mt={4}
            mb={8}
            height={80}
            width={80}
          >
            <Image
              src={logo}
              disableSpinner
              color={theme.palette.background.default}
            />
          </Box>


          <form onSubmit={handleSubmit(onSubmit)}>
            <Box
              display="flex"
              flexDirection="column"
              alignItems="center"
            >
              <Typography variant="h4" style={{width: "80%", marginBottom: 24}}>
                Registration for organizations and individual trainers. If you already have the account, please <Link
                href="#" color="primary" onClick={() => navigate('/')}> login here.</Link>
              </Typography>

              <TextField
                name="organizationName"
                label="Organization or Trainer"
                inputRef={
                  register({
                    required: true
                  })
                }
                helperText={errors.organizationName ? "This field is required!" : null}
                error={!!errors.organizationName}
                disabled={disabledForm}
                variant="outlined"
                style={{width: "80%", marginBottom: 16}}
              />
              <TextField
                name="fullName"
                label="Full Name"
                inputRef={
                  register({
                    required: true
                  })
                }
                helperText={errors.fullName ? "This field is required!" : null}
                error={!!errors.fullName}
                disabled={disabledForm}
                variant="outlined"
                style={{width: "80%", marginBottom: 16}}
              />
              <TextField
                name="email"
                label="Email"
                inputRef={
                  register({
                    required: true,
                    pattern: {
                      value: /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
                      message: "Invalid email!"
                    }
                  })
                }
                helperText={errors.email ? errors.email?.type === "required" ? "This field is required!" : "Invalid email!" : null}
                error={!!errors.email}
                disabled={disabledForm}
                variant="outlined"
                style={{width: "80%", marginBottom: 16}}
              />

              <Typography variant="body2" style={{width: "80%", marginBottom: 24}}>
                This email automatically links to your profile and is used for potential security and recovery actions, such as a forgotten email and / or password. You can also use this email to choose how to <Link
                href="#" color="primary" onClick={() => navigate('/')}>sign in to the MyFitWorld platform.</Link>
              </Typography>

              <Typography>Privacy Policy and Terms of Use <span style={{color: 'red'}}>*</span></Typography>
              <Box display='flex' flexDirection='row' alignItems='center' mt={1} mb={1}>
                <Checkbox
                  name="termsOfUse"
                  inputRef={
                    register({
                      required: true
                    })
                  }
                  disabled={disabledForm}
                />
                <Typography color='textPrimary'>I agree with <Link
                  href="https://myfitworld.net/privacy-policy-terms-of-use/" target="_blank">Privacy Policy & Terms Of
                  Use.</Link></Typography>
              </Box>
              {
                errors.termsOfUse && <Box mb={8}>
                  <span style={{color: 'red'}}>Before you continue, you have to agree to our Privacy Policy & Terms Of Use.</span>
                </Box>
              }

              <Button type="submit" variant="contained" color="primary" disabled={disabledForm}
                      style={{width: "80%", marginBottom: 16}}>
                Register
              </Button>

              <Box style={{width: "80%", marginTop: 24}}>
                {successMessage &&
                <Alert severity="success">
                  {successMessage}
                </Alert>
                }
                {accountExists &&
                <Alert severity="error">
                  There is an organization that you already created. You can<Link href="#" color="primary"
                                                                                  onClick={() => navigate('/')}> login
                  here. </Link>
                  Or if you didn't receive the initial email, please<Link href="#" color="primary"
                                                                          onClick={() => resendInvitation(formData)}> click
                  here </Link>so that we can resend it to you.
                </Alert>
                }
                {
                  resendMessage &&
                  <Alert severity="success">
                    {'In a few moments you will receive an email with a login link.'}
                  </Alert>
                }
              </Box>
            </Box>
          </form>
        </Box>
      </Grid>
    </Grid>
  );
};


export default TrainerOrganizationLoginPage;
