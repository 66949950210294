import {firestore} from "../firebase";
import APIResponse from "../model/APIResponse";
import queryBuilder from "./queryBuilder";
import ContentFilterValues from "../model/ContentFilter";
import {Entity, Resource} from "myfitworld-model";
import {sanitizeInput} from "./sanitizeInput";
import merge from "lodash/merge";
import clientSideContentFilter from "./clientSideContentFilter";
import get from 'lodash/get';
import globalState from "../globalState";

type CollectionReference = firebase.firestore.CollectionReference;

export interface ApiInterface<T> {
  get: (id: string) => Promise<T>;
  create: (raw: T) => Promise<APIResponse>;
  update: (raw: T) => Promise<APIResponse>;
  list: (organizationId: string, filter?: ContentFilterValues, sortKey?: string) => Promise<Array<T>>;
}

type Optional<T> = {
  [P in keyof T]?: T[P];
};

const withAuthorIdTag = (endpoint: string) => {
  const userId = globalState.getRawState().currentUserId;
  if ((
    endpoint === 'exercises' || endpoint === 'workouts' ||
    endpoint === 'programs'
    ) && userId) {
    return { authorId: userId };
  }

  return {};
}

export function createProviderFactory<T extends Resource>(
  endpoint: string,
  defaults?: Optional<T>
): (raw: T) => Promise<APIResponse> {
  return async (raw) => {
    const { id, ...data } = raw;

    return await new Promise((resolve, reject) => {
      firestore
        .collection(endpoint)
        .add({
          ...sanitizeInput(merge(defaults, data)),
          ...withAuthorIdTag(endpoint)
        })
        .then((documentRef) => {
          resolve({ success: true, documentRef: documentRef });
        })
        .catch((e) => {
          console.log("error creating object", e);
          reject({
            success: false,
            errorMessage:
              "There was an error saving your data. Check if you are connected to the Internet and try again…",
          });
        });
    });
  };
}

export function updateProviderFactory<T extends Resource>(endpoint: string): (data: T) => Promise<APIResponse> {
  return async (data) => {
    return await new Promise((resolve, reject) => {
      try {
        const documentRef = firestore
          .collection(endpoint)
          .doc(data.id);

          documentRef.set({ ...sanitizeInput(data) }, { merge: true })
          .then(() => {
            resolve({ success: true, documentRef });
          });
      } catch (e) {
        reject({
          success: false,
          errorMessage:
            "There was an error saving your data. Check if you are connected to the Internet and try again…",
        });
      }
    });
  };
}

export const filterByOrganization = (endpoint: string, organizationId: string, ref: CollectionReference) => {
  const orgs = [organizationId, process.env.REACT_APP_MFW_ORGANIZATION_ID].filter((_) => !!_);
  switch (endpoint) {
    case "exercises":
    case "workouts":
    case "programs":
    case "equipment":
    case "fitnessActivities":
    case "muscleGroups":
    case "goals":
    case "motorSkills":
    case "sports":
    case "healthIssues":
    case "expertises":
      return ref.where("organizationId", "in", orgs);
    default:
      return ref.where("organizationId", "==", organizationId);
  }
};

const shouldFilterByAuthor = (endpoint: string) => {
  switch (endpoint) {
    case "exercises":
    case "workouts":
    case "programs":
      return true;
    default:
      return false;
  }
}

export function listProviderFactory<T extends Entity>(
  endpoint: string
): (organizationId: string, filter?: ContentFilterValues, sortKey?: string) => Promise<Array<T>> {
  return async (organizationId, contentFilter, sortKey) => {
    return new Promise((resolve) => {
      let query = filterByOrganization(endpoint, organizationId, firestore.collection(endpoint));

      if (contentFilter) {
        query = queryBuilder(query, contentFilter);
      }

      query
        .get()
        .then((querySnapshot) => {
          let data: Array<T> = [];
          querySnapshot.forEach((snapshot) => {
            data.push({
              id: snapshot.id,
              ...snapshot.data(),
            } as T);
          });
          if (contentFilter) {
            data = clientSideContentFilter(data, contentFilter, shouldFilterByAuthor(endpoint)) as Array<T>;
          }
          if (sortKey) {
            data.sort((l, r) => get(l, sortKey)?.localeCompare(get(r, sortKey)));
          }
          resolve(data);
        })
        .catch((error) => {
          console.error("error fetching list", error);
          resolve([]);
        });
    });
  };
}

export function getProviderFactory<T extends Resource>(endpoint: string): (id: string) => Promise<T> {
  return async (id) => {
    return new Promise((resolve, reject) => {
      firestore
        .collection(endpoint)
        .doc(id)
        .get()
        .then((doc) => {
          if (doc.exists) {
            resolve({ id: doc.id, ...doc.data() } as T);
          } else {
            reject(`${endpoint}/${id} not found!`);
          }
        })
        .catch((error) => {
          console.error(`error getting item ${endpoint}, ${id}`, error);
          resolve(undefined);
        });
    });
  };
}

function interfaceFactory<T extends Entity>(endpoint: string): ApiInterface<T> {
  return {
    create: createProviderFactory<T>(endpoint),
    update: updateProviderFactory<T>(endpoint),
    list: listProviderFactory<T>(endpoint),
    get: getProviderFactory<T>(endpoint),
  };
}

export default interfaceFactory;
