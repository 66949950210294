import React, {useCallback} from "react";
import {Box} from "@material-ui/core";
import {useLocalizationProvider} from "../../../providers/LocalizationProvider";
import TextField from "@material-ui/core/TextField";
import MultiSelectField from "../../../components/MultiSelectField";
import useGlobalCachedResourceList from "../../../api/useGlobalCachedResourceList";
import {GeneralEntity, User} from "myfitworld-model";

const EditProfileInfo = ({userProfile, setUserProfile, toggleChanged, disabled} : Props) => {
  const {translate} = useLocalizationProvider();
  const {data: ExpertiseOptions} = useGlobalCachedResourceList<GeneralEntity>('expertisesCache');

  const onChange = useCallback(
    async (e) => {
      setUserProfile({...userProfile, [e.target.name]: e.target.value});
      toggleChanged(true);
    },
    [userProfile, setUserProfile, toggleChanged]
  );

  return (
    <Box display="flex" flexDirection="column" flex={1} m={2}>
      <TextField
        name="firstName"
        label={translate("First name")}
        value={userProfile.firstName ?? ''}
        onChange={(e) => onChange(e)}
        disabled={disabled}
        type="text"
        margin="dense"
        autoFocus
        fullWidth
        required
      />

      <TextField
        name="lastName"
        label={translate("Last name")}
        value={userProfile.lastName ?? ''}
        onChange={(e) => onChange(e)}
        disabled={disabled}
        type="text"
        margin="dense"
        autoFocus
        fullWidth
        required
      />

      <TextField
        name="email"
        label={translate("Email")}
        value={userProfile.email ?? ''}
        onChange={(e) => onChange(e)}
        disabled={disabled}
        type="text"
        margin="dense"
        autoFocus
        fullWidth
        required
      />

      <TextField
        name="phoneNumber"
        label={translate("Phone")}
        value={userProfile.phoneNumber ?? ''}
        onChange={(e) => onChange(e)}
        disabled={disabled}
        type="text"
        margin="dense"
        autoFocus
        fullWidth
        required
      />

      <MultiSelectField
        label={translate("Expertise")}
        options={ExpertiseOptions}
        value={userProfile.expertise ?? []}
        onChange={(keys) => {
          setUserProfile({...userProfile, 'expertise' : keys});
          toggleChanged(true);
        }}
        disabled={disabled}
      />

      <TextField
        name="aboutMe"
        label={translate("About me")}
        value={userProfile.aboutMe ?? ''}
        onChange={(e) => onChange(e)}
        disabled={disabled}
        placeholder={translate("trainer_profile.about_me_placeholder")}
        multiline
        rows={5}
        rowsMax={10}
        margin="dense"
        style={{width: "100%"}}
      />
    </Box>
  );
};

export default EditProfileInfo;

interface Props {
  userProfile: User;
  setUserProfile: (value: User) => void;
  toggleChanged: (value: boolean) => void;
  disabled: boolean;
}
