import DayOfWeek from 'myfitworld-model/dist/enums/DayOfWeek';

const dayOfWeekToDayIndex = (day: DayOfWeek) => {
  switch(day) {
    case DayOfWeek.MONDAY:
      return 1;
    case DayOfWeek.TUESDAY:
      return 2;
    case DayOfWeek.WEDNESDAY:
      return 3;
    case DayOfWeek.THURSDAY:
      return 4;
    case DayOfWeek.FRIDAY:
      return 5;
    case DayOfWeek.SATURDAY:
      return 6;
    case DayOfWeek.SUNDAY:
    default:
      return 0;
  }
};

export default dayOfWeekToDayIndex;
