import React, {Fragment, CSSProperties, useMemo, useState} from 'react';
import Exercise from 'myfitworld-model/dist/firestoreCollectionTypes/Exercise';
import Image from 'material-ui-image';
import {Chip, Typography} from '@material-ui/core';
import Box from "@material-ui/core/Box";
import MFWChip from "../../../components/MFWChip";
import getDescriptionSnippet from "../../../utils/getDescriptionSnippet";
import getLocalizedProperty from "../../../utils/getLocalizedProperty";
import BaseContentListItem, {baseContentListStyles} from "../BaseContentListItem";
import thumbPlaceholder from '../../../assets/images/thumb_placeholder.png';
import getImageThumbnail from "../../../utils/getImageThumbnail";
import ExercisePreviewDialog from "./ExercisePreviewDialog";

const ExerciseListItem = (
  {item, onSelect, sports, muscleGroups, equipment, hideTags, style}:
    {
      item: Exercise,
      onSelect: (event: React.MouseEvent) => void,
      sports: Record<string, string>,
      muscleGroups: Record<string, string>,
      equipment: Record<string, string>
      hideTags?: boolean,
      style?: CSSProperties
    }
) => {
  const tags = useMemo(() => {
    if (hideTags) {
      return [];
    }
    return [
      ...(item.muscleGroups || []).map(item => muscleGroups[item]),
      ...(item.secondaryMuscleGroups || []).map(item => muscleGroups[item]),
      ...(item.sports || []).map(item => sports[item]),
      ...(item.equipment || []).map(item => equipment[item])
    ]
  }, [sports, muscleGroups, equipment, item.muscleGroups, item.secondaryMuscleGroups, item.sports, item.equipment, hideTags]);
  const baseContentListClasses = baseContentListStyles();
  const [ dialogOpen, toggleDialogOpen ] = useState(false);

  return (
    <Fragment>
      <BaseContentListItem onSelect={onSelect} style={style}>
        <Box display="flex" justifyContent="space-between" flex={1}>
          <Box display="flex" flexDirection="row" alignItems="center">
            <Box className={baseContentListClasses.thumbnail}>
              <Image
                src={item.thumbnailURL ? getImageThumbnail((item as any).thumbURLS || [])?.url || item.thumbnailURL : thumbPlaceholder}
                aspectRatio={(16 / 9)}
                color="transparent"
                className={baseContentListClasses.image}
                alt={getLocalizedProperty(item.title)}
                disableSpinner
                onClick={(e) => {
                  e.stopPropagation();
                  toggleDialogOpen(true);
                }}
              />
            </Box>
            <Box>
              <MFWChip organizationId={item.organizationId}/>
              <Typography variant="h5">{getLocalizedProperty(item.title)}</Typography>
              <Typography variant="body2"
                          color="textSecondary">{item.description && getDescriptionSnippet(getLocalizedProperty(item.description))}</Typography>
              <Box className={baseContentListClasses.chips} display="flex" flexWrap="nowrap">
                {tags.map((tag, i) =>
                  <Box key={i} m={.25}>
                    <Chip size='small' label={tag} variant="outlined"/>
                  </Box>
                )}
              </Box>
            </Box>
          </Box>
          <Box>
            {item.difficultyLevel !== undefined &&
            <Box display="flex" flexDirection="row" ml={10}>
              <Typography noWrap variant='h2' color="primary">
                {`${item?.difficultyLevel}`}
              </Typography> &nbsp; &nbsp;
              <Typography noWrap variant='h2'>
                {`/ 5`}
              </Typography>
            </Box>
            }
          </Box>
        </Box>
      </BaseContentListItem>
      <ExercisePreviewDialog
        exercise={item}
        open={dialogOpen}
        onClose={() => toggleDialogOpen(false)}
      />
    </Fragment>
  );
};

export default ExerciseListItem;
