import React, {useContext, useEffect, useMemo, useState} from 'react';
import ToggleButton from '@material-ui/lab/ToggleButton';
import ToggleButtonGroup from '@material-ui/lab/ToggleButtonGroup';
import LanguageContext from "./LanguageContext";
import {Badge} from "@material-ui/core";
import {Resource} from "myfitworld-model";
import get from 'lodash/get';

const notTranslated = (value: string | undefined): boolean => !(value && value.length > 0);

function LanguageSelect<T extends Resource>({watch}: {watch: (_: Array<string>) => T}) {

  const {language, languages, setLanguage, registeredFields} = useContext(LanguageContext);

  const [issues, setIssues] = useState<Record<string, boolean>>(() =>
    languages.reduce((acc, curr) => ({...acc, [curr]: false}), {}) as Record<string, boolean>
  );

  const values = useMemo(() => watch(registeredFields), [registeredFields, watch]);
  useEffect(() => {
    // Not performant and a mess, idea is rewrite this to count the issues so we can show them in the indicator
    // const checkByField = registeredFields.map(field => {
    //   return languages.reduce((acc, curr) => ({
    //     ...acc, [curr]: notTranslated(get(values, `${field}.${curr}`))
    //   }), {});
    // });
    //
    // const hasIssues = checkByField.reduce((acc, curr) => {
    //   return languages.reduce((set, lang) => ({...set, [lang]: (acc as Record<Languages, boolean>)[lang] || (curr as  Record<Languages, boolean>)[lang] }), {})
    // }, {});
    //
    // setIssues(hasIssues as Record<Languages, boolean>);

    const issues = {} as Record<string, boolean>;

    languages.forEach(lang => {
      for(let field of registeredFields) {
        issues[lang] = notTranslated(get(values, `${field}.${lang}`));
        if(issues[lang]) {
          break;
        }
      }
    })

    setIssues(issues as Record<string, boolean>);

  }, [values, registeredFields, languages]);

  const handleChange = (event: React.MouseEvent<HTMLElement>, lang: string) => {
    if (lang) {
      setLanguage(lang);
    }
  };

  return (
    <ToggleButtonGroup
      size="small"
      value={language}
      exclusive
      onChange={handleChange}
      aria-label="Language select"
      >
        {languages.map(lang => (
          <ToggleButton value={lang} aria-label={lang} key={lang} >
            <Badge variant="dot" color="error" invisible={!issues[lang]}>
              <label>{lang}</label>
            </Badge>
          </ToggleButton>
        ))}
    </ToggleButtonGroup>
  );
}

export default LanguageSelect;
