import React, {useCallback, useEffect, useMemo, useState} from 'react';
import {
  Box,
  Button,
  createStyles,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Theme,
  Typography
} from '@material-ui/core';
import {IdentityNote, SecurityNote} from '../utils/Notes';
import {makeStyles} from "@material-ui/core/styles";
import {CardElement, useElements, useStripe} from "@stripe/react-stripe-js";
import IdentityDetails from "../forms/items/IdentityDetails";
import {
  BillingDetailsErrors,
  BillingDetailsInterface,
  emptyFullNameAndCardDetails,
  FullNameAndCardDetails,
  getFullNameAndCardDetails
} from "../utils/BillingDetailsInterface";
import globalState from "../../../globalState";
import {useStoreState} from "pullstate";
import {createOrganizationBillingDetails, updateOrganizationCardDetails} from "../../../api/billingDetailsApi";
import {BillingDetails} from "myfitworld-model";
import useNavigation from "../../../hooks/useNavigation";
import {auth} from "../../../firebase";
import ExitToAppIcon from "@material-ui/icons/ExitToApp";
import {Alert} from "@material-ui/lab";
import {MONTHLY_PLAN} from "../utils";
import LoadingSpinner from "../../LoadingSpinner";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    actions: {
      '& > *': {
        marginLeft: theme.spacing(1),
      }
    },
  }),
);

const useOptions = () => {
  const options = useMemo(
    () => ({
      style: {
        base: {
          fontSize: "1.5rem",
          color: "#aab7c4",
          backgroundColor: "#333333",
          letterSpacing: "0.025em",
          fontFamily: "Helvetica Neue, Arial, Roboto",
          "::placeholder": {
            color: "#6b6b6b"
          },
          iconColor: "#34d867"
        },
      }
    }), []
  );

  return options;
};

const CardDetailsDialog = (props: Props) => {
  const classes = useStyles();
  const options = useOptions();
  const stripe = useStripe();
  const elements = useElements();
  const currentOrganization = useStoreState(globalState, s => s.currentOrganization);

  const {open, action, defaultState, billingDetails, onSave, onClose, errors, setErrors} = props;

  const {navigation} = useNavigation();

  const handleLogout = () => {
    auth
      .signOut()
      .then(async (_) => {
        await navigation('/');
      })
      .catch((err) => console.log(err));
  };

  const [values, setValues] = useState<FullNameAndCardDetails>(emptyFullNameAndCardDetails);
  const [cardErrorMessage, setCardErrorMessage] = useState<string | undefined>(undefined);
  const [infoMessage, setInfoMessage] = useState<boolean>(false);
  const [disabled, setDisabled] = useState<boolean>(false);

  useEffect(() => {
    setValues(getFullNameAndCardDetails(defaultState));
  }, [defaultState]);

  const validate = () => {
    setErrors && setErrors(Object.entries(values).reduce((errors, pair,) => {
      return {...errors, [pair[0]]: pair[1] === ""};
    }, {}) as BillingDetailsErrors);

    return Object.entries(values).reduce((acc, el) => acc || (el[1] === ""), false);
  };

  const handleOnChange = useCallback((e: any) => {
    const {name, value} = e.target;

    if (setErrors && errors) {
      value === "" ?
        setErrors({...errors, [name]: true})
        : setErrors({...errors, [name]: false});
    }

    setValues(state => ({
      ...state,
      [name]: value
    }));
  }, [setValues, errors, setErrors]);

  const onSaveBillingDetails = async () => {
    if (!stripe || !elements || disabled) {
      return;
    }
    setDisabled(true);

    const payload = await stripe.createPaymentMethod({
      type: "card",
      // @ts-ignore
      card: elements.getElement(CardElement)
    });

    if (payload.error) {
      setCardErrorMessage(payload.error.message);
      setInfoMessage(false);
      setDisabled(false);
    } else {
      setCardErrorMessage(undefined);
      setInfoMessage(true);
      currentOrganization && currentOrganization.id && await createOrganizationBillingDetails(
        currentOrganization.id,
        {
          email: defaultState?.email,
          phone: defaultState?.phoneNumber,
          billing_address: {
            address: defaultState?.address,
            city: defaultState?.city,
            countryCode: defaultState?.countryCode,
            postalCode: defaultState?.postalCode,
            state: defaultState?.stateCode,
            taxID: defaultState?.taxID
          },
          stripe: {
            ...billingDetails?.stripe,
            paymentMethodId: payload.paymentMethod?.id,
            priceId: defaultState?.plan === MONTHLY_PLAN ? process.env.REACT_APP_MONTHLY_PLAN_ID : defaultState?.plan === process.env.REACT_APP_SIX_MONTH_PLAN ? process.env.REACT_APP_SIX_MONTH_PLAN_ID : process.env.REACT_APP_ANNUALLY_PLAN_ID
          },
          card: {
            name: `${values.firstName} ${values.lastName}`,
            exp_month: payload.paymentMethod?.card?.exp_month,
            exp_year: payload.paymentMethod?.card?.exp_year,
            last4: payload.paymentMethod?.card?.last4
          }
        }
      ).then((res) => {
        res.json().then((res) => {
          if (res?.error) {
            console.log("error", res);
            setCardErrorMessage(`${res?.error?.message || ''} Please try again with valid data or try again with another card.`);
            setDisabled(false);
            setInfoMessage(false);
          }
        });
      });
    }
  };

  const onEditCard = async () => {
    if (!stripe || !elements || disabled) {
      return;
    }
    setDisabled(true);

    const payload = await stripe.createPaymentMethod({
      type: "card",
      // @ts-ignore
      card: elements.getElement(CardElement)
    });

    console.log("payload", payload.error);
    if (payload.error) {
      setCardErrorMessage(payload.error.message);
      setInfoMessage(false);
      setDisabled(false);
    } else {
      setCardErrorMessage(undefined);
      setInfoMessage(true);
      currentOrganization && currentOrganization.id && await updateOrganizationCardDetails(
        currentOrganization.id,
        {
          ...billingDetails,
          stripe: {
            ...billingDetails?.stripe,
            paymentMethodId: payload.paymentMethod?.id
          },
          card: {
            name: `${values.firstName} ${values.lastName}`,
            exp_month: payload.paymentMethod?.card?.exp_month,
            exp_year: payload.paymentMethod?.card?.exp_year,
            last4: payload.paymentMethod?.card?.last4
          }
        }
      ).then((res) => {
        res.json().then((res) => {
          if (res?.error) {
            console.log("error", res);
            setCardErrorMessage(`${res?.error?.message || ''} Please try again with valid data or try again with another card.`);
          } else {
            onClose && onClose();
          }
          setDisabled(false);
          setInfoMessage(false);
        });
      });
    }
  };

  const onEdit = async () => {
    if (!validate()) {
      onSave && defaultState && onSave({
        ...defaultState,
        ...values
      });
      await onEditCard();
    }
  };

  const onCreateAndNext = async () => {
    if (!validate()) {
      onSave && defaultState && onSave({
        ...defaultState,
        ...values
      });
      await onSaveBillingDetails();
    }
  };

  return (
    <Dialog open={open} maxWidth='sm' fullWidth>
      <DialogTitle>
        <Box pt={1}>
          <Typography variant='h4' component='p'>
            Connect Your Card
          </Typography>
        </Box>
      </DialogTitle>
      {infoMessage && <LoadingSpinner marginTop={4}/>}
      <DialogContent style={{paddingBottom: '3rem'}}>
        <Box mb={2}>
          <IdentityDetails
            firstName={values.firstName}
            lastName={values.lastName}
            onChange={handleOnChange}
            errors={errors}
          />
          <IdentityNote/>
        </Box>

        <Box style={{padding: 15, backgroundColor: "#333333", borderRadius: 5}}>
          <CardElement
            options={options}
          />
        </Box>
        <SecurityNote/>
        {cardErrorMessage &&
        <Alert severity='error' style={{marginTop: 16}}>
          {cardErrorMessage}
        </Alert>}
        {infoMessage &&
        <Alert severity='info' style={{marginTop: 16}}>
          {"Validation of the entered data can take a few minutes. If the data validation is completed successfully, you will have access to the MyFitWorld platform."}
        </Alert>}
      </DialogContent>
      <DialogActions>
        <Box px={2} pb={2} className={classes.actions}>
          {action === "UPDATE" && <Button color='primary' variant='text' disabled={disabled} onClick={() => onClose && onClose()}>
            Cancel
          </Button>}
          {action === "UPDATE" && <Button color='primary' variant='contained' disabled={disabled} onClick={() => onEdit()}>
            Connect Card
          </Button>}
          {action === "CREATE" && <Button color='primary' variant='text' disabled={disabled} onClick={() => onCreateAndNext()}>
            Connect Card and Next
          </Button>}
          {action === "CREATE" && <Button
            onClick={handleLogout} color="secondary"
            startIcon={<ExitToAppIcon/>}
          >
            Or Log out
          </Button>}
        </Box>
      </DialogActions>
    </Dialog>
  );
}

interface Props {
  open: boolean;
  action: "CREATE" | "UPDATE",
  defaultState?: BillingDetailsInterface;
  onSave?: (data: BillingDetailsInterface) => void;
  onNext?: (dialogName: string) => void;
  onClose?: () => void;
  billingDetails?: BillingDetails;
  errors?: BillingDetailsErrors;
  setErrors?: (errors: BillingDetailsErrors) => void
}

export default CardDetailsDialog;
