import React from 'react';
import { Grid, TextField } from '@material-ui/core';
import {BillingDetailsErrors} from "../../utils/BillingDetailsInterface";

const IdentityDetails = ({
  firstName,
  lastName,
  onChange,
  errors
}: {
  firstName: string;
  lastName: string;
  onChange: (e: any) => void;
  errors?: BillingDetailsErrors;
}) => {
  return (
    <Grid container spacing={2}>
      <Grid item xs={6}>
        <TextField
          label='First name'
          name='firstName'
          color='primary'
          variant='standard'
          value={firstName}
          onChange={(e: any) => onChange(e)}
          required={true}
          helperText={errors?.firstName ? "This field is required." : null}
          error={!!errors?.firstName}
          fullWidth
        />
      </Grid>
      <Grid item xs={6}>
        <TextField
          label='Last name'
          name='lastName'
          color='primary'
          variant='standard'
          value={lastName}
          required={true}
          helperText={errors?.lastName ? "This field is required." : null}
          error={!!errors?.lastName}
          onChange={onChange}
          fullWidth
        />
      </Grid>
    </Grid>
  );
}

export default IdentityDetails;
