import {makeStyles} from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
  rootContainer: {
    marginBottom: theme.spacing(4),
  },
  header: {
    width: '100%',
    display: 'flex',
    paddingTop: theme.spacing(1),
    paddingRight: theme.spacing(2),
    paddingBottom: theme.spacing(1),
    paddingLeft: theme.spacing(2),
    backgroundColor: 'rgba(255, 255, 255, 0.035)'
  },
  leftSection: {
    width: '50%',
    display: 'inline-flex',
  },
  middleSection: {
    display: 'inline-flex',
  },
  rightSection: {
    display: 'inline-flex',
    justifyContent: 'flex-end',
    marginLeft: 'auto',
    marginTop: 'auto',
    marginBottom: 'auto',
  },
  groupItemRoot: {
    display: 'flex',
    borderBottom: '1px solid rgba(255, 255, 255, 0.05)'
  },
  exerciseProperties: {
    '& > * + *': {
      marginTop: theme.spacing(2),
    }
  },
  nowrap: {
    whiteSpace: 'nowrap',
  }
}));


export default useStyles;
