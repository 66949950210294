import React, {useRef} from 'react';
import { useDrag, useDrop, DropTargetMonitor } from 'react-dnd';
import {TableRow} from '@material-ui/core';

export interface CardProps {
  index: number
  onMove: (dragIndex: number, hoverIndex: number) => void
  accept: string
}

interface DragItem {
  index: number
  type: string
  children: React.ReactNode
}

const DraggableItem: React.FC<CardProps> = ({ children, index, onMove, accept }) => {
  const ref = useRef<HTMLDivElement>(null);

  const [, drop] = useDrop({
    accept: accept,
    drop(item: DragItem, monitor: DropTargetMonitor) {
      const dragIndex = item.index;
      onMove(dragIndex, index);
    }
  })

  const [{ isDragging }, drag] = useDrag({
    item: { type: accept, index },
    collect: (monitor: any) => ({
      isDragging: monitor.isDragging(),
    }),
  });

  drag(drop(ref));
  return (
    <TableRow
      innerRef={ref}
      style={{
        opacity: isDragging ? 0.1 : 1,
        cursor: isDragging ? 'grabbing' : 'grab',
        border: isDragging ? '2px dashed rgb(81, 81, 81)' : 'none'
      }}>
      {children}
    </TableRow>
  )
}

export default DraggableItem;
