import extractWorkouts from "./form/extractWorkouts";
import {UserProgram, Workout} from "myfitworld-model";
import ProgramTemplate from "myfitworld-model/dist/firestoreCollectionTypes/ProgramTemplate";
import {WorkoutDeletion} from "../../api/UserWithCRUDInstructions";

export const handleImportProgramTemplateToActiveProgram = ({
  startDate,
  programTemplate,
  workoutTemplates,
  programTemplates,
  activeProgram,
  handleProgramChange,
  activeProgramIndex
}: {
  startDate: string;
  programTemplate: string;
  workoutTemplates: Workout[];
  programTemplates: ProgramTemplate[],
  activeProgram: UserProgram;
  handleProgramChange: (index: number, changed: UserProgram) => void;
  activeProgramIndex: number;
}) => {
  const workouts = extractWorkouts(programTemplates, workoutTemplates, programTemplate, startDate);
  const changed = { ...activeProgram };
  changed.workouts = [ ...(changed.workouts || []), ...workouts ];
  handleProgramChange(activeProgramIndex, changed);
}

export const handleAddNewProgram = ({
  startDate,
  defineExplicitTime,
  programTemplate,
  programTemplates,
  workoutTemplates,
  setPrograms,
  programs,
 }: {
  startDate: string;
  defineExplicitTime: boolean;
  programTemplate?: string;
  workoutTemplates: Workout[];
  programTemplates: ProgramTemplate[];
  programs: UserProgram[];
  setPrograms: (programs: UserProgram[]) => void;
}) => {
  const newProgram = {
    title: `Auto Gen Program (on ${startDate})`,
    startDate,
    defineExplicitTime,
    isActive: true,
    workouts: programTemplate ? extractWorkouts(programTemplates, workoutTemplates, programTemplate, startDate) : [],
  } as UserProgram;
  if (programTemplate) {
    newProgram.originalProgramId = programTemplate;
  }
  setPrograms([...programs, newProgram]);
};

export const deleteWorkoutFromActiveProgram = ({
  workoutIndex,
  activeProgram,
  workoutDeletions,
  setWorkoutDeletions,
  handleProgramChange,
  activeProgramIndex,
}: {
  workoutIndex: number;
  activeProgram: UserProgram;
  handleProgramChange: (index: number, changed: UserProgram) => void;
  activeProgramIndex: number;
  workoutDeletions: WorkoutDeletion[];
  setWorkoutDeletions: (deletions: WorkoutDeletion[]) => void;
}) => {
  const workout = activeProgram.workouts![workoutIndex];
  if (activeProgram.id) {
    if (workout && workout.id) {
      const itemIndex = workoutDeletions.findIndex((_) => _.programId === activeProgram.id);
      if (itemIndex >= 0) {
        workoutDeletions[itemIndex].workoutIds.push(workout.id);
      } else {
        workoutDeletions.push({programId: activeProgram.id, workoutIds: [workout.id]});
      }
      setWorkoutDeletions([...workoutDeletions]);
    }

    const changed = {...activeProgram};
    changed.workouts!.splice(workoutIndex, 1);
    handleProgramChange(activeProgramIndex, changed);
  }
};
