import React, {Fragment, useMemo} from "react";
import {RouteComponentProps} from "@reach/router";
import {useUserProvider} from "../../providers/UserProvider";
import {getCurrentUserRole} from "../../api/usersApi";
import {Role} from "myfitworld-model";
import BillingDetailsAdminPage from "./BillingDetailsAdminPage";
import BillingDetailsTrainerPage from "./BillingDetailsTrainerPage";

const BillingDetailsPage = (_: RouteComponentProps) => {
  const {user, organizationOverride} = useUserProvider();
  const currentUserRole = useMemo(() => {
    return user ? getCurrentUserRole(user, organizationOverride) : undefined;
  }, [user, organizationOverride]);

  console.log("page bd");
  return (
    <Fragment>
      {currentUserRole === Role.Admin && <BillingDetailsAdminPage /> }
      {(currentUserRole === Role.Trainer || currentUserRole === Role.AssistantTrainer) && <BillingDetailsTrainerPage /> }
    </Fragment>
  );
}

export default BillingDetailsPage;
