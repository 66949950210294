import {
  Box,
  Button,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  Typography
} from "@material-ui/core";
import {format} from "date-fns";
import React, {useEffect, useState} from "react";
import {UpcomingInvoice, UpcomingInvoiceItem} from "../../components/payment/utils";
import useTableStyles from "../../theme/useTableStyles";
import {getUpcomingInvoice} from "../../api/billingDetailsApi";
import LoadingSpinner from "../../components/LoadingSpinner";
import {BillingDetails} from "myfitworld-model";

const UpcomingInvoiceItemsTable = ({billingDetails, setOpenDialog} : Props) => {
  const tableClasses = useTableStyles();

  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);

  const handleChangePage = (event: React.MouseEvent<HTMLButtonElement> | null, newPage: number) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
  ) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const [loading, setLoading] = useState<boolean>(true);
  const [upcomingInvoice, setUpcomingInvoice] = useState<UpcomingInvoice | null>(null);

  useEffect(() => {
    if (billingDetails && billingDetails?.stripe?.customerId && billingDetails?.stripe?.subscriptionId) {
      getUpcomingInvoice(billingDetails.stripe.customerId, billingDetails.stripe.subscriptionId).then((res) => {
        res.json().then((res) => {
          console.log("upcoming", res);
          setUpcomingInvoice(res);
        });
        setLoading(false);
      });
    }
  }, [billingDetails]);

  if (loading) {
    return <LoadingSpinner marginTop={16}/>;
  }

  return (
    <Box m={2} ml={4}>
      <Box
        display="flex"
        justifyContent="space-between"
        alignItems="baseline"
        mb={2}
      >
        <Box>
          <Typography
            variant='h4'
            component='h1'
            color='textSecondary'
          >
            {`Upcoming invoice ${ upcomingInvoice ? `(${format(new Date(upcomingInvoice.periodStart * 1000), "d MMMM yyyy")} - ${format(new Date(upcomingInvoice.periodEnd * 1000), "d MMMM yyyy")})` : ''}`}
          </Typography>
          {upcomingInvoice && <Typography variant='body2'>
            {`This is a preview of the invoice that will be billed on ${format(new Date(upcomingInvoice.periodEnd * 1000), "d MMMM yyyy")}. It may change if the subscription is updated.`}
          </Typography>}
        </Box>

        <Button
          color='primary'
          onClick={() => setOpenDialog(true)}
        >
          {"Edit billing details"}
        </Button>
      </Box>
      <TableContainer>
        <Table className={tableClasses.table} size="medium">
          <TableHead>
            <TableRow>
              <TableCell>{"Description"}</TableCell>
              <TableCell align="right">{"Quantity"}</TableCell>
              <TableCell align="right">{"Amount"}</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {(upcomingInvoice?.invoiceItems.slice(page*rowsPerPage, (page+1)*rowsPerPage) || []).map((item: UpcomingInvoiceItem, index) => (
              <TableRow key={index} hover>
                <TableCell>{item.description}</TableCell>
                <TableCell align="right">{`${item.quantity}`}</TableCell>
                <TableCell align="right">{item.amount}</TableCell>
              </TableRow>
            ))}
            <TableRow hover>
              <TableCell>{""}</TableCell>
              <TableCell align="right"><Typography variant="h6">Subtotal</Typography></TableCell>
              <TableCell align="right">{upcomingInvoice?.subtotal || '-'}</TableCell>
            </TableRow>
            {upcomingInvoice?.discountName && <TableRow hover>
              <TableCell>{""}</TableCell>
              <TableCell align="right"><Typography variant="h6">{upcomingInvoice?.discountName}</Typography></TableCell>
              <TableCell align="right">{upcomingInvoice?.total_discount_amounts || '-'}</TableCell>
            </TableRow>}
            {/*<TableRow hover>*/}
            {/*  <TableCell>{""}</TableCell>*/}
            {/*  <TableCell align="right"><Typography variant="h6">Tax</Typography></TableCell>*/}
            {/*  <TableCell align="right">{upcomingInvoice?.tax || '-'}</TableCell>*/}
            {/*</TableRow>*/}
            <TableRow hover>
              <TableCell>{""}</TableCell>
              <TableCell align="right"><Typography variant="h6">Total</Typography></TableCell>
              <TableCell align="right">{upcomingInvoice?.total || '-'}</TableCell>
            </TableRow>
            <TableRow hover>
              <TableCell>{""}</TableCell>
              <TableCell align="right"><Typography variant="h6">Amount Due</Typography></TableCell>
              <TableCell align="right">{upcomingInvoice?.amount_due || '-'}</TableCell>
            </TableRow>
          </TableBody>
        </Table>

        <TablePagination
          component="div"
          count={upcomingInvoice?.invoiceItems.length || 0}
          page={page}
          onChangePage={handleChangePage}
          rowsPerPage={rowsPerPage}
          onChangeRowsPerPage={handleChangeRowsPerPage}
        />
      </TableContainer>
    </Box>
  );
}

interface Props {
  billingDetails?: BillingDetails,
  setOpenDialog: (open: boolean) => void
}

export default UpcomingInvoiceItemsTable;
