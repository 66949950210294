import React, {Fragment, useEffect, useMemo, useState} from "react";
import {Button, CircularProgress, LinearProgress, Typography} from "@material-ui/core";
import {Alert} from "@material-ui/lab";
import {useForm} from "react-hook-form";
import useEntityList from "../../../hooks/useEntityList";
import ProgramTemplate from "myfitworld-model/dist/firestoreCollectionTypes/ProgramTemplate";
import {programTemplateApi, workoutApi} from "../../../api/common";
import Workout from "myfitworld-model/dist/firestoreCollectionTypes/Workout";
import User, {UserProgram} from "myfitworld-model/dist/firestoreCollectionTypes/User";
import UserWithCRUDInstructions, {WorkoutDeletion} from "../../../api/UserWithCRUDInstructions";
import {Equipment} from "myfitworld-model/dist/firestoreCollectionTypes/Equipment";
import {Role} from "myfitworld-model";
import MultiSelectField from "../../../components/MultiSelectField";
import {listUsers, updateUserOrganizations} from "../../../api/usersApi";
import {useOrganizationProvider} from "../../../providers/OrganizationProvider";
import Enumerable from "myfitworld-model/dist/firestoreCollectionTypes/Enumerable";
import transformWorkoutsIntoCalendarEvents from "./clientCalendar/transformWorkoutsIntoCalendarEvents";
import ClientCalendar from "./clientCalendar/ClientCalendar";
import i18n from "i18n-js";
import ClientProfile from "./ClientProfile";
import {useExerciseProvider} from "../../../providers/ExerciseProvider";
import useGlobalCachedResourceList from "../../../api/useGlobalCachedResourceList";
import Box from "@material-ui/core/Box";
import useNavigation from "../../../hooks/useNavigation";
import {useLocation} from "@reach/router";
import {
  deleteWorkoutFromActiveProgram as deleteWorkoutFromActiveProgram_,
  handleAddNewProgram as handleAddNewProgram_,
  handleImportProgramTemplateToActiveProgram as handleImportProgramTemplateToActiveProgram_,
} from '../utilities';
import TopActionBar from "../TopActionBar";
import globalState, {GlobalState} from "../../../globalState";
import {useLocalizationProvider} from "../../../providers/LocalizationProvider";
import {useStoreState} from "pullstate";
import {incrementSubscriptionQuantity} from "../../../api/billingDetailsApi";
import {useUserProvider} from "../../../providers/UserProvider";

const getArchivedField = (user: UserWithCRUDInstructions, organizationID: string) : boolean | undefined=> {
  return user?.organizations?.find((orgUser) =>
    orgUser.id === organizationID && orgUser.role === Role.Client
  )?.archived;
}

const ClientForm = ({
                      handleClose,
                      handleCreate,
                      handleUpdate,
                      defaultState,
                      loadUser,
                      mode,
                      loading,
                      formErrorMessage,
                    }: {
  handleClose: () => void;
  handleCreate?: (data: UserWithCRUDInstructions) => Promise<any>;
  handleUpdate?: (data: UserWithCRUDInstructions) => Promise<any>;
  defaultState?: UserWithCRUDInstructions;
  loadUser: () => Promise<any>;
  mode: "Create" | "Edit";
  loading: boolean;
  formErrorMessage?: string;
}) => {
  const {handleSubmit: useFormHandleSubmit} = useForm<User>(
    mode === "Edit" && defaultState !== undefined ? {defaultValues: defaultState} : {defaultValues: {}}
  );

  const [workoutDeletions, setWorkoutDeletions] = useState<WorkoutDeletion[]>([]);
  const [trainers, setTrainers] = useState<string[]>(defaultState?.trainers || []);
  // eslint-disable-next-line
  const [activeProgramChangesCount, setActiveProgramChangesCount] = useState(-1);


  const {translate} = useLocalizationProvider();
  const {navigation} = useNavigation();
  const location = useLocation();
  const role = globalState.getRawState().currentRole;
  const currentOrganization = useStoreState(globalState, s => s.currentOrganization);
  const {organizationOverride} = useUserProvider();
  const organizationID = currentOrganization?.id || organizationOverride;

  const [programs, setPrograms] = useState<Array<UserProgram>>(defaultState?.programs || []);
  const activeProgramIndex = programs.findIndex((p) => p.isActive);
  const activeProgram = programs[activeProgramIndex];
  const workoutsAsCalendarEvents = transformWorkoutsIntoCalendarEvents(activeProgram);

  const [{selectedOrganization}] = useOrganizationProvider();
  const listUsersApi = useMemo(() => listUsers([Role.Trainer, Role.Admin], selectedOrganization?.id), [
    selectedOrganization,
  ]);

  const {data: programTemplates, loading: programTemplatesLoading} = useEntityList<ProgramTemplate>(
    programTemplateApi.list, undefined,
    `title.${i18n.locale}`
  );
  const {data: workoutTemplates, loading: workoutTemplatesLoading} = useEntityList<Workout>(workoutApi.list, undefined,
    `title.${i18n.locale}`);
  const {exercises: exerciseList, isLoading: exercisesLoading} = useExerciseProvider();
  const {data: equipmentList, loading: equipmentLoading} = useGlobalCachedResourceList<Equipment>('equipmentCache');
  const {data: trainersList, loading: trainersLoading} = useEntityList<User>(listUsersApi, undefined,
    `name.${i18n.locale}`);

  const archivedUser = !!(organizationID && getArchivedField(defaultState as User, organizationID));
  const [archiveLoading, setArchiveLoading] = useState<boolean>(false);

  const trainerOptions = useMemo(
    () =>
      trainersList.map(
        (trainer) =>
          ({
            name: {en: (trainer.firstName ? `${trainer.firstName} ${trainer.lastName || ''}` : undefined) || trainer.email || trainer.phoneNumber},
            id: trainer.id,
          } as Enumerable)
      ),
    [trainersList]
  );

  const handleProgramChange = (index: number, program: UserProgram) => {
    const _ = [...programs];
    if (_[index]) {
      _[index] = program;
    }
    setPrograms(_);
  };

  const deleteWorkoutFromActiveProgram = (workoutIndex: number) => {
    deleteWorkoutFromActiveProgram_({
      workoutIndex, activeProgram, workoutDeletions,
      setWorkoutDeletions, handleProgramChange, activeProgramIndex,
    });
  };

  const handleImportProgramTemplateToActiveProgram = ({
                                                        startDate,
                                                        programTemplate,
                                                      }: {
    startDate: string;
    programTemplate: string;
  }) => {
    handleImportProgramTemplateToActiveProgram_({
      startDate, programTemplate, workoutTemplates, programTemplates,
      activeProgram, handleProgramChange, activeProgramIndex,
    });
  }

  const onReactivate = async () => {
    setArchiveLoading(true);
    organizationID && await updateUserOrganizations({
      ...(defaultState as User),
      organizations: (defaultState as User).organizations?.map(
        (orgUser) => {
          return orgUser.id === organizationID && orgUser.role === Role.Client
            ? {...orgUser, archived : false}
            : orgUser;
        }
      )
    });
    loadUser && await loadUser();
    if (selectedOrganization?.id) {
      await incrementSubscriptionQuantity(selectedOrganization?.id);
    }
    globalState.update((state: GlobalState) => {
      state.toastQueue.push({message: `Changes saved!`, severity: "success"});
    });
    setArchiveLoading(false);
  };

  const onSubmit = async (raw: User) => {
    const data = {...raw};
    if (mode === "Edit") {
      handleUpdate &&
      await handleUpdate({
        ...defaultState,
        ...data,
        trainers,
        programs,
        workoutDeletions,
      } as UserWithCRUDInstructions);
    } else if (mode === "Create") {
      handleCreate &&
      await handleCreate({
        ...data,
        trainers,
        programs,
      } as User);
    }
  };

  useEffect(() => {
    setActiveProgramChangesCount(s => {
      const count = s + 1;
      // @ts-ignore
      if (count > 0 && !location?.state?.formDirty) {
        navigation(location.pathname, {formDirty: true});
      }
      return count;
    });
  }, [activeProgram, navigation, location]);

  useEffect(() => {
    if (activeProgram === undefined) {
      handleAddNewProgram_({
        startDate: new Date().toJSON(),
        defineExplicitTime: true,
        programTemplate: '',
        programTemplates: [], workoutTemplates: [],
        setPrograms, programs,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div>
      <TopActionBar
        archivedUser={archivedUser}
        handleClose={handleClose}
        loading={loading}
        variant={activeProgramChangesCount <= 0 ? 'outlined' : 'contained'}
        handleSave={useFormHandleSubmit(onSubmit)}
        showSave
      />

      {archivedUser &&
        <Box p={1} pl={2} display="flex" justifyContent="space-between" alignItems="center"
             style={{border: `1px solid`, borderRadius: "5px", backgroundColor: "rgba(255, 255, 255, 0.1)"}}
        >
          <Typography>This client is archived. If you want to reactivate him,<span style={{fontWeight: "bold"}}> the number of clients in your subscription plan will increase by one.</span></Typography>
          <Button
            variant="outlined" size="small"
            onClick={onReactivate}
          >
            {archiveLoading && <CircularProgress size="1rem"/>} {" Reactivate"}
          </Button>
        </Box>
      }

      <Box mt={2} mb={6}>
        {defaultState && <ClientProfile user={defaultState} loadUser={loadUser} archivedUser={archivedUser} page="users"/>}
      </Box>

      {!programTemplatesLoading && !workoutTemplatesLoading && !exercisesLoading && !equipmentLoading ? (
        <Fragment>
          {(role === Role.Admin || role === Role.SuperAdmin) && !archivedUser &&
          <div style={{marginBottom: 24, maxWidth: 420}}>
            <MultiSelectField
              label={translate('client.assigned_trainers')}
              onChange={(val) => setTrainers(val)}
              options={trainerOptions}
              value={trainers}
              disabled={trainersLoading}
            />
          </div>
          }

          {activeProgram && defaultState &&
          <Fragment>
            <ClientCalendar
              user={defaultState}
              loadUser={loadUser}
              archivedUser={archivedUser}
              program={activeProgram}
              events={workoutsAsCalendarEvents.events}
              resourceMap={workoutsAsCalendarEvents.resourceMap}
              exercises={exerciseList || []}
              equipment={equipmentList}
              workoutTemplates={workoutTemplates}
              programTemplates={programTemplates}
              onActiveProgramChange={(p: UserProgram) => handleProgramChange(activeProgramIndex, p)}
              onDeleteWorkout={deleteWorkoutFromActiveProgram}
              onImportProgram={handleImportProgramTemplateToActiveProgram}
              type='users'
            />
          </Fragment>
          };
        </Fragment>
      ) : (
        <LinearProgress
          variant="determinate"
          value={!programTemplatesLoading || !workoutTemplatesLoading || !exercisesLoading ? 0.5 : 0}
        />
      )}
      {formErrorMessage && (
        <Alert severity="error" style={{marginTop: 24}}>
          {formErrorMessage}
        </Alert>
      )}
    </div>
  );
};

export default ClientForm;
