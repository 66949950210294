import {useState} from 'react';
import Image01 from './Image01.jpg';
import Image02 from './Image02.jpg';
import Image03 from './Image03.jpg';
import Image04 from './Image04.jpg';
import Image05 from './Image05.jpg';
import Image06 from './Image06.jpg';

export default () => {
  const [ state ] = useState([
    Image01, Image02, Image03, Image04, Image05, Image06
  ][Math.floor(Math.random() * 6)]);
  return state;
};
