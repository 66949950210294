import React, {useState, Fragment} from 'react';
import {DayOfWeek, Exercise, Workout} from "myfitworld-model";
import Box from "@material-ui/core/Box/Box";
import {Button, createStyles, makeStyles, Theme} from "@material-ui/core";
import AddIcon from "@material-ui/icons/Add";
import Header from "./Header";
import ContentSelectDialog from "../../workout/ContentSelectDialog";
import {WorkoutDay, WorkoutWeek} from "myfitworld-model/dist/firestoreCollectionTypes/ProgramTemplate";
import { PlainRow } from "./Row";
import dayOfWeekToSortValue from "../../../../utils/dayOfWeekToSortValue";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    week: {
      width: '100%',
      overflowX: 'auto',
    },
  }),
);

const getNextDayOfWeek = (daysCount: number) => {
  switch (daysCount) {
    case 1:
      return DayOfWeek.TUESDAY;
    case 2:
      return DayOfWeek.WEDNESDAY;
    case 3:
      return DayOfWeek.THURSDAY;
    case 4:
      return DayOfWeek.FRIDAY;
    case 5:
      return DayOfWeek.SATURDAY;
    case 6:
      return DayOfWeek.SUNDAY;
    case 0:
    default:
      return DayOfWeek.MONDAY
  }
};

const WorkoutWeeksForm = ({
  workoutWeeks,
  deleteGroup,
  copyGroup,
  updateGroup,
  workouts,
  exercises,
  equipment,
  disabled,
} : {
  workoutWeeks: Array<WorkoutWeek>,
  deleteGroup: (index: number) => void,
  copyGroup: (index: number) => void,
  updateGroup: (index: number, week: WorkoutWeek) => void,
  workouts: Array<Workout>,
  exercises: Array<Exercise>,
  equipment: Record<string, string>,
  disabled: boolean,
}) => {
  const classes = useStyles();
  const [ dialogState, setDialogState ] = useState({ open: false, index: -1 });

  const sortWeek = (week: WorkoutWeek) => {
    week.days.sort((l, r) =>
      dayOfWeekToSortValue(r.day) - dayOfWeekToSortValue(l.day)
    );
  };

  const addNewDay = (index?: number, workoutId?: string) => {
    if (index !== undefined && workoutId && workoutWeeks[index]) {
      const week = workoutWeeks[index];
      week.days.push({
        day: getNextDayOfWeek(week.days.length),
        workoutId,
      });
      sortWeek(week);
      updateGroup(index, week);
    }
    setDialogState({ open: false, index: - 1});
  };

  const handleWorkoutDayChange = (weekIndex: number, dayIndex: number, day: WorkoutDay) => {
    if (workoutWeeks[weekIndex] && workoutWeeks[weekIndex].days[dayIndex]) {
      workoutWeeks[weekIndex].days[dayIndex] = day;
      sortWeek(workoutWeeks[weekIndex]);
      updateGroup(weekIndex, workoutWeeks[weekIndex]);
    }
  };

  const deleteWorkoutDay = (weekIndex: number, dayIndex: number) => {
    if (workoutWeeks[weekIndex] && workoutWeeks[weekIndex].days[dayIndex]) {
      workoutWeeks[weekIndex].days.splice(dayIndex, 1);
      sortWeek(workoutWeeks[weekIndex]);
      updateGroup(weekIndex, workoutWeeks[weekIndex]);
    }
  };

  return (
    <Fragment>
      {workoutWeeks.map((workoutWeek, weekIndex) =>
        <div key={weekIndex}>
          <Header
            name={'Week ' + (weekIndex + 1)}
            deleteGroup={() => deleteGroup(weekIndex)}
            copyGroup={() => copyGroup(weekIndex)}
            disabled={disabled}
          />
          <Box className={classes.week} py={1}>
            {workoutWeek.days.map((day, dayIndex) =>
              <PlainRow
                key={dayIndex}
                workoutId={day.workoutId}
                dayOfWeek={day.day}
                workouts={workouts}
                exercises={exercises}
                equipment={equipment}
                onWorkoutDayChange={(workoutDay) => handleWorkoutDayChange(weekIndex, dayIndex, workoutDay)}
                deleteWorkoutDay={() => deleteWorkoutDay(weekIndex, dayIndex)}
                disabled={disabled}
              />
            )}
          </Box>

          <Box mt={1} mb={4}>
            <Button
              color='primary'
              variant='text'
              size='small'
              startIcon={<AddIcon />}
              onClick={() => setDialogState({ open: true, index: weekIndex })}
              disabled={disabled}
            >
              Add new workout to week {weekIndex + 1}
            </Button>
          </Box>
        </div>
      )}

      <ContentSelectDialog
        isOpen={dialogState.open}
        contentList={workouts}
        onClose={(workoutId) => addNewDay(dialogState.index, workoutId)}
        type='Workout'
      />
    </Fragment>
  );
};

export default WorkoutWeeksForm;
