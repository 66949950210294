import React from "react";
import SimpleMenu from "../../../../components/SimpleMenu";
import {Typography, Box} from "@material-ui/core";
import useStyles from '../../workout/groupForm/styles';

const getWeekContextMenuOptions = (includeGroupDeletion: boolean, includeGroupCopy: boolean) => {
  const options = [];
  if (includeGroupDeletion) {
    options.push({ label: 'Delete Week', action: 'DELETE' });
  }
  if (includeGroupCopy) {
    options.push({ label: 'Duplicate Week', action: 'COPY' });
  }
  return options;
};

const Header = ({
  name,
  deleteGroup,
  copyGroup,
  disabled,
} : {
  name: string,
  deleteGroup: () => void,
  copyGroup: () => void,
  disabled: boolean,
}) => {
  const groupContextMenuOptions = getWeekContextMenuOptions(!!deleteGroup, !!copyGroup);
  const classes = useStyles();

  return (
    <Box className={classes.header}>
      <Box display="inline-flex">
        <Typography variant='h5' style={{ alignSelf: 'center' }} color="textSecondary">{name}</Typography>
        {groupContextMenuOptions.length > 0 &&
        <SimpleMenu
          options={groupContextMenuOptions}
          onSelect={(action?: string) => {
            if (action && action === 'DELETE' && deleteGroup) {
              deleteGroup();
            }
            if (action && action === 'COPY' && copyGroup) {
              copyGroup();
            }
          }}
          disabled={disabled}
        />}
      </Box>
    </Box>
  );
};

export default Header;
