import React from "react";
import {Controller} from "react-hook-form";
import {Equipment, Exercise, GeneralEntity} from "myfitworld-model";
import MultiSelectField from "../../../components/MultiSelectField";
import LocalizedTextField from "../../../components/LocalizedTextField";
import LocalizedRichTextField from "../../../components/LocalizedRichTextField";
import FileUploadField from "../../../components/fileUpload/FileUploadField";
import EntityForm, {EntityFormProps} from "../../../entity/EntityForm";
import RatingWidget from "../../../components/RatingWidget";
import {useLocalizationProvider} from "../../../providers/LocalizationProvider";
import Grid from "@material-ui/core/Grid/Grid";
import useOrganization from "../../../hooks/useOrganization";
import ExercisePreview from "./ExercisePreview";
import MuscleRegimeOptions from "../../../utils/MuscleRegimeOptions";
import SelectField from "../../../components/SelectField";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Switch from "@material-ui/core/Switch";
import Visible from "../../../components/Visibile";
import useGlobalCachedResourceList from "../../../api/useGlobalCachedResourceList";
import SubtleInfoMessage from "../../../components/SubtleInfoMessage";

const ExerciseForm = (props: EntityFormProps<Exercise>) => {
  const {translate} = useLocalizationProvider();
  const {organizationId} = useOrganization();
  const disableForm = props.defaultState?.id ? props.defaultState?.organizationId !== organizationId : false;
  const {data: SportOptions} = useGlobalCachedResourceList<GeneralEntity>('sportsCache');
  const {data: EquipmentOptions} = useGlobalCachedResourceList<Equipment>('equipmentCache');
  const {data: FitnessActivitiesOptions} = useGlobalCachedResourceList<GeneralEntity>('fitnessActivitiesCache');
  const {data: MotorSkillsOptions} = useGlobalCachedResourceList<GeneralEntity>('motorSkillsCache');
  const {data: HealthIssueOptions} = useGlobalCachedResourceList<GeneralEntity>('healthIssuesCache');
  const {data: MuscleGroupsOptions} = useGlobalCachedResourceList<GeneralEntity>('muscleGroupsCache');
  const muscleRegimeOptions = MuscleRegimeOptions.map(options => ({
    id: options.value,
    name: {en: translate(options.label)}
  }));

  if (disableForm && props.defaultState && props.defaultState.id) {
    return (
      <ExercisePreview
        exercise={props.defaultState}
        sportOptions={SportOptions}
        equipmentOptions={EquipmentOptions}
        motorSkillsOptions={MotorSkillsOptions}
        healthIssueOptions={HealthIssueOptions}
        muscleGroupsOptions={MuscleGroupsOptions}
      />
    );
  }

  return (
    <EntityForm<Exercise> {...props} disabled={disableForm}>
      {({register, control, setValue, getValues, watch}) => {
        const isBilateralWatcher = watch('isBilateral');
        return (
          <Grid container direction="column" spacing={2}>
            <Grid item>
              <LocalizedTextField
                inputRef={register}
                autoFocus
                margin="dense"
                name="title"
                label={translate("Title")}
                type="text"
                fullWidth
                required
                disabled={disableForm}
              />
            </Grid>
            <Grid item>
              <LocalizedRichTextField
                initialValue={getValues("description")}
                control={control}
                name="description"
                label={translate("Description")}
                disabled={disableForm}
              />
            </Grid>
            <Grid item xs={12}>
              <Controller
                name="videoURL"
                control={control}
                render={({value}) => {
                  return (
                    <FileUploadField
                      name="videoURL"
                      uploadLabel="Upload video"
                      changeLabel="Choose a new video"
                      uploadPath="videos"
                      fileType="video"
                      onUploadSuccessful={(videoURL: string) => setValue("videoURL", videoURL)}
                      value={value}
                      disabled={disableForm}
                    />
                  );
                }}
              />
            </Grid>
            <Grid item xs={12}>
              <Controller
                name="thumbnailURL"
                control={control}
                render={({value}) => {
                  return (
                    <FileUploadField
                      name="thumbnailURL"
                      uploadLabel="Upload custom thumbnail"
                      changeLabel="Choose a new thumbnail"
                      uploadPath="exercises"
                      fileType="image"
                      onUploadSuccessful={(thumbnailURL: string) => setValue("thumbnailURL", thumbnailURL)}
                      value={value}
                      disabled={disableForm}
                    />
                  );
                }}
              />
              <SubtleInfoMessage>
                {translate('content.thumbnail_note')}
              </SubtleInfoMessage>
            </Grid>
            <Grid item>
              <RatingWidget
                control={control}
                name="difficultyLevel"
                label={translate("Training Level")}
                disabled={disableForm}
              />
            </Grid>
            <Grid item>
              <Controller
                name={"sports"}
                control={control}
                render={({onChange, value}) => {
                  return (
                    <MultiSelectField
                      label={translate("Sports")}
                      options={SportOptions}
                      value={value}
                      onChange={(values) => onChange(values)}
                      disabled={disableForm}
                    />
                  );
                }}
              />
              <Controller
                name={"fitnessActivity"}
                control={control}
                render={({onChange, value}) => {
                  return (
                    <MultiSelectField
                      label={translate("Activity")}
                      options={FitnessActivitiesOptions}
                      value={value}
                      onChange={(values) => onChange(values)}
                      disabled={disableForm}
                    />
                  );
                }}
              />
              <Controller
                name={'muscleRegime'}
                control={control}
                render={({onChange, value}) => {
                  return <SelectField label={translate('MuscleRegimeLabel')} options={muscleRegimeOptions}
                                      value={value} disabled={disableForm}
                                      onChange={values => onChange(values)}/>
                }}
              />
              <Controller
                name={"muscleGroups"}
                control={control}
                render={({onChange, value}) => {
                  return (
                    <MultiSelectField
                      label={translate("Muscle Groups")}
                      options={MuscleGroupsOptions}
                      value={value}
                      disabled={disableForm}
                      onChange={(values) => onChange(values)}
                    />
                  );
                }}
              />
              <Controller
                name={"secondaryMuscleGroups"}
                control={control}
                render={({onChange, value}) => {
                  return (
                    <MultiSelectField
                      label={translate("Secondary Muscle Groups")}
                      options={MuscleGroupsOptions}
                      value={value}
                      disabled={disableForm}
                      onChange={(values) => onChange(values)}
                    />
                  );
                }}
              />
              <Controller
                name={"motorSkills"}
                control={control}
                render={({onChange, value}) => {
                  return (
                    <MultiSelectField
                      label={translate("Motor Skills")}
                      options={MotorSkillsOptions}
                      value={value}
                      disabled={disableForm}
                      onChange={(values) => onChange(values)}
                    />
                  );
                }}
              />
              <Controller
                name={"healthIssues"}
                control={control}
                render={({onChange, value}) => {
                  return (
                    <MultiSelectField
                      label={translate("Suitable for health issues")}
                      options={HealthIssueOptions}
                      value={value}
                      disabled={disableForm}
                      onChange={(values) => onChange(values)}
                    />
                  );
                }}
              />
              <Controller
                name={"equipment"}
                control={control}
                render={({onChange, value}) => {
                  return (
                    <MultiSelectField
                      label={translate("Equipment")}
                      options={EquipmentOptions}
                      value={value}
                      disabled={disableForm}
                      onChange={(values) => onChange(values)}
                    />
                  );
                }}
              />
              <FormControlLabel
                label={translate("exercise.unilateral")}
                control={
                  <Controller
                    name="isBilateral"
                    control={control}
                    render={({onChange, value}) => {
                      return (
                        <Switch
                          name="isBilateral"
                          disabled={disableForm}
                          color="primary"
                          checked={value}
                          onChange={(_, checked) => onChange(checked)}
                        />
                      );
                    }}
                    disabled={disableForm}
                  />}
              />
              <Visible if={isBilateralWatcher === true}>
                <LocalizedRichTextField
                  initialValue={getValues("bilateralNote")}
                  control={control}
                  name="bilateralNote"
                  label={translate("exercise.unilateral_note")}
                  disabled={disableForm}
                />
              </Visible>
            </Grid>
          </Grid>
        );
      }}
    </EntityForm>
  );
};

export default ExerciseForm;
