import React from 'react';
import { Button, StepContent } from '@material-ui/core';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import isMonday from 'date-fns/fp/isMonday';

const Step1 = ({
  classNames,
  startDate,
  setStartDate,
  nextStep,
  ...props
} : {
  classNames: any,
  startDate: Date,
  setStartDate: (e: any) => void,
  nextStep: () => void,
}) =>
  <StepContent {...props}>
    <div className={classNames.calendarContainer}>
      <DatePicker
        inline
        selected={startDate}
        onChange={setStartDate}
        filterDate={isMonday}
      />
    </div>
    <Button
      color='primary'
      onClick={nextStep}
      disabled={!startDate}
    >
      Next
    </Button>
  </StepContent>;

export default Step1;
