import React, {useState} from 'react';
import Dialog from '@material-ui/core/Dialog';
import {WorkoutDay} from "myfitworld-model";
import {Box, Button, DialogActions, DialogContent, Typography} from "@material-ui/core";
import DateTimeSelect from "../../content/program/workoutWeeksForm/DateTimeSelect";

export interface DateTimeEditDialogProps {
  open: boolean;
  handleClose: () => void;
  workoutDay: WorkoutDay;
  handleSave: (workoutDay: WorkoutDay) => void;
  showTime: boolean;
}

const DateTimeEditDialog = (props: DateTimeEditDialogProps) => {
  const { open, handleClose, handleSave, workoutDay, showTime } = props;
  const [ state, setState ] = useState<string>(workoutDay.dateTime);


  return (
    <Dialog onClose={handleClose} open={open}>
      <DialogContent>
        <Box
          px={2} py={4}
          style={{ minHeight: 420, minWidth: 420 }}
        >
          <Typography variant='body2' style={{ marginBottom: 48 }}>
            {showTime ? 'Select a new time and date for the workout.' : 'Select a new date for the workout.'}
          </Typography>
          <DateTimeSelect
            value={new Date(state)}
            showTime={showTime}
            onChange={date => setState(date.toJSON())}
            autoFocus
          />
        </Box>
      </DialogContent>
      <DialogActions>
        <Button color="primary" onClick={() => {
          const day = { ...workoutDay };
          day.dateTime = state;
          handleSave && handleSave(day);
        }}>
          {showTime ? 'Apply Date and Time' : 'Apply Date'}
        </Button>
      </DialogActions>
    </Dialog>
  );
}

export default DateTimeEditDialog;
