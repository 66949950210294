import React, {Fragment, useEffect, useState} from "react";
import BillingPlanDialog from "../../components/payment/dialogs/BillingPlanDialog";
import dialogNames from "../../components/payment/utils/DialogNames";
import BillingAddressDialog from "../../components/payment/dialogs/BillingAddressDialog";
import CardDetailsDialog from "../../components/payment/dialogs/CardDetailsDialog";
import {
  BillingDetailsErrors,
  BillingDetailsInterface, defaultBillingDetailsErrors,
  emptyBillingDetails,
  getBillingDetailsInterface
} from "../../components/payment/utils/BillingDetailsInterface";
import {useStoreState} from "pullstate";
import globalState from "../../globalState";
import {getOrganizationBillingDetails} from "../../api/billingDetailsApi";
import {BillingDetails} from "myfitworld-model";

const action = "CREATE";

const BillingDetailsAdminPage = () => {
  const currentOrganization = useStoreState(globalState, s => s.currentOrganization);

  const [dialogName, setDialogName] = useState<string>(dialogNames[0]);
  const onNext = (dialogName: string) => setDialogName(dialogName);

  const [ values, setValues ] = useState<BillingDetailsInterface>(emptyBillingDetails);
  const [ billingDetails, setBillingDetails ] = useState<BillingDetails | undefined>(undefined);
  const [ errors, setErrors ] = useState<BillingDetailsErrors>(defaultBillingDetailsErrors);

  useEffect( () => {
    currentOrganization && currentOrganization.id && getOrganizationBillingDetails(currentOrganization.id).then(
      (res) => {
        if (res) {
          setValues(getBillingDetailsInterface(res));
          setBillingDetails(res);
        }
      }
    )
  }, [currentOrganization]);

  return (
    <Fragment>
      <BillingPlanDialog
        open={dialogName === dialogNames[0]}
        action={action}
        onNext={onNext}
        defaultState={values}
        onSave={(data) => setValues(data)}
      />
      <BillingAddressDialog
        open={dialogName === dialogNames[1]}
        action={action}
        onNext={onNext}
        defaultState={values}
        errors={errors}
        setErrors={setErrors}
        onSave={(data) => setValues(data)}
      />
      <CardDetailsDialog
        open={dialogName === dialogNames[2]}
        action={action}
        onNext={onNext}
        defaultState={values}
        errors={errors}
        setErrors={setErrors}
        onSave={(data) => setValues(data)}
        billingDetails={billingDetails}
      />
    </Fragment>
  );
}

export default BillingDetailsAdminPage;
