import React from "react";
import { Box, Typography } from "@material-ui/core";

const GeneralStatisticInformationField = ({ label, value } : Props) => (
  <Box display="flex" flexDirection="column" justifyContent="center" alignItems="center">
    <Typography variant="h3">
      {value}
    </Typography>
    <Typography color="textSecondary" variant="body2">
      {label}
    </Typography>
  </Box>
);

export default GeneralStatisticInformationField;

interface Props {
  label: string;
  value: string;
}
