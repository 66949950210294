import React, {useEffect, useRef, useState} from "react";
import Image from 'material-ui-image';
import {auth} from "../../firebase";
import {Box, Button, CssBaseline, Typography, useTheme} from "@material-ui/core";
import Grid from "@material-ui/core/Grid";
import Paper from '@material-ui/core/Paper';
import logo from "../../assets/logo.png";
import firebase from 'firebase';
import * as firebaseui from 'firebaseui';
import {useNavigate} from "@reach/router";
import {Alert} from "@material-ui/lab";
import {useLocalizationProvider} from "../../providers/LocalizationProvider";
import {INV_KEY, useInvitationProvider} from "../../providers/InvitationProvider";
import organizationsApi from "../../api/organizationsApi";
import {Invitation, Organization} from "myfitworld-model";
import useRandomImage from "../assets/useRandomImage";
import Role from "myfitworld-model/dist/enums/Role";
import useLoginPageStyles from "./useLoginPageStyles";

const CLIENT_ID = process.env.REACT_APP_GOOGLE_CLIENT_ID || "507705216004-3eo0j87uul8991b5jg45otaavnp6r7q4.apps.googleusercontent.com";

const LoginPage = ({invitation} : Props) => {
  const theme = useTheme();
  const classes = useLoginPageStyles();
  const {translate} = useLocalizationProvider();

  const navigate = useNavigate();
  const firebaseRef = useRef<HTMLDivElement>(null);
  const [errorMessage, setErrorMessage] = useState<string>();

  const {setInvitation} = useInvitationProvider();
  const [organization, setOrganization] = useState<Organization>();

  useEffect(() => {
    if (!invitation) return;

    invitation.organization && organizationsApi.get(invitation.organization).then(result => {
      setOrganization(result)
    })

  }, [invitation]);

  useEffect(() => {
    if (!firebaseRef) return;

    const uiConfig = ({
      signInFlow: "redirect",
      signInSuccessUrl: '/',
      signInOptions: [
          {
            provider: firebase.auth.EmailAuthProvider.PROVIDER_ID,
            signInMethod: firebase.auth.EmailAuthProvider.EMAIL_PASSWORD_SIGN_IN_METHOD
          },
          {
            provider: firebase.auth.PhoneAuthProvider.PROVIDER_ID,
            requireDisplayName: true,
            defaultCountry: 'RS',//'US',
            recaptchaParameters: {
              type: 'image',
              size: 'invisible',
              badge: 'bottomright'
            }
          },
          "apple.com",
          {
            provider: firebase.auth.GoogleAuthProvider.PROVIDER_ID,
            clientId: CLIENT_ID
          },
          firebase.auth.FacebookAuthProvider.PROVIDER_ID

          // {
          //   provider: 'apple.com',
          //
          // }
      ],
      callbacks: {
        signInSuccessWithAuthResult: (authResult: firebase.auth.UserCredential) => {
          if (authResult.additionalUserInfo?.isNewUser && authResult.user) {
            const invitationCode = window.localStorage.getItem(INV_KEY);
            if (!invitationCode) {
              console.error('Access is invitation only, please check with your admin to share you the invitation');
              setErrorMessage(translate('Access is invitation only, please check with your admin to share you the invitation'))
              auth.signOut();
            }
          } else {
            const accountExists = window.localStorage.getItem('accountExists');

            if (accountExists) {
              setErrorMessage('Your account is already been used. Please use different signup method to use the app.');
              setInvitation(null);
              auth.signOut();
            } else {
              navigate('/');
            }
          }

          // we will control the navigation on promise resolve
          return false;
        },
      },
      tosUrl: '/terms-of-service'//, // This doesn't exist yet,
      // credentialHelper: CLIENT_ID ?
      //   firebaseui.auth.CredentialHelper.GOOGLE_YOLO :
      //   firebaseui.auth.CredentialHelper.NONE // do not show account chooser when logging in with email
    })

    // This sets up firebaseui
      const ui = new firebaseui.auth.AuthUI(auth);

      ui.disableAutoSignIn();

      ui.start("#firebaseui", uiConfig)

      return () => {
        if (ui) {
          ui.reset();
          ui.delete().catch(err => console.error('error cleaning up fb authID', err));
        }
      }

  }, [firebaseRef, navigate, translate]);

  const loginImageSource = useRandomImage();

  return (
    <Grid container component="main" className={classes.root}>
      <CssBaseline/>
      <Grid item xs={false} sm={4} md={8} className={classes.image}>
        <Image
          src={loginImageSource}
          disableSpinner
          color={theme.palette.background.default}
          style={{opacity: 0.35, height: '100%'}}
        />
      </Grid>
      <Grid item xs={12} sm={8} md={4} component={Paper} elevation={6} square>
        <Box
          mb={2}
          display="flex"
          flexDirection="column"
          alignItems="center"
        >
          <Box
            mt={4}
            mb={2}
            height={80}
            width={80}
          >
            <Image
              src={logo}
              disableSpinner
              color={theme.palette.background.default}
            />
          </Box>

          <Box style={{width: "80%", marginTop: 5, marginBottom: 5}}>
            {invitation &&
            <Alert severity='success' style={{marginTop: 24}}>
              {
                invitation && (invitation.role === Role.SuperAdmin ? translate('invitation.info_page.title_super_admin')
                    : invitation?.invitedBy ? translate('login_page.invitation_info', {organization: organization?.name})
                      : translate('login_page.login_info_organization', {organization: organization?.name})
                )
              }
            </Alert>}
          </Box>

          <Typography variant="h4" style={{ marginBottom: 5}}>
            Please choose your log in method
          </Typography>
        </Box>

        <div id='firebaseui' ref={firebaseRef}/>

        <Box
          mt={2}
          display="flex"
          flexDirection="column"
          alignItems="center"

        >
          <Typography variant="h6" style={{marginBottom: 1, width: '80%'}}>
            <span style={{color: theme.palette.primary.light, fontWeight: 'bold'}}>PLEASE NOTE:</span> When you choose your log in method for the first time, you will have to use that method for every future log in to MyFitWorld.
          </Typography>
          <Typography variant="h6" style={{marginBottom: 1, width: '80%'}}>
            <span style={{color: theme.palette.primary.light, fontWeight: 'bold'}}>• Log in with email</span> means that you will use your email and a password in order to use your profile. If you ever forget your password or email – recovery steps will be sent to you on the email chosen during your Sign Up steps.
          </Typography>
          <Typography variant="h6" style={{marginBottom: 1, width: '80%'}}>
            <span style={{color: theme.palette.primary.light, fontWeight: 'bold'}}>• Log in with phone number</span> means that you will get a verification code on your phone every time you want to log in to your profile. This means that you do not have a password, and you will have to use the same phone number every time you want to log in to your account.
          </Typography>
          <Typography variant="h6" style={{marginBottom: 1, width: '80%'}}>
            <span style={{color: theme.palette.primary.light, fontWeight: 'bold'}}>Still have a problem?</span> Write to us at <span style={{color: theme.palette.primary.light, fontWeight: 'bold'}}>support@myfitworld.net</span> and we will contact you as soon as possible.
          </Typography>
        </Box>

        {errorMessage &&
        <Alert severity='error' style={{marginTop: 24}}>
          {errorMessage}
        </Alert>}

        {
          window.localStorage.getItem('accountExists') &&
            <Button onClick={() => {
              window.localStorage.removeItem('accountExists');
              window.location.reload();
            }}>Back to Login</Button>
        }
      </Grid>
    </Grid>
  );
};

interface Props {
  invitation: Invitation | null;
}


export default LoginPage;



