import React from "react";
import {Box, Grid, InputAdornment, TextField, Typography} from "@material-ui/core";
import UserWithCRUDInstructions, {UserInvitationWithCrudInstructions} from "../../../api/UserWithCRUDInstructions";
import {useLocalizationProvider} from "../../../providers/LocalizationProvider";
import {Invitation} from "myfitworld-model";
import {Controller, useForm} from "react-hook-form";
import Gender from "myfitworld-model/src/enums/Gender";
import {Form} from "../../auth/shared";
import Button from "@material-ui/core/Button";
import {Alert} from "@material-ui/lab";
import {isEmpty} from "../../../validators/isEmpty";
import SelectField from "../../../components/SelectField";
import GenderOptions from "../../../utils/GenderOptions";
import RatingWidget from "../../../components/RatingWidget";
import {updateUserOrInvitationProfileData} from "../../../api/usersApi";
import User from "myfitworld-model/dist/firestoreCollectionTypes/User";
import {getTrainingLevelByEnum, getTrainingLevelByNumber} from "../../../utils/getTrainingLevel";
import {validatePhoneNumber} from "../../../validators/validatePhoneNumber";
import {validateEmail} from "../../../validators/validateEmail";
import {validateNumber} from "../../../validators/validateNumber";

type UserData = {
  firstName: string;
  lastName: string;
  email: string;
  phoneNumber: string;
  age?: number;
  gender?: Gender | null;
  weight?: number;
  height?: number;
  level?: number | null;
};

const EditProfileForm = ({user, loadUser, page, onClose}: Props) => {
  const {translate} = useLocalizationProvider();

  const genderOptions = GenderOptions.map(options => ({
    id: options.id,
    name: {en: translate(options.name)}
  }));

  const {register, errors, handleSubmit, control} = useForm<UserData>({
    defaultValues: {
      firstName: user.firstName,
      lastName: user.lastName,
      email: user.email,
      phoneNumber: user.phoneNumber,
      age: user?.fitnessProfile?.age ?? 0,
      gender: user?.fitnessProfile?.gender ?? null,
      weight: user?.fitnessProfile?.weight ?? 0,
      height: user?.fitnessProfile?.height ?? 0,
      level: getTrainingLevelByEnum(user?.fitnessProfile?.level) ?? null,
    }
  });

  const onSubmit = async (data: UserData) => {
    console.log(data);
    const userData = {
      ...user,
      firstName: data.firstName,
      lastName: data.lastName,
      email: data.email,
      phoneNumber: data.phoneNumber,
      fitnessProfile: {
        age: data.age,
        gender: data.gender,
        weight: data.weight,
        height: data.height,
        level: getTrainingLevelByNumber(data.level)
      }
    };

    if (user.id) {
      if (page === 'users') {
        await updateUserOrInvitationProfileData({
          userId: user.id,
          userData: userData as unknown as User,
          updatePath: page,
        });
      } else if (page === 'invitations') {
        await updateUserOrInvitationProfileData({
          userId: user.id,
          userData: userData as unknown as UserInvitationWithCrudInstructions,
          updatePath: page,
        });
      }
    }
    loadUser && await loadUser();
    onClose();
  };

  return (
    <Form heading="Edit User Profile" onSubmit={handleSubmit(onSubmit)}>
      <Grid container direction="column" spacing={2}>
        <Grid item>
          <TextField
            name='firstName'
            inputRef={register({
              required: "Please enter first name",
              validate: value => isEmpty(value)
            })}
            error={!!errors.firstName}
            label={translate('client.first_name')}
            type='text'
            autoFocus
            fullWidth
          />
        </Grid>

        <Grid item>
          <TextField
            name='lastName'
            inputRef={register({
              required: "Please enter last name",
              validate: value => isEmpty(value)
            })}
            error={!!errors.lastName}
            label={translate('client.last_name')}
            type='text'
            autoFocus
            fullWidth
          />
        </Grid>

        <Grid item>
          <TextField
            name='email'
            inputRef={register({
              required: "Please enter email",
              validate: value => validateEmail(value)
            })}
            error={!!errors.email}
            label={translate('client.email')}
            type='text'
            autoFocus
            fullWidth
          />
        </Grid>

        <Grid item>
          <TextField
            name='phoneNumber'
            inputRef={register({
              required: "Please enter phone number",
              validate: value => validatePhoneNumber(value)
            })}
            error={!!errors.phoneNumber}
            label={translate('client.phone')}
            type='text'
            autoFocus
            fullWidth
          />
        </Grid>

        <Grid item>
          <TextField
            name='age'
            inputRef={register({
              validate: value => validateNumber(value)
            })}
            error={!!errors.age}
            label={translate('client.age')}
            type='number'
            autoFocus
            fullWidth
          />
        </Grid>

        <Grid item>
          <Controller
            name={"gender"}
            control={control}
            render={({onChange, value}) => {
              return (<SelectField
                label={translate('client.gender')}
                options={genderOptions}
                value={value}
                onChange={(val) => onChange(val)}
              />);
            }}
          />
        </Grid>

        <Grid item>
          <TextField
            name='height'
            inputRef={register({
              validate: value => validateNumber(value)
            })}
            error={!!errors.height}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <Typography color="textSecondary">cm</Typography>
                </InputAdornment>
              ),
            }}
            label={translate('client.height')}
            type='number'
            autoFocus
            fullWidth
          />
        </Grid>

        <Grid item>
          <TextField
            name='weight'
            inputRef={register({
              validate: value => validateNumber(value)
            })}
            error={!!errors.weight}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <Typography color="textSecondary">kg</Typography>
                </InputAdornment>
              ),
            }}
            label={translate('client.weight')}
            type='number'
            autoFocus
            fullWidth
          />
        </Grid>

        <Grid item>
          <RatingWidget
            control={control}
            name="level"
            label={translate("Training Level")}
          />
        </Grid>

        <Grid item>
          <Box display="flex" flexDirection="row">
            <Button type="submit" color="primary" variant="outlined">
              {translate("button.save_changes")}
            </Button>
            <Box ml={1}>
              <Button color="secondary" onClick={onClose}>
                {translate("button.cancel")}
              </Button>
            </Box>
          </Box>
        </Grid>

        <Grid item>
          {(errors.email || errors.phoneNumber || errors.firstName || errors.lastName || errors.weight || errors.height || errors.age) &&
          <Alert severity='error' style={{marginTop: 24}}>
            {errors.firstName?.message && <Typography color="inherit">{translate(errors.firstName.message)}</Typography>}
            {errors.lastName?.message && <Typography color="inherit">{translate(errors.lastName.message)}</Typography>}
            {errors.email?.message && <Typography color="inherit">{translate(errors.email.message)}</Typography>}
            {errors.phoneNumber?.message && <Typography color="inherit">{translate(errors.phoneNumber.message)}</Typography>}
            {(errors.age?.message || errors.weight?.message || errors.height?.message) &&
            <Typography color="inherit">{translate("The entry must be a number")}</Typography>
            }
          </Alert>}
        </Grid>
      </Grid>
    </Form>
  );
};

export default EditProfileForm;

interface Props {
  user: UserWithCRUDInstructions | Invitation;
  page: 'users' | 'invitations';
  loadUser: () => Promise<any>;
  onClose: () => void;
}
