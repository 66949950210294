import React, {useCallback, useState} from 'react';
import {useNavigate} from '@reach/router';
import Role from 'myfitworld-model/dist/enums/Role';
import Tab from '@material-ui/core/Tab';
import Tabs from '@material-ui/core/Tabs';
import PageContainer from '../PageContainer';
import {useLocalizationProvider} from '../../providers/LocalizationProvider';
import TabPanel from '../../components/TabPanel';
import UsersTable from '../../components/users/UsersTable';
import ClientInvitationsTable from "./ClientInvitationsTable";
import globalState from "../../globalState";
import {useStoreState} from "pullstate";
import {OrganizationUser} from "myfitworld-model";

export default function ClientManagementPage() {
  const {translate} = useLocalizationProvider();
  const navigate = useNavigate();

  const [selectedTab, setSelectedTab] = useState(0);
  const roles = [Role.Client];

  const role = useStoreState(globalState, s => s.currentRole);
  const trainerId = useStoreState(globalState, s => s.currentUserId);

  const filterClientsForTrainer = useCallback((data: OrganizationUser[]) => {
    return data.filter(user => (user?.user?.trainers)?.includes(trainerId || ''));
  }, [ trainerId ]);

  const filterActiveClientsForTrainer = useCallback((data: OrganizationUser[]) => {
    return filterClientsForTrainer(data).filter((user) => user.archived !== true);
  }, [ filterClientsForTrainer ]);

  const filterArchivedClientsForTrainer = useCallback((data: OrganizationUser[]) => {
    return filterClientsForTrainer(data).filter((user) => user.archived === true);
  }, [ filterClientsForTrainer ]);

  const filterActiveClientsForAdmin = useCallback((data: OrganizationUser[]) => {
    return data.filter((user) => user.archived !== true);
  }, []);

  const filterArchivedClientsForAdmin = useCallback((data: OrganizationUser[]) => {
    return data.filter((user) => user.archived === true);
  }, []);

  return (
    <PageContainer>
      <Tabs
        centered
        value={selectedTab}
        textColor='primary'
        onChange={(_, value: number) => {
          setSelectedTab(value);
        }}
      >
        <Tab label={translate('Clients')}/>
        <Tab label={translate('Archived Clients')}/>
        <Tab label={translate('Invitations')}/>
      </Tabs>
      <TabPanel value={selectedTab} index={0}>
        <UsersTable
          roles={roles}
          onClick={(id: string) => navigate(`/clients/${id}`)}
          showRole={false}
          filterFn={role === Role.Trainer ? filterActiveClientsForTrainer : filterActiveClientsForAdmin}
          showWarnings
        />
      </TabPanel>
      <TabPanel value={selectedTab} index={1}>
        <UsersTable
          roles={roles}
          onClick={(id: string) => navigate(`/clients/${id}`)}
          showRole={false}
          filterFn={role === Role.Trainer ? filterArchivedClientsForTrainer : filterArchivedClientsForAdmin}
          showWarnings
        />
      </TabPanel>
      <TabPanel value={selectedTab} index={2}>
        <ClientInvitationsTable
          onRowClick={(id: string) => navigate(`/invitations/${id}?form=open`)}
          invitationsBlocked={role === Role.AssistantTrainer}
        />
      </TabPanel>
    </PageContainer>
  );
};





