import {ProgramTemplate, Workout} from "myfitworld-model";
import uniqBy from "lodash/uniqBy";
import {WorkoutDay} from "myfitworld-model/src/firestoreCollectionTypes/ProgramTemplate";
import {WorkoutWeek} from "myfitworld-model/dist/firestoreCollectionTypes/ProgramTemplate";
import uniq from "lodash/uniq";

export const getProgramWorkouts = (item: ProgramTemplate, workouts: Array<Workout> | undefined): Array<Workout> => {
  const workoutIds = uniqBy(item.workoutWeeks.reduce(
    (res: Array<WorkoutDay>, elem: WorkoutWeek): Array<WorkoutDay> => [...(res || []), ...(elem?.days || [])] as Array<WorkoutDay>, []) || [], 'workoutId'
  );
  return uniq(workoutIds.flatMap((value) => {
    const workout = workouts?.find(e => value.workoutId === e.id);
    return workout? [workout] : [];
  }));
};
