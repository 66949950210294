import React, {useMemo, useState} from "react";
import {Exercise, Workout} from "myfitworld-model";
import DayOfWeek from "myfitworld-model/dist/enums/DayOfWeek";
import {Button, makeStyles, Theme, IconButton, Chip} from "@material-ui/core";
import {WorkoutDay} from "myfitworld-model/src/firestoreCollectionTypes/ProgramTemplate";
import Typography from "@material-ui/core/Typography/Typography";
import DayOfWeekSelect from "./DayOfWeekSelect";
import DateTimeSelect from "./DateTimeSelect";
import startOfWeek from "date-fns/startOfWeek";
import endOfWeek from "date-fns/endOfWeek";
import Box from "@material-ui/core/Box";
import Hidden from "@material-ui/core/Hidden";
import DeleteIcon from "@material-ui/icons/Delete";
import {getWorkoutExercisesCount} from "../../../../utils/getWorkoutExercisesCount";
import getLocalizedProperty from "../../../../utils/getLocalizedProperty";
import {getWorkoutEquipment} from "../../../../utils/getWorkoutEquipment";
import Image from "material-ui-image";
import getImageThumbnail from "../../../../utils/getImageThumbnail";
import thumbPlaceholder from "../../../../assets/images/thumb_placeholder.png";
import {baseContentListStyles} from "../../BaseContentListItem";
import {useLocalizationProvider} from "../../../../providers/LocalizationProvider";
import SimpleDialog from "../../../../components/SimpleDialog";

const getStartAndEndOfWeek = (day: Date) => {
  return {
    start: startOfWeek(day, {weekStartsOn: 1}),
    end: endOfWeek(day, {weekStartsOn: 1}),
  };
};

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    display: "flex",
    padding: theme.spacing(3)
  },
  section: {
    display: "flex",
    alignItems: 'center',
    marginRight: theme.spacing(3),
  },
  action: {
    marginLeft: "auto",
  },
  chips: {
    marginTop: theme.spacing(1),
    marginLeft: -theme.spacing(.5),
    marginRight: -theme.spacing(.5),
  },
  item: {
    marginRight: theme.spacing(1),
    alignSelf: "flex-start"
  },
}));

interface BaseProps {
  workoutId: string;
  workouts: Array<Workout>;
  exercises?: Array<Exercise>;
  equipment?: Record<string, string>;
  deleteWorkoutDay: () => void;
  onEdit?: () => void;
  onHistory?: () => void;
  canChange?: boolean;
  disabled?: boolean;
}

interface PlainRow extends BaseProps {
  dayOfWeek: DayOfWeek;
  onWorkoutDayChange: (day: WorkoutDay) => void;
  disabled: boolean;
}

interface DateTimeRow extends BaseProps {
  dateTime: Date;
  showTime: boolean;
  deleteWorkoutDay: () => void;
  onWorkoutDateTimeChange: (dateTime: Date) => void;
}

interface BaseRowProps extends BaseProps {
  children: React.ReactNode;
}

const BaseRow = ({workoutId, workouts, deleteWorkoutDay, onEdit, onHistory, canChange, exercises, equipment, disabled, children}: BaseRowProps) => {
  const classes = useStyles();
  const baseContentListClasses = baseContentListStyles();
  const {translate} = useLocalizationProvider();

  const [isOpenDialog, setIsOpenDialog] = useState(false);

  const workout = useMemo(() => {
    return workouts.find(item => item.id === workoutId);
  }, [workouts, workoutId]);

  const tags = useMemo(() => {
    const equipmentIds = getWorkoutEquipment(workout, exercises);
    return equipment ? [
      ...(equipmentIds.map((k) => equipment[k]) || [])
    ] : [];
  }, [equipment, exercises, workout]);

  return (
    <div className={classes.root}>
      <div className={classes.section}>{children}</div>

      {
        workout &&
        <Box display="flex" flexDirection="row" flex={1} alignItems="center">
          <Box className={baseContentListClasses.thumbnail}>
            <Image
              src={workout.thumbnailURL ? getImageThumbnail((workout as any).thumbURLS || [])?.url || workout.thumbnailURL : thumbPlaceholder}
              aspectRatio={(16 / 9)}
              color="transparent"
              className={baseContentListClasses.image}
              alt={getLocalizedProperty(workout.title)}
              disableSpinner
            />
          </Box>
          <Box display="flex" flexDirection="column">
            <Typography variant="h6">
              {getLocalizedProperty(workout?.title)}
            </Typography>
            <Typography variant="body2" color="textSecondary">
              {`${getWorkoutExercisesCount(workout)} • ${workout.difficultyLevel !== undefined ? workout.difficultyLevel : 0} / 5`}
            </Typography>
            <Box className={classes.chips} display="flex" flexWrap="wrap">
              {tags.map((tag, i) =>
                <Box key={i} m={.25}>
                  <Chip size='small' label={tag} variant="outlined"/>
                </Box>
              )}
            </Box>
          </Box>
        </Box>
      }

      <Box display="flex" alignItems="center" className={classes.action}>
        {onEdit && canChange && (
          <Button size="small" color="primary" variant="text" onClick={onEdit}>
            Edit
          </Button>
        )}
        {!canChange && onHistory && (
          <Button size="small" color="primary" variant="text" onClick={onHistory}>
            History
          </Button>
        )}
        <Hidden smUp implementation="css">
          <IconButton size="small" aria-label="delete" color="secondary" onClick={() => setIsOpenDialog(true)} disabled={disabled}>
            <DeleteIcon/>
          </IconButton>
        </Hidden>
        <Hidden smDown implementation="css">
          <Button size="small" color="secondary" variant="text"  onClick={() => setIsOpenDialog(true)} disabled={disabled}>
            {translate('program.remove_workout')}
          </Button>
        </Hidden>
        <SimpleDialog
          title={translate('program.remove_workout')}
          text={translate('program.remove_workout_question')}
          open={isOpenDialog}
          onClose={() => setIsOpenDialog(false)}
          onConfirm={() => {
            setIsOpenDialog(false);
            deleteWorkoutDay();
          }}
          onConfirmLabel={translate('button.yes')}
          onCancel={() => setIsOpenDialog(false)}
          onCancelLabel={translate('button.no')}
        />
      </Box>
    </div>
  );
};

export const PlainRow = ({workoutId, workouts, exercises, equipment, dayOfWeek, onWorkoutDayChange, deleteWorkoutDay, disabled}: PlainRow) => {
  return (
      <BaseRow workoutId={workoutId} workouts={workouts} exercises={exercises} equipment={equipment} deleteWorkoutDay={deleteWorkoutDay} disabled={disabled}>
        <DayOfWeekSelect value={dayOfWeek} workoutId={workoutId} onChange={onWorkoutDayChange} disabled={disabled}/>
      </BaseRow>
  );
};

export const DateTimeRow = ({
  workoutId,
  workouts,
  deleteWorkoutDay,
  dateTime,
  showTime,
  onWorkoutDateTimeChange,
  onEdit,
  canChange,
  onHistory,
}: DateTimeRow) => {
  return (
    <BaseRow
      workoutId={workoutId}
      workouts={workouts}
      deleteWorkoutDay={deleteWorkoutDay}
      onEdit={onEdit}
      canChange={canChange}
      onHistory={onHistory}
    >
      <DateTimeSelect
        value={dateTime}
        showTime={showTime}
        minDate={getStartAndEndOfWeek(dateTime).start}
        maxDate={getStartAndEndOfWeek(dateTime).end}
        onChange={onWorkoutDateTimeChange}
      />
    </BaseRow>
  );
};
