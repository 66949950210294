import React from 'react';
import {useForm} from 'react-hook-form';
import {Equipment, Exercise, ProgramTemplate} from 'myfitworld-model';
import Workout from "myfitworld-model/dist/firestoreCollectionTypes/Workout";
import {Box, Typography} from "@material-ui/core";
import LanguageSelect from "../../../components/language/LanguageSelect";
import Button from "@material-ui/core/Button/Button";
import {Alert} from "@material-ui/lab";
import useGroupState from "../useGroupState";
import {WorkoutWeek} from "myfitworld-model/dist/firestoreCollectionTypes/ProgramTemplate";
import WorkoutWeeksForm from "./workoutWeeksForm/index";
import AddIcon from "@material-ui/core/SvgIcon/SvgIcon";
import GeneralDetails from "../workout/workoutForm/GeneralDetails";
import ContentFormActionBar from "../ContentFormActionBar";
import useOrganization from "../../../hooks/useOrganization";
import useEntityList from "../../../hooks/useEntityList";
import {exercisesApi, workoutApi} from "../../../api/common";
import useGlobalCachedResourceList from "../../../api/useGlobalCachedResourceList";
import buildKeyToNameMap from "../../../utils/buildKeyToNameMap";

const ProgramTemplateForm =  ({
  handleClose,
  handleCreate,
  handleUpdate,
  defaultState,
  mode,
  loading,
  formErrorMessage,
} : {
  handleClose: () => void,
  handleCreate?: (data: ProgramTemplate) => Promise<void>,
  handleUpdate?: (data: ProgramTemplate) => Promise<void>,
  defaultState?: ProgramTemplate,
  mode: 'Create' | 'Edit',
  loading: boolean,
  formErrorMessage?: string,
}) => {
  const { organizationId } = useOrganization();
  const disableForm = defaultState?.id ? defaultState?.organizationId !== organizationId : false;

  const { data: workouts } = useEntityList<Workout>(workoutApi.list);
  const { data: exercises } = useEntityList<Exercise>(exercisesApi.list);
  const { data: equipmentList } = useGlobalCachedResourceList<Equipment>('equipmentCache');
  const equipment = buildKeyToNameMap(equipmentList);

  const { register, handleSubmit: useFormHandleSubmit, watch, getValues, setValue, control, formState } = useForm<ProgramTemplate>(
    mode === 'Edit' && defaultState !== undefined ?
      { defaultValues: defaultState } :
      { defaultValues: { difficultyLevel: 1 } }
  );

  const { groups: workoutWeeks, addGroup, removeGroup, copyGroup, updateGroup, isGroupDirty } = useGroupState<WorkoutWeek>(
    { days: [] },
    mode === 'Edit' ? defaultState?.workoutWeeks : [ { days: [] } ]
  );

  const isFormDirty = formState.isDirty || isGroupDirty;

  const onSubmit = async (raw: ProgramTemplate) => {
    const data = { ...raw };

    if (mode === 'Edit') {
      handleUpdate && handleUpdate({
        ...defaultState,
        ...data,
        workoutWeeks,
      } as ProgramTemplate);
    } else if (mode === 'Create') {
      handleCreate && handleCreate({
        ...data,
        workoutWeeks,
      } as ProgramTemplate);
    }
  };

  return (
    <Box>
      <Typography variant='h3' gutterBottom>
        {mode === 'Edit' ? 'Edit Program Template' : 'Create a New Program Template'}
      </Typography>

      <LanguageSelect<ProgramTemplate> watch={watch} />

      <GeneralDetails
        register={register}
        getValues={getValues}
        setValue={setValue}
        control={control}
        disabled={disableForm}
        withRestTimes={false}
      />

      <WorkoutWeeksForm
        workoutWeeks={workoutWeeks}
        deleteGroup={removeGroup}
        copyGroup={copyGroup}
        updateGroup={updateGroup}
        workouts={workouts}
        exercises={exercises}
        equipment={equipment}
        disabled={disableForm}
      />

      <Box
        display='flex'
        alignItems='center'
        justifyContent='center'
        my={4}
      >
        <Button
          color='primary'
          disabled={loading || disableForm}
          onClick={addGroup}
          size='large'
          fullWidth
          variant='outlined'
          startIcon={<AddIcon />}
        >
          Add Week
        </Button>
      </Box>

      <ContentFormActionBar
        onSubmit={useFormHandleSubmit(onSubmit)}
        onClose={handleClose}
        archived={defaultState?.archived}
        handleArchive={() => handleUpdate && handleUpdate({...defaultState, archived: !defaultState?.archived} as ProgramTemplate)}
        disabled={loading || disableForm}
        onSubmitLabel={mode === 'Edit' ? 'Update Program' : 'Create Program'}
        entityName='Program'
        mode={mode}
        isFormDirty={isFormDirty}
      />

      {
        formErrorMessage &&
        <Alert severity='error' style={{marginTop: 24}}>
          {formErrorMessage}
        </Alert>
      }
    </Box>
  )
};

export default ProgramTemplateForm;
