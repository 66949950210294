import React, {useState} from 'react';
import {Button, Card, CardContent, Typography} from '@material-ui/core';
import BillingAddressDialog from "../dialogs/BillingAddressDialog";
import {
  BillingDetailsErrors,
  BillingDetailsInterface,
  defaultBillingDetailsErrors
} from "../utils/BillingDetailsInterface";
import {BillingDetails} from "myfitworld-model";

const BillingAddressCard = ({defaultState, billingDetails, onSave} : Props) => {
  const [openDialog, setOpenDialog] = useState<boolean>(false);
  const [ errors, setErrors ] = useState<BillingDetailsErrors>(defaultBillingDetailsErrors);

  const line1 = [ defaultState?.address || "", defaultState?.postalCode || ""].filter(x => x.length > 0).join(', ');
  const line2 = [ defaultState?.city || "", defaultState?.countryCode || "", defaultState?.stateCode || "", defaultState?.taxID || "" ].filter(x => x.length > 0).join(', ');

  return (
    <Card variant='outlined' style={{ width: 435, height: 220 }}>
      <CardContent>
        <Typography color='textSecondary' variant='body2' gutterBottom>
          Billed to:
        </Typography>
        <Typography variant='body1'>
          {line1}.
        </Typography>
        <Typography variant='body1'>
          {line2}.
        </Typography>
        <Button color="primary" onClick={() => setOpenDialog(true)}>
          Change Billing Address
        </Button>
      </CardContent>
      <BillingAddressDialog
        open={openDialog}
        action="UPDATE"
        defaultState={defaultState}
        billingDetails={billingDetails}
        onSave={onSave}
        onClose={() => setOpenDialog(false)}
        errors={errors}
        setErrors={setErrors}
      />
    </Card>
  );
};

export default BillingAddressCard;

interface Props {
  defaultState: BillingDetailsInterface,
  billingDetails?: BillingDetails,
  onSave: (data: BillingDetailsInterface) => void
}

